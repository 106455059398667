import { getCTSDK } from 'services/ctClient'
import { showToastError } from 'hooks/useToast'
import { useGetMSAccount } from 'integrations/email/microsoft/queries/ms.user.queries'
import MSAuthButton from './components/MSAuthButton'

const ctSDK = getCTSDK()

const MSAuthButtonContainer = () => {
    const { isLoading: isLoadingAccount, data, refetch } = useGetMSAccount()

    const isConnected =
        !!data?.account?.token?.isValid &&
        !!data?.account?.subscriptions?.length

    const _onConnectToMS = async () => {
        try {
            const resp = await ctSDK.get('/integrations/microsoft/authCodeUrl')

            const url = resp.data?.url
            if (!url) {
                showToastError('Error getting Microsoft login page')
                return
            }

            const popup = window.open(url, '_blank', 'width=650,height=600')
            if (!popup) {
                showToastError('Microsoft login popup is blocked by browser')
                return
            }

            const timer = setInterval(() => {
                if (popup.closed) {
                    clearInterval(timer)
                    refetch()
                }
            }, 1000)

            popup.focus()
        } catch (error) {
            showToastError('Error connecting to Microsoft')
        }
    }

    return (
        <MSAuthButton
            isLoading={isLoadingAccount}
            isConnected={isConnected}
            onConnectToMS={_onConnectToMS}
        />
    )
}

export default MSAuthButtonContainer
