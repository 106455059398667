import Select from 'components/core/Select'
import { EtaStatusType } from 'types/eta.types'

const ETA_STATUS_OPTIONS: { value: EtaStatusType; label: string }[] = [
    {
        value: 'onTime',
        label: 'On Time',
    },
    {
        value: 'late',
        label: 'Late',
    },
]

type EtaStatusSelectProps = {
    status?: EtaStatusType
    onChange: (status: EtaStatusType) => void
}

export const EtaStatusSelect = ({ status, onChange }: EtaStatusSelectProps) => {
    const _onChange = (value: string) => {
        onChange(value as EtaStatusType)
    }

    return (
        <Select
            value={status}
            options={ETA_STATUS_OPTIONS}
            onSelectedOptionChanged={_onChange}
        />
    )
}
