import Row from 'components/core/Row'
import Typography from 'components/core/Typography'
import { ORDER_STATUS_LABELS } from 'constants/order.constants'
import { SPACING } from 'constants/spacing.constants'
import { Circle, CircleCheck, Timer } from 'lucide-react'
import { OrderStatus } from 'types/order.types'

export type OrderStatusChipProps = {
    status: OrderStatus
}

const ICON_MAP: Record<OrderStatus, React.ReactNode> = {
    new: <Circle size={SPACING.p2} />,
    // quoting: <Circle size={SPACING.p2} />,
    booked: <Circle size={SPACING.p2} />,
    inTransit: <Timer size={SPACING.p2} />,
    delivered: <CircleCheck size={SPACING.p2} />,
}

export const OrderStatusChip = (props: OrderStatusChipProps) => {
    return (
        <Row className="flex items-center space-x-2">
            {ICON_MAP[props.status]}
            <Typography shouldTruncate color="primary" variant="body2">
                {ORDER_STATUS_LABELS[props.status]}
            </Typography>
        </Row>
    )
}
