import styled from '@emotion/styled'
import { pxToRem } from 'utils/rem.utils'
import { ACTIONS_HEIGHT, HEADER_HEIGHT } from '../constants/dialog2.constants'

type DialogSize = 'md' | 'lg' | 'xl'

const StyledDialogContent = styled.div<{ size?: DialogSize }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;

    ${(p) => {
        switch (p.size) {
            case 'md':
                return ``
            case 'lg':
                return `
                    height: calc(90vh - ${pxToRem(
                        HEADER_HEIGHT + ACTIONS_HEIGHT
                    )});
                `
            case 'xl':
                return `
                    height: calc(90vh - ${pxToRem(
                        HEADER_HEIGHT + ACTIONS_HEIGHT
                    )});
                `
            default:
                return ``
        }
    }}
`

type DialogContentProps = {
    size?: DialogSize
    children: React.ReactNode
}

const DialogContent = ({ size, children }: DialogContentProps) => {
    return <StyledDialogContent size={size}>{children}</StyledDialogContent>
}

export default DialogContent
