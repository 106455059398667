import { useState } from 'react'
import { UsersBoard } from './components/UsersBoard'
import { useFindUsers } from 'hooks/useFindUsers'
import { TABLE_ROW_LIMIT, useBoardv2 } from 'components/composite/Board'

export const UsersContainer = () => {
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)

    const {
        isLoading,
        isLastPage,
        isFirstPage,
        isRefetching,
        total,
        offset,
        selectedRowIds,
        rows,
        refetchRows,
        onNextPage,
        onSelectRow,
        onPreviousPage,
        onSelectAllRows,
    } = useBoardv2({ useRowsFn: useFindUsers })

    return (
        <UsersBoard
            isLoading={isLoading}
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
            isRefetching={isRefetching}
            isCreateDialogOpen={isCreateDialogOpen}
            limit={TABLE_ROW_LIMIT}
            offset={offset}
            numRows={total}
            rows={rows}
            selectedRowIds={selectedRowIds}
            onNextPage={onNextPage}
            onRefetchRows={refetchRows}
            onSelectRow={onSelectRow}
            onPreviousPage={onPreviousPage}
            onSelectAllRows={onSelectAllRows}
            onToggleCreateDialogOpen={setIsCreateDialogOpen}
        />
    )
}
