import { Order } from 'types/order.types'
import Card from 'components/composite/Card'
import Typography from 'components/core/Typography'

type CarrierInfoProps = { order?: Pick<Order, 'carrierName'> }

export const CarrierInfo = ({ order }: CarrierInfoProps) => {
    if (!order) return null

    return (
        <Card title="Carrier">
            <Typography color="primary" variant="body1">
                {order?.carrierName || '--'}
            </Typography>
        </Card>
    )
}
