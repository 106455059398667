import { useMutation } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { Document } from 'types/document.types'

const ctSDK = getCTSDK()

export const useUpdateDocumentById = () => {
    return useMutation(
        ['useUpdateDocumentById'],
        ({ id, data }: { id: string; data: Partial<Document> }) =>
            ctSDK.put(`/documents/${id}`, { ...data })
    )
}
