import { SelectAsync, SelectOption } from 'components/core/Select'
import { LucideIcon, Truck } from 'lucide-react'
import { useState } from 'react'
import { getCTSDK } from 'services/ctClient'
import { Carrier } from 'types/carrier.types'

const sdk = getCTSDK()

export type CarrierAsyncSelectProps = {
    shouldPortalMenu?: boolean
    shouldFocusOnMount?: boolean
    label?: string
    placeholder?: string
    icon?: LucideIcon
    error?: string
    onChangeCarrier: (carrierId: string, carrier?: Carrier) => void
}

export const CarrierAsyncSelect = ({
    shouldPortalMenu,
    shouldFocusOnMount,
    label,
    placeholder = 'Search carriers',
    icon = Truck,
    error,
    onChangeCarrier,
}: CarrierAsyncSelectProps) => {
    const [carriers, setCarriers] = useState<Carrier[]>([])

    const _onFetchCarrierOptions = async (
        name: string
    ): Promise<SelectOption[]> => {
        try {
            const resp = await sdk.get<{ carriers: Carrier[] }>('/carriers', {
                params: {
                    limit: 10,
                    offset: 0,
                    name,
                },
            })

            const carriers = resp.data?.carriers || []

            const fetchedOptions = carriers.map((c) => ({
                value: c.id,
                label: c.name,
            }))

            setCarriers(carriers)

            return fetchedOptions
        } catch (error) {
            console.error('Error fetching carrier options:', error)
            return []
        }
    }

    const _onSelectedCarriersChanged = (options: SelectOption[]) => {
        const carrierOption = options[0]
        const carrierId = carrierOption.value

        const selectedCarrier = carriers.find((c) => c.id === carrierId)
        onChangeCarrier(carrierId, selectedCarrier)
    }

    return (
        <SelectAsync
            shouldPortalMenu={shouldPortalMenu}
            shouldFocusOnMount={shouldFocusOnMount}
            label={label}
            placeholder={placeholder}
            Icon={icon}
            error={error}
            onFetchOptions={_onFetchCarrierOptions}
            onSelectedOptionsChanged={_onSelectedCarriersChanged}
        />
    )
}
