import { AutomationActionStatus } from 'types/automationAction.types'

export const isAutomationActionCompleted = (
    actionStatus?: AutomationActionStatus
) => {
    return actionStatus && actionStatus === 'completed'
}

export const isAutomationActionScheduled = (
    actionStatus?: AutomationActionStatus
) => {
    return actionStatus && actionStatus === 'scheduled'
}

export const isAutomationActionManualCompleted = (
    actionStatus?: AutomationActionStatus
) => {
    return actionStatus && actionStatus === 'manualCompleted'
}
