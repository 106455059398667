import { Route } from 'react-router-dom'
import Login from 'publicPages/modules/Login'
import {
    LoginForm,
    LoginLayout,
    LoginSuccess,
} from 'publicPages/modules/LoginForm'
import Register from 'publicPages/modules/Register'
import Tracking from 'publicPages/modules/Tracking'

export const PublicRoutes = () => {
    return (
        <>
            <Route path="/user/login" element={<LoginLayout />}>
                <Route index element={<LoginForm />} />
                <Route path="success" element={<LoginSuccess />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/tracking" element={<Tracking />} />
        </>
    )
}
