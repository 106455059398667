import Typography from 'components/core/Typography'
import { LoginFormRoot } from './LoginFormRoot'
import { LoginFormSection } from './LoginFormSection'
import { useSearchParams } from 'react-router-dom'

export const LoginSuccess = () => {
    const [searchParams] = useSearchParams()
    const email = searchParams.get('email')

    return (
        <LoginFormRoot>
            <LoginFormSection>
                <Typography color="primary" variant="h2">
                    Login to Cartage
                </Typography>
                <Typography color="secondary" variant="body1">
                    An email was sent {email ? `to ${email}` : ''} with a login
                    link if it is associated with a Cartage account.
                </Typography>
            </LoginFormSection>
        </LoginFormRoot>
    )
}
