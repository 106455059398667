import styled from '@emotion/styled'
import Button from 'components/core/Button'
import TextInput from 'components/core/TextInput'
import AnchorTag from 'components/core/AnchorTag'
import Typography from 'components/core/Typography'
import { BASE_TOKENS } from 'constants/color.constants'
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'constants/urls'
import { SPACING } from 'constants/spacing.constants'
import { getImageUrl } from 'utils/getImageUrl'

const emptyFunc = () => undefined

const StyledRegister = styled.div`
    height: 100%;
    display: flex;

    .register__left {
        width: 50%;
        background-color: ${BASE_TOKENS.grey[900]};
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;
        padding-left: 3.2rem;
        padding-right: 3.2rem;

        .register__left__logo {
            display: flex;
            align-items: center;
            column-gap: ${SPACING.p1};

            .register__left__logo__image {
                height: 2.4rem;
            }

            .register__left__logo__text {
                color: ${BASE_TOKENS.grey[0]};
                font-family: Inter;
                font-size: 3rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin: 0;
            }
        }
    }

    .register__right {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 3.6rem;
        padding-bottom: 2.4rem;

        .register__right__register {
            display: flex;
            justify-content: flex-end;
            padding-left: 3.6rem;
            padding-right: 3.6rem;
        }

        .register__right__form {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 2.4rem;
            padding-left: 8.8rem;
            padding-right: 8.8rem;

            .register__right__form__text {
                display: flex;
                flex-direction: column;
                align-items: center;
                row-gap: 1.2rem;
            }

            .register__right__form__inputs {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                row-gap: 1.6rem;
            }
        }
    }
`

type RegisterProps = {
    isSubmitting: boolean
    orgId: string
    email: string
    password: string
    lastName: string
    firstName: string
    repeatedPassword: string
    onRegister: () => void
    onGoToLogin: () => void
    onChangeEmail: (value: string) => void
    onChangeOrgId: (value: string) => void
    onChangePassword: (value: string) => void
    onChangeLastName: (value: string) => void
    onChangeFirstName: (value: string) => void
    onChangeRepeatedPassword: (value: string) => void
}

const Register = ({
    isSubmitting,
    email,
    orgId,
    password,
    lastName,
    firstName,
    repeatedPassword,
    onRegister = emptyFunc,
    onGoToLogin = emptyFunc,
    onChangeEmail = emptyFunc,
    onChangeOrgId = emptyFunc,
    onChangePassword = emptyFunc,
    onChangeLastName = emptyFunc,
    onChangeFirstName = emptyFunc,
    onChangeRepeatedPassword = emptyFunc,
}: RegisterProps) => {
    return (
        <StyledRegister>
            <div className="register__left">
                <div className="register__left__logo">
                    <img
                        alt="Logo"
                        src={getImageUrl('shortLogoWhite.png')}
                        className="register__left__logo__image"
                    />
                    <p className="register__left__logo__text">Cartage</p>
                </div>
            </div>
            <div className="register__right">
                <div className="register__right__register">
                    <Button variant="secondary" onClick={onGoToLogin}>
                        Login
                    </Button>
                </div>
                <div className="register__right__form">
                    <div className="register__right__form__text">
                        <Typography color="primary" variant="h2">
                            Create an account
                        </Typography>
                        <Typography color="secondary" variant="body1">
                            Enter your information below to create your account
                        </Typography>
                    </div>
                    <div className="register__right__form__inputs">
                        <TextInput
                            value={orgId}
                            placeholder="Organization ID"
                            onChange={onChangeOrgId}
                        />
                        <TextInput
                            value={firstName}
                            placeholder="First name"
                            onChange={onChangeFirstName}
                        />
                        <TextInput
                            value={lastName}
                            placeholder="Last name"
                            onChange={onChangeLastName}
                        />
                        <TextInput
                            value={email}
                            placeholder="name@example.com"
                            onChange={onChangeEmail}
                        />
                        <TextInput
                            isPassword
                            value={password}
                            placeholder="Password"
                            onChange={onChangePassword}
                        />
                        <TextInput
                            isPassword
                            value={repeatedPassword}
                            placeholder="Repeat password"
                            onChange={onChangeRepeatedPassword}
                        />
                        <Button
                            isDisabled={
                                !orgId ||
                                !email ||
                                !firstName ||
                                !lastName ||
                                !password ||
                                password !== repeatedPassword
                            }
                            isSubmitting={isSubmitting}
                            isFullWidth
                            variant="primary"
                            onClick={onRegister}
                        >
                            Continue
                        </Button>
                    </div>
                    <div>
                        <Typography color="secondary" variant="body2">
                            By clicking continue, you agree to our{' '}
                            <AnchorTag
                                rel="noreferrer noopener"
                                href={TERMS_OF_USE_URL}
                                color="secondary"
                                target="_blank"
                            >
                                Terms of Use
                            </AnchorTag>{' '}
                            and{' '}
                            <AnchorTag
                                rel="noreferrer noopener"
                                href={PRIVACY_POLICY_URL}
                                color="secondary"
                                target="_blank"
                            >
                                Privacy Policy
                            </AnchorTag>
                            .
                        </Typography>
                    </div>
                </div>
            </div>
        </StyledRegister>
    )
}

export default Register
