import { EquipmentType } from 'types/equipment.types'

export const EQUIPMENT_OPTIONS: { value: EquipmentType; label: string }[] = [
    {
        value: 'flatdeck',
        label: 'Flatdeck',
    },
    {
        value: 'stepdeck',
        label: 'Stepdeck',
    },
    {
        value: 'dryVan',
        label: 'Dry Van',
    },
    {
        value: 'reefer',
        label: 'Reefer',
    },
    {
        value: 'lowbed',
        label: 'Lowbed',
    },
    {
        value: 'drayage',
        label: 'Drayage',
    },
]
