import {
    BOLEntity,
    PODEntity,
    DocumentEntity,
    CarrierInvoiceEntity,
    MultipleCarrierInvoicesEntity,
    BulkCarrierInvoiceEntity,
} from 'types/document.types'

export const isCarrierInvoiceEntity = (
    entity: DocumentEntity
): entity is CarrierInvoiceEntity => {
    return entity.type === 'carrier_invoice'
}

export const isBulkCarrierInvoiceEntity = (
    entity: DocumentEntity
): entity is BulkCarrierInvoiceEntity => {
    return entity.type === 'bulk_carrier_invoice'
}

export const isMultipleCarrierInvoicesEntity = (
    entity: DocumentEntity
): entity is MultipleCarrierInvoicesEntity => {
    return entity.type === 'multiple_carrier_invoices'
}

export const isBOLEntity = (entity: DocumentEntity): entity is BOLEntity => {
    return entity.type === 'bill_of_lading'
}

export const isPODEntity = (entity: DocumentEntity): entity is PODEntity => {
    return entity.type === 'proof_of_delivery'
}
