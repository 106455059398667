import { useState } from 'react'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { showToastError, showToastSuccess } from 'hooks/useToast'
import Pricing from './components/Pricing'
import { AvailableRate, Location } from './types/pricing.types'
import { usePostQuote } from './mutations/pricing.mutations'
import { getCTSDK } from 'services/ctClient'

const CT_HANDLING_FEE = 0.1

const ctSDK = getCTSDK()

const getMothershipPlace = async (placeId: string): Promise<Location> => {
    const place = await axios.get(
        `https://services.mothership.com/axel/location/place/${placeId}`
    )

    return place.data
}

export type Rate = {
    id: string
    ctFee: number
    carrierRate: number
    carrierName?: string
    carrierScore: number
    type?: 'lowest' | 'best' | 'fastest'
    etaDays: number
}

const PricingContainer = () => {
    const [origin, setOrigin] = useState<Location>()
    const [destination, setDestination] = useState<Location>()
    const [numPallets, setNumPallets] = useState<number>()
    const [weight, setWeight] = useState<number>()
    const [length, setLength] = useState<number>()
    const [width, setWidth] = useState<number>()
    const [height, setHeight] = useState<number>()
    const [rates, setRates] = useState<Rate[]>([])
    const [featuredRates, setFeaturedRates] = useState<Rate[]>([])
    const [selectedRate, setSelectedRate] = useState<Rate>()
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false)
    const [poFileUrl, setPOFileUrl] = useState<string>()

    const { isLoading: isSubmitting, mutateAsync: postQuote } = usePostQuote({
        onSuccess: (resp) => {
            const availableRatesMap = resp.data?.data?.availableRates
            const featuredRatesMap = resp.data?.data?.rates?.standard

            const featuredRateIds = new Set()
            if (featuredRatesMap) {
                if (featuredRatesMap.lowest?.id) {
                    featuredRateIds.add(featuredRatesMap.lowest.id)
                }
                if (featuredRatesMap.bestValue?.id) {
                    featuredRateIds.add(featuredRatesMap.bestValue.id)
                }
                if (featuredRatesMap.fastest?.id) {
                    featuredRateIds.add(featuredRatesMap.fastest.id)
                }
            }

            if (availableRatesMap) {
                let availableRates = Object.values(availableRatesMap)
                    .filter(
                        (rate: AvailableRate) => !featuredRateIds.has(rate.id)
                    )
                    .map((rate: AvailableRate) => ({
                        id: rate.id,
                        ctFee: rate.baseQuotePrice * CT_HANDLING_FEE,
                        carrierRate: rate.baseQuotePrice,
                        carrierName: rate.carrierInfo?.name,
                        carrierScore: Math.random() < 0.5 ? 4 : 5,
                        etaDays: rate.days,
                        estimatedDeliveryDate: new Date(
                            rate.estimatedDeliveryDate
                        ),
                    }))

                availableRates = availableRates.sort(
                    (a, b) =>
                        a.estimatedDeliveryDate.getTime() -
                        b.estimatedDeliveryDate.getTime()
                )

                setRates(availableRates)
            }

            if (featuredRatesMap) {
                const featuredRates: Rate[] = []

                if (featuredRatesMap.lowest?.id) {
                    featuredRates.push({
                        id: featuredRatesMap.lowest.id,
                        ctFee:
                            featuredRatesMap.lowest.baseQuotePrice *
                            CT_HANDLING_FEE,
                        carrierRate: featuredRatesMap.lowest.baseQuotePrice,
                        carrierName: featuredRatesMap.lowest.carrierInfo?.name,
                        carrierScore: Math.random() < 0.5 ? 4 : 5,
                        type: 'lowest',
                        etaDays: featuredRatesMap.lowest.days,
                    })
                }
                if (featuredRatesMap.bestValue?.id) {
                    featuredRates.push({
                        id: featuredRatesMap.bestValue.id,
                        ctFee:
                            featuredRatesMap.bestValue.baseQuotePrice *
                            CT_HANDLING_FEE,
                        carrierRate: featuredRatesMap.bestValue.baseQuotePrice,
                        carrierName:
                            featuredRatesMap.bestValue.carrierInfo?.name,
                        carrierScore: Math.random() < 0.5 ? 4 : 5,
                        type: 'best',
                        etaDays: featuredRatesMap.bestValue.days,
                    })
                }
                if (featuredRatesMap.fastest?.id) {
                    featuredRates.push({
                        id: featuredRatesMap.fastest.id,
                        ctFee:
                            featuredRatesMap.fastest.baseQuotePrice *
                            CT_HANDLING_FEE,
                        carrierRate: featuredRatesMap.fastest.baseQuotePrice,
                        carrierName: featuredRatesMap.fastest.carrierInfo?.name,
                        carrierScore: Math.random() < 0.5 ? 4 : 5,
                        type: 'fastest',
                        etaDays: featuredRatesMap.fastest.days,
                    })
                }

                setFeaturedRates(featuredRates)
            }
        },
    })

    const _onChangeOrigin = async (placeId?: string) => {
        if (!placeId) {
            return
        }

        try {
            const place = await getMothershipPlace(placeId)
            setOrigin(place)
        } catch {
            showToastError('Error getting address details')
        }
    }

    const _onChangeDestination = async (placeId?: string) => {
        if (!placeId) {
            return
        }

        try {
            const place = await getMothershipPlace(placeId)
            setDestination(place)
        } catch {
            showToastError('Error getting address details')
        }
    }

    const _onSelectQuote = (rateId: string) => {
        if (rateId === selectedRate?.id) {
            setSelectedRate(undefined)
            return
        }

        const rate = [...rates, ...featuredRates].find(
            (rate) => rate.id === rateId
        )
        setSelectedRate(rate)
    }

    const _onGetQuotes = async () => {
        const cargoId = uuidv4()

        if (!origin || !destination) {
            return
        }
        if (!numPallets || !weight || !length || !width || !height) {
            return
        }

        setRates([])
        setSelectedRate(undefined)

        try {
            await postQuote({
                shipment: {
                    pickupDate: new Date().toISOString(),
                    pickupLocation: origin,
                    deliveryLocation: destination,
                    cargo: {
                        [cargoId]: {
                            length,
                            width,
                            height,
                            quantity: numPallets,
                            weight,
                            type: 'Pallet',
                        },
                    },
                },
            })
        } catch {
            showToastError('Error getting quote')
        }
    }

    const _onUploadPO = async (files: File[]) => {
        if (!files.length) {
            showToastError('No files to upload')
            return
        }

        try {
            const formData = new FormData()

            for (const file of files) {
                formData.append('files', file)
            }

            const resp = await ctSDK.post<{
                origin: Location
                destination: Location
                details: {
                    numPallets?: number
                    weight?: number
                    length?: number
                    width?: number
                    height?: number
                }
                poFileUrl?: string
            }>(`/purchaseOrders/parse`, formData)

            const { data } = resp || {}
            const { origin, destination, details, poFileUrl } = data || {}
            const { numPallets, weight, length, width, height } = details || {}

            if (origin) {
                setOrigin(origin)
            }
            if (destination) {
                setDestination(destination)
            }
            if (numPallets) {
                setNumPallets(numPallets)
            }
            if (weight) {
                setWeight(weight)
            }
            if (length) {
                setLength(length)
            }
            if (width) {
                setWidth(width)
            }
            if (height) {
                setHeight(height)
            }
            if (poFileUrl) {
                setPOFileUrl(poFileUrl)
            }

            setIsUploadDialogOpen(false)
            showToastSuccess('Purchase order uploaded')

            if (
                origin &&
                destination &&
                numPallets &&
                weight &&
                length &&
                width &&
                height
            ) {
                const cargoId = uuidv4()

                await postQuote({
                    shipment: {
                        pickupDate: new Date().toISOString(),
                        pickupLocation: origin,
                        deliveryLocation: destination,
                        cargo: {
                            [cargoId]: {
                                length,
                                width,
                                height,
                                quantity: numPallets,
                                weight,
                                type: 'Pallet',
                            },
                        },
                    },
                })
            }
        } catch {
            showToastError('Error uploading purchase order')
        }
    }

    const isDisabled =
        !origin ||
        !destination ||
        !numPallets ||
        !weight ||
        !length ||
        !width ||
        !height

    return (
        <Pricing
            isDisabled={isDisabled}
            isSubmitting={isSubmitting}
            isUploadDialogOpen={isUploadDialogOpen}
            poFileUrl={poFileUrl}
            numPallets={numPallets}
            weight={weight}
            length={length}
            width={width}
            height={height}
            selectedOriginPlaceId={origin?.placeId}
            selectedDestinationPlaceId={destination?.placeId}
            allRates={rates}
            featuredRates={featuredRates}
            selectedRate={selectedRate}
            onChangeNumPallets={setNumPallets}
            onChangeWeight={setWeight}
            onChangeLength={setLength}
            onChangeWidth={setWidth}
            onChangeHeight={setHeight}
            onChangeOrigin={_onChangeOrigin}
            onChangeDestination={_onChangeDestination}
            onGetQuotes={_onGetQuotes}
            onSelectQuote={_onSelectQuote}
            onUploadPO={_onUploadPO}
            onChangeIsUploadDialogOpen={setIsUploadDialogOpen}
        />
    )
}

export default PricingContainer
