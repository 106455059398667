import styled from '@emotion/styled'

const StyledChip = styled.div<{
    textColor: string
    borderColor: string
    backgroundColor: string
}>`
    height: 2.4rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
    font-size: 1.2rem;
    color: ${(p) => p.textColor};
    border: 0.1rem solid ${(p) => p.borderColor};
    background-color: ${(p) => p.backgroundColor};
    font-family: Inter;
    font-size: 1.2rem;
    line-height: 2rem;
    letter-spacing: -0.04rem;
    font-weight: 400;
`

type CommonChipProps = {
    label: string
    textColor: string
    borderColor: string
    backgroundColor: string
}

const CommonChip = ({
    label,
    textColor,
    borderColor,
    backgroundColor,
}: CommonChipProps) => {
    return (
        <StyledChip
            textColor={textColor}
            borderColor={borderColor}
            backgroundColor={backgroundColor}
        >
            {label}
        </StyledChip>
    )
}

export default CommonChip
