import { useMutation } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { User } from 'types/user.types'

const ctSDK = getCTSDK()

export const useLoginOld = () => {
    return useMutation((params: { email: string; password: string }) =>
        ctSDK.post<{ token: string; refreshToken: string }>('/loginOld', params)
    )
}

export const useLogin = () => {
    return useMutation((params: { email: string }) =>
        ctSDK.post<{ token: string; refreshToken: string }>('/login', params)
    )
}

export const useMagicLinkLogin = () => {
    return useMutation((params: { email: string; loginToken: string }) =>
        ctSDK.post<{ token: string; refreshToken: string }>(
            '/magicLinkLogin',
            params
        )
    )
}

export const useSwitchOrg = () => {
    return useMutation((orgId: string) =>
        ctSDK.post<{ token: string; refreshToken: string }>(
            '/users/orgSwitch',
            {
                orgId,
            }
        )
    )
}

export const useRegister = () => {
    return useMutation(
        ({
            email,
            orgId,
            password,
            firstName,
            lastName,
        }: {
            email: string
            orgId: string
            password: string
            firstName: string
            lastName: string
        }) =>
            ctSDK.post<{ token: string }>('/register', {
                email,
                orgId,
                password,
                firstName,
                lastName,
            })
    )
}

export const useCreateUser = () => {
    return useMutation((data: Partial<User>) =>
        ctSDK.post<{ carrier: User }>('/users', {
            data,
        })
    )
}
