import { tableColumns } from '../users.columns'
import { User } from 'types/user.types'
import { CreateUserDialog } from 'dialogs/CreateUserDialog'
import Board from 'components/composite/Board'
import Button from 'components/core/Button'

const emptyFunc = () => undefined

type UsersBoardProps = {
    isLoading: boolean
    isLastPage: boolean
    isFirstPage: boolean
    isRefetching: boolean
    isCreateDialogOpen: boolean
    limit: number
    offset: number
    numRows: number
    rows: User[]
    selectedRowIds: string[]
    onNextPage: () => void
    onRefetchRows: () => void
    onPreviousPage: () => void
    onSelectAllRows: (isChecked: boolean) => void
    onToggleCreateDialogOpen: (isOpen: boolean) => void
    onSelectRow: ({
        isChecked,
        id,
        index,
    }: {
        isChecked: boolean
        id: string
        index: number
    }) => void
}

export const UsersBoard = ({
    isLoading,
    isLastPage,
    isFirstPage,
    isRefetching,
    isCreateDialogOpen,
    limit,
    offset,
    numRows,
    rows,
    selectedRowIds,
    onNextPage = emptyFunc,
    onRefetchRows = emptyFunc,
    onSelectRow = emptyFunc,
    onPreviousPage = emptyFunc,
    onSelectAllRows = emptyFunc,
    onToggleCreateDialogOpen = emptyFunc,
}: UsersBoardProps) => {
    const _onToggleCreateDialogOpen = (isOpen: boolean) => () => {
        onToggleCreateDialogOpen(isOpen)
    }

    const tabs: [] = []

    const buttons = [
        <Button
            key="create"
            variant="primary"
            onClick={_onToggleCreateDialogOpen(true)}
        >
            Create
        </Button>,
    ]

    const subButtons: [] = []

    return (
        <>
            <CreateUserDialog
                isOpen={isCreateDialogOpen}
                onClose={_onToggleCreateDialogOpen(false)}
                onSuccess={onRefetchRows}
            />
            <Board
                isLoading={isLoading}
                isLastPage={isLastPage}
                isFirstPage={isFirstPage}
                isRefetching={isRefetching}
                limit={limit}
                offset={offset}
                header="Users"
                numRows={numRows}
                rows={rows}
                tabs={tabs}
                columns={tableColumns}
                buttons={buttons}
                subButtons={subButtons}
                selectedRowIds={selectedRowIds}
                onNextPage={onNextPage}
                onSelectRow={onSelectRow}
                onRefetchRows={onRefetchRows}
                onPreviousPage={onPreviousPage}
                onSelectAllRows={onSelectAllRows}
            />
        </>
    )
}
