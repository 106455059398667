import Select from 'components/core/Select'
import { TIME_OPTIONS } from './timeSelect.constants'

type TimeSelectProps = {
    label: string
    value?: string
    placeholder?: string
    onChange: (value: string) => void
}

export const TimeSelect = ({
    label,
    value,
    placeholder = 'HH:MM',
    onChange,
}: TimeSelectProps) => {
    return (
        <Select
            label={label}
            value={value}
            placeholder={placeholder}
            options={TIME_OPTIONS}
            onSelectedOptionChanged={onChange}
        />
    )
}
