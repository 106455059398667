import { useQuery } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { Receivable, ReceivableStatus } from 'types/receivable.types'

const ctSDK = getCTSDK()

export const useGetReceivablesStatusCounts = ({
    createdAtStartAt,
    inStatusIdsMap,
}: {
    createdAtStartAt?: string
    inStatusIdsMap: {
        [key: string]: ReceivableStatus[]
    }
}) => {
    return useQuery(
        ['useGetReceivablesStatusCounts', inStatusIdsMap, createdAtStartAt],
        async () =>
            await ctSDK.get<{ counts: { [key: string]: number } }>(
                '/receivables/statusCount',
                {
                    params: {
                        createdAtStartAt,
                        inStatusIdsMap: encodeURIComponent(
                            JSON.stringify(inStatusIdsMap)
                        ),
                    },
                }
            )
    )
}

export const useGetQueuedReceivables = () => {
    return useQuery(
        ['useGetQueuedReceivables'],
        async () =>
            await ctSDK.get<{ receivables: Receivable[] }>(
                '/receivables/queued'
            )
    )
}
