import { useMutation } from 'react-query'
import { getCTSDK } from 'services/ctClient'

const ctSDK = getCTSDK()

export const useProcessPayables = () => {
    const { isLoading, error, mutateAsync } = useMutation(
        (payableIds: string[]) =>
            ctSDK.post('/payables/process', { payableIds })
    )

    return { isSubmitting: isLoading, error, onProcess: mutateAsync }
}
