import styled from '@emotion/styled'
import { BoardColumn } from 'components/composite/Board'
import Row from 'components/core/Row'
import Typography from 'components/core/Typography'
import { SPACING } from 'constants/spacing.constants'
import { User, UserRole } from 'types/user.types'
import { getImageUrl } from 'utils/getImageUrl'

const Img = styled.img`
    height: ${SPACING.p2};
    width: ${SPACING.p2};
`

const RoleCell = styled(Row)`
    grid-gap: ${SPACING.sm};
    align-items: center;
`

export const tableColumns: BoardColumn[] = [
    {
        id: 'firstName',
        label: 'First Name',
        width: 80,
    },
    {
        id: 'lastName',
        label: 'Last Name',
        width: 80,
    },
    {
        id: 'email',
        label: 'Email',
        width: 80,
    },
    {
        id: 'role',
        label: 'Role',
        width: 80,
        renderCell: (user: User) => {
            let role: string = user.role

            if (user.role === 'user') {
                role = 'Ops User'
            } else if (role === 'customerUser') {
                role = 'Customer User'
            }

            return (
                <RoleCell>
                    <Typography color="primary" variant="body2">
                        {role}
                    </Typography>
                    {user.role === 'user' && (
                        <Img alt="Logo" src={getImageUrl('newShortLogo.png')} />
                    )}
                </RoleCell>
            )
        },
    },
    {
        id: 'customerName',
        label: 'Customer',
        width: 80,
    },
]
