import { ReactElement, ReactNode } from 'react'
import styled from '@emotion/styled'
import Typography from 'components/core/Typography'
import { DESIGN_TOKENS } from 'constants/color.constants'

const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    border: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};
    border-radius: 0.6rem;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    row-gap: 1.6rem;

    .card__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 1.6rem;

        .card__header__content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 0.6rem;

            .card__header__content__icon {
                margin-bottom: 0.4rem;
            }
        }
    }

    .card__inputs {
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
    }
`

type CardProps = {
    title: string
    subtitle?: string
    HeaderIcon?: ReactElement
    HeaderInput?: ReactElement
    children?: ReactNode
}

const Card = ({
    title,
    subtitle,
    HeaderIcon,
    HeaderInput,
    children,
}: CardProps) => {
    return (
        <StyledCard>
            <div className="card__header">
                <div className="card__header__content">
                    {HeaderIcon && (
                        <div className="card__header__content__icon">
                            {HeaderIcon}
                        </div>
                    )}
                    <Typography isBold color="primary" variant="body2">
                        {title}
                    </Typography>
                    {subtitle && (
                        <Typography color="secondary" variant="body2">
                            {subtitle}
                        </Typography>
                    )}
                </div>
                {HeaderInput && HeaderInput}
            </div>
            {children && children}
        </StyledCard>
    )
}

export default Card
