import { Payable } from 'types/payable.types'
import { getDateFromISOString } from 'utils/date.utils'
import { DERIVED_STATUS_LABELS } from '../payables.constants'

export const mapPayablesToCSVRows = (payables: Payable[]) => {
    return payables.map((payable) => {
        const {
            fileName,
            invoiceNumber,
            createdAt,
            status,
            carrierName,
            manifestNumber,
            shipperName,
            consigneeName,
            invoiceTotal,
            rrInvoiceTotal,
            // manifestStatus,
            // rrBillNumber,
            // notes,
        } = payable

        return {
            'File name': fileName,
            'Invoice number': invoiceNumber,
            'Upload date': getDateFromISOString(createdAt),
            Status: DERIVED_STATUS_LABELS[status],
            Carrier: carrierName,
            Manifest: manifestNumber,
            Shipper: shipperName,
            Consignee: consigneeName,
            'Invoice total': invoiceTotal,
            'RR total': rrInvoiceTotal,
            // 'Manifest Complete':
            //     manifestStatus &&
            //     [
            //         'completed',
            //         'bill-created',
            //         'bill-approved',
            //         'bill-paid',
            //     ].includes(manifestStatus)
            //         ? 'X'
            //         : '',
            // 'RR Total': rrInvoiceTotal,
            // 'Is Same':
            //     isValidTotalComparison && invoiceTotal === rrInvoiceTotal
            //         ? 'X'
            //         : '',
            // 'Is Different':
            //     isValidTotalComparison && invoiceTotal !== rrInvoiceTotal
            //         ? 'X'
            //         : '',
            // 'Is Less':
            //     isValidTotalComparison && invoiceTotal < rrInvoiceTotal
            //         ? 'X'
            //         : '',
            // 'Is Within +$10':
            //     isValidTotalComparison &&
            //     invoiceTotal - rrInvoiceTotal > 0 &&
            //     invoiceTotal - rrInvoiceTotal <= 10
            //         ? 'X'
            //         : '',
            // Notes: notes,
        }
    })
}
