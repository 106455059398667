import styled from '@emotion/styled'
import { MoreHorizontal } from 'lucide-react'
import { CircularProgress } from '@mui/material'
import Typography from 'components/core/Typography'
import IconButton from 'components/core/IconButton'
import Menu, { MenuItem } from 'components/core/Menu'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'

const emptyArr: [] = []

const StyledActionsCell = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${BASE_TOKENS.grey[500]};
`

const StyledLoadingContainer = styled.div`
    display: flex;
    padding-left: 0.8rem;
`

type ActionsCellProps = {
    isSubmitting: boolean
    options: { id: string; label: string; onClick: () => void }[]
}

const ActionsCell = ({
    isSubmitting,
    options = emptyArr,
}: ActionsCellProps) => {
    return (
        <StyledActionsCell>
            {isSubmitting && (
                <StyledLoadingContainer>
                    <CircularProgress color="inherit" size={20} />
                </StyledLoadingContainer>
            )}
            {!isSubmitting && options.length > 0 && (
                <Menu
                    trigger={
                        <IconButton>
                            <MoreHorizontal
                                size={16}
                                color={DESIGN_TOKENS.secondaryForeground}
                            />
                        </IconButton>
                    }
                >
                    {options.map((o) => {
                        return (
                            <MenuItem key={o.id} onClick={o.onClick}>
                                <Typography color="primary" variant="body2">
                                    {o.label}
                                </Typography>
                            </MenuItem>
                        )
                    })}
                </Menu>
            )}
        </StyledActionsCell>
    )
}

export default ActionsCell
