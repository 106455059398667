import { Package } from 'lucide-react'
import OrderEventControl from './OrderEventControl'

type OrderCreatedEventProps = {
    createdAt: string
}

const OrderCreatedEvent = ({ createdAt }: OrderCreatedEventProps) => {
    return (
        <OrderEventControl
            hasTopBorder
            title="Order created"
            timestamp={createdAt}
            Icon={Package}
        />
    )
}

export default OrderCreatedEvent
