import { useEffect, useState } from 'react'
import DocumentViewerDialog from 'dialogs/DocumentViewerDialog'
import { DocumentItem } from '../documentViewerDialogExtended.types'
import DocumentDataTable from './DocumentDataTable'

const emptyFunc = () => undefined

type DocumentViewerDialogExtendedProps = {
    isOpen: boolean
    isEditable: boolean
    isSubmitting: boolean
    isSaveButtonDisabled: boolean
    fileUrl: string
    documentItems: DocumentItem[]
    onSave: () => void
    onClose: () => void
    onChangeEntityType: ({
        entityId,
        entityType,
        pageNumber,
    }: {
        entityId: string
        entityType: string
        pageNumber: number
    }) => void
}

const DocumentViewerDialogExtended = ({
    isOpen,
    isEditable,
    isSubmitting,
    isSaveButtonDisabled,
    fileUrl,
    documentItems,
    onSave = emptyFunc,
    onClose = emptyFunc,
    onChangeEntityType = emptyFunc,
}: DocumentViewerDialogExtendedProps) => {
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight)

    useEffect(() => {
        const handleResize = () => {
            setViewportHeight(window.innerHeight)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const contentHeight = viewportHeight - 48 - 24 - 32 - 24 - 24 - 48 - 72

    return (
        <DocumentViewerDialog
            isEditable={isEditable}
            isSubmitting={isSubmitting}
            isSaveButtonDisabled={isSaveButtonDisabled}
            isOpen={isOpen}
            size="xl"
            fileUrl={fileUrl}
            onSave={onSave}
            onClose={onClose}
        >
            {documentItems.length > 0 && (
                <div className="documentViewerDialog__items">
                    <DocumentDataTable
                        height={contentHeight}
                        items={documentItems}
                        onChangeEntityType={onChangeEntityType}
                    />
                </div>
            )}
        </DocumentViewerDialog>
    )
}

export default DocumentViewerDialogExtended
