import { ReactNode } from 'react'
import styled from '@emotion/styled'
import Typography from 'components/core/Typography'
import { pxToRem } from 'utils/rem.utils'
import Tabs, { Tab, TabsList } from 'components/core/Tabs'
import { MODULE_HEADER_HEIGHT } from 'constants/app.constants'
import BoardDataTable from './components/BoardDataTable'
import { SPACER_HEIGHT, TABS_HEIGHT } from './board.constants'
import { BoardColumn, Tab as TabType } from './board.types'

const emptyFunc = () => undefined

const StyledBoard = styled.div`
    display: flex;
    flex-direction: column;

    .board__header {
        height: ${pxToRem(MODULE_HEADER_HEIGHT)};
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .board__header__buttons {
            display: flex;
            column-gap: 1.6rem;
        }
    }

    .board__tabs {
        height: ${pxToRem(TABS_HEIGHT)};
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .board__spacer {
        height: ${pxToRem(SPACER_HEIGHT)};
    }
`

type BoardProps<TRow extends { id: string }> = {
    isLoading: boolean
    isLastPage: boolean
    isFirstPage: boolean
    isRefetching: boolean
    isSubmitting?: boolean
    hasHeader?: boolean
    limit: number
    offset: number
    header: string
    numRows: number
    selectedTabId?: string
    tabs: TabType[]
    rows: TRow[]
    columns: BoardColumn[]
    buttons: ReactNode[]
    subButtons?: ReactNode[]
    selectedRowIds: string[]
    onNextPage: () => void
    onClickTab?: (tabId: string) => void
    onRefetchRows?: () => void
    onPreviousPage: () => void
    onSelectAllRows: (isChecked: boolean) => void
    onSelectRow: ({
        isChecked,
        id,
        index,
    }: {
        isChecked: boolean
        id: string
        index: number
    }) => void
}

const Board = <TRow extends { id: string }>({
    isLoading,
    isLastPage,
    isFirstPage,
    isRefetching,
    isSubmitting,
    hasHeader = true,
    limit,
    offset,
    header,
    numRows,
    selectedTabId,
    tabs,
    rows,
    columns,
    buttons,
    subButtons = [],
    selectedRowIds,
    onNextPage = emptyFunc,
    onClickTab = emptyFunc,
    onSelectRow = emptyFunc,
    onRefetchRows = emptyFunc,
    onPreviousPage = emptyFunc,
    onSelectAllRows = emptyFunc,
}: BoardProps<TRow>) => {
    return (
        <StyledBoard>
            {hasHeader && (
                <div className="board__header">
                    <Typography color="primary" variant="h1">
                        {header}
                    </Typography>
                    <div className="board__header__buttons">{buttons}</div>
                </div>
            )}
            {tabs.length > 0 ? (
                <div className="board__tabs">
                    <Tabs
                        defaultValue={selectedTabId}
                        onValueChange={onClickTab}
                    >
                        <TabsList>
                            {tabs.map((t) => {
                                return (
                                    <Tab
                                        isSelected={selectedTabId === t.id}
                                        key={t.id}
                                        value={t.id}
                                    >
                                        {t.label}
                                    </Tab>
                                )
                            })}
                        </TabsList>
                    </Tabs>
                    {subButtons}
                </div>
            ) : (
                <div className="board__spacer" />
            )}
            <BoardDataTable
                hasTabs={tabs.length > 0}
                isLoading={isLoading}
                isLastPage={isLastPage}
                isFirstPage={isFirstPage}
                isRefetching={isRefetching}
                isSubmitting={!!isSubmitting}
                limit={limit}
                offset={offset}
                numRows={numRows}
                columns={columns}
                rows={rows}
                selectedRowIds={selectedRowIds}
                onNextPage={onNextPage}
                onSelectRow={onSelectRow}
                onRefetchRows={onRefetchRows}
                onPreviousPage={onPreviousPage}
                onSelectAllRows={onSelectAllRows}
            />
        </StyledBoard>
    )
}

export default Board
