import Papa from 'papaparse'
import { saveAs } from 'file-saver'

export const downloadCSV = (
    filename: string,
    data: Record<string, unknown>[]
) => {
    // Convert the array of objects to a CSV string using Papaparse
    const csv = Papa.unparse(data)

    // Create a Blob containing the CSV data
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' })

    // Trigger the file download using FileSaver.js
    saveAs(blob, `${filename}.csv`)
}
