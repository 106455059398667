import { useMemo, useState } from 'react'
import { File } from 'lucide-react'
import styled from '@emotion/styled'
import Typography from 'components/core/Typography'
import IconButton from 'components/core/IconButton'
import { OrderDocumentItem } from '../orderDetails.types'
import { Tooltip } from 'components/core/Tooltip'
import { Order } from 'types/order.types'
import { CircularProgress } from '@mui/material'
import { BASE_TOKENS } from 'constants/color.constants'
import { generateOrderDocumentItems } from '../utils/orderDetails.utils'

const StyledLinkColumn = styled.div`
    width: 6rem;
`

export const OrderDetailsDocumentsSection = ({
    order,
    onGenerateRateCon,
}: {
    order?: Order
    onGenerateRateCon: () => Promise<void>
}) => {
    const documents: OrderDocumentItem[] = useMemo(
        () => generateOrderDocumentItems(order, { onGenerateRateCon }),
        [onGenerateRateCon, order]
    )

    const [loadingMap, setLoadingMap] = useState<Record<string, boolean>>({})

    const _onDownload = (document: OrderDocumentItem) => async () => {
        try {
            setLoadingMap((prev) => ({ ...prev, [document.id]: true }))
            await document.onDownload()
        } finally {
            setLoadingMap((prev) => ({ ...prev, [document.id]: false }))
        }
    }

    if (!documents.length) {
        return null
    }

    return (
        <>
            <div className="details__content__right__body__section">
                <div className="details__content__right__body__section__documents">
                    <Typography color="secondary" variant="body2">
                        Documents
                    </Typography>
                </div>
            </div>

            <div className="details__content__right__body__section">
                <div className="details__content__right__body__row details__content__right__body__row--compressed">
                    <StyledLinkColumn>
                        <Typography isBold color="secondary" variant="body2">
                            Link
                        </Typography>
                    </StyledLinkColumn>
                    <div>
                        <Typography isBold color="secondary" variant="body2">
                            Name
                        </Typography>
                    </div>
                </div>
                {documents.map((document) => {
                    return (
                        <div
                            key={document.id}
                            className="details__content__right__body__row details__content__right__body__row--compressed"
                        >
                            <StyledLinkColumn>
                                <Tooltip content={document.tooltip}>
                                    <IconButton
                                        disabled={document.isDisabled}
                                        onClick={_onDownload(document)}
                                    >
                                        {loadingMap[document.id] ? (
                                            <CircularProgress
                                                size={16}
                                                style={{
                                                    color: BASE_TOKENS
                                                        .grey[950],
                                                }}
                                            />
                                        ) : (
                                            <File size={16} />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            </StyledLinkColumn>
                            <div>
                                <Typography color="primary" variant="body2">
                                    {document.label}
                                </Typography>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}
