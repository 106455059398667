import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useLogin, useMagicLinkLogin } from 'mutations/user.mutations'
import { showToastError } from 'hooks/useToast'
import { LoginForm } from './components/LoginForm'
import Cookies from 'js-cookie'
import { User } from 'types/user.types'
import { isCustomerUser, isSuperAdmin } from 'utils/user.utils'
import { getCTSDK } from 'services/ctClient'
import { URLS } from 'utils/url.utils'

const ctSDK = getCTSDK()

export const LoginFormContainer = () => {
    const [searchParams] = useSearchParams()
    const queryEmail = searchParams.get('email')
    const queryLoginLinkId = searchParams.get('loginToken')
    const redirectTo = searchParams.get('redirectTo')
    const navigate = useNavigate()
    const { isLoading: isSubmitting, mutateAsync: login } = useLogin()
    const { isLoading: isSubmittingMagicLink, mutateAsync: magicLinkLogin } =
        useMagicLinkLogin()
    const [email, setEmail] = useState('')

    useEffect(() => {
        if (!queryEmail || !queryLoginLinkId) return

        const login = async () => {
            try {
                const resp = await magicLinkLogin({
                    email: queryEmail,
                    loginToken: queryLoginLinkId,
                })

                Cookies.set('token', resp.data?.token)
                Cookies.set('refreshToken', resp.data?.refreshToken)

                const meResp = await ctSDK.get<{ user: User }>('/users/me')
                const me = meResp.data?.user

                if (redirectTo) {
                    navigate(redirectTo)
                    return
                } else if (isSuperAdmin(me)) {
                    navigate(URLS.cartage.superAdmin.home())
                    return
                } else if (isCustomerUser(me)) {
                    navigate(URLS.cartage.shipperPortal.shipments())
                    return
                }

                navigate(URLS.cartage.home())
            } catch (error) {
                // do nothing
                navigate(URLS.cartage.login())
            }
        }

        login()
    }, [queryEmail, queryLoginLinkId])

    const _onLogin = async () => {
        try {
            await login({ email })

            navigate(URLS.cartage.loginSuccess({ email }))
        } catch (error) {
            showToastError(error)
        }
    }

    return (
        <LoginForm
            isSubmitting={isSubmitting || isSubmittingMagicLink}
            email={email}
            onLogin={_onLogin}
            onChangeEmail={setEmail}
        />
    )
}
