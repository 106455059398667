import { BoardColumn } from 'components/composite/Board'

export const tableColumns: BoardColumn[] = [
    {
        id: 'name',
        label: 'Name',
        width: 80,
    },
    {
        id: 'contactEmail',
        label: 'Contact email',
        width: 80,
    },
]
