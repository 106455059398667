import { CircleCheck } from 'lucide-react'
import ActionsCell from 'components/composite/ActionsCell'
import { DESIGN_TOKENS } from 'constants/color.constants'
import { getAvailableActions } from 'modules/Automations/utils/automation.utils'
import { showToastError, showToastSuccess } from 'hooks/useToast'
import {
    useDeleteAutomation,
    useMarkAutomationsAsCompleted,
    useScheduleAutomationAction,
    useUnscheduleAutomationAction,
} from 'mutations/automation.mutations'
import {
    AutomationActionStatus,
    AutomationActionType,
} from 'types/automationAction.types'
import { AutomationStatus } from 'types/automation.types'

const emptyFunc = () => undefined

type AutomationActionsCellProps = {
    automationId: string
    currentActionId?: string
    automationStatus: AutomationStatus
    currentActionType?: AutomationActionType
    currentActionStatus?: AutomationActionStatus
    onRefetchAutomations: () => void
}

const AutomationActionsCell = ({
    automationId,
    currentActionId,
    automationStatus,
    currentActionType,
    currentActionStatus,
    onRefetchAutomations = emptyFunc,
}: AutomationActionsCellProps) => {
    const { isLoading: isDeleting, mutateAsync: deleteAutomation } =
        useDeleteAutomation()
    const { isLoading: isScheduling, mutateAsync: scheduleAction } =
        useScheduleAutomationAction()
    const { isLoading: isUnscheduling, mutateAsync: unscheduleAction } =
        useUnscheduleAutomationAction()
    const { isLoading: isCompleting, mutateAsync: markAsCompleted } =
        useMarkAutomationsAsCompleted()

    const _onDeleteAutomation = async () => {
        try {
            await deleteAutomation(automationId)
            showToastSuccess('Automation deleted')
            onRefetchAutomations()
        } catch {
            showToastError('Error deleting automation')
        }
    }

    const _onScheduleAction = async () => {
        try {
            if (!currentActionId) {
                throw new Error('No current action ID')
            }

            await scheduleAction({
                automationId,
                automationActionId: currentActionId,
            })
            showToastSuccess('Email scheduled')
            onRefetchAutomations()
        } catch {
            showToastError('Error scheduling email')
        }
    }

    const _onUnscheduleAction = async () => {
        try {
            if (!currentActionId) {
                throw new Error('No current action ID')
            }

            await unscheduleAction({
                automationId,
                automationActionId: currentActionId,
            })
            showToastSuccess('Email unscheduled')
            onRefetchAutomations()
        } catch {
            showToastError('Error unscheduling email')
        }
    }

    const _onMarkAsCompleted = async () => {
        try {
            await markAsCompleted([automationId])
            showToastSuccess('Email marked as completed')
            onRefetchAutomations()
        } catch {
            showToastError('Error marking email as completed')
        }
    }

    const availableActions = getAvailableActions({
        automationStatus,
        currentActionType,
        currentActionStatus,
    })

    const options = [
        { id: 'schedule', label: 'Schedule', onClick: _onScheduleAction },
        { id: 'unschedule', label: 'Unschedule', onClick: _onUnscheduleAction },
        {
            id: 'markAsCompleted',
            label: 'Mark as completed',
            onClick: _onMarkAsCompleted,
        },
        {
            id: 'delete',
            label: 'Delete',
            onClick: _onDeleteAutomation,
        },
    ]

    return (
        <>
            {automationStatus && ['completed'].includes(automationStatus) ? (
                <CircleCheck
                    size={16}
                    color={DESIGN_TOKENS.secondaryForeground}
                />
            ) : (
                <ActionsCell
                    isSubmitting={
                        isDeleting ||
                        isScheduling ||
                        isUnscheduling ||
                        isCompleting
                    }
                    options={options.filter((o) =>
                        availableActions.includes(o.id)
                    )}
                />
            )}
        </>
    )
}

export default AutomationActionsCell
