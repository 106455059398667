import { useEffect, useRef } from 'react'
import { useGetQueuedReceivables } from 'queries/receivable.queries'

export const useQueuedReceivables = () => {
    const ws = useRef<WebSocket | null>(null)

    const { isLoading, isRefetching, error, data, refetch } =
        useGetQueuedReceivables()

    useEffect(() => {
        ws.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL_2 || '')

        ws.current.onmessage = (event) => {
            const wsEvent = JSON.parse(event.data)

            if (
                ['receivableQueued', 'receivableProcessed'].includes(
                    wsEvent.type
                )
            ) {
                refetch()
            }
        }

        return () => {
            ws.current?.close()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        isLoading,
        isRefetching,
        error,
        receivables: data?.data?.receivables || [],
        refetch,
    }
}
