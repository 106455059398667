import { DetailsRow } from './DetailsRow'
import Typography from 'components/core/Typography'
import { Order } from 'types/order.types'
import { EtaStatusChip } from 'components/composite/EtaStatusChip'
import { DetailsTimeRow } from './DetailsTimeRow'

type OrderDetailsEtaSectionProps = {
    order?: Order
}

export const OrderDetailsEtaSection = ({
    order,
}: OrderDetailsEtaSectionProps) => {
    const { etaStatus, eta } = order || {}

    return (
        <>
            <DetailsTimeRow isEditable={false} caption="ETA" value={eta} />
            <DetailsRow
                isEditable={false}
                trigger={
                    <>
                        <Typography color="secondary" variant="body2">
                            Status
                        </Typography>
                        {etaStatus ? <EtaStatusChip status={etaStatus} /> : ''}
                    </>
                }
            />
        </>
    )
}
