import styled from '@emotion/styled'
import { Upload } from 'lucide-react'
import { useDropzone } from 'react-dropzone'
import Typography from 'components/core/Typography'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'

const emptyFunc = () => undefined

const StyledFileDropzone = styled.div<{ isDisabled?: boolean }>`
    width: 100%;
    height: 28rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.8rem;
    background-color: ${BASE_TOKENS.grey[100]};
    border: 0.1rem solid ${BASE_TOKENS.grey[300]};
    border-radius: 0.8rem;
    cursor: pointer;
`

const StyledIcon = styled(Upload)<{ isDragActive: boolean }>`
    transition: color 0.3s ease;
    color: ${(p) =>
        p.isDragActive
            ? BASE_TOKENS.grey[500]
            : DESIGN_TOKENS.text.textForeground};
`

const StyledTypography = styled(Typography)`
    pointer-events: none;
`

type FileDropzoneProps = {
    isDisabled?: boolean
    onDrop: (files: File[]) => void
}

const FileDropzone = ({
    isDisabled,
    onDrop = emptyFunc,
}: FileDropzoneProps) => {
    const { isDragActive, getRootProps, getInputProps } = useDropzone({
        disabled: isDisabled,
        // @ts-ignore
        accept: '.pdf',
        onDrop,
    })

    return (
        <StyledFileDropzone {...getRootProps()} isDisabled={isDisabled}>
            <input {...getInputProps()} />
            <StyledIcon isDragActive={isDragActive} size={28} />
            <StyledTypography color="primary" variant="body1">
                Drag and drop files here, or click to select files
            </StyledTypography>
        </StyledFileDropzone>
    )
}

export default FileDropzone
