import { ReactNode } from 'react'
import styled from '@emotion/styled'
import * as RadixPopover from '@radix-ui/react-popover'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'

const StyledPopoverContent = styled(RadixPopover.Content)`
    min-width: 16rem;
    background-color: ${BASE_TOKENS.grey[0]};
    border: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};
    border-radius: 0.6rem;
    box-shadow:
        0px 4px 6px -1px rgba(0, 0, 0, 0.1),
        0px 2px 4px -2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    z-index: 101;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    &[data-state='open'] {
        animation: fadeIn 300ms ease-out;
    }
`

const StyledPopoverTrigger = styled.button`
    width: 100%;
`

type PopoverProps = {
    align?: 'start' | 'center' | 'end'
    trigger: ReactNode
    children: ReactNode
}

const Popover = ({ align = 'end', trigger, children }: PopoverProps) => {
    return (
        <RadixPopover.Root>
            <RadixPopover.Trigger asChild>
                <StyledPopoverTrigger>{trigger}</StyledPopoverTrigger>
            </RadixPopover.Trigger>
            <RadixPopover.Portal>
                <StyledPopoverContent align={align} sideOffset={12}>
                    {children}
                </StyledPopoverContent>
            </RadixPopover.Portal>
        </RadixPopover.Root>
    )
}

export default Popover
