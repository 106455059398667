import { format, parseISO } from 'date-fns'

export const getDateFromISOString = (isoString: string): string => {
    const parsedDate = parseISO(isoString)

    const formattedDate = format(parsedDate, 'dd-MM-yyyy')

    return formattedDate
}

export const getTimeFromISOString = (isoString: string): string => {
    const parsedDate = parseISO(isoString)

    const formattedTime = format(parsedDate, 'h:mm a')

    return formattedTime
}

export const getPayableDateString = (dateString: string): string => {
    const [year, month, day] = dateString.split('-')
    return `${day}-${month}-${year}`
}

export const formatDateTimeRangeString = ({
    from,
    to,
    dateFormat = 'MMM d, yyyy',
    timeFormat = 'HH:mm',
    hasTime = true,
}: {
    from: Date | null | string | undefined
    to?: Date | null | string | undefined
    dateFormat?: string
    timeFormat?: string
    hasTime?: boolean
}): string => {
    if (!from) return ''

    const fromDate = typeof from === 'string' ? new Date(from) : from
    const toDate = to ? (typeof to === 'string' ? new Date(to) : to) : undefined

    const formatDateOnly = (date: Date | string) => format(date, dateFormat)
    const formatTimeOnly = (date: Date | string) => format(date, timeFormat)

    const formatDateTime = (date: Date | string) => {
        if (hasTime) {
            return formatDateOnly(date) + ' @ ' + formatTimeOnly(date)
        }
        return formatDateOnly(date)
    }

    let result = formatDateTime(fromDate)

    if (!toDate) return result

    const isSameDate =
        fromDate.getFullYear() === toDate.getFullYear() &&
        fromDate.getMonth() === toDate.getMonth() &&
        fromDate.getDate() === toDate.getDate()

    const isSameTime =
        fromDate.getHours() === toDate.getHours() &&
        fromDate.getMinutes() === toDate.getMinutes() &&
        fromDate.getSeconds() === toDate.getSeconds()

    if (isSameDate && isSameTime) {
        // If both date and time are the same, return only one date-time string
        return formatDateTime(fromDate)
    }

    if (isSameDate) {
        if (hasTime) {
            const fromTime = formatTimeOnly(fromDate)
            const toTime = formatTimeOnly(toDate)
            return formatDateOnly(fromDate) + ` @ ${fromTime} - ${toTime}`
        } else {
            return formatDateOnly(fromDate)
        }
    }

    result += ' - ' + formatDateTime(toDate)

    return result
}
