import styled from '@emotion/styled'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import { SPACING } from 'constants/spacing.constants'
import { DayPicker } from 'react-day-picker'

export const StyledDayPicker = styled(DayPicker)`
    font-family: Inter;

    .rdp-months {
        position: relative;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }

    .rdp-nav {
        position: absolute;
        top: 1.2rem;
        left: 1.2rem;
        right: 1.2rem;
        display: flex;
        justify-content: space-between;
    }

    .rdp-button_previous,
    .rdp-button_next {
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 0.6rem;
        border: 0.1rem solid ${DESIGN_TOKENS.input};
        color: ${BASE_TOKENS.grey[500]};

        transition: color 0.3s ease;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: ${BASE_TOKENS.grey[100]};
            color: ${DESIGN_TOKENS.text.textForeground};
        }
    }

    .rdp-month_caption {
        display: flex;
        justify-content: center;
        margin-bottom: 0.6rem;
    }

    .rdp-caption_label {
        font-size: 1.4rem;
        line-height: 2rem;
        letter-spacing: -0.04rem;
        font-weight: 500;
        color: ${DESIGN_TOKENS.text.textForeground};
    }

    .rdp-weekday {
        font-size: 1.2rem;
        line-height: 1.8rem;
        letter-spacing: -0.02rem;
        font-weight: 400;
        color: ${BASE_TOKENS.grey[700]};
    }

    .rdp-day {
        font-size: 1.4rem;
        line-height: 2rem;
        letter-spacing: -0.04rem;
        font-weight: 400;
        border-radius: ${SPACING.p1};

        background-color: ${BASE_TOKENS.grey[0]};
        color: ${DESIGN_TOKENS.text.textForeground};

        transition: background-color 0.3s ease;

        &:hover {
            background-color: ${BASE_TOKENS.grey[100]};
        }

        &.rdp-selected {
            background-color: ${DESIGN_TOKENS.secondaryForeground};

            button {
                color: ${BASE_TOKENS.grey[0]};
                border: none;
            }

            &:hover {
                color: ${BASE_TOKENS.grey[0]};
                background-color: ${DESIGN_TOKENS.secondaryForeground};
            }

            &.rdp-range_start {
                background-color: ${BASE_TOKENS.grey[100]};
                border-radius: ${SPACING.p0};
                border-top-left-radius: ${SPACING.p1};
                border-bottom-left-radius: ${SPACING.p1};

                .rdp-day_button {
                    background-color: ${DESIGN_TOKENS.secondaryForeground};
                    border-radius: ${SPACING.p1};
                }
            }
            &.rdp-range_end {
                background-color: ${BASE_TOKENS.grey[100]};
                border-radius: ${SPACING.p0};
                border-top-right-radius: ${SPACING.p1};
                border-bottom-right-radius: ${SPACING.p1};

                .rdp-day_button {
                    background-color: ${DESIGN_TOKENS.secondaryForeground};
                    border-radius: ${SPACING.p1};
                }
            }
            &.rdp-range_middle {
                border-radius: ${SPACING.p0};
                background-color: ${BASE_TOKENS.grey[100]};
                .rdp-day_button {
                    color: ${DESIGN_TOKENS.text.textForeground};
                }
            }
        }

        &.rdp-today {
            .rdp-day_button {
                border-radius: ${SPACING.p1};
                color: ${DESIGN_TOKENS.text.textForeground};
                border: 0.1rem solid ${BASE_TOKENS.grey[100]};
                background-color: ${BASE_TOKENS.grey[100]};
            }
        }

        .rdp-day_button {
            width: 3.6rem;
            height: 3.6rem;
        }
    }
`
