import { useState } from 'react'
import { CircleCheck } from 'lucide-react'
import ActionsCell from 'components/composite/ActionsCell'
import { DESIGN_TOKENS } from 'constants/color.constants'
import ConfirmDeleteDialog from 'dialogs/ConfirmDeleteDialog'
import { ReceivableStatus } from 'types/receivable.types'
import ProcessReceivablesDialog from 'dialogs/ProcessReceivablesDialog'
import { canReceivableBeInvoiced } from 'modules/Receivables/utils/receivables.utils'
import { showToastError, showToastSuccess } from 'hooks/useToast'
import {
    useDeleteReceivable,
    useMarkReceivablesAsProcessed,
    useProcessReceivables,
} from 'mutations/receivable.mutations'
import { getAvailableActions } from './receivableActionsCell.utils'

const emptyFunc = () => undefined

type ReceivableActionsCellProps = {
    receivableId: string
    receivableStatus: ReceivableStatus
    onRefetchReceivables: () => void
}

const ReceivableActionsCell = ({
    receivableId,
    receivableStatus,
    onRefetchReceivables = emptyFunc,
}: ReceivableActionsCellProps) => {
    const [isInvoiceDialogOpen, setIsInvoiceDialogOpen] = useState(false)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

    const { isLoading: isProcessing, mutateAsync: processReceivables } =
        useProcessReceivables()
    const {
        isLoading: isMarkingAsProcessed,
        mutateAsync: markReceivablesAsProcessed,
    } = useMarkReceivablesAsProcessed()
    const { isLoading: isDeleting, mutateAsync: deleteReceivable } =
        useDeleteReceivable()

    const isSubmitting = isProcessing || isMarkingAsProcessed || isDeleting

    const _onClickProcessOption = () => {
        setIsInvoiceDialogOpen(true)
    }

    const _onClickMarkAsProcessedOption = _onClickProcessOption

    const _onClickDeleteOption = () => {
        setIsDeleteDialogOpen(true)
    }

    const _onProcessReceivable = async () => {
        setIsInvoiceDialogOpen(false)

        try {
            if (canReceivableBeInvoiced(receivableStatus)) {
                await processReceivables([receivableId])
                showToastSuccess('Receivable added to queue')
            } else {
                await markReceivablesAsProcessed([receivableId])
                showToastSuccess('Receivable processed')
            }

            onRefetchReceivables()
        } catch (error) {
            showToastError('Error processing receivable')
        }
    }

    const _onDeleteReceivable = async () => {
        setIsDeleteDialogOpen(false)

        try {
            await deleteReceivable(receivableId)
            showToastSuccess('Receivable deleted')
        } catch (error) {
            showToastError('Error deleting receivable')
        }
    }

    const availableActions = getAvailableActions({
        status: receivableStatus,
    })

    const options = [
        { id: 'invoice', label: 'Invoice', onClick: _onClickProcessOption },
        {
            id: 'markAsInvoiced',
            label: 'Mark as invoiced',
            onClick: _onClickMarkAsProcessedOption,
        },
        { id: 'delete', label: 'Delete', onClick: _onClickDeleteOption },
    ]

    return (
        <>
            <ProcessReceivablesDialog
                isOpen={isInvoiceDialogOpen}
                onClose={() => setIsInvoiceDialogOpen(false)}
                onConfirm={_onProcessReceivable}
            />
            <ConfirmDeleteDialog
                isOpen={isDeleteDialogOpen}
                header="Delete receivable"
                message="Are you sure you want to delete this receivable?"
                onClose={() => setIsDeleteDialogOpen(false)}
                onConfirm={_onDeleteReceivable}
            />
            {['invoiced', 'manuallyInvoiced'].includes(receivableStatus) ? (
                <CircleCheck
                    size={16}
                    color={DESIGN_TOKENS.secondaryForeground}
                />
            ) : (
                <ActionsCell
                    isSubmitting={isSubmitting}
                    options={options.filter((o) =>
                        availableActions.includes(o.id)
                    )}
                />
            )}
        </>
    )
}

export default ReceivableActionsCell
