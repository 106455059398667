import styled from '@emotion/styled'
import { Mail, Zap } from 'lucide-react'
import AnchorTag from 'components/core/AnchorTag'
import Typography from 'components/core/Typography'
import { AutomationAction } from 'types/automationAction.types'
import { getTimeFromISOString } from 'utils/date.utils'
import { getAutomationActionSummary } from 'modules/Automations/utils/automation.utils'
import { useEffect, useState } from 'react'
import {
    isAutomationActionCompleted,
    isAutomationActionManualCompleted,
    isAutomationActionScheduled,
} from 'utils/automationAction.utils'
import { useUpdateAutomationActionById } from 'mutations/automation.mutations'
import { showToastError } from 'hooks/useToast'
import Log from './Log'
import Email from './Email'

const StyledCheckEmailAction = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;

    .action__email {
        display: flex;
        flex-direction: column;
        row-gap: 0.8rem;
    }
`

const renderCheckEmailTrigger = ({
    orgSubdomain,
    automationTmsEntityNumbers,
}: {
    orgSubdomain?: string
    automationTmsEntityNumbers?: { id: string; fullId: string }[]
}) => {
    if (!automationTmsEntityNumbers) {
        return null
    }
    if (automationTmsEntityNumbers.length > 3) {
        return <>ETA is today for {automationTmsEntityNumbers.length} loads</>
    }

    return (
        <>
            {automationTmsEntityNumbers?.map((e, i) => {
                return (
                    <>
                        <AnchorTag
                            key={e.id}
                            rel="noopener noreferrer"
                            // @TODO: Must dynamically construct URL when reference can be more than just manifest
                            href={`https://${orgSubdomain}.roserocket.com/#/ops/manifests/${e.id}`}
                            color="primary"
                            target="_blank"
                        >
                            {e.fullId}
                        </AnchorTag>
                        {i === automationTmsEntityNumbers.length - 1
                            ? ' '
                            : ', '}
                    </>
                )
            })}{' '}
            ETA is today
        </>
    )
}

type CheckEmailActionProps = {
    automationId?: string
    meEmail: string
    orgSubdomain?: string
    automationCreatedAt?: string
    automationTmsEntityNumbers?: { id: string; fullId: string }[]
    action?: AutomationAction
}

const CheckEmailAction = ({
    automationId,
    meEmail,
    orgSubdomain,
    automationCreatedAt,
    automationTmsEntityNumbers,
    action,
}: CheckEmailActionProps) => {
    const {
        id,
        type,
        status,
        toEmail,
        fromEmail,
        subject: propsSubject,
        htmlContent: propsHtmlContent,
        scheduledAt,
    } = action || {}

    const [subject, setSubject] = useState(propsSubject)
    const [htmlContent, setHtmlContent] = useState(propsHtmlContent)

    const { mutateAsync: updateAction } = useUpdateAutomationActionById()

    const _onSave = async (data: Partial<AutomationAction>) => {
        if (!id || !automationId) {
            return
        }

        try {
            await updateAction({
                id,
                automationId,
                data,
            })
        } catch (error) {
            setHtmlContent(propsHtmlContent)
            showToastError('Error updating email content')
        }
    }

    const _onBlurSubject = async () => {
        if (!subject || subject === propsSubject) {
            return
        }

        await _onSave({ subject })
    }

    const _onBlurContent = async () => {
        if (!htmlContent || htmlContent === propsHtmlContent) {
            return
        }

        await _onSave({ htmlContent })
    }

    useEffect(() => {
        setSubject(propsSubject)
    }, [propsSubject])

    useEffect(() => {
        setHtmlContent(htmlContent)
    }, [htmlContent])

    return (
        <StyledCheckEmailAction>
            <Log>
                <span className="log__icon">
                    <Zap size={12} />
                </span>
                <Typography color="primary" variant="body2">
                    Trigger:{' '}
                    {type === 'sendCheckEmail' ? (
                        renderCheckEmailTrigger({
                            automationTmsEntityNumbers,
                        })
                    ) : (
                        <>
                            <AnchorTag
                                key={automationTmsEntityNumbers?.[0]?.id}
                                rel="noopener noreferrer"
                                // @TODO: Must dynamically construct URL when reference can be more than just manifest
                                href={`https://${orgSubdomain}.roserocket.com/#/ops/orders/${automationTmsEntityNumbers?.[0]?.id}`}
                                color="primary"
                                target="_blank"
                            >
                                {automationTmsEntityNumbers?.[0]?.fullId}
                            </AnchorTag>{' '}
                            entered "booked" status
                        </>
                    )}
                </Typography>
                <Typography color="secondary" variant="body2">
                    {automationCreatedAt &&
                        getTimeFromISOString(automationCreatedAt)}
                </Typography>
            </Log>
            <div className="action__email">
                <Log>
                    <span className="log__icon">
                        <Mail size={12} />
                    </span>
                    <Typography color="primary" variant="body2">
                        {getAutomationActionSummary({
                            actionType: type,
                            actionStatus: status,
                        })}
                    </Typography>
                    {scheduledAt && (
                        <Typography color="secondary" variant="body2">
                            {getTimeFromISOString(scheduledAt)}
                        </Typography>
                    )}
                </Log>
                <Email
                    isOutbound
                    isEditable={
                        !isAutomationActionCompleted(status) &&
                        !isAutomationActionManualCompleted(status) &&
                        !isAutomationActionScheduled(status)
                    }
                    fromEmail={fromEmail || meEmail}
                    toEmail={toEmail}
                    subject={subject}
                    htmlContent={htmlContent}
                    onBlurSubject={_onBlurSubject}
                    onBlurContent={_onBlurContent}
                    onChangeSubject={setSubject}
                    onChangeContent={setHtmlContent}
                />
            </div>
        </StyledCheckEmailAction>
    )
}

export default CheckEmailAction
