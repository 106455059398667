import { AxiosResponse } from 'axios'
import { UseQueryOptions, useQuery } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { OrgSettings } from 'types/orgSettings.types'

const ctSDK = getCTSDK()

export const useGetMyOrgSettings = (
    options?: Omit<
        UseQueryOptions<AxiosResponse<{ settings: OrgSettings }>>,
        'queryKey' | 'queryFn'
    >
) => {
    return useQuery(
        ['useGetMyOrgSettings'],
        async () =>
            await ctSDK.get<{ settings: OrgSettings }>(`/orgs/myOrg/settings`),
        options
    )
}
