import { useMe } from 'hooks/useMe'
import { useMyOrg } from 'hooks/useMyOrg'
import { showToastError, showToastSuccess } from 'hooks/useToast'
import {
    useGetAutomationActionsById,
    useGetAutomationById,
} from 'queries/automation.queries'
import AutomationDetailsDialog from './components/AutomationDetailsDialog'
import {
    useScheduleAutomationAction,
    useUnscheduleAutomationAction,
} from 'mutations/automation.mutations'
import { useGetAutomationEventsById } from 'queries/automationEvent.queries'

const emptyFunc = () => undefined

type AutomationDetailsDialogContainerProps = {
    isOpen: boolean
    id: string
    onClose: () => void
    onSuccess: () => void
}

const AutomationDetailsDialogContainer = ({
    isOpen,
    id,
    onClose = emptyFunc,
    onSuccess = emptyFunc,
}: AutomationDetailsDialogContainerProps) => {
    const { me } = useMe()
    const { org } = useMyOrg()

    const { isLoading: isScheduling, mutateAsync: scheduleAction } =
        useScheduleAutomationAction()
    const { isLoading: isUnscheduling, mutateAsync: unscheduleAction } =
        useUnscheduleAutomationAction()

    const {
        isLoading: isLoadingAutomation,
        data: automationData,
        error: automationsError,
    } = useGetAutomationById(id, {
        enabled: isOpen && !!id,
    })
    const automation = automationData?.data?.automation

    const {
        isLoading: isLoadingActions,
        data: actionsData,
        error: actionsError,
    } = useGetAutomationActionsById(id, {
        enabled: isOpen && !!id,
    })
    const action = actionsData?.data?.actions?.[0]

    const {
        isLoading: isLoadingEvents,
        data: eventsData,
        error: eventsError,
    } = useGetAutomationEventsById(id, {
        enabled: isOpen && !!id,
    })
    const events = eventsData?.data?.events || []

    const _onScheduleAction = async () => {
        try {
            if (!action?.id) {
                throw new Error('No current action ID')
            }

            await scheduleAction({
                automationId: id,
                automationActionId: action.id,
            })
            showToastSuccess('Email scheduled')
            onSuccess()
        } catch {
            showToastError('Error scheduling email')
        }
    }

    const _onUnscheduleAction = async () => {
        try {
            if (!action?.id) {
                throw new Error('No current action ID')
            }

            await unscheduleAction({
                automationId: id,
                automationActionId: action.id,
            })
            showToastSuccess('Email unscheduled')
            onSuccess()
        } catch {
            showToastError('Error unscheduling email')
        }
    }

    if (automationsError || actionsError || eventsError) {
        showToastError('Error getting automation details')
    }

    return (
        <AutomationDetailsDialog
            isOpen={isOpen}
            isLoading={
                isLoadingAutomation || isLoadingActions || isLoadingEvents
            }
            isSubmitting={isScheduling || isUnscheduling}
            orgSubdomain={org.rrSubdomain}
            me={me}
            automation={automation}
            action={action}
            events={events}
            onClose={onClose}
            onScheduleAction={_onScheduleAction}
            onUnscheduleAction={_onUnscheduleAction}
        />
    )
}

export default AutomationDetailsDialogContainer
