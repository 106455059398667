// inspired by https://time.openstatus.dev/

import * as React from 'react'
import { Clock, LucideProps } from 'lucide-react'
import { SPACING } from 'constants/spacing.constants'
import Typography from 'components/core/Typography'
import styled from '@emotion/styled'
import { TimePickerInput } from './TimePickerInput'

const StyledTimePicker = styled.div`
    display: flex;
    align-items: flex-end;
    gap: ${SPACING.p1};
    padding: ${SPACING.p1};

    .timePickerDemo__column {
        display: grid;
        gap: ${SPACING.sm};
        text-align: center;
    }

    .timePickerDemo__clock {
        display: flex;
        height: ${SPACING.p5};
        align-items: center;
    }
`

interface TimePickerProps {
    date: Date | undefined
    Icon?: React.ComponentType<LucideProps>
    onChange: (date: Date | undefined) => void
}

export function TimePicker({ date, Icon = Clock, onChange }: TimePickerProps) {
    const minuteRef = React.useRef<HTMLInputElement>(null)
    const hourRef = React.useRef<HTMLInputElement>(null)
    const secondRef = React.useRef<HTMLInputElement>(null)

    return (
        <StyledTimePicker>
            <div className="timePickerDemo__column">
                <Typography color="primary" variant="caption">
                    Hours
                </Typography>
                <TimePickerInput
                    picker="hours"
                    date={date}
                    setDate={onChange}
                    ref={hourRef}
                    onRightFocus={() => minuteRef.current?.focus()}
                />
            </div>
            <div className="timePickerDemo__column">
                <Typography color="primary" variant="caption">
                    Minutes
                </Typography>
                <TimePickerInput
                    picker="minutes"
                    date={date || undefined}
                    setDate={onChange}
                    ref={minuteRef}
                    onLeftFocus={() => hourRef.current?.focus()}
                    onRightFocus={() => secondRef.current?.focus()}
                />
            </div>
            <div className="timePickerDemo__clock">
                {Icon && <Icon size={SPACING.p2} />}
            </div>
        </StyledTimePicker>
    )
}
