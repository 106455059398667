import styled from '@emotion/styled'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import { LucideProps } from 'lucide-react'
import { JSXElementConstructor } from 'react'

const StyledDateTimeButton = styled.div`
    display: flex;
    position: relative;
    align-items: center;

    .dateTimeButton__icon {
        margin-top: 0.3rem;
        position: absolute;
        left: 1.2rem;
        color: ${DESIGN_TOKENS.muted};
    }
`

export const StyledButton = styled.button<{
    hasValue: boolean
    hasIcon: boolean
    disabled?: boolean
}>`
    width: 100%;
    height: 4rem;
    padding-left: ${(p) => (p.hasIcon ? '3.4rem' : '1.2rem')};
    padding-right: 1.2rem;
    padding-bottom: 0.1rem;

    display: flex;
    align-items: center;
    background-color: ${BASE_TOKENS.grey[0]};

    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    color: ${(p) =>
        p.hasValue
            ? DESIGN_TOKENS.text.textForeground
            : DESIGN_TOKENS.mutedForeground};

    border-radius: 0.6rem;
    border: 0.1rem solid ${DESIGN_TOKENS.input};

    cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
    transition: background-color 0.3s ease;

    &::placeholder {
        color: ${DESIGN_TOKENS.mutedForeground};
    }

    &:focus,
    &:focus-visible {
        outline: none;
        box-shadow: ${(p) =>
            p.disabled
                ? 'none'
                : `0 0 0 0.2rem ${BASE_TOKENS.grey[0]}, 0 0 0 0.4rem ${BASE_TOKENS.grey[400]}`};
    }

    &:hover {
        background-color: ${(p) =>
            p.disabled ? 'auto' : BASE_TOKENS.grey[50]};
    }
`

type DateTimeButtonProps = {
    isDisabled?: boolean
    placeholder?: string
    dateText?: string
    Icon?: JSXElementConstructor<LucideProps>
    ButtonComponent?: JSXElementConstructor<any>
}

export const DateTimeButton = ({
    isDisabled,
    placeholder,
    dateText,
    Icon,
    ButtonComponent = StyledButton,
}: DateTimeButtonProps) => {
    return (
        <StyledDateTimeButton>
            {Icon && (
                <span className="dateTimeButton__icon">
                    <Icon
                        size={16}
                        color={
                            dateText
                                ? DESIGN_TOKENS.text.textForeground
                                : DESIGN_TOKENS.text.textMutedForeground
                        }
                    />
                </span>
            )}
            <ButtonComponent
                hasValue={!!dateText}
                hasIcon={!!Icon}
                disabled={isDisabled}
                tabIndex={isDisabled ? -1 : 0}
            >
                {dateText || placeholder}
            </ButtonComponent>
        </StyledDateTimeButton>
    )
}
