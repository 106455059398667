import styled from '@emotion/styled'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import TextareaAutosize from 'react-textarea-autosize'

const emptyFunc = () => undefined

const StyledTextAreaContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.6rem;
`

const StyledLabel = styled.label`
    color: ${DESIGN_TOKENS.text.textForeground};
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
`

const StyledTextArea = styled(TextareaAutosize)`
    width: 100%;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;

    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    line-height: 2rem;
    font-weight: 400;
    line-height: 2rem;
    color: ${DESIGN_TOKENS.text.textForeground};

    border-radius: 0.6rem;
    border: 0.1rem solid ${DESIGN_TOKENS.input};

    &::placeholder {
        color: ${DESIGN_TOKENS.mutedForeground};
    }

    &:focus-visible {
        outline: none;
        box-shadow:
            0 0 0 0.2rem ${BASE_TOKENS.grey[0]},
            0 0 0 0.4rem ${BASE_TOKENS.grey[400]};
    }
`

type TextAreaProps = {
    isDisabled?: boolean
    label?: string
    value?: string
    className?: string
    placeholder?: string
    minRows?: number
    maxRows?: number
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
    onChange: (
        value: string,
        e?: React.ChangeEvent<HTMLTextAreaElement>
    ) => void
}

const TextArea = ({
    isDisabled = false,
    label,
    value,
    placeholder,
    className,
    minRows = 3,
    maxRows = 6,
    onBlur = emptyFunc,
    onChange = emptyFunc,
}: TextAreaProps) => {
    const _onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e.target.value, e)
    }

    return (
        <StyledTextAreaContainer>
            {label && <StyledLabel>{label}</StyledLabel>}
            <StyledTextArea
                disabled={isDisabled}
                value={value}
                minRows={minRows}
                maxRows={maxRows}
                placeholder={placeholder}
                className={className}
                onBlur={onBlur}
                onChange={_onChange}
            />
        </StyledTextAreaContainer>
    )
}

export default TextArea
