import { Navigate, Route } from 'react-router-dom'
import { ShipperPortalRoute } from '../../routes/ShipperPortalRoute'
// import { MotherComponent } from 'modules/MotherComponent2';
import MotherComponent from 'modules/MotherComponent'
import { ShipperPortalOrders } from 'shipperPortal/modules/Orders'
import { OrderDetails } from 'shipperPortal/modules/OrderDetails'

export const ShipperPortalRoutes = () => {
    return (
        <Route path="sp" element={<ShipperPortalRoute />}>
            <Route element={<MotherComponent />}>
                <Route
                    index
                    element={
                        <Navigate
                            replace
                            to="/sp/shipments"
                            state={{ from: '/' }}
                        />
                    }
                />
                <Route path="shipments" element={<ShipperPortalOrders />} />
                <Route path="shipments/:id" element={<OrderDetails />} />
            </Route>
        </Route>
    )
}
