import { useState } from 'react'
import { getCTSDK } from 'services/ctClient'
import { downloadCSV } from 'utils/csv.utils'
import { useFindDocuments } from 'modules/Payables/hooks/useFindDocuments'
import { useQueuedPayables } from 'hooks/useQueuedPayables'
import { usePayablesStatusCounts } from 'hooks/usePayablesStatusCounts'
import { useBoard, TABLE_ROW_LIMIT } from 'components/composite/Board'
import { showToastError, showToastSuccess } from 'hooks/useToast'
import Payables from './components/Payables'
import { useFindPayables } from './hooks/useFindPayables'
import { useProcessPayables } from './hooks/useProcessPayables'
import { mapPayablesToCSVRows } from './utils/csv.utils'
import {
    PAYABLE_STATUS_GROUPS,
    PAYABLE_STATUS_GROUP_IDS,
} from './payables.constants'
import { useMyOrgSettings } from 'hooks/useMyOrgSettings'
import { useNavigate } from 'react-router-dom'
import { isOrdersModuleFFEnabled } from 'utils/featureFlag.utils'

const ctSDK = getCTSDK()

const PayablesContainer = () => {
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false)
    const [isProcessDialogOpen, setIsProcessDialogOpen] = useState(false)

    const navigate = useNavigate()
    const { isLoading: isLoadingSettings, settings } = useMyOrgSettings()

    const { isSubmitting: isProcessing, onProcess } = useProcessPayables()
    const {
        isLoading: isLoadingPayables,
        isLastPage,
        isFirstPage,
        isRefetching: isRefetchingPayables,
        total,
        offset,
        statusCounts,
        selectedTabId,
        selectedRowIds: selectedPayableIds,
        rows: payables,
        error: payablesError,
        refetchRows: refetchPayables,
        refetchStatusCounts,
        onClickTab,
        onNextPage,
        onSelectRow: onSelectPayable,
        onPreviousPage,
        setSelectedTabId,
        setSelectedRowIds: setSelectedPayableIds,
        onSelectAllRows: onSelectAllPayables,
    } = useBoard({
        defaultTabId: PAYABLE_STATUS_GROUP_IDS.process,
        statusGroupsMap: PAYABLE_STATUS_GROUPS,
        useRowsFn: useFindPayables,
        useRowCountsFn: usePayablesStatusCounts,
    })
    const {
        isLoading: isLoadingDocuments,
        isRefetching: isRefetchingDocuments,
        documents: unparsedDocuments = [],
        error: documentsError,
        refetchDocuments,
    } = useFindDocuments({
        limit: TABLE_ROW_LIMIT,
        offset,
        inStatusIds: ['unparsed'],
    })
    const {
        isLoading: isLoadingQueuedPayables,
        isRefetching: isRefetchingQueuedPayables,
        error: queuedPayablesError,
        payables: queuedPayables,
        refetch: refetchQueuedPayables,
    } = useQueuedPayables()

    const _onRefetchPayables = async () => {
        refetchPayables()
        refetchStatusCounts()
        refetchQueuedPayables()
    }

    const _onDownloadCSV = () => {
        if (!selectedPayableIds.length) {
            showToastError('No CSV to download')
            return
        }

        const selectedPayables = payables.filter((payable) =>
            selectedPayableIds.includes(payable.id)
        )

        const csvRows = mapPayablesToCSVRows(selectedPayables)
        downloadCSV('payables', csvRows)
        setSelectedPayableIds([])
    }

    const _onProcessPayables = async () => {
        setIsProcessDialogOpen(false)

        try {
            await onProcess(selectedPayableIds)
            showToastSuccess('Payables added to queue')
            _onRefetchPayables()
        } catch (error) {
            showToastError('Error processing payables')
        } finally {
            setSelectedPayableIds([])
        }
    }

    const _onUploadPayables = async (files: File[]) => {
        if (!files.length) {
            showToastError('No files to upload')
            return
        }

        try {
            const formData = new FormData()

            for (const file of files) {
                formData.append('files', file)
            }

            await ctSDK.post(`/payables/splitAndUpload`, formData)

            setSelectedTabId(PAYABLE_STATUS_GROUP_IDS.process)
            setIsUploadDialogOpen(false)
            refetchDocuments()
            _onRefetchPayables()
        } catch (error) {
            showToastError('Error uploading documents')
        }
    }

    if (isOrdersModuleFFEnabled(settings)) {
        navigate('/orders', { replace: true })
        return null
    }

    if (payablesError || documentsError || queuedPayablesError) {
        // showToastError('Error getting payables');
    }

    return (
        <Payables
            isLoading={
                isLoadingPayables ||
                isLoadingDocuments ||
                isLoadingQueuedPayables ||
                isLoadingSettings
            }
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
            isRefetching={
                isRefetchingPayables ||
                isRefetchingDocuments ||
                isRefetchingQueuedPayables
            }
            isSubmitting={isProcessing}
            isUploadDialogOpen={isUploadDialogOpen}
            isProcessDialogOpen={isProcessDialogOpen}
            limit={TABLE_ROW_LIMIT}
            offset={offset}
            numPayables={total}
            selectedTabId={selectedTabId}
            statusCounts={statusCounts}
            // Small hack to render "loading" rows when document is being parsed
            // @ts-ignore
            payables={[...unparsedDocuments, ...payables]}
            queuedPayables={queuedPayables}
            selectedPayableIds={selectedPayableIds}
            onNextPage={onNextPage}
            onClickTab={onClickTab}
            onRefetchRows={_onRefetchPayables}
            onDownloadCSV={_onDownloadCSV}
            onPreviousPage={onPreviousPage}
            onSelectPayable={onSelectPayable}
            onUploadPayables={_onUploadPayables}
            onProcessPayables={_onProcessPayables}
            onSelectAllPayables={onSelectAllPayables}
            onToggleUploadDialogOpen={setIsUploadDialogOpen}
            onToggleProcessDialogOpen={setIsProcessDialogOpen}
        />
    )
}

export default PayablesContainer
