import Board from 'components/composite/Board/Board'
import Button from 'components/core/Button'
import { Receivable } from 'types/receivable.types'
import ProcessReceivablesDialog from 'dialogs/ProcessReceivablesDialog'
import { tableColumns } from '../receivables.columns'
import {
    RECEIVABLE_STATUS_GROUP_IDS,
    RECEIVABLE_STATUS_GROUP_LABELS,
} from '../receivables.constants'
import {
    canReceivableBeInvoiced,
    canReceivableBeMarkedAsInvoiced,
} from '../utils/receivables.utils'
import Menu, { MenuItem } from 'components/core/Menu'
import DropdownButton from 'components/core/DropdownButton'
import Typography from 'components/core/Typography'

const emptyFunc = () => undefined

const getTabLabel = ({
    label,
    count,
}: {
    label: string
    count?: number
}): string => {
    return `${label}${count !== undefined ? ` (${count})` : ''}`
}

type ReceivablesProps = {
    isLoading: boolean
    isLastPage: boolean
    isFirstPage: boolean
    isRefetching: boolean
    isSubmitting: boolean
    isProcessDialogOpen: boolean
    limit: number
    offset: number
    numRows: number
    selectedTabId: string
    statusCounts?: { [key in string]: number }
    rows: Receivable[]
    queuedRows: Receivable[]
    selectedRowIds: string[]
    onNextPage: () => void
    onClickTab: (tabId: string) => void
    onRefetchRows: () => void
    onPreviousPage: () => void
    onSelectAllRows: (isChecked: boolean) => void
    onProcessReceivables: () => void
    onToggleProcessDialogOpen: (isOpen: boolean) => void
    onSelectRow: ({
        isChecked,
        id,
        index,
    }: {
        isChecked: boolean
        id: string
        index: number
    }) => void
}

const Receivables = ({
    isLoading,
    isLastPage,
    isFirstPage,
    isRefetching,
    isSubmitting,
    isProcessDialogOpen,
    limit,
    offset,
    numRows,
    selectedTabId,
    statusCounts,
    rows,
    queuedRows,
    selectedRowIds,
    onNextPage = emptyFunc,
    onClickTab = emptyFunc,
    onRefetchRows = emptyFunc,
    onPreviousPage = emptyFunc,
    onSelectRow = emptyFunc,
    onSelectAllRows = emptyFunc,
    onProcessReceivables = emptyFunc,
    onToggleProcessDialogOpen = emptyFunc,
}: ReceivablesProps) => {
    const _onToggleProcessDialogOpen = (isOpen: boolean) => () =>
        onToggleProcessDialogOpen(isOpen)

    const tabs = [
        {
            id: RECEIVABLE_STATUS_GROUP_IDS.invoice,
            label: getTabLabel({
                label: RECEIVABLE_STATUS_GROUP_LABELS[
                    RECEIVABLE_STATUS_GROUP_IDS.invoice
                ],
                count: statusCounts?.[RECEIVABLE_STATUS_GROUP_IDS.invoice],
            }),
        },
        {
            id: RECEIVABLE_STATUS_GROUP_IDS.review,
            label: getTabLabel({
                label: RECEIVABLE_STATUS_GROUP_LABELS[
                    RECEIVABLE_STATUS_GROUP_IDS.review
                ],
                count: statusCounts?.[RECEIVABLE_STATUS_GROUP_IDS.review],
            }),
        },
        {
            id: RECEIVABLE_STATUS_GROUP_IDS.invoiced,
            label: getTabLabel({
                label: RECEIVABLE_STATUS_GROUP_LABELS[
                    RECEIVABLE_STATUS_GROUP_IDS.invoiced
                ],
                count: statusCounts?.[RECEIVABLE_STATUS_GROUP_IDS.invoiced],
            }),
        },
        {
            id: RECEIVABLE_STATUS_GROUP_IDS.manuallyInvoiced,
            label: getTabLabel({
                label: RECEIVABLE_STATUS_GROUP_LABELS[
                    RECEIVABLE_STATUS_GROUP_IDS.manuallyInvoiced
                ],
                count: statusCounts?.[
                    RECEIVABLE_STATUS_GROUP_IDS.manuallyInvoiced
                ],
            }),
        },
    ]

    const buttons = [
        <Button
            isDisabled={
                !selectedRowIds.some(
                    (id) =>
                        canReceivableBeInvoiced(
                            rows.find((row) => row.id === id)?.status
                        ) ||
                        canReceivableBeMarkedAsInvoiced(
                            rows.find((row) => row.id === id)?.status
                        )
                )
            }
            key="process"
            variant="primary"
            onClick={_onToggleProcessDialogOpen(true)}
        >
            Process
        </Button>,
    ]

    const subButtons = [
        <Menu
            isDisabled={!queuedRows.length}
            trigger={
                <DropdownButton>
                    {getTabLabel({
                        label: 'Processing',
                        count: queuedRows?.length,
                    })}
                </DropdownButton>
            }
        >
            {queuedRows.map((r) => {
                return (
                    <MenuItem isClickable={false} key={r.id}>
                        <Typography color="primary" variant="body2">
                            {r.rrInvoiceNumber}
                        </Typography>
                    </MenuItem>
                )
            })}
        </Menu>,
    ]

    return (
        <>
            <ProcessReceivablesDialog
                isOpen={isProcessDialogOpen}
                onClose={_onToggleProcessDialogOpen(false)}
                onConfirm={onProcessReceivables}
            />
            <Board
                isLoading={isLoading}
                isLastPage={isLastPage}
                isFirstPage={isFirstPage}
                isRefetching={isRefetching}
                isSubmitting={isSubmitting}
                limit={limit}
                offset={offset}
                header="Receivables"
                numRows={numRows}
                selectedTabId={selectedTabId}
                rows={rows}
                tabs={tabs}
                columns={tableColumns}
                buttons={buttons}
                subButtons={subButtons}
                selectedRowIds={selectedRowIds}
                onNextPage={onNextPage}
                onClickTab={onClickTab}
                onSelectRow={onSelectRow}
                onRefetchRows={onRefetchRows}
                onPreviousPage={onPreviousPage}
                onSelectAllRows={onSelectAllRows}
            />
        </>
    )
}

export default Receivables
