import styled from '@emotion/styled'
import Column from 'components/core/Column'
import { SPACING } from 'constants/spacing.constants'

export const LoginFormRoot = styled(Column)`
    height: 100%;
    justify-content: center;
    align-items: center;
    row-gap: ${SPACING.p3};
    padding-left: ${SPACING.p5};
    padding-right: ${SPACING.p5};
`
