import { useMutation } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { Carrier } from 'types/carrier.types'

const sdk = getCTSDK()

export const useCreateCarrier = () => {
    return useMutation((data: Partial<Carrier>) =>
        sdk.post<{ carrier: Carrier }>('/carriers', {
            data,
        })
    )
}
