import { useQuery } from 'react-query'
import { Org } from 'types/org.types'
import { getCTSDK } from 'services/ctClient'

const ctSDK = getCTSDK()

export const useMyOrgs = () => {
    const { isLoading, data } = useQuery(
        ['useMyOrgs'],
        async () => await ctSDK.get<{ orgs: Org[] }>('/orgs/myOrgs')
    )

    return {
        isLoading,
        orgs: data?.data?.orgs || [],
    }
}
