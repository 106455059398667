import { useState } from 'react'
import { useCreateCarrier } from 'mutations/carrier.mutations'
import { showToastError, showToastSuccess } from 'hooks/useToast'
import CreateCarrierDialog from './components/CreateCarrierDialog'

type CreateCarrierDialogContainerProps = {
    isOpen: boolean
    onClose: () => void
    onSuccess: () => void
}

const CreateCarrierDialogContainer = ({
    isOpen,
    onClose,
    onSuccess,
}: CreateCarrierDialogContainerProps) => {
    const [name, setName] = useState<string>()
    const [contactEmail, setBillingEmail] = useState<string>()

    const { isLoading: isSubmitting, mutateAsync: createCarrier } =
        useCreateCarrier()

    const _onClear = () => {
        setName(undefined)
        setBillingEmail(undefined)
    }

    const _onClose = () => {
        onClose()
        _onClear()
    }

    const _onSave = async () => {
        try {
            await createCarrier({
                name,
                contactEmail,
            })
            showToastSuccess('Carrier created')
            onClose()
            onSuccess()
            _onClear()
        } catch (error) {
            showToastError('Error creating carrier')
        }
    }

    return (
        <CreateCarrierDialog
            isOpen={isOpen}
            isSubmitting={isSubmitting}
            name={name}
            contactEmail={contactEmail}
            onSave={_onSave}
            onClose={_onClose}
            onChangeName={setName}
            onChangeContactEmail={setBillingEmail}
        />
    )
}

export default CreateCarrierDialogContainer
