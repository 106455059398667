import { useState } from 'react'
import { useMyOrg } from 'hooks/useMyOrg'
import { isTestOrg } from 'utils/org.utils'
import { useGetMyOrgSettings } from 'queries/orgSettings.queries'
import { useRRPayableSettings } from 'integrations/roserocket/components/RRPayableSettings'
import { useUpdateMyOrgSettings } from 'mutations/orgSettings.mutations'
import { showToastError, showToastSuccess } from 'hooks/useToast'
import PayableSettings from './components/PayableSettings'
import { useGetMyUsers } from 'queries/user.queries'

const PayableSettingsContainer = () => {
    const [acceptableLowerRange, setAcceptableLowerRange] = useState<number>()
    const [acceptableUpperRange, setAcceptableUpperRange] = useState<number>()
    const [isAutoProcessEnabled, setIsAutoProcessEnabled] =
        useState<boolean>(false)
    const [userIdAutoProcessAs, setUserIdAutoProcessAs] = useState<string>()
    const [isUseInvoiceDateEnabled, setIsUseInvoiceDateEnabled] =
        useState<boolean>(false)

    const { org } = useMyOrg()
    const { isLoading: isLoadingUsers, data } = useGetMyUsers()
    const { isLoading: isSubmitting, mutateAsync: updateMyOrgSettings } =
        useUpdateMyOrgSettings()
    const { isLoading: isLoadingSettings } = useGetMyOrgSettings({
        onSuccess: (data) => {
            setAcceptableLowerRange(
                data?.data?.settings?.payables?.acceptableLowerRange
            )
            setAcceptableUpperRange(
                data?.data?.settings?.payables?.acceptableUpperRange
            )
            setIsAutoProcessEnabled(
                !!data?.data?.settings?.payables?.isAutoProcessEnabled
            )
            setUserIdAutoProcessAs(
                data?.data?.settings?.payables?.userIdAutoProcessAs
            )
            setIsUseInvoiceDateEnabled(
                !!data?.data?.settings?.payables?.isUseInvoiceDateEnabled
            )
        },
    })

    const autoProcessAsOptions =
        data?.data?.users?.map((user) => {
            return {
                value: user.id,
                label: `${user.firstName} ${user.lastName}`,
            }
        }) || []

    const {
        isLoading: isLoadingRRSettings,
        isSubmitting: isSubmittingRRSettings,
        onSaveSettings: onSaveRRSettings,
        onChangeVendors,
        onFetchInitVendors,
        onChangeSkipCarriers,
        onFetchCarrierOptions,
        onFetchInitSkipCarriers,
        onChangeBillableCarriers,
        onFetchInitBillableCarriers,
        onChangeInvoiceDateCarriers,
        onFetchInitInvoiceDateCarriers,
    } = useRRPayableSettings()

    const _onSave = async () => {
        try {
            await updateMyOrgSettings({
                payables: {
                    isAutoProcessEnabled,
                    isUseInvoiceDateEnabled,
                    userIdAutoProcessAs,
                    acceptableLowerRange,
                    acceptableUpperRange,
                },
            })
            await onSaveRRSettings()

            showToastSuccess('Settings saved')
        } catch (error) {
            showToastError('Error saving settings')
        }
    }

    return (
        <PayableSettings
            isTestOrg={isTestOrg(org)}
            isLoading={
                isLoadingUsers || isLoadingSettings || isLoadingRRSettings
            }
            isSubmitting={isSubmitting || isSubmittingRRSettings}
            userIdAutoProcessAs={userIdAutoProcessAs}
            isAutoProcessEnabled={isAutoProcessEnabled}
            acceptableLowerRange={acceptableLowerRange}
            acceptableUpperRange={acceptableUpperRange}
            autoProcessAsOptions={autoProcessAsOptions}
            isUseInvoiceDateEnabled={isUseInvoiceDateEnabled}
            onChangeIsUseInvoiceDateEnabled={setIsUseInvoiceDateEnabled}
            onSaveSettings={_onSave}
            onChangeVendors={onChangeVendors}
            onFetchInitVendors={onFetchInitVendors}
            onChangeSkipCarriers={onChangeSkipCarriers}
            onFetchCarrierOptions={onFetchCarrierOptions}
            onFetchInitSkipCarriers={onFetchInitSkipCarriers}
            onChangeBillableCarriers={onChangeBillableCarriers}
            onFetchInitBillableCarriers={onFetchInitBillableCarriers}
            onChangeInvoiceDateCarriers={onChangeInvoiceDateCarriers}
            onChangeUserIdAutoProcessAs={setUserIdAutoProcessAs}
            onChangeAcceptableLowerRange={setAcceptableLowerRange}
            onChangeAcceptableUpperRange={setAcceptableUpperRange}
            onChangeIsAutoProcessEnabled={setIsAutoProcessEnabled}
            onFetchInitInvoiceDateCarriers={onFetchInitInvoiceDateCarriers}
        />
    )
}

export default PayableSettingsContainer
