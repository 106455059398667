import { DetailsRow } from './DetailsRow'
import { OrderChangeProp } from '../orderDetails.types'
import { Order } from 'types/order.types'
import TextArea from 'components/core/TextArea'

type OrderDetailsInternalNotesSectionProps = {
    isEditable: boolean
    order?: Order
    orderMutation?: Partial<Order>
    onClosePopup: (propName: OrderChangeProp) => void
    onChangeOrder: (orderMutation: Partial<Order>) => void
}

export const OrderDetailsInternalNotesSection = ({
    isEditable,
    order,
    orderMutation,
    onClosePopup,
    onChangeOrder,
}: OrderDetailsInternalNotesSectionProps) => {
    const { internalNotes } = order || {}
    const { internalNotes: internalNotesMutation } = orderMutation || {}

    const _onClosePopup = (propName: OrderChangeProp) => () => {
        onClosePopup(propName)
    }

    const _onChangeOrder = (propName: OrderChangeProp) => (value: string) => {
        onChangeOrder({ [propName]: value })
    }

    return (
        <DetailsRow
            isEditable={isEditable}
            caption="Internal Notes"
            valueText={internalNotes || ''}
            onClosePopup={_onClosePopup('internalNotes')}
        >
            <TextArea
                minRows={5}
                maxRows={18}
                value={internalNotesMutation || ''}
                onChange={_onChangeOrder('internalNotes')}
            />
        </DetailsRow>
    )
}
