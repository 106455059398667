import { AutomationStatus } from 'types/automation.types'
import {
    AutomationActionStatus,
    AutomationActionType,
} from 'types/automationAction.types'
import { AutomationEventType } from 'types/automationEvent.types'
import { AUTOMATION_STATUS_LABELS } from '../automations.constants'

export const getAutomationActionSummary = ({
    eventType,
    actionType,
    actionStatus,
    eventCreatedAt,
    actionScheduledAt,
    automationStatus,
}: {
    eventType?: AutomationEventType
    actionType?: AutomationActionType
    actionStatus?: AutomationActionStatus
    eventCreatedAt?: string
    actionScheduledAt?: string
    automationStatus?: AutomationStatus
}): string | undefined => {
    if (!actionType || !actionStatus) {
        return
    }

    if (
        eventCreatedAt &&
        actionScheduledAt &&
        eventCreatedAt > actionScheduledAt
    ) {
        if (eventType === 'automationStatusChanged' && automationStatus) {
            return `We updated task status to "${AUTOMATION_STATUS_LABELS[automationStatus]}"`
        }
    }

    if (actionType === 'sendCheckEmail') {
        if (actionStatus === 'scheduled') {
            return `We're requesting an ETA update`
        }
        if (actionStatus === 'requiresReview') {
            return `We want to request an ETA update`
        }
        if (['completed', 'manualCompleted'].includes(actionStatus)) {
            return `We requested an ETA update`
        }
        if (actionStatus === 'error') {
            return `An error occured while trying to request an ETA update`
        }
    }
    if (actionType === 'sendOrderConfirmationEmail') {
        if (actionStatus === 'completed') {
            return `We sent an e-tracking link`
        }
    }
}

export const getAvailableActions = ({
    automationStatus,
    currentActionType,
    currentActionStatus,
}: {
    automationStatus?: AutomationStatus
    currentActionType?: AutomationActionType
    currentActionStatus?: AutomationActionStatus
}): string[] => {
    const actions: string[] = []

    if (!currentActionType || currentActionType !== 'sendCheckEmail') {
        return actions
    }
    if (!currentActionStatus) {
        return actions
    }

    if (
        automationStatus === 'requiresReview' &&
        currentActionStatus === 'completed'
    ) {
        actions.push('markAsCompleted')
        actions.push('delete')
        return actions
    }
    if (currentActionStatus === 'scheduled') {
        actions.push('unschedule')
        return actions
    }
    if (currentActionStatus === 'requiresReview') {
        actions.push('schedule')
        actions.push('markAsCompleted')
        actions.push('delete')
        return actions
    }
    if (currentActionStatus === 'error') {
        actions.push('delete')
        return actions
    }

    return actions
}
