import { SelectAsync, SelectOption } from 'components/core/Select'
import { BookUser, LucideIcon } from 'lucide-react'
import { useState } from 'react'
import { getCTSDK } from 'services/ctClient'
import { Customer } from 'types/customer.types'

const sdk = getCTSDK()

export type CustomerAsyncSelectProps = {
    label?: string
    placeholder?: string
    icon?: LucideIcon
    error?: string
    onChangeCustomer: (customerId: string, customer?: Customer) => void
}

export const CustomerAsyncSelect = ({
    label,
    placeholder = 'Search customers',
    icon = BookUser,
    error,
    onChangeCustomer,
}: CustomerAsyncSelectProps) => {
    const [customers, setCustomers] = useState<Customer[]>([])

    const _onFetchCustomerOptions = async (
        name: string
    ): Promise<SelectOption[]> => {
        try {
            const resp = await sdk.get<{ customers: Customer[] }>(
                '/customers',
                {
                    params: {
                        limit: 10,
                        offset: 0,
                        name,
                    },
                }
            )

            const customers = resp.data?.customers || []

            const fetchedOptions = customers.map((customer) => ({
                value: customer.id,
                label: customer.name,
            }))

            setCustomers(customers)

            return fetchedOptions
        } catch (error) {
            console.error('Error fetching customer options:', error)
            return []
        }
    }

    const _onSelectedCustomersChanged = (options: SelectOption[]) => {
        const customerOption = options[0]
        const customerId = customerOption.value

        const selectedCustomer = customers.find((c) => c.id === customerId)
        onChangeCustomer(customerId, selectedCustomer)
    }

    return (
        <SelectAsync
            label={label}
            placeholder={placeholder}
            Icon={icon}
            error={error}
            onFetchOptions={_onFetchCustomerOptions}
            onSelectedOptionsChanged={_onSelectedCustomersChanged}
        />
    )
}
