import { Address } from 'types/address.types'
import { Destination, Order } from 'types/order.types'
import { ORDER_COMMODITY_DEFAULT } from '../orderDetails.constants'
import { v4 as uuidv4 } from 'uuid'
import { OrderCommodity } from 'types/orderCommodity.types'
import {
    DestinationType,
    OrderChangeProp,
    OrderDetailsAction,
    OrderDocumentItem,
} from '../orderDetails.types'

export const getAvailableOrderActions = (
    order?: Order
): OrderDetailsAction[] => {
    if (!order) {
        return []
    }

    const actions: OrderDetailsAction[] = []

    if (order.deliveredAt) {
    } else if (order.pickedUpAt) {
        actions.push('markAsDelivered')
    } else if (order.bookedAt) {
        actions.push('markAsPickedUp')
    } else if (order.createdAt) {
        actions.push('assignCarrier')
    }

    if (order.trackingToken) {
        actions.push('getTrackingLink')
    }

    return actions
}

export const checkIfAddressessAreEqual = (
    address1: Partial<Address> | undefined,
    address2: Partial<Address> | undefined
): boolean => {
    return (
        address1?.address1 === address2?.address1 &&
        address1?.address2 === address2?.address2 &&
        address1?.city === address2?.city &&
        address1?.state === address2?.state &&
        address1?.country === address2?.country &&
        address1?.postalCode === address2?.postalCode &&
        address1?.name === address2?.name &&
        address1?.placeId === address2?.placeId &&
        address1?.phoneNumber === address2?.phoneNumber &&
        address1?.phoneNumberExt === address2?.phoneNumberExt &&
        address1?.contactName === address2?.contactName &&
        address1?.businessHoursStartAt === address2?.businessHoursStartAt &&
        address1?.businessHoursEndAt === address2?.businessHoursEndAt
    )
}

export const createNewEmptyCommodity = () => {
    return { id: `new-${uuidv4()}`, ...ORDER_COMMODITY_DEFAULT }
}

export const deleteAllNewCommodityIds = (
    commodities: { id?: string; [key: string]: any }[]
) => {
    return commodities.map((commodity) => {
        if (commodity.id?.includes('new-')) {
            return { ...commodity, id: undefined }
        }
        return commodity
    })
}

export const compareCommodity = (
    commodityOld: Partial<OrderCommodity>,
    commodityNew: Partial<OrderCommodity>
) => {
    return (
        commodityOld.id === commodityNew.id &&
        commodityOld.mode === commodityNew.mode &&
        commodityOld.description === commodityNew.description &&
        commodityOld.quantity === commodityNew.quantity &&
        commodityOld.freightType === commodityNew.freightType &&
        commodityOld.length === commodityNew.length &&
        commodityOld.width === commodityNew.width &&
        commodityOld.height === commodityNew.height &&
        commodityOld.pieces === commodityNew.pieces &&
        commodityOld.weight === commodityNew.weight &&
        commodityOld.class === commodityNew.class
    )
}

export const compareCommodities = (
    commoditiesOld: Partial<OrderCommodity>[],
    commoditiesNew: Partial<OrderCommodity>[]
) => {
    let isSame = true

    if (commoditiesOld.length !== commoditiesNew.length) {
        return false
    }

    for (let i = 0; i < commoditiesOld.length; i++) {
        if (!compareCommodity(commoditiesOld[i], commoditiesNew[i])) {
            isSame = false
            break
        }
    }

    return isSame
}

export const checkIfOrderHasChanged = ({
    propName,
    order,
    newOrder,
    index,
    destinationType,
}: {
    propName: OrderChangeProp
    order: Partial<Order>
    newOrder: Partial<Order>
    index?: number
    destinationType?: DestinationType
}): boolean => {
    if (!newOrder || !order) {
        return false
    }

    let destination: Partial<Destination> | undefined
    let newDestination: Partial<Destination> | undefined

    if (index === undefined) {
        if (destinationType === 'pickup') {
            destination = order.pickup
            newDestination = newOrder.pickup
        } else {
            destination = order.dropoff
            newDestination = newOrder.dropoff
        }
    } else {
        if (destinationType === 'pickup') {
            destination = order.extraPickups?.[index]
            newDestination = newOrder.extraPickups?.[index]
        } else {
            destination = order.extraDropoffs?.[index]
            newDestination = newOrder.extraDropoffs?.[index]
        }
    }

    if (propName === 'address') {
        if (checkIfAddressessAreEqual(destination, newDestination)) {
            return false
        }
    } else if (propName === 'arrivalStartAt') {
        if (
            newDestination?.arrivalStartAt === destination?.arrivalStartAt &&
            newDestination?.arrivalEndAt === destination?.arrivalEndAt
        ) {
            return false
        }
    } else if (propName === 'referenceNumber') {
        if (newDestination?.referenceNumber === destination?.referenceNumber) {
            return false
        }
    } else if (propName === 'internalNotes') {
        if (newOrder.internalNotes === order.internalNotes) {
            return false
        }
    } else if (propName === 'equipment') {
        if (newOrder.equipment?.join('-') === order.equipment?.join('-')) {
            return false
        }
    } else if (propName === 'specialInstructions') {
        if (
            newDestination?.specialInstructions ===
            destination?.specialInstructions
        ) {
            return false
        }
    } else if (propName === 'isAppointmentRequired') {
        if (
            newDestination?.isAppointmentRequired ===
            destination?.isAppointmentRequired
        ) {
            return false
        }
    } else {
        if (order[propName] === newOrder[propName]) {
            return false
        }
    }

    return true
}

export const getMissingRateConProperties = (
    order: Partial<Order>
): string[] | undefined => {
    const missingValues = []

    if (!order?.carrierName) {
        missingValues.push('Carrier')
    }
    if (!order.commodities?.length) {
        missingValues.push('Commodities')
    }
    if (!order.pickup?.address1) {
        missingValues.push('Pickup address')
    }
    if (!order.dropoff?.address1) {
        missingValues.push('Dropoff address')
    }
    if (!order.carrierCost) {
        missingValues.push('Carrier cost')
    }

    return missingValues.length ? missingValues : undefined
}

export const generateOrderDocumentItems = (
    order: Partial<Order> | undefined,
    deps: { onGenerateRateCon: () => Promise<void> }
): OrderDocumentItem[] => {
    if (!order?.id || !order?.fullId) {
        return []
    }

    const documentItems: OrderDocumentItem[] = []

    if (order.bookedAt) {
        const missingRateConProperties = getMissingRateConProperties(order)
        documentItems.push({
            id: 'rateConfirmation',
            type: 'rateConfirmation',
            label: 'Rate confirmation',
            isDisabled: !!missingRateConProperties,
            tooltip: missingRateConProperties ? (
                <div>
                    Order is missing the following properties:
                    <ul>
                        {missingRateConProperties.map((property, index) => (
                            <li key={index}>- {property}</li>
                        ))}
                    </ul>
                </div>
            ) : undefined,
            onDownload: deps.onGenerateRateCon,
        })
    }

    return documentItems
}
