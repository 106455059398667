import { useEffect, useRef } from 'react'
import { ReceivableStatus } from 'types/receivable.types'
import { useGetReceivablesStatusCounts } from 'queries/receivable.queries'

export const useReceivablesStatusCounts = (params: {
    createdAtStartAt?: string
    inStatusIdsMap: {
        [key: string]: ReceivableStatus[]
    }
}) => {
    const ws = useRef<WebSocket | null>(null)

    const { data, refetch } = useGetReceivablesStatusCounts(params)

    useEffect(() => {
        ws.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL_2 || '')

        ws.current.onmessage = (event) => {
            const wsEvent = JSON.parse(event.data)

            if (
                [
                    'receivableCreated',
                    'receivableProcessed',
                    'receivableDeleted',
                ].includes(wsEvent.type)
            ) {
                refetch()
            }
        }

        return () => {
            ws.current?.close()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { statusCounts: data?.data?.counts, refetchStatusCounts: refetch }
}
