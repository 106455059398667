import { useState } from 'react'
import { showToastError, showToastSuccess } from 'hooks/useToast'
import { CreateUserDialog } from './components/CreateUserDialog'
import { User } from 'types/user.types'
import { useCreateUser } from 'mutations/user.mutations'
import {
    PropNameChange,
    UserValidationError,
} from './CreateUserDialogContainer.types'

type CreateUserDialogContainerProps = {
    isOpen: boolean
    onClose: () => void
    onSuccess: () => void
}

const DEFAULT_USER: Partial<User> = {
    role: 'user',
}

export const CreateUserDialogContainer = ({
    isOpen,
    onClose,
    onSuccess,
}: CreateUserDialogContainerProps) => {
    const [user, setUser] = useState<Partial<User> | undefined>(DEFAULT_USER)
    const [errors, setErrors] = useState<UserValidationError | undefined>()

    const { isLoading: isSubmitting, mutateAsync: createUser } = useCreateUser()

    const _onClear = () => {
        setUser(DEFAULT_USER)
    }

    const _onClose = () => {
        onClose()
        _onClear()
    }

    const _onValidate = (user?: Partial<User>) => {
        const errors: UserValidationError = {}

        if (!user?.role) {
            errors.role = 'Role is required'
        }
        if (user?.role === 'customerUser' && !user?.customerId) {
            errors.customerId = 'Customer is required'
        }
        if (!user?.email) {
            errors.email = 'Email is required'
        }
        if (!user?.firstName) {
            errors.firstName = 'First Name is required'
        }
        if (!user?.lastName) {
            errors.lastName = 'Last Name is required'
        }

        if (!Object.keys(errors).length) {
            setErrors(undefined)
            return
        }

        setErrors(errors)
        return errors
    }

    const _onSave = async () => {
        try {
            const errors = _onValidate(user)
            if (errors || !user) {
                showToastError('Please fill in all required fields')
                return
            }

            await createUser(user)

            showToastSuccess('User created')
            onClose()
            onSuccess()
            _onClear()
        } catch (error) {
            showToastError('Error creating a user')
        }
    }

    const _onChangeUser = (user: Partial<User>, propName: PropNameChange) => {
        setErrors({
            ...errors,
            [propName]: undefined,
        })
        setUser(user)
    }

    return (
        <CreateUserDialog
            isOpen={isOpen}
            isSubmitting={isSubmitting}
            user={user}
            errors={errors}
            onSave={_onSave}
            onClose={_onClose}
            onChangeUser={_onChangeUser}
        />
    )
}
