export const removeSpaces = (str: string) => str.replace(/\s+/g, '')

export const removeDuplicates = (
    str: string,
    options: { separator: string } = { separator: ',' }
) => {
    const strArray = str.split(options.separator)
    const uniqueArray = Array.from(new Set(strArray))
    return uniqueArray.join(options.separator)
}
