import { ReceivableStatus } from 'types/receivable.types'
import {
    canReceivableBeDeleted,
    canReceivableBeInvoiced,
    canReceivableBeMarkedAsInvoiced,
} from '../../utils/receivables.utils'

export const getAvailableActions = ({
    status,
}: {
    status?: ReceivableStatus
}): string[] => {
    const actions: string[] = []

    if (!status) {
        return actions
    }

    if (canReceivableBeInvoiced(status)) {
        actions.push('invoice')
    }
    if (canReceivableBeMarkedAsInvoiced(status)) {
        actions.push('markAsInvoiced')
    }
    if (canReceivableBeDeleted(status)) {
        actions.push('delete')
    }

    return actions
}
