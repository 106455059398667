import { BASE_TOKENS } from 'constants/color.constants'
import { OrderStatus } from 'types/order.types'

export const ORDER_STATUS_LABELS: { [key in OrderStatus]: string } = {
    new: 'New',
    // quoting: 'Quoting',
    booked: 'Booked',
    inTransit: 'In Transit',
    delivered: 'Delivered',
}

export const ORDER_STATUS_BACKGROUND_COLORS: { [key in OrderStatus]: string } =
    {
        new: BASE_TOKENS.grey[50],
        // quoting: BASE_TOKENS.blue[50],
        booked: BASE_TOKENS.pink[50],
        inTransit: BASE_TOKENS.purple[50],
        delivered: BASE_TOKENS.green[50],
    }

export const ORDER_STATUS_BORDER_COLORS: { [key in OrderStatus]: string } = {
    new: BASE_TOKENS.grey[300],
    // quoting: BASE_TOKENS.blue[300],
    booked: BASE_TOKENS.pink[300],
    inTransit: BASE_TOKENS.purple[300],
    delivered: BASE_TOKENS.green[500],
}

export const ORDER_STATUS_TEXT_COLORS: { [key in OrderStatus]: string } = {
    new: BASE_TOKENS.grey[900],
    // quoting: BASE_TOKENS.blue[900],
    booked: BASE_TOKENS.pink[900],
    inTransit: BASE_TOKENS.purple[900],
    delivered: BASE_TOKENS.green[900],
}

type FreightType = 'ltl' | 'tl'

export const ORDER_COMMODITY_TYPE_LONG_LABELS: {
    [key in FreightType]: string
} = {
    ltl: 'Less than truckload',
    tl: 'Truckload',
}
