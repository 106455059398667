import styled from '@emotion/styled'
import * as RadixAvatar from '@radix-ui/react-avatar'
import { BASE_TOKENS } from 'constants/color.constants'

const StyledAvatar = styled(RadixAvatar.Root)`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: ${BASE_TOKENS.slate[200]};
    display: flex;
    align-items: center;
    justify-content: center;
`

export default StyledAvatar
