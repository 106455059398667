import { ReactNode } from 'react'
import styled from '@emotion/styled'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import { CircularProgress } from '@mui/material'

const emptyFunc = () => undefined

export const BUTTON_COLORS = {
    primary: DESIGN_TOKENS.primaryForeground,
    secondary: DESIGN_TOKENS.secondaryForeground,
    ghost: DESIGN_TOKENS.secondaryForeground,
    destructive: BASE_TOKENS.grey[0],
    confirm: BASE_TOKENS.grey[0],
}

export const StyledButton = styled.button<{
    isFullWidth?: boolean
    size?: keyof typeof BUTTON_SIZE_STYLES
}>`
    width: ${(p) => (p.isFullWidth ? '100%' : 'fit-content')};
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;

    ${(p) => BUTTON_SIZE_STYLES[p.size || 'base']}

    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.6rem;
    border-radius: 0.6rem;

    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.4rem;

    transition: background-color 0.3s ease;

    cursor: pointer;

    &:disabled {
        cursor: default;
        opacity: 0.7;
    }

    &:focus-visible {
        outline: none;
        box-shadow:
            0 0 0 0.2rem ${BASE_TOKENS.grey[0]},
            0 0 0 0.4rem ${BASE_TOKENS.grey[400]};
    }
`

const StyledPrimaryButton = styled(StyledButton)`
    color: ${BUTTON_COLORS.primary};
    background-color: ${BASE_TOKENS.grey[950]};
    border: none;

    &:hover {
        background-color: ${BASE_TOKENS.grey[800]};
    }

    &:disabled {
        background-color: ${BASE_TOKENS.grey[950]};
    }
`

const StyledSecondaryButton = styled(StyledButton)`
    color: ${BUTTON_COLORS.secondary};
    background-color: ${BASE_TOKENS.grey[0]};
    border: 1px solid ${DESIGN_TOKENS.input};

    &:hover {
        background-color: ${BASE_TOKENS.grey[100]};
    }

    &:disabled {
        background-color: transparent;
    }
`

const StyledDestructiveButton = styled(StyledButton)`
    color: ${BUTTON_COLORS.destructive};
    background-color: ${BASE_TOKENS.red[600]};
    border: none;

    &:hover {
        background-color: ${BASE_TOKENS.red[500]};
    }

    &:disabled {
        background-color: ${BASE_TOKENS.red[600]};
    }
`

const StyledGhostButton = styled(StyledButton)`
    color: ${BUTTON_COLORS.ghost};
    background-color: transparent;
    border: none;

    &:hover {
        background-color: ${BASE_TOKENS.grey[100]};
    }

    &:disabled {
        background-color: transparent;
    }
`

const StyledConfirmButton = styled(StyledButton)`
    color: ${BUTTON_COLORS.confirm};
    background-color: ${BASE_TOKENS.green[600]};
    border: none;

    &:hover {
        background-color: ${BASE_TOKENS.green[700]};
    }

    &:disabled {
        background-color: ${BASE_TOKENS.green[800]};
    }
`

const BUTTON_VARIANTS = {
    primary: StyledPrimaryButton,
    secondary: StyledSecondaryButton,
    ghost: StyledGhostButton,
    destructive: StyledDestructiveButton,
    confirm: StyledConfirmButton,
}

const BUTTON_SIZE_STYLES = {
    sm: `
        height: 3.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
    `,
    base: `
        height: 4rem;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    `,
}

export type ButtonVariant = keyof typeof BUTTON_VARIANTS

type ButtonProps = {
    isDisabled?: boolean
    isFullWidth?: boolean
    isSubmitting?: boolean
    size?: keyof typeof BUTTON_SIZE_STYLES
    variant: ButtonVariant
    className?: string
    children: ReactNode
    onClick?: () => void
}

const Button = ({
    isDisabled = false,
    isFullWidth = false,
    isSubmitting = false,
    size = 'base',
    variant,
    className,
    children,
    onClick = emptyFunc,
}: ButtonProps) => {
    const Component = BUTTON_VARIANTS[variant]

    return (
        <Component
            disabled={isDisabled || isSubmitting}
            isFullWidth={isFullWidth}
            size={size}
            className={className}
            onClick={onClick}
        >
            {children}
            {isSubmitting && (
                <CircularProgress
                    size={16}
                    style={{ color: BUTTON_COLORS[variant] }}
                />
            )}
        </Component>
    )
}

export default Button
