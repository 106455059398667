import styled from '@emotion/styled'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'

const ANCHOR_TAG_COLORS = {
    primary: DESIGN_TOKENS.text.textForeground,
    secondary: DESIGN_TOKENS.text.textMutedForeground,
}

const ANCHOR_TAG_HOVER_COLORS = {
    primary: BASE_TOKENS.grey[600],
    secondary: BASE_TOKENS.grey[800],
}

const AnchorTag = styled.a<{ color: 'primary' | 'secondary' }>`
    margin: 0;
    font-style: normal;
    font-family: Inter;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: -0.04rem;
    font-weight: 400;
    text-underline-offset: 0.4rem;

    transition: color 0.3s ease;

    &:hover {
        color: ${(p) => ANCHOR_TAG_HOVER_COLORS[p.color]};
    }

    color: ${(p) => ANCHOR_TAG_COLORS[p.color]};
`

export default AnchorTag
