import styled from '@emotion/styled'
import * as RadixNavigationMenu from '@radix-ui/react-navigation-menu'
import { DESIGN_TOKENS } from 'constants/color.constants'

const StyledNavigationMenuItem = styled(RadixNavigationMenu.Item)<{
    isSelected: boolean
}>`
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;

    font-family: Inter;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;

    color: ${(p) =>
        p.isSelected
            ? DESIGN_TOKENS.foreground
            : DESIGN_TOKENS.mutedForeground};
`

export default StyledNavigationMenuItem
