import styled from '@emotion/styled'
import Column from 'components/core/Column'
import { BASE_TOKENS } from 'constants/color.constants'
import { SPACING } from 'constants/spacing.constants'
import { Outlet } from 'react-router-dom'
import { getImageUrl } from 'utils/getImageUrl'

const StyledLogin = styled.div`
    height: 100%;
    display: flex;
`

const LoginLeftPanel = styled(Column)`
    width: 100%;
    background-color: ${BASE_TOKENS.grey[900]};
    justify-content: flex-end;
    padding-top: ${SPACING.p4};
    padding-bottom: ${SPACING.p4};
    padding-left: ${SPACING.p4};
    padding-right: ${SPACING.p4};
`

const LoginRightPanel = styled(Column)`
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    padding-top: ${SPACING.p4};
    padding-bottom: ${SPACING.p3};
`

const LoginLogoContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: ${SPACING.p1};
`

const LoginLogo = styled.img`
    height: 2.4rem;
`

const LoginLogoText = styled.p`
    color: ${BASE_TOKENS.grey[0]};
    font-family: Inter;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
`

export const LoginLayout = () => {
    return (
        <StyledLogin>
            <LoginLeftPanel>
                <LoginLogoContainer>
                    <LoginLogo
                        alt="Logo"
                        src={getImageUrl('shortLogoWhite.png')}
                    />
                    <LoginLogoText>Cartage</LoginLogoText>
                </LoginLogoContainer>
            </LoginLeftPanel>
            <LoginRightPanel>
                <Outlet />
            </LoginRightPanel>
        </StyledLogin>
    )
}
