import { ReactNode, useState } from 'react'
import styled from '@emotion/styled'
import { SCREEN_SIZES } from 'constants/screenSizes.constants'
import { pxToRem } from 'utils/rem.utils'

export const StyledCell = styled.div<{
    isEditable?: boolean
    isEditMode?: boolean
    isHiddenForSmScreen?: boolean
    width: number
}>`
    width: ${(p) => pxToRem(p.width)};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    padding-left: ${(p) => (p.isEditMode ? '0' : '1.6rem')};
    padding-right: ${(p) => (p.isEditMode ? '0' : '1.6rem')};

    &:hover {
        cursor: ${(p) => (p.isEditable ? 'text' : 'inherit')};
    }

    @media (max-width: ${SCREEN_SIZES.lg}) {
        display: ${(p) => (p.isHiddenForSmScreen ? 'none' : 'flex')};
    }
`

type BoardCellProps = {
    isEditable: boolean
    isHiddenForSmScreen?: boolean
    width: number
    CellReadOnly: ReactNode
    CellEditable?: ReactNode
}

const BoardCell = ({
    isEditable,
    isHiddenForSmScreen,
    width,
    CellReadOnly,
    CellEditable,
}: BoardCellProps) => {
    const [isEditMode, setIsEditMode] = useState(false)

    const _onClick = () => {
        if (!isEditable || isEditMode || !CellEditable) {
            return
        }

        setIsEditMode(true)
    }

    const _onBlur = () => {
        setIsEditMode(false)
    }

    return (
        <StyledCell
            isEditable={isEditable}
            isEditMode={isEditMode}
            isHiddenForSmScreen={isHiddenForSmScreen}
            width={width}
            onBlur={_onBlur}
            onClick={_onClick}
        >
            {isEditMode ? CellEditable : CellReadOnly}
        </StyledCell>
    )
}

export default BoardCell
