import { useMutation } from 'react-query'
import { getCTSDK } from 'services/ctClient'

const ctSDK = getCTSDK()

export const useDeletePayable = (id: string) => {
    const { isLoading, error, mutateAsync } = useMutation(() =>
        ctSDK.post(`/payables/${id}/delete`)
    )

    return { isSubmitting: isLoading, error, onDelete: mutateAsync }
}
