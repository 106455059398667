import { useQuery } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { Order } from 'types/order.types'

const sdk = getCTSDK()

export const useGetTracking = (params: { trackingToken?: string | null }) => {
    return useQuery(
        ['useGetTracking', params],
        async () =>
            await sdk.get<{ order: Order }>(`/tracking`, {
                params,
            }),
        {
            enabled: !!params.trackingToken,
        }
    )
}
