import styled from '@emotion/styled'
import Button from 'components/core/Button'
import Typography from 'components/core/Typography'
import FileDropzone from 'components/composite/FileDropzone'
import Dialog, { DialogActions, DialogContent } from 'components/core/Dialog'

const emptyFunc = () => undefined

const StyledUploadFilesDialog = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1.2rem;
`

type UploadFilesDialogProps = {
    isOpen: boolean
    isSubmitting: boolean
    numFiles: number
    onClose: () => void
    onDropFiles: (files: File[]) => void
    onUploadFiles: () => void
}

const UploadFilesDialog = ({
    isOpen,
    isSubmitting,
    numFiles,
    onClose = emptyFunc,
    onDropFiles = emptyFunc,
    onUploadFiles = emptyFunc,
}: UploadFilesDialogProps) => {
    return (
        <Dialog isOpen={isOpen} size="sm" title="Upload" onClose={onClose}>
            <DialogContent>
                <StyledUploadFilesDialog>
                    <FileDropzone onDrop={onDropFiles} />
                    {numFiles > 0 && (
                        <Typography color="primary" variant="body1">
                            {numFiles} file(s) ready to upload
                        </Typography>
                    )}
                </StyledUploadFilesDialog>
            </DialogContent>
            <DialogActions>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    isDisabled={!numFiles}
                    isSubmitting={isSubmitting}
                    variant="primary"
                    onClick={onUploadFiles}
                >
                    Upload
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UploadFilesDialog
