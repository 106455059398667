import { OrgSettings } from 'types/orgSettings.types'

export const isCustomersModuleFFEnabled = (
    orgSettings?: OrgSettings
): boolean => {
    return !!orgSettings?.featureFlags?.isCustomersModuleEnabled
}

export const isCarriersModuleFFEnabled = (
    orgSettings?: OrgSettings
): boolean => {
    return !!orgSettings?.featureFlags?.isCarriersModuleEnabled
}

export const isPricingModuleFFEnabled = (
    orgSettings?: OrgSettings
): boolean => {
    return !!orgSettings?.featureFlags?.isPricingModuleEnabled
}

export const isOrdersModuleFFEnabled = (orgSettings?: OrgSettings): boolean => {
    return !!orgSettings?.featureFlags?.isOrdersModuleEnabled
}

export const isPayablesModuleFFDisabled = (
    orgSettings?: OrgSettings
): boolean => {
    return !!orgSettings?.featureFlags?.isPayablesModuleDisabled
}

export const isReceivablesModuleFFDisabled = (
    orgSettings?: OrgSettings
): boolean => {
    return !!orgSettings?.featureFlags?.isReceivablesModuleDisabled
}

export const isAutomationsModuleFFDisabled = (
    orgSettings?: OrgSettings
): boolean => {
    return !!orgSettings?.featureFlags?.isAutomationsModuleDisabled
}
