import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRegister } from 'mutations/user.mutations'
import { showToastError, showToastSuccess } from 'hooks/useToast'
import Register from './components/Register'

const RegisterContainer = () => {
    const [orgId, setOrgId] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [repeatedPassword, setRepeatedPassword] = useState('')

    const navigate = useNavigate()
    const { isLoading: isSubmitting, mutateAsync: register } = useRegister()

    const _onGoToLogin = () => {
        navigate('/login')
    }

    const _onRegister = async () => {
        try {
            await register({
                email,
                orgId,
                password,
                firstName,
                lastName,
            })

            showToastSuccess('Registration successful')
            navigate('/login')
        } catch (error) {
            console.error(error)
            showToastError(error)
        }
    }

    return (
        <Register
            isSubmitting={isSubmitting}
            orgId={orgId}
            email={email}
            password={password}
            lastName={lastName}
            firstName={firstName}
            repeatedPassword={repeatedPassword}
            onRegister={_onRegister}
            onGoToLogin={_onGoToLogin}
            onChangeEmail={setEmail}
            onChangeOrgId={setOrgId}
            onChangeLastName={setLastName}
            onChangeFirstName={setFirstName}
            onChangePassword={setPassword}
            onChangeRepeatedPassword={setRepeatedPassword}
        />
    )
}

export default RegisterContainer
