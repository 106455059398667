import styled from '@emotion/styled'
import { Pen } from 'lucide-react'
import AnchorTag from 'components/core/AnchorTag'
import Typography from 'components/core/Typography'
import { useMyOrg } from 'hooks/useMyOrg'
import { DESIGN_TOKENS } from 'constants/color.constants'
import { PayableStatus } from 'types/payable.types'
import {
    canPayableBeMarkedAsProcessed,
    canPayableBeProcessed,
} from '../utils/payable.utils'

const StyledManifestCell = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

type ManifestCellProps = {
    manifestId?: string
    payableStatus: PayableStatus
    manifestNumber?: string
}

const ManifestCell = ({
    manifestId,
    payableStatus,
    manifestNumber,
}: ManifestCellProps) => {
    const { org } = useMyOrg()

    if (!manifestNumber) {
        return ['unparsed', 'processed', 'manuallyProcessed'].includes(
            payableStatus
        ) ? (
            <Typography color="primary" variant="body2">
                --
            </Typography>
        ) : (
            <Typography color="secondary" variant="body2">
                Connect
            </Typography>
        )
    }

    return (
        <StyledManifestCell>
            <AnchorTag
                rel="noopener noreferrer"
                href={`https://${org.rrSubdomain}.roserocket.com/#/ops/manifests/${manifestId}`}
                color="primary"
                target="_blank"
            >
                {manifestNumber}
            </AnchorTag>
            {canPayableBeProcessed(payableStatus) ||
                (canPayableBeMarkedAsProcessed(payableStatus) && (
                    <Pen
                        size={12}
                        color={DESIGN_TOKENS.text.textMutedForeground}
                    />
                ))}
        </StyledManifestCell>
    )
}

export default ManifestCell
