import { UseQueryResult, useQuery } from 'react-query'
import { getCTSDK } from 'services/ctClient'

const sdk = getCTSDK()

type GetGcOrgAccountResponse = {
    account: {
        token: {
            isValid: boolean
        }
    }
}

export const useGetGcAccount = (): UseQueryResult<
    GetGcOrgAccountResponse,
    unknown
> => {
    return useQuery(['useGetGcAccount'], async () => {
        const resp = await sdk.get<GetGcOrgAccountResponse>(
            `/integrations/email/google/users/me`
        )
        return resp.data
    })
}
