import ProcessPayablesDialog from './components/ProcessPayablesDialog'

const emptyFunc = () => undefined

type ProcessPayablesDialogContainerProps = {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
}

const ProcessPayablesDialogContainer = ({
    isOpen,
    onClose = emptyFunc,
    onConfirm = emptyFunc,
}: ProcessPayablesDialogContainerProps) => {
    return (
        <ProcessPayablesDialog
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={onConfirm}
        />
    )
}

export default ProcessPayablesDialogContainer
