import Board from 'components/composite/Board/Board'
import { tableColumns } from '../automations.columns'
import { Automation } from 'types/automation.types'
import {
    AUTOMATION_STATUS_GROUP_IDS,
    AUTOMATION_STATUS_GROUP_LABELS,
} from '../automations.constants'

const emptyFunc = () => undefined

const getTabLabel = ({
    label,
    count,
}: {
    label: string
    count?: number
}): string => {
    return `${label}${count !== undefined ? ` (${count})` : ''}`
}

type AutomationsProps = {
    isLoading: boolean
    isLastPage: boolean
    isFirstPage: boolean
    isRefetching: boolean
    limit: number
    offset: number
    numRows: number
    selectedTabId: string
    statusCounts?: { [key in string]: number }
    rows: Automation[]
    selectedRowIds: string[]
    onNextPage: () => void
    onClickTab: (tabId: string) => void
    onRefetchRows: () => void
    onPreviousPage: () => void
    onSelectAllRows: (isChecked: boolean) => void
    onSelectRow: ({
        isChecked,
        id,
        index,
    }: {
        isChecked: boolean
        id: string
        index: number
    }) => void
}

const Automations = ({
    isLoading,
    isLastPage,
    isFirstPage,
    isRefetching,
    limit,
    offset,
    numRows,
    selectedTabId,
    statusCounts,
    rows,
    selectedRowIds,
    onNextPage = emptyFunc,
    onClickTab = emptyFunc,
    onRefetchRows = emptyFunc,
    onPreviousPage = emptyFunc,
    onSelectRow = emptyFunc,
    onSelectAllRows = emptyFunc,
}: AutomationsProps) => {
    const tabs = [
        {
            id: AUTOMATION_STATUS_GROUP_IDS.review,
            label: getTabLabel({
                label: AUTOMATION_STATUS_GROUP_LABELS[
                    AUTOMATION_STATUS_GROUP_IDS.review
                ],
                count: statusCounts?.[AUTOMATION_STATUS_GROUP_IDS.review],
            }),
        },
        {
            id: AUTOMATION_STATUS_GROUP_IDS.active,
            label: getTabLabel({
                label: AUTOMATION_STATUS_GROUP_LABELS[
                    AUTOMATION_STATUS_GROUP_IDS.active
                ],
                count: statusCounts?.[AUTOMATION_STATUS_GROUP_IDS.active],
            }),
        },
        {
            id: AUTOMATION_STATUS_GROUP_IDS.completed,
            label: getTabLabel({
                label: AUTOMATION_STATUS_GROUP_LABELS[
                    AUTOMATION_STATUS_GROUP_IDS.completed
                ],
                count: statusCounts?.[AUTOMATION_STATUS_GROUP_IDS.completed],
            }),
        },
        {
            id: AUTOMATION_STATUS_GROUP_IDS.manual,
            label: getTabLabel({
                label: AUTOMATION_STATUS_GROUP_LABELS[
                    AUTOMATION_STATUS_GROUP_IDS.manual
                ],
                count: statusCounts?.[AUTOMATION_STATUS_GROUP_IDS.manual],
            }),
        },
    ]

    const buttons: [] = []

    return (
        <Board
            isLoading={isLoading}
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
            isRefetching={isRefetching}
            limit={limit}
            offset={offset}
            header="Tasks"
            numRows={numRows}
            selectedTabId={selectedTabId}
            rows={rows}
            tabs={tabs}
            columns={tableColumns}
            buttons={buttons}
            selectedRowIds={selectedRowIds}
            onNextPage={onNextPage}
            onClickTab={onClickTab}
            onSelectRow={onSelectRow}
            onRefetchRows={onRefetchRows}
            onPreviousPage={onPreviousPage}
            onSelectAllRows={onSelectAllRows}
        />
    )
}

export default Automations
