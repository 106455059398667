import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Dialog, { DialogActions, DialogContent } from 'components/core/Dialog'
import Button from 'components/core/Button'

const emptyFunc = () => undefined

const StyledDocumentViewerDialog = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 3.2rem;

    .documentViewerDialog__items {
        display: flex;
        flex-direction: column;
    }
`

type DocumentViewerDialogProps = {
    isOpen: boolean
    isEditable?: boolean
    isSubmitting?: boolean
    isSaveButtonDisabled?: boolean
    size: 'lg' | 'xl'
    fileUrl?: string
    children?: React.ReactNode
    onSave?: () => void
    onClose: () => void
}

const DocumentViewerDialog = ({
    isOpen,
    isEditable = false,
    isSubmitting = false,
    isSaveButtonDisabled = false,
    size,
    fileUrl,
    children,
    onSave = emptyFunc,
    onClose = emptyFunc,
}: DocumentViewerDialogProps) => {
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight)

    useEffect(() => {
        const handleResize = () => {
            setViewportHeight(window.innerHeight)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const contentHeight = viewportHeight - 48 - 24 - 32 - 24 - 24 - 48 - 72

    return (
        <Dialog
            isOpen={isOpen}
            size={size}
            title="View document"
            onClose={onClose}
        >
            <DialogContent>
                <StyledDocumentViewerDialog>
                    <iframe
                        src={fileUrl}
                        width={800}
                        title={fileUrl}
                        height={contentHeight}
                        style={{ borderRadius: '0.6rem' }}
                    />
                    {children}
                </StyledDocumentViewerDialog>
            </DialogContent>
            <DialogActions>
                <Button
                    isDisabled={isSubmitting}
                    variant="secondary"
                    onClick={onClose}
                >
                    Close
                </Button>
                {isEditable && (
                    <Button
                        isSubmitting={isSubmitting}
                        isDisabled={isSaveButtonDisabled}
                        variant="primary"
                        onClick={onSave}
                    >
                        Save
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

export default DocumentViewerDialog
