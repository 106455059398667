import { useState } from 'react'
import { getCTSDK } from 'services/ctClient'
import { RRCustomer } from 'integrations/roserocket/types/rrCustomer.types'
import { SelectOption } from 'components/core/Select'
import { useMutateRRSettings } from 'integrations/roserocket/mutations/settings.mutations'

const ctSDK = getCTSDK()

export const useRRReceivableSettings = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [selectedBOLCustomers, setSelectedBOLCustomers] = useState<
        SelectOption[]
    >([])
    const [
        selectedManualProcessingEnforcedCustomers,
        setSelectedManualProcessingEnforcedCustomers,
    ] = useState<SelectOption[]>([])
    const [
        selectedAutoProcessingDisabledCustomers,
        setSelectedAutoProcessingDisabledCustomers,
    ] = useState<SelectOption[]>([])

    const { isLoading: isSubmitting, mutateAsync: saveRRSettings } =
        useMutateRRSettings()

    const onSaveSettings = async () => {
        await saveRRSettings({
            settings: {
                customerIdsBOLOnlyAsPOD: selectedBOLCustomers.map(
                    (customer) => customer.value
                ),
                customerIdsDisableAutoProcessingReceivables:
                    selectedAutoProcessingDisabledCustomers.map(
                        (customer) => customer.value
                    ),
                customerIdsToEnforceReceivableManualProcessing:
                    selectedManualProcessingEnforcedCustomers.map(
                        (customer) => customer.value
                    ),
            },
        })
    }

    const onFetchCustomerOptions = async (
        searchTerm: string
    ): Promise<SelectOption[]> => {
        try {
            const resp = await ctSDK.get<{ customers: RRCustomer[] }>(
                '/integrations/roserocket/customers',
                {
                    params: { searchTerm },
                }
            )

            const fetchedOptions =
                resp.data?.customers.map((c) => ({
                    value: c.id,
                    label: c.name,
                })) || []

            return fetchedOptions
        } catch (error) {
            console.error('Error fetching customer options:', error)
            return []
        }
    }

    const onFetchInitAutoProcessingDisabledCustomers = async (): Promise<
        SelectOption[]
    > => {
        setIsLoading(true)

        try {
            const settingsResp = await ctSDK.get<{
                settings: {
                    customerIdsDisableAutoProcessingReceivables: string[]
                }
            }>('/integrations/roserocket/settings')

            const customerIdsDisableAutoProcessingReceivables =
                settingsResp.data?.settings
                    ?.customerIdsDisableAutoProcessingReceivables || []

            if (!customerIdsDisableAutoProcessingReceivables.length) {
                return []
            }

            const customersToUseInvoiceDate = await ctSDK.get<{
                customers: RRCustomer[]
            }>('/integrations/roserocket/customers', {
                params: {
                    inCustomerIds: customerIdsDisableAutoProcessingReceivables,
                },
            })

            const initOptions =
                customersToUseInvoiceDate.data?.customers.map((c) => ({
                    value: c.id,
                    label: c.name,
                })) || []

            return initOptions
        } catch (error) {
            console.error('Error fetching settings:', error)
            return []
        } finally {
            setIsLoading(false)
        }
    }

    const onFetchInitManualProcessingEnforcedCustomers = async (): Promise<
        SelectOption[]
    > => {
        setIsLoading(true)

        try {
            const settingsResp = await ctSDK.get<{
                settings: {
                    customerIdsToEnforceReceivableManualProcessing: string[]
                }
            }>('/integrations/roserocket/settings')

            const customerIdsToEnforceReceivableManualProcessing =
                settingsResp.data?.settings
                    ?.customerIdsToEnforceReceivableManualProcessing || []

            if (!customerIdsToEnforceReceivableManualProcessing.length) {
                return []
            }

            const customersToUseInvoiceDate = await ctSDK.get<{
                customers: RRCustomer[]
            }>('/integrations/roserocket/customers', {
                params: {
                    inCustomerIds:
                        customerIdsToEnforceReceivableManualProcessing,
                },
            })

            const initOptions =
                customersToUseInvoiceDate.data?.customers.map((c) => ({
                    value: c.id,
                    label: c.name,
                })) || []

            return initOptions
        } catch (error) {
            console.error('Error fetching settings:', error)
            return []
        } finally {
            setIsLoading(false)
        }
    }

    const onFetchInitBOLCustomers = async (): Promise<SelectOption[]> => {
        setIsLoading(true)

        try {
            const settingsResp = await ctSDK.get<{
                settings: {
                    customerIdsBOLOnlyAsPOD: string[]
                }
            }>('/integrations/roserocket/settings')

            const customerIdsBOLOnlyAsPOD =
                settingsResp.data?.settings?.customerIdsBOLOnlyAsPOD || []

            if (!customerIdsBOLOnlyAsPOD.length) {
                return []
            }

            const customersBOLOnlyAsPOD = await ctSDK.get<{
                customers: RRCustomer[]
            }>('/integrations/roserocket/customers', {
                params: {
                    inCustomerIds: customerIdsBOLOnlyAsPOD,
                },
            })

            const initOptions =
                customersBOLOnlyAsPOD.data?.customers.map((c) => ({
                    value: c.id,
                    label: c.name,
                })) || []

            return initOptions
        } catch (error) {
            console.error('Error fetching settings:', error)
            return []
        } finally {
            setIsLoading(false)
        }
    }

    return {
        isLoading,
        isSubmitting,
        onSaveSettings,
        onFetchCustomerOptions,
        onFetchInitBOLCustomers,
        onChangeSelectedBOLCustomers: setSelectedBOLCustomers,
        onFetchInitAutoProcessingDisabledCustomers,
        onFetchInitManualProcessingEnforcedCustomers,
        onChangeSelectedAutoProcessingDisabledCustomers:
            setSelectedAutoProcessingDisabledCustomers,
        onChangeSelectedManualProcessingEnforcedCustomers:
            setSelectedManualProcessingEnforcedCustomers,
    }
}
