import { useBoard, TABLE_ROW_LIMIT } from 'components/composite/Board'
import Automations from './components/Automations'
import {
    AUTOMATION_STATUS_GROUPS,
    AUTOMATION_STATUS_GROUP_IDS,
} from './automations.constants'
import { useAutomationStatusCounts } from 'hooks/useAutomationStatusCounts'
import { useFindAutomations } from 'hooks/useFindAutomations'

const AutomationsContainer = () => {
    const {
        isLoading,
        isLastPage,
        isFirstPage,
        isRefetching,
        total,
        offset,
        statusCounts,
        selectedTabId,
        selectedRowIds,
        rows,
        error,
        refetchRows: refetchAutomations,
        refetchStatusCounts,
        onClickTab,
        onNextPage,
        onSelectRow,
        onPreviousPage,
        onSelectAllRows,
    } = useBoard({
        defaultTabId: AUTOMATION_STATUS_GROUP_IDS.review,
        statusGroupsMap: AUTOMATION_STATUS_GROUPS,
        useRowsFn: useFindAutomations,
        useRowCountsFn: useAutomationStatusCounts,
    })

    const _onRefetchRows = async () => {
        refetchAutomations()
        refetchStatusCounts()
    }

    if (error) {
        // showToastError('Error getting tasks');
    }

    return (
        <Automations
            isLoading={isLoading}
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
            isRefetching={isRefetching}
            limit={TABLE_ROW_LIMIT}
            offset={offset}
            numRows={total}
            selectedTabId={selectedTabId}
            statusCounts={statusCounts}
            rows={rows}
            selectedRowIds={selectedRowIds}
            onNextPage={onNextPage}
            onClickTab={onClickTab}
            onSelectRow={onSelectRow}
            onRefetchRows={_onRefetchRows}
            onPreviousPage={onPreviousPage}
            onSelectAllRows={onSelectAllRows}
        />
    )
}

export default AutomationsContainer
