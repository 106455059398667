import styled from '@emotion/styled'

const StyledDialogContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

type DialogContentProps = {
    children: React.ReactNode
}

const DialogContent = ({ children }: DialogContentProps) => {
    return <StyledDialogContent>{children}</StyledDialogContent>
}

export default DialogContent
