import { useMutation } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { Customer } from 'types/customer.types'

const sdk = getCTSDK()

export const useCreateCustomer = () => {
    return useMutation((data: Partial<Customer>) =>
        sdk.post<{ customer: Customer }>('/customers', {
            data,
        })
    )
}
