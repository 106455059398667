import { BoardColumn } from 'components/composite/Board'
import { Address } from 'types/address.types'
import { formatAddress } from 'utils/address.utils'

export const tableColumns: BoardColumn[] = [
    {
        id: 'name',
        label: 'Name',
        width: 80,
    },
    {
        id: 'billingEmail',
        label: 'Billing email',
        width: 80,
    },
    {
        id: 'address',
        label: 'Address',
        width: 80,
        getValue: (address: Address) => {
            return formatAddress(address)
        },
    },
]
