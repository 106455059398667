import { getEquipmentNames } from 'utils/equipment.utils'
import { DetailsRow } from './DetailsRow'
import { Order } from 'types/order.types'

type OrderDetailsEquipmentSectionProps = {
    order?: Order
}

export const OrderDetailsEquipmentSection = ({
    order,
}: OrderDetailsEquipmentSectionProps) => {
    const { equipment } = order || {}
    return (
        <DetailsRow
            isEditable={false}
            caption="Equipment"
            valueText={getEquipmentNames(equipment)}
        />
    )
}
