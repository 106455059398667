import Button from 'components/core/Button'
import Dialog, { DialogActions, DialogContent } from 'components/core/Dialog'
import { useEffect, useState } from 'react'

type PoViewerDialogProps = {
    isOpen: boolean
    fileUrl?: string
    onClose: () => void
}

const PoViewerDialog = ({ isOpen, fileUrl, onClose }: PoViewerDialogProps) => {
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight)

    useEffect(() => {
        const handleResize = () => {
            setViewportHeight(window.innerHeight)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const contentHeight = viewportHeight - 48 - 24 - 32 - 24 - 24 - 48 - 72

    return (
        <Dialog
            isOpen={isOpen}
            size="lg"
            title="Purchase order"
            onClose={onClose}
        >
            <DialogContent>
                <iframe
                    src={fileUrl}
                    width={800}
                    title={fileUrl}
                    height={contentHeight}
                    style={{ borderRadius: '0.6rem' }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PoViewerDialog
