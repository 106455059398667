import { useMutation } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { AutomationAction } from 'types/automationAction.types'

const ctSDK = getCTSDK()

export const useDeleteAutomation = () => {
    return useMutation((id: string) => ctSDK.post(`/automations/${id}/delete`))
}

export const useMarkAutomationsAsCompleted = () => {
    return useMutation((ids: string[]) =>
        ctSDK.post('/automations/markAsCompleted', { ids })
    )
}

export const useUpdateAutomationActionById = () => {
    return useMutation(
        ({
            id,
            automationId,
            data,
        }: {
            id: string
            automationId: string
            data: Partial<AutomationAction>
        }) => ctSDK.put(`/automations/${automationId}/actions/${id}`, data)
    )
}

export const useScheduleAutomationAction = () => {
    return useMutation(
        ({
            automationId,
            automationActionId,
        }: {
            automationId: string
            automationActionId: string
        }) =>
            ctSDK.post(
                `/automations/${automationId}/actions/${automationActionId}/schedule`
            )
    )
}

export const useUnscheduleAutomationAction = () => {
    return useMutation(
        ({
            automationId,
            automationActionId,
        }: {
            automationId: string
            automationActionId: string
        }) =>
            ctSDK.post(
                `/automations/${automationId}/actions/${automationActionId}/unschedule`
            )
    )
}
