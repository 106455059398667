import styled from '@emotion/styled'
import { CircularProgress } from '@mui/material'
import { Order } from 'types/order.types'
import Typography from 'components/core/Typography'
import { pxToRem } from 'utils/rem.utils'
import { NAV_HEIGHT } from 'constants/app.constants'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import OrderCreatedEvent from './events/OrderCreatedEvent'
import { OrderCommodity } from 'types/orderCommodity.types'
import OrderDeliveredEvent from './events/OrderDeliveredEvent'
import Map from 'components/composite/Map'
import { Link, Zap } from 'lucide-react'
import { SPACING } from 'constants/spacing.constants'
import OrderStatusChip from 'components/composite/OrderStatusChip'
import { useMemo } from 'react'
import OrderBookedEvent from './events/OrderBookedEvent'
import { getOrderStatus, getOrderSummary } from 'utils/order.utils'
import { OrderDetailsEtaSection } from './OrderDetailsEtaSection'
import { OrderDetailsCarrierSection } from './OrderDetailsCarrierSection'
import { OrderDetailsCustomerSection } from './OrderDetailsCustomerSection'
import OrderPickedUpEvent from './events/OrderPickedUpEvent'
import Button, { BUTTON_COLORS } from 'components/core/Button'
import { ActionOption } from '../orderDetails.types'
import { getAvailableOrderActions } from '../utils/orderDetails.utils'
import { OrderDetailsCommoditiesSection } from './OrderDetailsCommoditiesSection'
import { OrderDetailsEquipmentSection } from './OrderDetailsEquipmentSection'
import { OrderDetailsDestinationSection } from './OrderDetailsDestinationSection'
import { OrderDetailsExtraDestinationSection } from './OrderDetailsExtraDestinationSection'

const ORDER_DETAILS_PADDING = 32
const HEADER_HEIGHT = 60
const PANEL_HEADER_HEIGHT = 48
const MAP_HEIGHT = 300

const StyledLoadingScreen = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`

const StyledOrderDetails = styled.div`
    width: 100%;
    padding-top: ${pxToRem(ORDER_DETAILS_PADDING)};
    padding-bottom: ${pxToRem(ORDER_DETAILS_PADDING)};

    .details__header {
        display: flex;
        justify-content: space-between;
        height: ${pxToRem(HEADER_HEIGHT)};

        .details__header__buttons {
            display: flex;
            column-gap: ${SPACING.p2};
        }
    }

    .details__content {
        width: 100%;
        display: flex;
        column-gap: 1.6rem;

        .details__content__left {
            width: 67%;
            border: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};
            border-radius: 0.6rem;

            .details__content__left__header {
                height: ${pxToRem(PANEL_HEADER_HEIGHT)};
                grid-gap: ${SPACING.p2};
                padding-left: ${SPACING.p2};
                padding-right: ${SPACING.p2};
                display: flex;
                flex-direction: row;
                align-items: center;
                border-bottom: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};

                .details__content__left__header__summary {
                    display: flex;
                    column-gap: ${SPACING.p1};

                    .details__content__left__header__summary__icon {
                        width: 2rem;
                        height: 2rem;
                        background-color: ${DESIGN_TOKENS.accentGreyDark};
                        border: 0.1rem solid ${BASE_TOKENS.grey[300]};
                        border-radius: 50%;
                        padding: 0.2rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            .details__content__left__map {
                display: flex;
                align-items: center;
                border-bottom: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};
                height: ${pxToRem(MAP_HEIGHT)};
            }

            .details__content__left__body {
                display: flex;
                flex-direction: column;
                overflow: auto;
                padding-bottom: 1.6rem;
                background-color: ${BASE_TOKENS.slate[50]};
                border-bottom-left-radius: 0.6rem;
                border-bottom-right-radius: 0.6rem;
                height: calc(
                    100vh -
                        ${pxToRem(
                            NAV_HEIGHT +
                                HEADER_HEIGHT +
                                2 * ORDER_DETAILS_PADDING +
                                PANEL_HEADER_HEIGHT +
                                MAP_HEIGHT +
                                2
                        )}
                );
            }
        }

        .details__content__right {
            width: 33%;
            height: 100%;
            border: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};
            border-radius: 0.6rem;

            .details__content__right__header {
                height: ${pxToRem(PANEL_HEADER_HEIGHT)};
                padding-left: 1.6rem;
                padding-right: 1.6rem;
                display: flex;
                align-items: center;
                border-bottom: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};
            }

            .details__content__right__body {
                display: flex;
                flex-direction: column;
                overflow: auto;
                height: calc(
                    100vh -
                        ${pxToRem(
                            NAV_HEIGHT +
                                HEADER_HEIGHT +
                                2 * ORDER_DETAILS_PADDING +
                                PANEL_HEADER_HEIGHT +
                                2
                        )}
                );

                .details__content__right__body__section {
                    border-bottom: 0.1rem solid
                        ${DESIGN_TOKENS.border.borderInput};

                    padding-top: 1.6rem;
                    padding-bottom: 1.6rem;

                    .details__content__right__body__row {
                        padding-left: 1.6rem;
                        padding-right: 1.6rem;
                        min-height: 3.2rem;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        transition: background-color 0.3s ease;

                        .details__content__right__body__row__quantity {
                            min-width: 6rem;
                            width: 6rem;
                        }

                        &.details__content__right__body__row--compressed {
                            justify-content: normal;
                            grid-gap: ${SPACING.p2};
                        }
                    }
                }
            }
        }
    }
`

type OrderDetailsProps = {
    isLoading: boolean
    order?: Order
    commodities: OrderCommodity[]
    onGenerateTrackingLink: () => void
}

export const OrderDetails = ({
    isLoading,
    order,
    commodities,
    onGenerateTrackingLink,
}: OrderDetailsProps) => {
    const { fullId, createdAt, bookedAt, pickedUpAt, deliveredAt } = order || {}

    const options: ActionOption[] = [
        {
            id: 'getTrackingLink',
            label: 'Tracking link',
            buttonVariant: 'secondary',
            Icon: <Link size={16} color={BUTTON_COLORS.secondary} />,
            onClick: onGenerateTrackingLink,
        },
    ]

    const availableActions = getAvailableOrderActions(order)

    const placeIds = useMemo(() => {
        // Extract placeIds from extraPickups and extraDropoffs, if they exist
        const extraPickupPlaceIds =
            order?.extraPickups
                ?.map((pickup) => pickup.placeId)
                .filter(Boolean) || []
        const extraDropoffPlaceIds =
            order?.extraDropoffs
                ?.map((dropoff) => dropoff.placeId)
                .filter(Boolean) || []

        // Combine placeIds from pickup, dropoff, extraPickups, and extraDropoffs
        return [
            order?.pickup?.placeId,
            ...extraPickupPlaceIds,
            order?.dropoff?.placeId,
            ...extraDropoffPlaceIds,
        ].filter(Boolean)
    }, [
        order?.pickup?.placeId,
        order?.dropoff?.placeId,
        order?.extraPickups,
        order?.extraDropoffs,
    ]) as string[]

    return (
        <>
            <StyledOrderDetails>
                <div className="details__header">
                    <Typography isBold color="primary" variant="h1">
                        {fullId}
                    </Typography>
                    <span className="details__header__buttons">
                        {options
                            .filter((o) => availableActions.includes(o.id))
                            .map((o) => {
                                return (
                                    <Button
                                        key={o.id}
                                        variant={o.buttonVariant}
                                        onClick={o.onClick}
                                    >
                                        {o.Icon && o.Icon}
                                        {o.label}
                                    </Button>
                                )
                            })}
                    </span>
                </div>
                <div className="details__content">
                    <span className="details__content__left">
                        <div className="details__content__left__header">
                            <Typography isBold color="primary" variant="body1">
                                Summary
                            </Typography>
                            <span className="details__content__left__header__summary">
                                <span className="details__content__left__header__summary__icon">
                                    <Zap size={12} fill={BASE_TOKENS.grey[0]} />
                                </span>

                                <Typography color="primary" variant="body2">
                                    {getOrderSummary(order)}
                                </Typography>
                            </span>
                        </div>
                        <div className="details__content__left__map">
                            <Map
                                placeIds={placeIds}
                                styles={{
                                    height: MAP_HEIGHT,
                                }}
                            />
                        </div>
                        <div className="details__content__left__body">
                            {isLoading ? (
                                <StyledLoadingScreen>
                                    <CircularProgress
                                        style={{ color: BASE_TOKENS.grey[700] }}
                                    />
                                </StyledLoadingScreen>
                            ) : (
                                <>
                                    {createdAt && (
                                        <OrderCreatedEvent
                                            createdAt={createdAt}
                                        />
                                    )}
                                    {bookedAt && (
                                        <OrderBookedEvent bookedAt={bookedAt} />
                                    )}
                                    {pickedUpAt && (
                                        <OrderPickedUpEvent
                                            pickedUpAt={pickedUpAt}
                                        />
                                    )}
                                    {deliveredAt && (
                                        <OrderDeliveredEvent
                                            deliveredAt={deliveredAt}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </span>
                    <span className="details__content__right">
                        <div className="details__content__right__header">
                            <Typography isBold color="primary" variant="body1">
                                Details
                            </Typography>
                        </div>
                        <div className="details__content__right__body">
                            {isLoading ? (
                                <StyledLoadingScreen>
                                    <CircularProgress
                                        style={{ color: BASE_TOKENS.grey[700] }}
                                    />
                                </StyledLoadingScreen>
                            ) : (
                                <>
                                    <div className="details__content__right__body__section">
                                        <div className="details__content__right__body__row">
                                            <Typography
                                                color="secondary"
                                                variant="body2"
                                            >
                                                Status
                                            </Typography>
                                            <OrderStatusChip
                                                status={getOrderStatus(order)}
                                            />
                                        </div>
                                    </div>

                                    <div className="details__content__right__body__section">
                                        <OrderDetailsCustomerSection
                                            order={order}
                                        />
                                    </div>

                                    <div className="details__content__right__body__section">
                                        <OrderDetailsDestinationSection
                                            destination={order?.pickup}
                                            destinationLabel="Pickup"
                                            addressLabel="Shipper"
                                        />
                                    </div>

                                    <OrderDetailsExtraDestinationSection
                                        extraDestinations={
                                            order?.extraPickups || []
                                        }
                                        destinationLabel="Pickup"
                                        addressLabel="Shipper"
                                    />

                                    <div className="details__content__right__body__section">
                                        <OrderDetailsDestinationSection
                                            destination={order?.dropoff}
                                            destinationLabel="Dropoff"
                                            addressLabel="Consignee"
                                        />
                                    </div>

                                    <OrderDetailsExtraDestinationSection
                                        extraDestinations={
                                            order?.extraDropoffs || []
                                        }
                                        destinationLabel="Dropoff"
                                        addressLabel="Consignee"
                                    />

                                    <OrderDetailsCommoditiesSection
                                        commodities={commodities}
                                    />

                                    <div className="details__content__right__body__section">
                                        <OrderDetailsEquipmentSection
                                            order={order}
                                        />
                                    </div>

                                    <div className="details__content__right__body__section">
                                        <OrderDetailsCarrierSection
                                            order={order}
                                        />
                                    </div>
                                    <div className="details__content__right__body__section">
                                        <OrderDetailsEtaSection order={order} />
                                    </div>
                                </>
                            )}
                        </div>
                    </span>
                </div>
            </StyledOrderDetails>
        </>
    )
}
