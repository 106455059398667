import { useBoardv2 } from 'components/composite/Board'
import { useFindOrders } from 'hooks/useFindOrders'
import { TABLE_ROW_LIMIT } from 'components/composite/Board'
import Orders from './components/Orders'
import { useCreateOrder } from 'mutations/order.mutations'
import { showToastError, showToastSuccess } from 'hooks/useToast'
import { URLS } from 'utils/url.utils'
import { useNavigate } from 'react-router-dom'

const OrdersContainer = () => {
    const navigate = useNavigate()

    const {
        isLoading,
        isLastPage,
        isFirstPage,
        isRefetching,
        total,
        offset,
        selectedRowIds,
        rows,
        refetchRows,
        onNextPage,
        onSelectRow,
        onPreviousPage,
        onSelectAllRows,
    } = useBoardv2({ useRowsFn: useFindOrders })

    const { isLoading: isCreatingOrder, mutateAsync: createOrder } =
        useCreateOrder()

    const _onCreateOrder = async () => {
        try {
            const newOrder = await createOrder({
                order: {},
                commodities: [],
            })

            showToastSuccess('Order created')

            navigate(URLS.cartage.order(newOrder.id))
        } catch (error) {
            showToastError('Error creating order')
        }
    }

    return (
        <Orders
            isLoading={isLoading || isCreatingOrder}
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
            isRefetching={isRefetching}
            limit={TABLE_ROW_LIMIT}
            offset={offset}
            numOrders={total}
            orders={rows}
            selectedOrderIds={selectedRowIds}
            onNextPage={onNextPage}
            onRefetchRows={refetchRows}
            onSelectOrder={onSelectRow}
            onPreviousPage={onPreviousPage}
            onSelectAllOrders={onSelectAllRows}
            onCreateOrder={_onCreateOrder}
        />
    )
}

export default OrdersContainer
