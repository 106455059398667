export const transformToSentenceCase = (input?: string) => {
    if (!input) {
        return input
    }

    const specialWords = ['LLC', 'LTD', 'INC']

    // Convert the entire string to lowercase and split into words
    const words = input.toLowerCase().split(' ')

    // Capitalize the first letter of each word, except for special words
    const capitalizedWords = words.map((word) => {
        return specialWords.includes(word.toUpperCase())
            ? word.toUpperCase()
            : word.charAt(0).toUpperCase() + word.slice(1)
    })

    // Join the words back together with spaces
    return capitalizedWords.join(' ')
}
