import { useMutation } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { OrgSettings } from 'types/orgSettings.types'

const ctSDK = getCTSDK()

export const useUpdateMyOrgSettings = () => {
    return useMutation((settings: Omit<OrgSettings, 'id' | 'orgId'>) =>
        ctSDK.put(`/orgs/myOrg/settings`, { settings })
    )
}
