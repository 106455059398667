import { DocumentEntityType } from 'types/document.types'

export const DOCUMENT_ENTITY_TYPE_LABELS: Record<DocumentEntityType, string> = {
    bill_of_lading: 'Bill of lading',
    carrier_invoice: 'Invoice',
    commercial_invoice: 'Commercial invoice',
    invoice_statement: 'Invoice statement',
    multiple_carrier_invoices: 'Multi invoice',
    other: 'Other',
    proof_of_delivery: 'Proof of delivery',
    rate_confirmation: 'Rate confirmation',
    bulk_carrier_invoice: 'Bulk invoice',
}
