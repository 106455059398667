import { PayableStatus } from 'types/payable.types'
import {
    canPayableBeDeleted,
    canPayableBeMarkedAsProcessed,
    canPayableBeProcessed,
    canPayableBeRefreshed,
} from '../../utils/payable.utils'

export const getAvailableActions = ({
    status,
    documentId,
}: {
    status?: PayableStatus
    documentId?: string
}): string[] => {
    const actions: string[] = []

    if (!status) {
        return actions
    }

    if (canPayableBeProcessed(status)) {
        actions.push('process')
    }
    if (canPayableBeMarkedAsProcessed(status)) {
        actions.push('markAsProcessed')
    }
    if (canPayableBeRefreshed(status)) {
        actions.push('refresh')
    }
    if (canPayableBeDeleted(status) && documentId) {
        actions.push('delete')
    }

    return actions
}
