import styled from '@emotion/styled'
import * as RadixAvatar from '@radix-ui/react-avatar'
import { DESIGN_TOKENS } from 'constants/color.constants'

const StyledAvatarFallback = styled(RadixAvatar.AvatarFallback)`
    color: ${DESIGN_TOKENS.primary.brand};

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
`

export default StyledAvatarFallback
