import React, { memo, useEffect, useRef, useState } from 'react'
import { GoogleMap, Marker, Polyline } from '@react-google-maps/api'

const DEFAULT_STYLES = {
    width: '100%',
    height: '400px',
}

const CENTER_US = {
    lat: 39.0902,
    lng: -95.7129,
}

const MAP_STYLES = [
    {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road.local',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
]

const MAP_OPTIONS = {
    disableDefaultUI: true, // Disable all default UI
    zoomControl: false, // Disable the zoom control
    mapTypeControl: false, // Disable map/satellite toggle
    fullscreenControl: false, // Disable the full screen button
    streetViewControl: false, // Disable the street view icon
    styles: MAP_STYLES,
}

const POLYLINE_OPTIONS = {
    strokeColor: '#0000FF',
    strokeOpacity: 1.0,
    strokeWeight: 2,
}

type MapProps = {
    styles?: React.CSSProperties
    placeIds: string[]
}

const Map = ({ styles, placeIds }: MapProps) => {
    const [markers, setMarkers] = React.useState<
        {
            lat: number
            lng: number
        }[]
    >([])
    const [path, setPath] = useState<google.maps.LatLng[]>([])
    const mapRef = useRef<google.maps.Map | null>(null)
    const zoom = 5

    useEffect(() => {
        const service = new window.google.maps.places.PlacesService(
            document.createElement('div')
        )

        setMarkers([])

        placeIds.forEach((placeId) => {
            service.getDetails({ placeId }, (place, status) => {
                const lat = place?.geometry?.location?.lat()
                const lng = place?.geometry?.location?.lng()

                if (
                    status ===
                        window.google.maps.places.PlacesServiceStatus.OK &&
                    lat &&
                    lng
                ) {
                    setMarkers((prevMarkers) => [
                        ...prevMarkers,
                        {
                            lat,
                            lng,
                        },
                    ])
                }
            })
        })
    }, [placeIds])

    useEffect(() => {
        if (mapRef.current && markers.length > 0) {
            if (markers.length === 1) {
                mapRef.current.setCenter(markers[0])
                mapRef.current.setZoom(zoom)
            } else {
                const bounds = new window.google.maps.LatLngBounds()
                markers.forEach((marker) => bounds.extend(marker))
                mapRef.current.fitBounds(bounds)
            }
        }
    }, [markers])

    useEffect(() => {
        if (markers.length >= 2) {
            const directionsService = new google.maps.DirectionsService()
            const waypoints = markers.slice(1, -1).map((marker) => ({
                location: marker,
                stopover: true,
            }))

            directionsService.route(
                {
                    origin: markers[0],
                    destination: markers[markers.length - 1],
                    waypoints: waypoints,
                    travelMode: google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                    if (status === google.maps.DirectionsStatus.OK) {
                        const route = result?.routes[0].overview_path

                        if (!route) {
                            return
                        }

                        setPath(route)
                    }
                }
            )
        }
    }, [markers])

    return (
        <GoogleMap
            mapContainerStyle={{ ...DEFAULT_STYLES, ...styles }}
            center={CENTER_US}
            zoom={zoom}
            options={MAP_OPTIONS}
            onLoad={(map) => {
                mapRef.current = map
            }}
        >
            {markers.map((marker, index) => (
                <Marker key={index} position={marker} />
            ))}
            {path.length > 0 && (
                <Polyline path={path} options={POLYLINE_OPTIONS} />
            )}
        </GoogleMap>
    )
}

export default memo(Map)
