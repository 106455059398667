import styled from '@emotion/styled'
import Button from 'components/core/Button'
import Typography from 'components/core/Typography'
import Dialog, { DialogActions, DialogContent } from 'components/core/Dialog'

const emptyFunc = () => undefined

const StyledProcessPayablesDialog = styled.div`
    width: 100%;
`

const StyledList = styled.ul`
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
`

const StyledListItem = styled.li`
    padding-top: 0;
    padding-bottom: 0;
`

type ProcessPayablesDialogProps = {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
}

const ProcessPayablesDialog = ({
    isOpen,
    onClose = emptyFunc,
    onConfirm = emptyFunc,
}: ProcessPayablesDialogProps) => {
    return (
        <Dialog isOpen={isOpen} size="md" title="Process" onClose={onClose}>
            <DialogContent>
                <StyledProcessPayablesDialog>
                    <Typography color="primary" variant="body1">
                        This action will make changes to your system, including:
                    </Typography>
                    <StyledList>
                        <StyledListItem>
                            <Typography color="primary" variant="body1">
                                Mark your delivery as complete
                            </Typography>
                        </StyledListItem>
                        <StyledListItem>
                            <Typography color="primary" variant="body1">
                                Create a bill, if no bill exists
                            </Typography>
                        </StyledListItem>
                        <StyledListItem>
                            <Typography color="primary" variant="body1">
                                Update relevant fields and amounts
                            </Typography>
                        </StyledListItem>
                        <StyledListItem>
                            <Typography color="primary" variant="body1">
                                Export your bill to your accounting system
                            </Typography>
                        </StyledListItem>
                    </StyledList>
                    <Typography color="primary" variant="body1">
                        <strong>Note:</strong> Payables in "Requires review" and
                        "No match" status must be processed manually. Processing
                        in Cartage will simply update their status to "Manual
                        processed".
                    </Typography>
                </StyledProcessPayablesDialog>
            </DialogContent>
            <DialogActions>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={onConfirm}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProcessPayablesDialog
