import styled from '@emotion/styled'
import Button from 'components/core/Button'
import TextInput from 'components/core/TextInput'
import Dialog2, { DialogActions2, DialogContent2 } from 'components2/Dialog2'

const StyledDialog = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`

type CreateCarrierDialogProps = {
    isOpen: boolean
    isSubmitting: boolean
    name?: string
    contactEmail?: string
    onSave: () => void
    onClose: () => void
    onChangeName: (name?: string) => void
    onChangeContactEmail: (contactEmail?: string) => void
}

const CreateCarrierDialog = ({
    isOpen,
    isSubmitting,
    name,
    contactEmail,
    onSave,
    onClose,
    onChangeName,
    onChangeContactEmail,
}: CreateCarrierDialogProps) => {
    return (
        <Dialog2
            isOpen={isOpen}
            size="sm"
            title="Create carrier"
            onClose={onClose}
        >
            <DialogContent2>
                <StyledDialog>
                    <TextInput
                        label="Name"
                        placeholder="Name"
                        value={name}
                        onChange={onChangeName}
                    />
                    <TextInput
                        label="Contact email"
                        placeholder="Contact email"
                        value={contactEmail}
                        onChange={onChangeContactEmail}
                    />
                </StyledDialog>
            </DialogContent2>
            <DialogActions2>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    isSubmitting={isSubmitting}
                    variant="primary"
                    onClick={onSave}
                >
                    Create
                </Button>
            </DialogActions2>
        </Dialog2>
    )
}

export default CreateCarrierDialog
