import { Address } from 'types/address.types'
import { getDetails } from 'use-places-autocomplete'

/**
 * Returns the Monday business hours from the business hours array provided by Google Places API
 */
export const getBusinessHours = (
    periods?: google.maps.places.PlaceOpeningHoursPeriod[]
): { startAt?: string; endAt?: string } => {
    if (!periods || !periods.length) {
        return {}
    }

    const formatTime = (time: string): string => {
        // Convert '1700' to '17:00'
        return `${time.slice(0, 2)}:${time.slice(2)}`
    }

    const mondayPeriod = periods.find(({ open }) => open.day === 1)

    if (!mondayPeriod) {
        return {}
    }

    const formattedOpenTime = formatTime(mondayPeriod.open.time)
    const formattedCloseTime = mondayPeriod.close
        ? formatTime(mondayPeriod.close.time)
        : ''

    return {
        startAt: formattedOpenTime,
        endAt: formattedCloseTime,
    }
}

export const getAddressDetails = async (placeId: string): Promise<Address> => {
    const resp = await getDetails({ placeId })
    if (typeof resp !== 'object') {
        throw new Error('Error getting address details')
    }

    const addressComponents = resp.address_components
    if (!addressComponents?.length) {
        throw new Error('Error getting address details')
    }

    const address: Address = {
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        placeId,
        name: resp.name,
        phoneNumber: resp.formatted_phone_number,
    }
    // Not getting business hours from Google Places API
    // keeping this code for now in case we might need this in the future
    // if 6 months passed and we still don't need this, we can remove this code
    //
    // if (resp.opening_hours?.periods?.length) {
    //     const { startAt, endAt } = getBusinessHours(resp.opening_hours.periods)
    //     address.businessHoursStartAt = startAt
    //     address.businessHoursEndAt = endAt
    // }

    addressComponents.forEach((component) => {
        const types = component.types
        if (types.includes('street_number')) {
            address.address1 = `${component.long_name} ${address.address1}`
        }
        if (types.includes('route')) {
            address.address1 += component.long_name
        }
        if (
            types.includes('sublocality') ||
            types.includes('sublocality_level_1')
        ) {
            address.address2 = component.long_name
        }
        if (types.includes('locality')) {
            address.city = component.long_name
        }
        if (types.includes('administrative_area_level_1')) {
            address.state = component.short_name
        }
        if (types.includes('postal_code')) {
            address.postalCode = component.long_name
        }
        if (types.includes('country')) {
            address.country = component.short_name
        }
    })

    return address
}
