import Select from 'components/core/Select'
import { EQUIPMENT_OPTIONS } from 'constants/equipment.constants'
import { EquipmentType } from 'types/equipment.types'

type EquipmentSelectProps = {
    equipment?: EquipmentType[]
    onChange: (status: EquipmentType[]) => void
}

export const EquipmentSelect = ({
    equipment,
    onChange,
}: EquipmentSelectProps) => {
    const _onChange = (value: string[]) => {
        onChange(value as EquipmentType[])
    }

    return (
        <Select
            isMulti
            value={equipment}
            options={EQUIPMENT_OPTIONS}
            onSelectedOptionChanged={_onChange}
        />
    )
}
