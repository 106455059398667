import styled from '@emotion/styled'
import { BaseButton } from 'components/core/Button'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'

const DropdownButton = styled(BaseButton)`
    color: ${DESIGN_TOKENS.secondaryForeground};
    background-color: ${BASE_TOKENS.grey[0]};
    border: none;
    height: 3.2rem;

    &:hover {
        background-color: ${BASE_TOKENS.grey[100]};
    }

    &:disabled {
        background-color: ${BASE_TOKENS.grey[0]};
    }
`

export default DropdownButton
