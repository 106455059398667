import styled from '@emotion/styled'
import Progress from 'components/core/Progress'
import Column from 'components/core/Column'
import Row from 'components/core/Row'
import Typography from 'components/core/Typography'
import { BASE_TOKENS } from 'constants/color.constants'
import { Truck, CalendarCheck2, Check, MapPinCheck } from 'lucide-react'
import { Order } from 'types/order.types'
import { SPACING } from 'constants/spacing.constants'
import { SCREEN_SIZES } from 'constants/screenSizes.constants'
import { getOrderStatus } from 'utils/order.utils'

const StyledSteps = styled(Row)`
    justify-content: space-between;
    grid-gap: ${SPACING.p3};
    padding-bottom: ${SPACING.p2};

    @media (max-width: ${SCREEN_SIZES.lg}) {
        flex-direction: column;
        align-items: flex-start;

        padding: 0;
    }
`

const StyledStepHeader = styled(Column)<{
    align: 'flex-start' | 'center' | 'flex-end'
}>`
    justify-content: space-between;
    row-gap: ${SPACING.p1};
    width: 14rem;

    ${(p) => `align-items: ${p.align};`}

    @media (max-width: ${SCREEN_SIZES.lg}) {
        align-items: center;
        width: 100%;
        flex-direction: row;
    }

    .header__text {
        display: flex;

        @media (max-width: ${SCREEN_SIZES.lg}) {
            column-gap: ${SPACING.p1};
        }

        .header__text__iconContainer {
            width: 0;

            @media (max-width: ${SCREEN_SIZES.lg}) {
                width: 2.4rem;
            }

            .header__text__iconContainer__icon {
                display: none;

                @media (max-width: ${SCREEN_SIZES.lg}) {
                    display: flex;
                }
            }
        }
    }

    .header__icon {
        width: 3.2rem;
        height: 3.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${BASE_TOKENS.grey[100]};
        border: 1px solid ${BASE_TOKENS.grey[200]};
        border-radius: 50%;
    }
`

const StyledStep = styled(Column)`
    grid-gap: ${SPACING.p1};

    @media (max-width: ${SCREEN_SIZES.lg}) {
        align-items: center;
        width: 100%;
    }
`

const ProgressWrapper = styled.div`
    width: 100%;
    @media (max-width: ${SCREEN_SIZES.lg}) {
        display: none;
    }
`

type OrderProgressProps = { order?: Order }

export const OrderProgress = ({ order }: OrderProgressProps) => {
    let progressValue = 5
    const statusFinished = {
        confirmed: true,
        delivery: false,
        delivered: false,
    }

    const status = getOrderStatus(order)

    if (status === 'inTransit') {
        progressValue = 50
        statusFinished.delivery = true
    } else if (status === 'delivered') {
        progressValue = 100
        statusFinished.delivery = true
        statusFinished.delivered = true
    }

    return (
        <Column>
            <StyledSteps>
                <StyledStep>
                    <StyledStepHeader align="flex-start">
                        <span className="header__text">
                            <span className="header__text__iconContainer">
                                <span className="header__text__iconContainer__icon">
                                    {statusFinished.confirmed && (
                                        <Check color={BASE_TOKENS.green[600]} />
                                    )}
                                </span>
                            </span>

                            <Typography isBold color="primary" variant="body1">
                                Order confirmed
                            </Typography>
                        </span>
                        <span className="header__icon">
                            <CalendarCheck2
                                fill={BASE_TOKENS.grey[0]}
                                size={16}
                            />
                        </span>
                    </StyledStepHeader>
                </StyledStep>
                <StyledStep>
                    <StyledStepHeader align="center">
                        <span className="header__text">
                            <span className="header__text__iconContainer">
                                <span className="header__text__iconContainer__icon">
                                    {statusFinished.delivery && (
                                        <Check color={BASE_TOKENS.green[600]} />
                                    )}
                                </span>
                            </span>

                            <Typography isBold color="primary" variant="body1">
                                Out for delivery
                            </Typography>
                        </span>
                        <span className="header__icon">
                            <Truck fill={BASE_TOKENS.grey[0]} size={16} />
                        </span>
                    </StyledStepHeader>
                </StyledStep>
                <StyledStep>
                    <StyledStepHeader align="flex-end">
                        <span className="header__text">
                            <span className="header__text__iconContainer">
                                <span className="header__text__iconContainer__icon">
                                    {statusFinished.delivered && (
                                        <Check color={BASE_TOKENS.green[600]} />
                                    )}
                                </span>
                            </span>

                            <Typography isBold color="primary" variant="body1">
                                Delivered
                            </Typography>
                        </span>
                        <span className="header__icon">
                            <MapPinCheck fill={BASE_TOKENS.grey[0]} size={16} />
                        </span>
                    </StyledStepHeader>
                </StyledStep>
            </StyledSteps>
            <div className="orderProgress__progress">
                <ProgressWrapper>
                    <Progress
                        value={progressValue}
                        color={BASE_TOKENS.green[600]}
                    />
                </ProgressWrapper>
            </div>
        </Column>
    )
}
