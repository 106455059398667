import { useGetPODs } from 'queries/pod.queries'
import {
    useGetDocument,
    useGetDocumentPayables,
} from 'queries/document.queries'
import {
    getDocumentItems,
    updateDocumentEntities,
} from './utils/documentViewerDialogExtended.utils'
import DocumentViewerDialogExtended from './components/DocumentViewerDialogExtended'
import { useState } from 'react'
import { DocumentEntity } from 'types/document.types'
import isEqual from 'lodash.isequal'
import {
    canPayableBeMarkedAsProcessed,
    canPayableBeProcessed,
} from 'modules/Payables/utils/payable.utils'
import { useUpdateDocumentById } from 'mutations/document.mutations'
import { showToastError, showToastInfo, showToastSuccess } from 'hooks/useToast'

const emptyFunc = () => undefined

type DocumentViewerDialogExtendedContainerProps = {
    isOpen: boolean
    fileUrl: string
    documentId?: string
    onClose: () => void
    onDocumentSaved?: () => void
}

const DocumentViewerDialogExtendedContainer = ({
    isOpen,
    fileUrl,
    documentId,
    onClose = emptyFunc,
    onDocumentSaved = emptyFunc,
}: DocumentViewerDialogExtendedContainerProps) => {
    const [documentEntities, setDocumentEntities] = useState<DocumentEntity[]>(
        []
    )

    const { data: documentData } = useGetDocument(documentId, {
        enabled: isOpen && !!documentId,
        onSuccess: (data) => {
            setDocumentEntities(data.data.document.entities || [])
        },
    })
    const { data: payablesData } = useGetDocumentPayables(documentId, {
        enabled: isOpen && !!documentId,
    })
    const { data: podsData } = useGetPODs(
        { documentId: documentId! },
        {
            enabled: isOpen && !!documentId,
        }
    )
    const { isLoading: isSubmitting, mutateAsync: updateDocument } =
        useUpdateDocumentById()

    const pods = podsData?.data?.pods || []
    const payables = payablesData?.data?.payables || []

    const _onChangeEntityType = ({
        entityId,
        entityType,
        pageNumber,
    }: {
        entityId: string
        entityType: string
        pageNumber: number
    }) => {
        const updatedEntities = updateDocumentEntities({
            entityId,
            entityType,
            pageNumber,
            entities: documentEntities,
        })

        setDocumentEntities(updatedEntities)
    }

    const _onSave = async () => {
        if (!documentId) {
            return
        }

        const { dismiss } = showToastInfo('Saving document')

        try {
            await updateDocument({
                id: documentId,
                data: {
                    entities: documentEntities,
                },
            })

            showToastSuccess('Document saved')
            onDocumentSaved()
        } catch (error) {
            showToastError('Error saving document')
        } finally {
            dismiss()
        }
    }

    return (
        <DocumentViewerDialogExtended
            isOpen={isOpen}
            isEditable={payables.some(
                (p) =>
                    canPayableBeProcessed(p.status) ||
                    canPayableBeMarkedAsProcessed(p.status)
            )}
            isSubmitting={isSubmitting}
            isSaveButtonDisabled={isEqual(
                documentEntities,
                documentData?.data?.document.entities
            )}
            fileUrl={fileUrl}
            documentItems={getDocumentItems({
                documentId,
                entities: documentEntities,
                payables,
                pods,
            })}
            onSave={_onSave}
            onClose={onClose}
            onChangeEntityType={_onChangeEntityType}
        />
    )
}

export default DocumentViewerDialogExtendedContainer
