import { DetailsRow } from './DetailsRow'
import { OrderChangeProp } from '../orderDetails.types'
import Typography from 'components/core/Typography'
import { EtaStatusSelect } from 'components/composite/EtaStatusSelect/EtaStatusSelect'
import { EtaStatusType } from 'types/eta.types'
import { Order } from 'types/order.types'
import { EtaStatusChip } from 'components/composite/EtaStatusChip'
import { DetailsTimeRow } from './DetailsTimeRow'

type OrderDetailsEtaSectionProps = {
    isEditable: boolean
    order?: Order
    orderMutation?: Partial<Order>
    onClosePopup: (propName: OrderChangeProp) => void
    onChangeOrder: (orderMutation: Partial<Order>) => void
}

export const OrderDetailsEtaSection = ({
    isEditable,
    order,
    orderMutation,
    onClosePopup,
    onChangeOrder,
}: OrderDetailsEtaSectionProps) => {
    const { etaStatus } = order || {}
    const { eta: etaMutation, etaStatus: etaStatusMutation } =
        orderMutation || {}

    const _onClosePopup = (propName: OrderChangeProp) => () => {
        onClosePopup(propName)
    }

    const _onChangeEta = (value: EtaStatusType) => {
        onChangeOrder({ etaStatus: value })
    }

    return (
        <>
            <DetailsTimeRow
                isEditable={isEditable}
                caption="ETA"
                value={etaMutation}
                onClosePopup={_onClosePopup('eta')}
                onChange={(date) => {
                    onChangeOrder({
                        eta: date ? date.toISOString() : null,
                    })
                }}
            />
            <DetailsRow
                isEditable={isEditable}
                onClosePopup={_onClosePopup('etaStatus')}
                trigger={
                    <>
                        <Typography color="secondary" variant="body2">
                            Status
                        </Typography>
                        {etaStatus ? <EtaStatusChip status={etaStatus} /> : ''}
                    </>
                }
            >
                <EtaStatusSelect
                    status={etaStatusMutation}
                    onChange={_onChangeEta}
                />
            </DetailsRow>
        </>
    )
}
