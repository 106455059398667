import { BoardColumn } from 'components/composite/Board'
import CommonChip from 'components/core/CommonChip'
import { Receivable } from 'types/receivable.types'
import { getDateFromISOString } from 'utils/date.utils'
import {
    STATUS_BACKGROUND_COLORS,
    STATUS_BORDER_COLORS,
    STATUS_LABELS,
    STATUS_TEXT_COLORS,
} from './receivables.constants'
import PODCell from './components/PODCell'
import OrderCell from './components/OrderCell'
import InvoiceCell from './components/InvoiceCell'
import ReceivableActionsCell from './components/ReceivableActionsCell'
import { mapReceivableProcessErrorReasonToNote } from './utils/receivables.utils'

export const tableColumns: BoardColumn[] = [
    {
        id: 'rrInvoiceNumber',
        label: 'Invoice number',
        width: 150,
        renderCell: (row: Receivable) => (
            <InvoiceCell
                invoiceId={row.rrInvoiceId}
                invoiceNumber={row.rrInvoiceNumber}
            />
        ),
    },
    {
        id: 'proofOfDelivery',
        label: 'Proof of delivery',
        width: 125,
        renderCell: (row: Receivable) => <PODCell podIds={row.podIds} />,
    },
    {
        id: 'createdAt',
        label: 'Upload date',
        width: 100,
        getValue: (value: any) => getDateFromISOString(value),
    },
    {
        id: 'status',
        label: 'Status',
        width: 125,
        renderCell: (row: Receivable) => (
            <CommonChip
                label={STATUS_LABELS[row.status]}
                textColor={STATUS_TEXT_COLORS[row.status]}
                borderColor={STATUS_BORDER_COLORS[row.status]}
                backgroundColor={STATUS_BACKGROUND_COLORS[row.status]}
            />
        ),
    },
    {
        id: 'customerName',
        label: 'Customer',
        width: 100,
    },
    {
        id: 'orderNumber',
        label: 'Order',
        width: 125,
        renderCell: (row: Receivable) => (
            <OrderCell orderId={row.rrOrderId} orderNumber={row.orderNumber} />
        ),
    },
    {
        id: 'shipperName',
        label: 'Shipper',
        width: 125,
    },
    {
        id: 'consigneeName',
        label: 'Consignee',
        width: 125,
    },
    {
        id: 'rrRevenueTotal',
        label: 'Invoice total',
        width: 100,
        getValue: (value: any) =>
            value?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
    },
    {
        id: 'costTotal',
        label: 'Cost total',
        width: 100,
        getValue: (value: any) =>
            value?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
    },
    {
        id: 'processErrorReason',
        label: 'Notes',
        width: 132,
        getValue: (value: any) => mapReceivableProcessErrorReasonToNote(value),
    },
    {
        id: 'actions',
        width: 50,
        renderCell: (row: Receivable, onRefetchRows: () => void) => (
            <ReceivableActionsCell
                receivableId={row.id}
                receivableStatus={row.status}
                onRefetchReceivables={onRefetchRows}
            />
        ),
    },
]
