import styled from '@emotion/styled'
import { CircularProgress } from '@mui/material'
import Checkbox from 'components/core/Checkbox'
import Typography from 'components/core/Typography'
import { pxToRem } from 'utils/rem.utils'
import { useDimensions } from 'hooks/useDimensions'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import { MODULE_HEADER_HEIGHT, NAV_HEIGHT } from 'constants/app.constants'
import BoardCell from './BoardCell'
import Pagination from './Pagination'
import { BoardColumn } from '../board.types'
import { FOOTER_HEIGHT, SPACER_HEIGHT, TABS_HEIGHT } from '../board.constants'
import { SCREEN_SIZES } from 'constants/screenSizes.constants'
import { useMemo } from 'react'

const emptyFunc = () => undefined

const CHECKBOX_COLUMN_WIDTH = 48

const StyledRow = styled.div`
    display: flex;
`

const StyledHeaderRow = styled(StyledRow)`
    height: 4.8rem;
    position: sticky;
    top: 0;
    border-bottom: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
    background-color: ${BASE_TOKENS.grey[0]};
`

const StyledBodyRow = styled(StyledRow)`
    height: 6.4rem;
    border-bottom: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};
    background-color: ${BASE_TOKENS.grey[0]};
`

const StyledCell = styled.div<{
    isEditable?: boolean
    width: number
    isHiddenForSmScreen?: boolean
}>`
    width: ${(p) => pxToRem(p.width)};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: ${SCREEN_SIZES.lg}) {
        display: ${(p) => (p.isHiddenForSmScreen ? 'none' : 'flex')};
    }
`

const StyledTable = styled.div<{ hasTabs: boolean; hasScroll: boolean }>`
    .table__scrollable {
        position: relative;
        overflow-y: ${(p) => (p.hasScroll ? 'auto' : 'none')};
        border: 1px solid ${DESIGN_TOKENS.border.borderInput};
        border-radius: 0.6rem;
        background-color: ${BASE_TOKENS.grey[50]};

        ${(p) => `
            height: calc(
                100vh - ${NAV_HEIGHT / 10}rem - ${
                    MODULE_HEADER_HEIGHT / 10
                }rem -
                    ${p.hasTabs ? TABS_HEIGHT / 10 : SPACER_HEIGHT / 10}rem - ${
                        FOOTER_HEIGHT / 10
                    }rem
            );
        `}

        .table__scrollable__noRows {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .table__footer {
        height: ${pxToRem(FOOTER_HEIGHT)};
        display: flex;
        flex-direction: column;

        .table__footer__content {
            height: 5.6rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: ${BASE_TOKENS.grey[0]};
        }
    }
`

const StyledBoardBodyRow = styled(StyledBodyRow)<{ isSubmitting: boolean }>`
    ${(p) =>
        p.isSubmitting &&
        `
        pointer-events: none;
    `}
`

type BoardDataTableProps<TRow extends { id: string }> = {
    hasTabs: boolean
    isLoading: boolean
    isRefetching: boolean
    isSubmitting: boolean
    isLastPage: boolean
    isFirstPage: boolean
    limit: number
    offset: number
    numRows: number
    columns: BoardColumn[]
    rows: TRow[]
    selectedRowIds: string[]
    onNextPage: () => void
    onRefetchRows?: () => void
    onPreviousPage: () => void
    onSelectAllRows: (isChecked: boolean) => void
    onSelectRow: ({
        isChecked,
        id,
        index,
    }: {
        isChecked: boolean
        id: string
        index: number
    }) => void
}

const BoardDataTable = <TRow extends { id: string }>({
    hasTabs,
    isLoading,
    isRefetching,
    isSubmitting,
    isLastPage,
    isFirstPage,
    limit,
    offset,
    numRows,
    columns,
    rows,
    selectedRowIds,
    onNextPage = emptyFunc,
    onSelectRow = emptyFunc,
    onRefetchRows = emptyFunc,
    onPreviousPage = emptyFunc,
    onSelectAllRows = emptyFunc,
}: BoardDataTableProps<TRow>) => {
    const [dimensions, ref] = useDimensions()

    // Convert the 'sm' size to a number
    const smallScreenWidth = parseInt(SCREEN_SIZES.lg, 10)
    const isSmallScreen = dimensions.width < smallScreenWidth

    const totalColumnsWidth = useMemo(() => {
        const visibleColumns = isSmallScreen
            ? columns.filter((column) => !column.isHiddenForSmScreen)
            : columns

        const totalColumnsWidth = visibleColumns.reduce(
            (acc, column) => (acc += column.width),
            0
        )

        return totalColumnsWidth
    }, [isSmallScreen])

    // We need to add extra 2 pxs to be sure that columns do not take more space than the total width - checkbox column
    let columnDimensions = dimensions.width - 2
    if (!isSmallScreen) {
        columnDimensions = columnDimensions - CHECKBOX_COLUMN_WIDTH
    }

    const _onSelectRow = (id: string, index: number) => (isChecked: boolean) =>
        onSelectRow({ isChecked, id, index })

    return (
        <StyledTable ref={ref} hasTabs={hasTabs} hasScroll={!!rows.length}>
            <div className="table__scrollable">
                <StyledHeaderRow>
                    <StyledCell
                        key="checkbox"
                        width={CHECKBOX_COLUMN_WIDTH}
                        isHiddenForSmScreen={isSmallScreen}
                    >
                        <Checkbox
                            isChecked={
                                !!rows.length &&
                                selectedRowIds.length === rows.length
                            }
                            onChange={onSelectAllRows}
                        />
                    </StyledCell>

                    {columns.map((column) => {
                        return (
                            <StyledCell
                                key={column.id}
                                width={
                                    (columnDimensions / totalColumnsWidth) *
                                    column.width
                                }
                                isHiddenForSmScreen={column.isHiddenForSmScreen}
                            >
                                {column.label && (
                                    <Typography
                                        isBold
                                        shouldTruncate
                                        color="secondary"
                                        variant="body2"
                                    >
                                        {column.label}
                                    </Typography>
                                )}
                            </StyledCell>
                        )
                    })}
                </StyledHeaderRow>
                {rows?.length ? (
                    <>
                        {rows?.map((row, index) => {
                            return (
                                <StyledBoardBodyRow
                                    isSubmitting={isSubmitting}
                                    key={row.id}
                                >
                                    <StyledCell
                                        key={`checkbox-${row.id}`}
                                        width={CHECKBOX_COLUMN_WIDTH}
                                        isHiddenForSmScreen={isSmallScreen}
                                    >
                                        <Checkbox
                                            isChecked={selectedRowIds.includes(
                                                row.id
                                            )}
                                            onChange={_onSelectRow(
                                                row.id,
                                                index
                                            )}
                                        />
                                    </StyledCell>

                                    {columns.map((column) => {
                                        const value =
                                            row[column.id as keyof typeof row]

                                        return (
                                            <BoardCell
                                                isHiddenForSmScreen={
                                                    column.isHiddenForSmScreen
                                                }
                                                isEditable={
                                                    !!column.isEditable?.(row)
                                                }
                                                key={`${column.id}-${row.id}`}
                                                width={
                                                    (columnDimensions /
                                                        totalColumnsWidth) *
                                                    column.width
                                                }
                                                CellEditable={column.renderCellEditable?.(
                                                    row,
                                                    onRefetchRows
                                                )}
                                                CellReadOnly={
                                                    column.renderCell?.(
                                                        row,
                                                        onRefetchRows
                                                    ) || (
                                                        <Typography
                                                            shouldTruncate
                                                            color="primary"
                                                            variant="body2"
                                                        >
                                                            {column.getValue?.(
                                                                value,
                                                                row
                                                            ) ||
                                                                value ||
                                                                '--'}
                                                        </Typography>
                                                    )
                                                }
                                            />
                                        )
                                    })}
                                </StyledBoardBodyRow>
                            )
                        })}
                    </>
                ) : (
                    <div className="table__scrollable__noRows">
                        <Typography color="secondary" variant="body2">
                            No rows to show
                        </Typography>
                    </div>
                )}
                {(isLoading || isRefetching || isSubmitting) && (
                    <div
                        style={{
                            position: 'absolute',
                            pointerEvents: 'none',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: `${BASE_TOKENS.grey[50]}80`,
                            zIndex: 2,
                        }}
                    >
                        <CircularProgress
                            size={48}
                            style={{ color: BASE_TOKENS.grey[700] }}
                        />
                    </div>
                )}
            </div>
            <div className="table__footer">
                <div className="table__footer__content">
                    <span>
                        {numRows > 0 && (
                            <Typography color="secondary" variant="body2">
                                {offset + 1} -{' '}
                                {Math.min(offset + limit, numRows)} of {numRows}
                            </Typography>
                        )}
                    </span>
                    <Pagination
                        isFirstPage={isFirstPage}
                        isLastPage={isLastPage}
                        currentPage={Math.floor(offset / limit) + 1}
                        totalPages={Math.ceil(numRows / limit)}
                        onNextPage={onNextPage}
                        onPreviousPage={onPreviousPage}
                    />
                </div>
            </div>
        </StyledTable>
    )
}

export default BoardDataTable
