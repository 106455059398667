/* eslint-disable no-useless-computed-key */

export const BASE_TOKENS = {
    pink: {
        [50]: '#FDF2F8',
        [300]: '#F9A8D4',
        [900]: '#831843',
    },
    purple: {
        [50]: '#FAF5FF',
        [300]: '#D8B4FE',
        [900]: '#581C87',
    },
    blue: {
        [50]: '#EFF6FF',
        [100]: '#DBEAFE',
        [200]: '#BFDBFE',
        [300]: '#93C5FD',
        [400]: '#60A5FA',
        [500]: '#3B82F6',
        [600]: '#2563EB',
        [700]: '#1D4ED8',
        [800]: '#1E40AF',
        [900]: '#1E3A8A',
    },
    orange: {
        [50]: '#FFF7ED',
        [100]: '#FFEDD5',
        [200]: '#FED7AA',
        [300]: '#FDBA74',
        [400]: '#FB923C',
        [500]: '#F59E0B',
        [600]: '#EA580C',
        [700]: '#C2410C',
        [900]: '#7C2D12',
    },
    red: {
        [50]: '#FEF2F2',
        [300]: '#FCA5A5',
        [500]: '#EF4444',
        [600]: '#DC2626',
        [700]: '#B91C1C',
        [900]: '#7F1D1D',
    },
    grey: {
        [0]: '#FFFFFF',
        [50]: '#F9FAFB',
        [100]: '#F3F4F6',
        [200]: '#e5e7eb',
        [300]: '#D1D5DB',
        [400]: '#9CA3AF',
        [500]: '#6B7280',
        [600]: '#4B5563',
        [700]: '#374151',
        [800]: '#1F2937',
        [900]: '#111827',
        [950]: '#030712',
    },
    slate: {
        [50]: '#F8FAFC',
        [100]: '#F1F5F9',
        [200]: '#E2E8F0',
    },
    green: {
        [50]: '#F0FDF4',
        [300]: '#86EFAC',
        [500]: '#22C55E',
        [600]: '#16A34A',
        [700]: '#15803D',
        [800]: '#166534',
        [900]: '#14532D',
    },
} as const

export const DESIGN_TOKENS = {
    muted: '#F4F4F5',
    background: '#FFFFFF',
    foreground: '#09090B',
    mutedForeground: '#71717A',
    primaryForeground: '#FAFAFA',
    secondaryForeground: '#18181B',
    input: '#E4E4E7',
    error: '#EF4444',

    accent: '#F1F5F9',
    accentForeground: '#0F172A',
    accentGreyDark: '#DBE1E9',

    text: {
        textForeground: '#09090B',
        textMutedForeground: '#71717A',
        textError: '#EF4444',
    },

    primary: {
        brand: '#1C1C1C',
    },

    border: {
        borderInput: '#E4E4E7',
        borderPrimary: '#18181B',
    },
} as const
