import { X } from 'lucide-react'
import { GroupBase, MultiValueProps } from 'react-select'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import styled from '@emotion/styled'
import Typography from 'components/core/Typography'
import { SelectOption } from '../select.types'

const StyledMultiValue = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.4rem;
    background-color: ${DESIGN_TOKENS.muted};
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border-radius: 0.2rem;

    .multiValue__button {
        padding: 0;
        margin: 0;
        cursor: pointer;
        color: ${DESIGN_TOKENS.mutedForeground};
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border-radius: 0.2rem;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: ${BASE_TOKENS.grey[200]};
        }
    }
`

const MultiValue = ({
    data,
    removeProps,
}: MultiValueProps<SelectOption, true, GroupBase<SelectOption>>) => {
    return (
        <StyledMultiValue>
            <Typography color="primary" variant="body2">
                {data.label}
            </Typography>
            <button
                className="multiValue__button"
                // @ts-ignore
                onClick={(e) => removeProps?.onClick?.(e)}
            >
                <X size={16} />
            </button>
        </StyledMultiValue>
    )
}

export default MultiValue
