import styled from '@emotion/styled'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'

const emptyFunc = () => undefined

const StyledPagination = styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    column-gap: 0.8rem;
`

const StyledPaginationItem = styled.li`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
`

const StyledButton = styled.button`
    height: 2.4rem;
    margin: 0;

    border: 0.1rem solid transparent;
    color: ${DESIGN_TOKENS.secondaryForeground};
    background-color: ${BASE_TOKENS.grey[0]};
    border-radius: 0.4rem;

    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.4rem;

    font-family: Inter;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    transition: background-color 0.3s ease;

    cursor: pointer;

    &:hover {
        background-color: ${BASE_TOKENS.grey[100]};
    }

    &:disabled {
        cursor: default;
        opacity: 0.7;
        background-color: ${BASE_TOKENS.grey[0]};
    }
`

const StyledPreviousButton = styled(StyledButton)`
    padding-left: 0;
`

const StyledNextButton = styled(StyledButton)`
    padding-right: 0;
`

const StyledNumberContainer = styled.span<{ isSelected: boolean }>`
    width: 2.4rem;
    height: 2.4rem;

    border: 0.1rem solid transparent;
    color: ${DESIGN_TOKENS.secondaryForeground};
    background-color: ${BASE_TOKENS.grey[0]};
    border-radius: 0.4rem;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: Inter;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    ${(p) =>
        p.isSelected &&
        `
        border-color: ${BASE_TOKENS.grey[300]};
    `}
`

const getPageNumbers = (currentPage: number, totalPages: number): number[] => {
    // Initialize an array to hold the page numbers
    const pageNumbers: number[] = []

    // If there's only one page, return [1]
    if (totalPages === 1) {
        pageNumbers.push(1)
        return pageNumbers
    }

    // Calculate the start and end page numbers based on the current page
    let startPage = Math.max(1, currentPage - 1)
    let endPage = Math.min(startPage + 2, totalPages)

    // Adjust the start and end page numbers if they don't fill up three slots
    if (endPage - startPage < 2) {
        endPage = Math.min(totalPages, startPage + 2)
        startPage = Math.max(1, endPage - 2)
    }

    // Add the page numbers to the array
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i)
    }

    return pageNumbers
}

type PaginationProps = {
    isFirstPage: boolean
    isLastPage: boolean
    currentPage: number
    totalPages: number
    onNextPage: () => void
    onPreviousPage: () => void
}

const Pagination = ({
    isFirstPage,
    isLastPage,
    currentPage,
    totalPages,
    onNextPage = emptyFunc,
    onPreviousPage = emptyFunc,
}: PaginationProps) => {
    const pageNumbers = getPageNumbers(currentPage, totalPages)

    return (
        <StyledPagination>
            <StyledPaginationItem>
                <StyledPreviousButton
                    disabled={isFirstPage}
                    onClick={onPreviousPage}
                >
                    <ChevronLeft size={16} />
                    Previous
                </StyledPreviousButton>
            </StyledPaginationItem>
            {pageNumbers.map((pageNumber) => {
                return (
                    <StyledPaginationItem key={pageNumber}>
                        <StyledNumberContainer
                            isSelected={pageNumber === currentPage}
                        >
                            {pageNumber}
                        </StyledNumberContainer>
                    </StyledPaginationItem>
                )
            })}
            <StyledPaginationItem>
                <StyledNextButton disabled={isLastPage} onClick={onNextPage}>
                    Next
                    <ChevronRight size={16} />
                </StyledNextButton>
            </StyledPaginationItem>
        </StyledPagination>
    )
}

export default Pagination
