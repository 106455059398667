export const downloadFile = (blob: Blob, fileName: string) => {
    // Create a download link for the Blob
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName) // Set dynamic file name
    document.body.appendChild(link)
    link.click()
    link.remove()
}
