import { EQUIPMENT_OPTIONS } from 'constants/equipment.constants'
import { EquipmentType } from 'types/equipment.types'

export const getEquipmentNames = (equipment?: EquipmentType[]) => {
    if (!equipment) {
        return ''
    }

    return equipment
        .map((e) => {
            const option = EQUIPMENT_OPTIONS.find((o) => o.value === e)
            return option?.label || e
        })
        .join(', ')
}
