import { Order } from 'types/order.types'
import { BoardColumn } from 'components/composite/Board'
import AnchorTag from 'components/core/AnchorTag'
import { format } from 'date-fns'
import { getOrderStatus } from 'utils/order.utils'
import OrderStatusChip from 'components/composite/OrderStatusChip'

export const tableColumns: BoardColumn[] = [
    {
        id: 'fullId',
        label: 'Order',
        width: 80,
        renderCell: (row: Order) => (
            <AnchorTag href={`/orders/${row.id}`} color="primary">
                {row.fullId}
            </AnchorTag>
        ),
    },
    {
        id: 'status',
        label: 'Status',
        width: 80,
        renderCell: (row: Order) => {
            return <OrderStatusChip status={getOrderStatus(row)} />
        },
    },
    {
        id: 'customerName',
        label: 'Customer',
        width: 80,
    },
    {
        id: 'consigneeName',
        label: 'Consignee',
        width: 80,
        getValue: (_?: string, row?: Order) => {
            return row?.dropoff?.name
        },
    },
    {
        id: 'pickupStartAt',
        label: 'Pickup date',
        width: 80,
        getValue: (_: string, row?: Order) => {
            if (!row?.pickup?.arrivalStartAt) {
                return
            }

            const date = new Date(row.pickup.arrivalStartAt)
            return format(date, 'MMM do, yyyy')
        },
    },
    {
        id: 'dropoffStartAt',
        label: 'Dropoff date',
        width: 80,
        getValue: (_?: string, row?: Order) => {
            if (!row?.dropoff?.arrivalStartAt) {
                return
            }

            const date = new Date(row.dropoff.arrivalStartAt)
            return format(date, 'MMM do, yyyy')
        },
    },
    {
        id: 'carrierName',
        label: 'Partner',
        width: 80,
    },
]
