import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { Payable } from 'types/payable.types'
import { getCTSDK } from 'services/ctClient'

export const USE_FIND_PAYABLES_QUERY_KEY = 'useFindPayables'

const ctSDK = getCTSDK()

export const useFindPayables = ({
    limit = 0,
    offset = 10,
    createdAtStartAt,
    inStatusIds = [],
}: {
    limit?: number
    offset?: number
    createdAtStartAt?: string
    inStatusIds?: Payable['status'][]
}) => {
    const ws = useRef<WebSocket | null>(null)

    const [total, setTotal] = useState(0)
    const [payables, setPayables] = useState<Payable[]>([])

    const { isLoading, isRefetching, error, refetch } = useQuery(
        [
            USE_FIND_PAYABLES_QUERY_KEY,
            limit,
            offset,
            inStatusIds,
            createdAtStartAt,
        ],
        async () =>
            await ctSDK.get<{ payables: Payable[]; total: number }>(
                '/payables',
                {
                    params: {
                        limit,
                        offset,
                        createdAtStartAt,
                        inStatusIds: inStatusIds.join(','),
                    },
                }
            ),
        {
            onSuccess: (data) => {
                setTotal(data?.data?.total || 0)
                setPayables(data?.data?.payables || [])
            },
        }
    )

    useEffect(() => {
        ws.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL_2 || '')

        ws.current.onmessage = (event) => {
            const wsEvent = JSON.parse(event.data)

            if (
                [
                    'payableQueued',
                    'payableProcessed',
                    'payableDeleted',
                    'payableSynced',
                    'documentParsed',
                ].includes(wsEvent.type)
            ) {
                refetch()
            }
        }

        return () => {
            ws.current?.close()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        isLoading,
        isRefetching,
        total,
        rows: payables,
        error,
        refetch,
    }
}
