import styled from '@emotion/styled'
import { Order } from 'types/order.types'
import { AddressInfo } from './AddressInfo'
import { SPACING } from 'constants/spacing.constants'
import { SCREEN_SIZES } from 'constants/screenSizes.constants'
import Column from 'components/core/Column'

const StyledAddresses = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${SPACING.p5};

    @media (max-width: ${SCREEN_SIZES.lg}) {
        grid-template-columns: 1fr;
        grid-gap: ${SPACING.p3};
    }

    .addresses__column {
        gap: ${SPACING.p3};
    }
`

type AddressesInfoProps = {
    order?: Pick<Order, 'dropoff' | 'pickup' | 'extraPickups' | 'extraDropoffs'>
}

export const AddressesInfo = ({ order }: AddressesInfoProps) => {
    if (!order) return null

    return (
        <StyledAddresses>
            <Column className="addresses__column">
                <AddressInfo
                    address={order?.pickup}
                    title={
                        !!order?.extraPickups?.length ? 'Pickup 1' : 'Origin'
                    }
                />
                {order?.extraPickups?.map((extraPickup, index) => (
                    <AddressInfo
                        address={extraPickup}
                        title={`Pickup ${index + 2}`}
                    />
                ))}
            </Column>

            <Column className="addresses__column">
                <AddressInfo
                    address={order?.dropoff}
                    title={
                        !!order?.extraDropoffs?.length ? 'Dropoff 1' : 'Origin'
                    }
                />
                {order?.extraDropoffs?.map((extraDropoff, index) => (
                    <AddressInfo
                        address={extraDropoff}
                        title={`Dropoff ${index + 2}`}
                    />
                ))}
            </Column>
        </StyledAddresses>
    )
}
