import { UseQueryResult, useQuery } from 'react-query'
import { getCTSDK } from 'services/ctClient'

const sdk = getCTSDK()

type GetMSOrgAccountResponse = {
    account: {
        token: {
            isValid: boolean
        }
        subscriptions: {
            id: string
            resource: string
            expirationDateTime: string
        }[]
    }
}

export const useGetMSAccount = (): UseQueryResult<
    GetMSOrgAccountResponse,
    unknown
> => {
    return useQuery(['useGetMSAccount'], async () => {
        const resp = await sdk.get<GetMSOrgAccountResponse>(
            `/integrations/microsoft/users/me`
        )
        return resp.data
    })
}
