import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import * as RadixProgress from '@radix-ui/react-progress'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'

const StyledProgress = styled(RadixProgress.Root)`
    overflow: hidden;
    background-color: ${DESIGN_TOKENS.muted};
    border-radius: 999.9rem;
    width: 100%;
    height: 1.6rem;
    min-height: 1.6rem;

    /* Fix overflow clipping in Safari */
    /* https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
    transform: translateZ(0);
`

const StyledIndicator = styled(RadixProgress.Indicator)<{ color?: string }>`
    background-color: ${(p) => (p.color ? p.color : BASE_TOKENS.grey[950])};
    width: 100%;
    height: 100%;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
    border-radius: 999.9rem;
`

type ProgressProps = {
    value: number
    color?: string
}

const Progress = ({ value: propsValue, color }: ProgressProps) => {
    const [value, setValue] = useState(0)

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setValue(propsValue)
        }, 300)

        return () => {
            clearTimeout(timeoutId)
        }
    }, [])

    return (
        <StyledProgress value={value}>
            <StyledIndicator
                color={color}
                style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
            />
        </StyledProgress>
    )
}

export default Progress
