import { ReactElement } from 'react'
import styled from '@emotion/styled'
import ReactQuill from 'react-quill'
import TextInput from 'components/core/TextInput'
import Typography from 'components/core/Typography'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'

import 'react-quill/dist/quill.snow.css'

const emptyFunc = () => undefined

const StyledEmail = styled.div<{ isOutbound: boolean }>`
    position: relative;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    background-color: ${(p) =>
        p.isOutbound ? BASE_TOKENS.slate[100] : BASE_TOKENS.grey[50]};
    border-radius: 0.6rem;

    .email__icon {
        position: absolute;
        top: 1.6rem;
        right: 1.6rem;
    }
`

const StyledQuill = styled(ReactQuill)`
    display: flex;
    flex-direction: column;

    .ql-toolbar {
        border-top-left-radius: 0.6rem;
        border-top-right-radius: 0.6rem;
    }

    .ql-container {
        border-bottom-left-radius: 0.6rem;
        border-bottom-right-radius: 0.6rem;
        min-height: 8rem;
    }

    .ql-toolbar,
    .ql-container {
        font-family: Inter !important;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2rem;
        color: ${DESIGN_TOKENS.text.textForeground};
        border: 0.1rem solid ${DESIGN_TOKENS.input};
        background-color: ${BASE_TOKENS.grey[0]};
    }
`

const StyledContentReadOnly = styled.div`
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    color: ${DESIGN_TOKENS.text.textForeground};
    margin-top: 1.6rem;
`

type EmailProps = {
    isOutbound: boolean
    isEditable?: boolean
    fromEmail: string
    toEmail?: string
    subject?: string
    htmlContent?: string
    Icon?: ReactElement
    onBlurSubject?: () => void
    onBlurContent?: () => void
    onChangeSubject?: (subject: string) => void
    onChangeContent?: (htmlContent: string) => void
}

const Email = ({
    isOutbound,
    isEditable = false,
    fromEmail,
    toEmail,
    subject,
    htmlContent,
    Icon,
    onBlurSubject = emptyFunc,
    onBlurContent = emptyFunc,
    onChangeSubject = emptyFunc,
    onChangeContent = emptyFunc,
}: EmailProps) => {
    return (
        <StyledEmail isOutbound={isOutbound}>
            <Typography color="primary" variant="body2">
                From: {fromEmail}
            </Typography>
            {Icon && <span className="email__icon">{Icon}</span>}
            <Typography color="primary" variant="body2">
                To: {toEmail}
            </Typography>
            {isEditable ? (
                <TextInput
                    value={subject}
                    onBlur={onBlurSubject}
                    onChange={onChangeSubject}
                />
            ) : (
                <Typography color="primary" variant="body2">
                    Subject: {subject}
                </Typography>
            )}
            {htmlContent && (
                <>
                    {isEditable ? (
                        <StyledQuill
                            value={htmlContent}
                            onBlur={onBlurContent}
                            onChange={onChangeContent}
                        />
                    ) : (
                        <StyledContentReadOnly
                            dangerouslySetInnerHTML={{
                                __html: htmlContent,
                            }}
                        />
                    )}
                </>
            )}
        </StyledEmail>
    )
}

export default Email
