import { BoardColumn } from 'components/composite/Board'
import CommonChip from 'components/core/CommonChip'
import { Automation } from 'types/automation.types'
import { BASE_TOKENS } from 'constants/color.constants'
import { getDateFromISOString, getTimeFromISOString } from 'utils/date.utils'
import { AutomationActionType } from 'types/automationAction.types'
import FullIdCell from './components/FullIdCell'
import ReferenceCell from './components/ReferenceCell'
import AutomationActionsCell from './components/AutomationActionsCell'
import { getAutomationActionSummary } from './utils/automation.utils'
import {
    AUTOMATION_TYPE_LABELS,
    AUTOMATION_STATUS_LABELS,
    AUTOMATION_STATUS_TEXT_COLORS,
    AUTOMATION_STATUS_BORDER_COLORS,
    AUTOMATION_STATUS_BACKGROUND_COLORS,
} from './automations.constants'
import Typography from 'components/core/Typography'

export const tableColumns: BoardColumn[] = [
    {
        id: 'fullId',
        label: 'Automation ID',
        width: 80,
        renderCell: (row: Automation, onRefetchRows: () => void) => (
            <FullIdCell
                id={row.id}
                fullId={row.fullId}
                onRefetchRows={onRefetchRows}
            />
        ),
    },
    {
        id: 'currentActionScheduledAt',
        label: 'Date',
        width: 80,
        getValue: (value: string) => getDateFromISOString(value),
    },
    {
        id: 'currentActionScheduledAt',
        label: 'Time',
        width: 80,
        getValue: (value: string) => getTimeFromISOString(value),
    },
    {
        id: 'summary',
        label: 'Summary',
        width: 208,
        getValue: (_: AutomationActionType, row: Automation) =>
            getAutomationActionSummary({
                eventType: row.currentEventType,
                actionType: row.currentActionType,
                actionStatus: row.currentActionStatus,
                eventCreatedAt: row.currentEventCreatedAt,
                actionScheduledAt: row.currentActionScheduledAt,
                automationStatus: row.status,
            }),
    },
    {
        id: 'type',
        label: 'Type',
        width: 80,
        renderCell: (row: Automation) => (
            <CommonChip
                label={AUTOMATION_TYPE_LABELS[row.type]}
                textColor={BASE_TOKENS.grey[900]}
                borderColor={BASE_TOKENS.grey[300]}
                backgroundColor={BASE_TOKENS.grey[50]}
            />
        ),
    },
    {
        id: 'status',
        label: 'Status',
        width: 80,
        renderCell: (row: Automation) => (
            <CommonChip
                label={AUTOMATION_STATUS_LABELS[row.status]}
                textColor={AUTOMATION_STATUS_TEXT_COLORS[row.status]}
                borderColor={AUTOMATION_STATUS_BORDER_COLORS[row.status]}
                backgroundColor={
                    AUTOMATION_STATUS_BACKGROUND_COLORS[row.status]
                }
            />
        ),
    },
    {
        id: 'referenceNumber',
        label: 'Reference',
        width: 80,
        renderCell: (row: Automation) =>
            row.tmsEntityNumbers.length > 1 ? (
                <Typography color="primary" variant="body2">
                    Multiple
                </Typography>
            ) : (
                <ReferenceCell
                    referenceId={row.tmsEntityNumbers[0].id}
                    referenceNumber={row.tmsEntityNumbers[0].fullId}
                    automationTmsEntityType={row.tmsEntityType}
                />
            ),
    },
    {
        id: 'tmsCarrierName',
        label: 'Partner',
        width: 80,
    },
    {
        id: 'actions',
        width: 28,
        renderCell: (row: Automation, onRefetchRows: () => void) => (
            <AutomationActionsCell
                automationId={row.id}
                automationStatus={row.status}
                currentActionId={row.currentActionId}
                currentActionType={row.currentActionType}
                currentActionStatus={row.currentActionStatus}
                onRefetchAutomations={onRefetchRows}
            />
        ),
    },
]
