import { DetailsRow } from './DetailsRow'
import { CarrierAsyncSelect } from 'components/composite/CarrierAsyncSelect'
import { Order } from 'types/order.types'
import NumberInput from 'components/composite/NumberInput'
import { CurrencySelect } from 'components/composite/CurrencySelect'
import { CURRENCY_LABELS } from 'constants/currency.constants'
import { OrderChangeProp } from '../orderDetails.types'
import { Carrier } from 'types/carrier.types'
import Select from 'components/core/Select'
import { ORDER_PRICING_OPTIONS } from '../orderDetails.constants'
import { stringToPercent, stringToCurrency } from 'utils/finances.utils'

type OrderDetailsCarrierSectionProps = {
    isEditable: boolean
    order?: Order
    orderMutation?: Partial<Order>
    onChangeOrder: (orderMutation: Partial<Order>) => void
    onClosePopup: (propName: OrderChangeProp) => void
}

export const OrderDetailsCarrierSection = ({
    isEditable,
    order,
    orderMutation,
    onChangeOrder,
    onClosePopup,
}: OrderDetailsCarrierSectionProps) => {
    const {
        carrierId,
        carrierName,
        carrierCost,
        markupPercent,
        revenue,
        carrierCurrency,
        pricingType,
    } = order || {}

    const {
        carrierCurrency: carrierCurrencyMutation,
        carrierCost: carrierCostMutation,
        revenue: revenueMutation,
        markupPercent: markupPercentMutation,
    } = orderMutation || {}

    const _onChangeOrder =
        (propName: OrderChangeProp) => (value: string | number | undefined) => {
            let orderMutation: Partial<Order> = {}

            if (typeof value === 'number' || value === undefined) {
                orderMutation = { [propName]: value ?? 0 }
            } else {
                orderMutation = { [propName]: value }
            }

            onChangeOrder(orderMutation)
        }

    const _onChangeCarrier = (carrierId: string, carrier?: Carrier) => {
        onChangeOrder({ carrierId, carrierName: carrier?.name })
    }

    const _onClosePopup = (propName: OrderChangeProp) => () => {
        onClosePopup(propName)
    }

    return (
        <>
            <DetailsRow
                isEditable={isEditable}
                caption="Carrier"
                valueText={carrierName}
                onClosePopup={_onClosePopup('carrierId')}
            >
                <CarrierAsyncSelect
                    shouldFocusOnMount
                    onChangeCarrier={_onChangeCarrier}
                />
            </DetailsRow>

            <DetailsRow
                isEditable={isEditable}
                onClosePopup={_onClosePopup('pricingType')}
                caption="Pricing Type"
                valueText={
                    ORDER_PRICING_OPTIONS.find(
                        (option) => option.value === pricingType
                    )?.label || ''
                }
            >
                <Select
                    value={pricingType || ''}
                    options={ORDER_PRICING_OPTIONS}
                    onSelectedOptionChanged={_onChangeOrder('pricingType')}
                />
            </DetailsRow>
            <DetailsRow
                isEditable={isEditable}
                caption="Currency"
                valueText={
                    carrierCurrency ? CURRENCY_LABELS[carrierCurrency] : ''
                }
                onClosePopup={_onClosePopup('carrierCurrency')}
            >
                <CurrencySelect
                    currencyType={carrierCurrencyMutation}
                    onChange={_onChangeOrder('carrierCurrency')}
                />
            </DetailsRow>
            <DetailsRow
                isEditable={isEditable}
                caption="Carrier cost"
                valueText={stringToCurrency(carrierCost, carrierCurrency)}
                onClosePopup={_onClosePopup('carrierCost')}
            >
                <NumberInput
                    placeholder="Carrier cost"
                    prefix="$ "
                    value={carrierCostMutation}
                    onChange={_onChangeOrder('carrierCost')}
                />
            </DetailsRow>
            <DetailsRow
                isEditable={pricingType === 'markup'}
                caption="Handling fee"
                onClosePopup={_onClosePopup('markupPercent')}
                valueText={
                    stringToCurrency(
                        (markupPercent ?? 0) * (carrierCost ?? 0),
                        carrierCurrency
                    ) + ` (${stringToPercent(markupPercent)})`
                }
            >
                <NumberInput
                    isPercentage
                    placeholder="Markup"
                    value={markupPercentMutation}
                    onChange={_onChangeOrder('markupPercent')}
                />
            </DetailsRow>
            <DetailsRow
                isEditable={pricingType === 'flatFee'}
                caption="Revenue"
                valueText={stringToCurrency(revenue, carrierCurrency)}
                onClosePopup={_onClosePopup('revenue')}
            >
                <NumberInput
                    placeholder="Revenue"
                    prefix="$ "
                    value={revenueMutation}
                    onChange={_onChangeOrder('revenue')}
                />
            </DetailsRow>
        </>
    )
}
