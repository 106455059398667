import { Order } from 'types/order.types'
import { OrderDetailsAction } from '../orderDetails.types'

export const getAvailableOrderActions = (
    order?: Order
): OrderDetailsAction[] => {
    if (!order) {
        return []
    }

    const actions: OrderDetailsAction[] = []

    if (order.trackingToken) {
        actions.push('getTrackingLink')
    }

    return actions
}
