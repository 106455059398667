import { useQuery } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { Order } from 'types/order.types'
import { OrderCommodity } from 'types/orderCommodity.types'

const sdk = getCTSDK()

export const useGetOrderById = (id?: string) => {
    return useQuery(
        ['useGetOrderById', id],
        async () => await sdk.get<{ order: Order }>(`/sp/shipments/${id}`),
        {
            enabled: !!id,
        }
    )
}

export const useGetOrderCommoditiesById = (id?: string) => {
    return useQuery(
        ['useGetOrderCommoditiesById', id],
        async () =>
            await sdk.get<{ commodities: OrderCommodity[] }>(
                `/sp/shipments/${id}/commodities`
            ),
        {
            enabled: !!id,
        }
    )
}
