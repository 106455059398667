import styled from '@emotion/styled'
import * as RadixTabs from '@radix-ui/react-tabs'
import { DESIGN_TOKENS } from 'constants/color.constants'

const StyledTabsList = styled(RadixTabs.List)`
    height: 4.2rem;
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.4rem;
    background-color: ${DESIGN_TOKENS.muted};
`

export default StyledTabsList
