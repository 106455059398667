import { getCTSDK } from 'services/ctClient'
import { showToastError } from 'hooks/useToast'
import GCAuthButton from './components/GcAuthButton'
import { useGetGcAccount } from '../../queries/gc.user.queries'

const ctSDK = getCTSDK()

const GcAuthButtonContainer = () => {
    const { isLoading, data, refetch } = useGetGcAccount()

    const isConnected = !!data?.account?.token?.isValid

    const _onConnectToEmail = async () => {
        try {
            const resp = await ctSDK.get('/integrations/email/google/authUrl')

            const url = resp.data?.url
            if (!url) {
                showToastError('Error getting email login page')
                return
            }

            const popup = window.open(url, '_blank', 'width=650,height=600')
            if (!popup) {
                showToastError('Email login popup is blocked by browser')
                return
            }

            const timer = setInterval(() => {
                if (popup.closed) {
                    clearInterval(timer)
                    refetch()
                }
            }, 1000)

            popup.focus()
        } catch (error) {
            showToastError('Error connecting to email')
        }
    }

    return (
        <GCAuthButton
            isLoading={isLoading}
            isConnected={isConnected}
            onConnectToEmail={_onConnectToEmail}
        />
    )
}

export default GcAuthButtonContainer
