import { Route } from 'react-router-dom'
import PrivateRoute from 'routes/PrivateRoute'
import MotherComponent from 'modules/MotherComponent'
import Payables from 'modules/Payables'
import Receivables from 'modules/Receivables'
import Automations from 'modules/Automations'
import Settings from 'modules/Settings'
import Pricing from 'modules/Pricing'
import Orders from 'modules/Orders'
import OrderDetails from 'modules/OrderDetails'
import Customers from 'modules/Customers'
import Carriers from 'modules/Carriers'
import { Users } from 'modules/Users'

export const OpsRoutes = () => {
    return (
        <Route element={<PrivateRoute />}>
            <Route element={<MotherComponent />}>
                <Route path="payables" element={<Payables />} />
                <Route path="receivables" element={<Receivables />} />
                <Route path="tasks" element={<Automations />} />
                <Route path="settings" element={<Settings />} />
                <Route path="pricing" element={<Pricing />} />
                <Route path="orders" element={<Orders />} />
                <Route path="orders/:id" element={<OrderDetails />} />
                <Route path="customers" element={<Customers />} />
                <Route path="carriers" element={<Carriers />} />
                <Route path="users" element={<Users />} />
            </Route>
        </Route>
    )
}
