import { useQuery } from 'react-query'
import { Org } from 'types/org.types'
import { getCTSDK } from 'services/ctClient'
import { ConnectorOrg } from 'types/connectorOrg.types'

const ctSDK = getCTSDK()

export const USE_MY_ORG_QUERY_KEY = 'useMyOrg'

export const useMyOrg = () => {
    const { isLoading, data } = useQuery(
        [USE_MY_ORG_QUERY_KEY],
        async () =>
            await ctSDK.get<{ org: Org; connectorOrgs: ConnectorOrg[] }>(
                '/orgs/myOrg'
            )
    )

    return {
        isLoading,
        org: data?.data?.org || ({} as Org),
        connectorOrgs: data?.data?.connectorOrgs || [],
    }
}
