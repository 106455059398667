import { format } from 'date-fns'
import { Order, OrderStatus } from 'types/order.types'

export const getOrderSummary = (order?: Order): string => {
    if (!order) {
        return '--'
    }

    if (order.deliveredAt) {
        const deliveryDate = new Date(order.deliveredAt)
        const deliveryDateStr = format(deliveryDate, 'EEEE MMM do')
        const deliveryTimeStr = format(deliveryDate, 'h:mm a')
        return `Delivered on ${deliveryDateStr} at ${deliveryTimeStr}`
    } else if (order.pickedUpAt) {
        const pickupDate = new Date(order.pickedUpAt)
        const pickupDateStr = format(pickupDate, 'EEEE MMM do')
        const pickupTimeStr = format(pickupDate, 'h:mm a')
        return `Order picked up on ${pickupDateStr} at ${pickupTimeStr}`
    } else if (order.bookedAt) {
        const bookedDate = new Date(order.bookedAt)
        const bookedDateStr = format(bookedDate, 'EEEE MMM do')
        const bookedTimeStr = format(bookedDate, 'h:mm a')
        return `Booked on ${bookedDateStr} at ${bookedTimeStr}`
    } else if (order.createdAt) {
        return `Waiting on carrier confirmation`
    }

    return '--'
}

export const getOrderStatus = (order?: Order): OrderStatus => {
    if (!order) {
        return 'new'
    }

    if (order.deliveredAt) {
        return 'delivered'
    } else if (order.pickedUpAt) {
        return 'inTransit'
    } else if (order.bookedAt) {
        return 'booked'
    }

    return 'new'
}
