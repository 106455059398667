import styled from '@emotion/styled'
import AddressSearchInput from 'components/composite/AddressSearchInput'
import Button from 'components/core/Button'
import TextInput from 'components/core/TextInput'
import Dialog2, { DialogActions2, DialogContent2 } from 'components2/Dialog2'
import { Search } from 'lucide-react'
import { Address } from 'types/address.types'

const StyledDialog = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    .dialog__double {
        display: flex;
        column-gap: 2rem;
    }

    .dialog__single {
        width: calc(50% - 1rem);
    }
`

type CreateCustomerDialogProps = {
    isOpen: boolean
    isSubmitting: boolean
    billingEmail?: string
    address?: Address
    onSave: () => void
    onClose: () => void
    onChangeBillingEmail: (billingEmail: string) => void
    onChangeAddress: (address: Address) => void
}

const CreateCustomerDialog = ({
    isOpen,
    isSubmitting,
    billingEmail,
    address,
    onSave,
    onClose,
    onChangeBillingEmail,
    onChangeAddress,
}: CreateCustomerDialogProps) => {
    const { name, address1, address2, city, state, country, postalCode } =
        address || {}

    const _onChangeAddress = (key: keyof Address) => (value?: string) => {
        // @ts-ignore
        onChangeAddress({ ...address, [key]: value })
    }

    return (
        <Dialog2
            isOpen={isOpen}
            size="md"
            title="Create customer"
            onClose={onClose}
        >
            <DialogContent2>
                <StyledDialog>
                    <AddressSearchInput
                        label="Search"
                        placeholder="Search address"
                        Icon={Search}
                        onChange={onChangeAddress}
                    />
                    <TextInput
                        label="Name"
                        placeholder="Name"
                        value={name}
                        onChange={_onChangeAddress('name')}
                    />
                    <div className="dialog__double">
                        <TextInput
                            label="Address 1"
                            placeholder="Address 1"
                            value={address1}
                            onChange={_onChangeAddress('address1')}
                        />
                        <TextInput
                            label="Address 2"
                            placeholder="Address 2"
                            value={address2}
                            onChange={_onChangeAddress('address2')}
                        />
                    </div>
                    <div className="dialog__double">
                        <TextInput
                            label="City"
                            placeholder="City"
                            value={city}
                            onChange={_onChangeAddress('city')}
                        />
                        <TextInput
                            label="State"
                            placeholder="State"
                            value={state}
                            onChange={_onChangeAddress('state')}
                        />
                    </div>
                    <div className="dialog__double">
                        <TextInput
                            label="Postal code"
                            placeholder="Postal code"
                            value={postalCode}
                            onChange={_onChangeAddress('postalCode')}
                        />
                        <TextInput
                            label="Country"
                            placeholder="Country"
                            value={country}
                            onChange={_onChangeAddress('country')}
                        />
                    </div>
                    <div className="dialog__single">
                        <TextInput
                            label="Billing email"
                            placeholder="Billing email"
                            value={billingEmail}
                            onChange={onChangeBillingEmail}
                        />
                    </div>
                </StyledDialog>
            </DialogContent2>
            <DialogActions2>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    isSubmitting={isSubmitting}
                    variant="primary"
                    onClick={onSave}
                >
                    Create
                </Button>
            </DialogActions2>
        </Dialog2>
    )
}

export default CreateCustomerDialog
