import { MapPinHouse } from 'lucide-react'
import OrderEventControl from './OrderEventControl'

type OrderPickedUpEventProps = {
    pickedUpAt: string
}

const OrderPickedUpEvent = ({ pickedUpAt }: OrderPickedUpEventProps) => {
    return (
        <OrderEventControl
            title="Order picked up"
            timestamp={pickedUpAt}
            Icon={MapPinHouse}
        />
    )
}

export default OrderPickedUpEvent
