import styled from '@emotion/styled'
import { CustomerAsyncSelect } from 'components/composite/CustomerAsyncSelect'
import Button from 'components/core/Button'
import Select, { SelectAsync, SelectOption } from 'components/core/Select'
import TextInput from 'components/core/TextInput'
import Dialog2, { DialogActions2, DialogContent2 } from 'components2/Dialog2'
import { User } from 'types/user.types'
import {
    PropNameChange,
    UserValidationError,
} from '../CreateUserDialogContainer.types'

const StyledDialog = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`

type CreateUserDialogProps = {
    isOpen: boolean
    isSubmitting: boolean
    user?: Partial<User>
    errors?: UserValidationError
    onSave: () => void
    onClose: () => void
    onChangeUser: (user: Partial<User>, propName: PropNameChange) => void
}

export const CreateUserDialog = ({
    isOpen,
    isSubmitting,
    user,
    errors,
    onSave,
    onClose,
    onChangeUser,
}: CreateUserDialogProps) => {
    const _onChangeUser = (propName: PropNameChange) => (value: any) => {
        onChangeUser(
            {
                ...user,
                [propName]: value,
            },
            propName
        )
    }

    return (
        <Dialog2
            isOpen={isOpen}
            size="sm"
            title="Create user"
            onClose={onClose}
        >
            <DialogContent2>
                <StyledDialog>
                    <Select
                        isDisabled={isSubmitting}
                        label="User Role"
                        value={user?.role}
                        error={errors?.role}
                        placeholder="Role"
                        options={[
                            { label: 'Ops User', value: 'user' },
                            { label: 'Customer User', value: 'customerUser' },
                        ]}
                        onSelectedOptionChanged={_onChangeUser('role')}
                    />
                    {user?.role === 'customerUser' && (
                        <CustomerAsyncSelect
                            label="Search"
                            error={errors?.customerId}
                            onChangeCustomer={_onChangeUser('customerId')}
                        />
                    )}
                    <TextInput
                        label="Email"
                        placeholder="Email"
                        error={errors?.email}
                        value={user?.email || ''}
                        onChange={_onChangeUser('email')}
                    />
                    <TextInput
                        label="First Name"
                        placeholder="First Name"
                        error={errors?.firstName}
                        value={user?.firstName || ''}
                        onChange={_onChangeUser('firstName')}
                    />
                    <TextInput
                        label="Last Name"
                        placeholder="Last Name"
                        error={errors?.lastName}
                        value={user?.lastName || ''}
                        onChange={_onChangeUser('lastName')}
                    />
                </StyledDialog>
            </DialogContent2>
            <DialogActions2>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    isSubmitting={isSubmitting}
                    variant="primary"
                    onClick={onSave}
                >
                    Create
                </Button>
            </DialogActions2>
        </Dialog2>
    )
}
