import { QueryKey, useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { Payable } from 'types/payable.types'
import { useUpdatePayableById } from 'mutations/payable.mutations'
import { USE_FIND_PAYABLES_QUERY_KEY } from 'modules/Payables/hooks/useFindPayables'
import { USE_GET_PAYABLE_STATUS_COUNTS_QUERY_KEY } from 'queries/payable.queries'

export const usePayable = (id: string) => {
    const queryClient = useQueryClient()
    const queryCache = queryClient.getQueryCache()
    const allQueries = queryCache.getAll()

    const useFindPayablesKeys =
        allQueries
            .map((query) => query.queryKey)
            .filter((k) => k?.[0] === USE_FIND_PAYABLES_QUERY_KEY) || []
    const usePayableStatusCountsKeys =
        allQueries
            .map((query) => query.queryKey)
            .filter(
                (k) => k?.[0] === USE_GET_PAYABLE_STATUS_COUNTS_QUERY_KEY
            ) || []

    const { isLoading: isSubmitting, mutateAsync: updatePayable } =
        useUpdatePayableById(id)

    const onUpdatePayable = async (payable: Partial<Payable>) => {
        const keysWithCachedResp: { key: QueryKey; cachedResp: any }[] =
            useFindPayablesKeys.map((key) => {
                const cachedResp =
                    queryClient.getQueryData<
                        AxiosResponse<{ payables: Payable[] }>
                    >(key)

                if (cachedResp) {
                    const prevPayables = cachedResp.data?.payables || []

                    const updatedPayables = prevPayables.map((p) =>
                        p.id === id ? { ...p, ...payable } : p
                    )

                    queryClient.setQueryData(key, {
                        ...cachedResp,
                        data: { ...cachedResp.data, payables: updatedPayables },
                    })
                }

                return { key, cachedResp }
            })

        try {
            await updatePayable(payable)
            useFindPayablesKeys.forEach((key) =>
                queryClient.invalidateQueries(key)
            )
            usePayableStatusCountsKeys.forEach((key) =>
                queryClient.invalidateQueries(key)
            )
        } catch (error) {
            keysWithCachedResp.forEach(({ key, cachedResp }) =>
                queryClient.setQueryData(key, cachedResp)
            )
            throw error
        }
    }

    return {
        isSubmitting,
        onUpdatePayable,
    }
}
