import { useState } from 'react'
import Settings from './components/Settings'
import { TabId } from './settings.types'
import { useMyOrgSettings } from 'hooks/useMyOrgSettings'
import {
    isAutomationsModuleFFDisabled,
    isReceivablesModuleFFDisabled,
} from 'utils/featureFlag.utils'

const SettingsContainer = () => {
    const { settings } = useMyOrgSettings()
    const [selectedTabId, setSelectedTabId] = useState<TabId>('payables')

    return (
        <Settings
            isAutomationsFFEnabled={!isAutomationsModuleFFDisabled(settings)}
            isReceivablesFFEnabled={!isReceivablesModuleFFDisabled(settings)}
            selectedTabId={selectedTabId}
            onSetSelectedTabId={setSelectedTabId}
        />
    )
}

export default SettingsContainer
