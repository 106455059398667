import { Route } from 'react-router-dom'
import Admin from 'superAdminPortal/modules/Admin'
import AdminRoute from '../../routes/AdminRoute'

export const SuperAdminRoutes = () => {
    return (
        <Route path="admin" element={<AdminRoute />}>
            <Route index element={<Admin />} />
        </Route>
    )
}
