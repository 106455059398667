import { UseQueryOptions, useQuery } from 'react-query'
import { Payable } from 'types/payable.types'
import { Document } from 'types/document.types'
import { getCTSDK } from 'services/ctClient'
import { AxiosResponse } from 'axios'

const ctSDK = getCTSDK()

export const useGetDocument = (
    documentId?: string,
    options?: Omit<
        UseQueryOptions<AxiosResponse<{ document: Document }>>,
        'queryKey' | 'queryFn'
    >
) => {
    return useQuery(
        ['useGetDocument', documentId],
        async () =>
            await ctSDK.get<{ document: Document }>(`/documents/${documentId}`),
        options
    )
}

export const useGetDocumentPayables = (documentId?: string, options?: any) => {
    return useQuery(
        ['useGetDocumentPayables', documentId],
        async () =>
            await ctSDK.get<{ payables: Payable[] }>(
                `/documents/${documentId}/payables`
            ),
        options
    )
}
