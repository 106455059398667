import styled from '@emotion/styled'
import Row from 'components/core/Row'
import Select from 'components/core/Select'
import TextInput from 'components/core/TextInput'
import { SPACING } from 'constants/spacing.constants'
import { OrderCommodity } from 'types/orderCommodity.types'
import NumberInput from '../NumberInput'
import Table from '../Table'
import IconButton from 'components/core/IconButton'
import { Copy, Trash2 } from 'lucide-react'
import { v4 as uuidv4 } from 'uuid'

const MODE_OPTIONS = [
    { value: 'ltl', label: 'LTL' },
    { value: 'tl', label: 'TL' },
]

const FREIGHT_TYPE_OPTIONS = [
    { value: 'skid', label: 'Skid' },
    { value: 'carton', label: 'Carton' },
    { value: 'bundle', label: 'Bundle' },
    { value: 'package', label: 'Package' },
    { value: 'container', label: 'Container' },
    { value: 'crate', label: 'Crate' },
    { value: 'pallet', label: 'Pallet' },
    { value: 'other', label: 'Other' },
]

const CLASS_OPTIONS = [
    { value: '50', label: '50' },
    { value: '55', label: '55' },
    { value: '60', label: '60' },
    { value: '65', label: '65' },
    { value: '70', label: '70' },
    { value: '77.5', label: '77.5' },
    { value: '85', label: '85' },
    { value: '92.5', label: '92.5' },
    { value: '100', label: '100' },
    { value: '125', label: '125' },
    { value: '150', label: '150' },
    { value: '175', label: '175' },
    { value: '200', label: '200' },
    { value: '250', label: '250' },
    { value: '300', label: '300' },
    { value: '400', label: '400' },
    { value: '500', label: '500' },
]

const StyledCommodityInputs = styled(Row)`
    grid-gap: ${SPACING.p2};
    padding: ${SPACING.p2};
`

type CommodityInputsProps = {
    commodities?: Partial<OrderCommodity>[]
    tableClassName?: string
    onChange: (commodities: Partial<OrderCommodity>[]) => void
}

const emptyArr: [] = []

export const CommodityInputs = ({
    commodities = emptyArr,
    tableClassName,
    onChange,
}: CommodityInputsProps) => {
    const _onChangeCommodity =
        ({ key, index }: { key: keyof OrderCommodity; index: number }) =>
        (value?: string | number) => {
            const newCommodities = commodities.map((commodity, i) =>
                i === index ? { ...commodity, [key]: value } : commodity
            )
            onChange(newCommodities)
        }

    const _onDeleteCommodity = (index: number) => {
        const newCommodities = commodities.filter((_, i) => i !== index)
        onChange(newCommodities)
    }

    const _onDuplicateCommodity = (index: number) => {
        const newCommodities = [...commodities]
        newCommodities.splice(index, 0, {
            ...commodities[index],
            id: `new-${uuidv4()}`,
        })
        onChange(newCommodities)
    }

    const columns = [
        {
            isRequired: true,
            id: 'description',
            label: 'Description',
            width: 80,
            renderCell: (row: Partial<OrderCommodity>, index: number) => (
                <TextInput
                    isCellStyle
                    value={row.description}
                    placeholder="Description"
                    onChange={_onChangeCommodity({ key: 'description', index })}
                />
            ),
        },
        {
            isRequired: true,
            id: 'mode',
            label: 'Mode',
            width: 30,
            renderCell: (row: Partial<OrderCommodity>, index: number) => (
                <Select
                    isCellStyle
                    shouldPortalMenu
                    shouldHideIndicators
                    value={row.mode}
                    placeholder="Mode"
                    options={MODE_OPTIONS}
                    onSelectedOptionChanged={_onChangeCommodity({
                        key: 'mode',
                        index,
                    })}
                />
            ),
        },
        {
            isRequired: true,
            id: 'quantity',
            label: 'Qty',
            width: 30,
            renderCell: (row: Partial<OrderCommodity>, index: number) => (
                <NumberInput
                    isCellStyle
                    value={row.quantity}
                    placeholder="Qty"
                    onChange={_onChangeCommodity({ key: 'quantity', index })}
                />
            ),
        },
        {
            isRequired: true,
            id: 'freightType',
            label: 'Type',
            width: 30,
            renderCell: (row: Partial<OrderCommodity>, index: number) => (
                <Select
                    isCellStyle
                    shouldPortalMenu
                    shouldHideIndicators
                    value={row.freightType}
                    placeholder="Type"
                    menuPlacement="top"
                    options={FREIGHT_TYPE_OPTIONS}
                    onSelectedOptionChanged={_onChangeCommodity({
                        key: 'freightType',
                        index,
                    })}
                />
            ),
        },
        {
            id: 'length',
            label: 'Lg',
            width: 30,
            renderCell: (row: Partial<OrderCommodity>, index: number) => (
                <NumberInput
                    isCellStyle
                    value={row.length}
                    placeholder="Lg"
                    suffix=" in"
                    onChange={_onChangeCommodity({ key: 'length', index })}
                />
            ),
        },
        {
            id: 'width',
            label: 'Wd',
            width: 30,
            renderCell: (row: Partial<OrderCommodity>, index: number) => (
                <NumberInput
                    isCellStyle
                    value={row.width}
                    placeholder="Wd"
                    suffix=" in"
                    onChange={_onChangeCommodity({ key: 'width', index })}
                />
            ),
        },
        {
            id: 'height',
            label: 'Ht',
            width: 30,
            renderCell: (row: Partial<OrderCommodity>, index: number) => (
                <NumberInput
                    isCellStyle
                    value={row.height}
                    placeholder="Ht"
                    suffix=" in"
                    onChange={_onChangeCommodity({ key: 'height', index })}
                />
            ),
        },
        {
            id: 'pieces',
            label: 'Pieces',
            width: 30,
            renderCell: (row: Partial<OrderCommodity>, index: number) => (
                <NumberInput
                    isCellStyle
                    value={row.pieces}
                    placeholder="Pieces"
                    onChange={_onChangeCommodity({ key: 'pieces', index })}
                />
            ),
        },
        // {
        //     id: 'linearFeet',
        //     label: 'LF',
        //     width: 40,
        //     renderCell: (row: Partial<OrderCommodity>, index: number) => (
        //         <Typography color="primary" variant="body2">
        //             {row.linearFeet ? row.linearFeet : '--'}
        //         </Typography>
        //     ),
        // },
        {
            isRequired: true,
            id: 'weight',
            label: 'Wt',
            width: 30,
            renderCell: (row: Partial<OrderCommodity>, index: number) => (
                <NumberInput
                    isCellStyle
                    value={row.weight}
                    placeholder="Wt"
                    suffix=" lbs"
                    onChange={_onChangeCommodity({ key: 'weight', index })}
                />
            ),
        },
        {
            id: 'class',
            label: 'Class',
            width: 30,
            renderCell: (row: Partial<OrderCommodity>, index: number) => (
                <Select
                    isCellStyle
                    shouldPortalMenu
                    shouldHideIndicators
                    value={row.class}
                    placeholder="Class"
                    menuPlacement="top"
                    options={CLASS_OPTIONS}
                    onSelectedOptionChanged={_onChangeCommodity({
                        key: 'class',
                        index,
                    })}
                />
            ),
        },
        {
            id: 'duplicate',
            label: '',
            width: 10,
            renderCell: (row: Partial<OrderCommodity>, index: number) =>
                index !== commodities.length - 1 ? (
                    <IconButton
                        size="sm"
                        onClick={() => _onDuplicateCommodity(index)}
                    >
                        <Copy size={16} />
                    </IconButton>
                ) : (
                    <div></div>
                ),
        },
        {
            id: 'delete',
            label: '',
            width: 10,
            renderCell: (row: Partial<OrderCommodity>, index: number) =>
                index !== commodities.length - 1 ? (
                    <IconButton
                        size="sm"
                        onClick={() => _onDeleteCommodity(index)}
                    >
                        <Trash2 size={16} />
                    </IconButton>
                ) : (
                    <div></div>
                ),
        },
    ]

    return (
        <StyledCommodityInputs>
            <Table
                className={tableClassName}
                rows={commodities}
                columns={columns}
            />
        </StyledCommodityInputs>
    )
}
