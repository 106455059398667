import styled from '@emotion/styled'
import { BASE_TOKENS } from 'constants/color.constants'

const Log = styled.div`
    height: 2.4rem;
    display: flex;
    align-items: center;
    column-gap: 0.8rem;

    .log__icon {
        width: 2.2rem;
        height: 2.2rem;
        background-color: ${BASE_TOKENS.grey[100]};
        border: 0.1rem solid ${BASE_TOKENS.grey[300]};
        border-radius: 50%;
        padding: 0.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

export default Log
