export const assembleUrl = (
    url: string,
    params: Record<string, string | string[] | boolean | undefined | null>
) => {
    const urlParams = new URLSearchParams()

    for (const key in params) {
        const val = params[key]

        if (Array.isArray(val)) {
            urlParams.append(key, JSON.stringify(val))
        } else if (val !== undefined && val !== null && val !== '') {
            urlParams.append(key, String(val))
        }
    }

    // if there are no valid parameters, return the URL unchanged
    if (!urlParams.toString()) return url

    // add query args
    return `${url}?${urlParams.toString()}`
}
