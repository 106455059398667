import { useEffect, useRef } from 'react'
import { AutomationStatus } from 'types/automation.types'
import { useGetAutomationStatusCounts } from 'queries/automation.queries'

export const useAutomationStatusCounts = (params: {
    createdAtStartAt?: string
    inStatusIdsMap: {
        [key: string]: AutomationStatus[]
    }
}) => {
    const ws = useRef<WebSocket | null>(null)

    const { data, refetch } = useGetAutomationStatusCounts(params)

    useEffect(() => {
        ws.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL_2 || '')

        ws.current.onmessage = (event) => {
            const wsEvent = JSON.parse(event.data)
            if (
                ['automationCreated', 'automationUpdated'].includes(
                    wsEvent.type
                )
            ) {
                refetch()
            }
        }

        return () => {
            ws.current?.close()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { statusCounts: data?.data?.counts, refetchStatusCounts: refetch }
}
