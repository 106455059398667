import styled from '@emotion/styled'
import Typography from 'components/core/Typography'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import { Calendar, Star } from 'lucide-react'
import { Rate } from '../PricingContainer'

const renderStars = (score: number) => {
    const stars = []

    for (let i = 0; i < score; i++) {
        stars.push(
            <Star
                key={i}
                fill={BASE_TOKENS.orange[300]}
                color={BASE_TOKENS.grey[900]}
                strokeWidth={0}
                size={16}
            />
        )
    }

    return stars
}

const StyledQuote = styled.button<{ isSelected: boolean }>`
    width: 100%;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 2rem;
    padding-right: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 0.4rem;
    background-color: ${(p) =>
        p.isSelected ? BASE_TOKENS.grey[200] : BASE_TOKENS.grey[0]};

    border: none;

    cursor: pointer;
    transition: background-color 0.3s ease;

    &:focus {
        outline: none;
    }

    &:focus-visible {
        outline: none;
    }

    &:hover {
        background-color: ${(p) =>
            p.isSelected ? BASE_TOKENS.grey[200] : BASE_TOKENS.grey[100]};
    }

    .quote__carrier {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .quote__carrier__name {
            display: flex;
            width: 70%;
        }

        .quote__carrier__rate {
            display: flex;
            justify-content: flex-end;
        }
    }

    .quote__stars {
        margin-left: -0.2rem;
        display: flex;
        column-gap: 0.2rem;
    }

    .quote__type {
        display: flex;
        padding-right: 2rem;
    }

    .quote__eta {
        margin-top: 0.4rem;
        display: flex;
        align-items: center;
        column-gap: 0.4rem;
    }
`

type QuoteProps = {
    isSelected: boolean
    rate: Rate
    onClick: () => void
}

const Quote = ({ isSelected, rate, onClick }: QuoteProps) => {
    return (
        <StyledQuote isSelected={isSelected} key={rate.id} onClick={onClick}>
            <div className="quote__carrier">
                <span className="quote__carrier__name">
                    <Typography
                        isBold
                        shouldTruncate
                        color="primary"
                        variant="body1"
                    >
                        {rate.carrierName || '--'}
                    </Typography>
                </span>
                <div className="quote__carrier__rate">
                    <Typography color="primary" variant="body1">
                        {Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }).format(rate.carrierRate)}
                    </Typography>
                </div>
            </div>
            <div className="quote__stars">{renderStars(rate.carrierScore)}</div>
            <div className="quote__type">
                <Typography
                    isItalic
                    shouldTruncate
                    color="primary"
                    variant="body1"
                >
                    {rate.type === 'best' && 'Best value'}
                    {rate.type === 'fastest' && 'Fastest delivery'}
                    {rate.type === 'lowest' && 'Lowest price'}
                </Typography>
            </div>
            <div className="quote__eta">
                <Calendar
                    size={16}
                    color={DESIGN_TOKENS.text.textMutedForeground}
                />
                <Typography color="secondary" variant="body2">
                    Est. delivery {rate.etaDays} business day
                    {rate.etaDays > 1 ? 's' : ''}
                </Typography>
            </div>
        </StyledQuote>
    )
}

export default Quote
