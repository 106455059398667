import styled from '@emotion/styled'
import * as RadixSeparator from '@radix-ui/react-separator'
import { DESIGN_TOKENS } from 'constants/color.constants'

const StyledSeparator = styled(RadixSeparator.Root)`
    height: 0.1rem;
    background-color: ${DESIGN_TOKENS.border.borderInput};
`

const Separator = () => {
    return <StyledSeparator decorative />
}

export default Separator
