import { useState } from 'react'
import { CircleCheck } from 'lucide-react'
import ActionsCell from 'components/composite/ActionsCell'
import { PayableStatus } from 'types/payable.types'
import { DESIGN_TOKENS } from 'constants/color.constants'
import ConfirmDeleteDialog from 'dialogs/ConfirmDeleteDialog'
import ProcessPayablesDialog from 'dialogs/ProcessPayablesDialog'
import { showToastError, showToastSuccess } from 'hooks/useToast'
import { getAvailableActions } from './payableActionsCell.utils'
import { useDeletePayable } from '../../hooks/useDeletePayable'
import { useProcessPayables } from '../../hooks/useProcessPayables'
import { useSyncPayableById } from 'mutations/payable.mutations'

const emptyFunc = () => undefined

type PayableActionsCellProps = {
    payableId: string
    documentId?: string
    payableStatus: PayableStatus
    onRefetchPayables: () => void
}

const PayableActionsCell = ({
    payableId,
    documentId,
    payableStatus,
    onRefetchPayables = emptyFunc,
}: PayableActionsCellProps) => {
    const [isProcessDialogOpen, setIsProcessDialogOpen] = useState(false)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

    const { isSubmitting: isProcessing, onProcess } = useProcessPayables()
    const { isSubmitting: isDeleting, onDelete } = useDeletePayable(payableId)
    const { isLoading: isSyncing, mutateAsync: syncPayable } =
        useSyncPayableById()

    const isSubmitting = isProcessing || isDeleting || isSyncing

    const _onClickProcessOption = () => {
        setIsProcessDialogOpen(true)
    }

    const _onClickMarkAsProcessedOption = _onClickProcessOption

    const _onClickDeleteOption = () => {
        setIsDeleteDialogOpen(true)
    }

    const _onProcessPayable = async () => {
        setIsProcessDialogOpen(false)

        try {
            await onProcess([payableId])
            showToastSuccess('Payable added to queue')
            onRefetchPayables()
        } catch (error) {
            showToastError('Error processing payable')
        }
    }

    const _onDeletePayable = async () => {
        setIsDeleteDialogOpen(false)

        try {
            await onDelete()
            showToastSuccess('Payable deleted')
            onRefetchPayables()
        } catch (error) {
            showToastError('Error deleting payable')
        }
    }

    const _onSyncPayable = async () => {
        try {
            await syncPayable(payableId)
            showToastSuccess('Payable refreshed')
            onRefetchPayables()
        } catch (error) {
            showToastError('Error refreshing payable')
        }
    }

    const availableActions = getAvailableActions({
        status: payableStatus,
        documentId,
    })

    const options = [
        { id: 'process', label: 'Process', onClick: _onClickProcessOption },
        {
            id: 'markAsProcessed',
            label: 'Mark as processed',
            onClick: _onClickMarkAsProcessedOption,
        },
        { id: 'refresh', label: 'Refresh', onClick: _onSyncPayable },
        { id: 'delete', label: 'Delete', onClick: _onClickDeleteOption },
    ]

    return (
        <>
            <ProcessPayablesDialog
                isOpen={isProcessDialogOpen}
                onClose={() => setIsProcessDialogOpen(false)}
                onConfirm={_onProcessPayable}
            />
            <ConfirmDeleteDialog
                isOpen={isDeleteDialogOpen}
                header="Delete payable"
                message="Are you sure you want to delete this payable?"
                onClose={() => setIsDeleteDialogOpen(false)}
                onConfirm={_onDeletePayable}
            />
            {['processed', 'manuallyProcessed'].includes(payableStatus) ? (
                <CircleCheck
                    size={16}
                    color={DESIGN_TOKENS.secondaryForeground}
                />
            ) : (
                <ActionsCell
                    isSubmitting={isSubmitting}
                    options={options.filter((o) =>
                        availableActions.includes(o.id)
                    )}
                />
            )}
        </>
    )
}

export default PayableActionsCell
