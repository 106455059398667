import { useGetPODById } from 'queries/pod.queries'
import PODViewerDialog from './components/PODViewerDialog'

const emptyFunc = () => undefined

type PODViewerDialogContainerProps = {
    isOpen: boolean
    podId?: string
    onClose: () => void
}

const PODViewerDialogContainer = ({
    isOpen,
    podId,
    onClose = emptyFunc,
}: PODViewerDialogContainerProps) => {
    const { data: podData } = useGetPODById(podId, {
        enabled: isOpen && !!podId,
    })

    return (
        <PODViewerDialog
            isOpen={isOpen}
            fileUrl={podData?.data?.pod?.fileUrl}
            onClose={onClose}
        />
    )
}

export default PODViewerDialogContainer
