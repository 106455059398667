import { AxiosResponse } from 'axios'
import { UseQueryOptions, useQuery } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { AutomationEvent } from 'types/automationEvent.types'

const ctSDK = getCTSDK()

export const useGetAutomationEventsById = (
    id: string,
    options?: Omit<
        UseQueryOptions<AxiosResponse<{ events: AutomationEvent[] }>>,
        'queryKey' | 'queryFn'
    >
) => {
    return useQuery(
        ['useGetAutomationEventsById', id],
        async () =>
            await ctSDK.get<{ events: AutomationEvent[] }>(
                `/automations/${id}/events`
            ),
        options
    )
}
