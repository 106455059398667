import axios, { AxiosResponse } from 'axios'
import { useMutation, UseMutationResult, UseMutationOptions } from 'react-query'
import { AvailableRate, Location } from '../types/pricing.types'

type Cargo = {
    length: number
    width: number
    height: number
    quantity: number
    weight: number
    type: string
}

type Shipment = {
    pickupDate: string
    pickupLocation: Location
    deliveryLocation: Location
    cargo: Record<string, Cargo>
}

type CargoDetails = {
    length: number
    width: number
    height: number
    quantity: number
    weight: number
    type: string
    freightClass: number
    id: string
}

type ShipmentResponseData = {
    availableRates: Record<string, AvailableRate>
    cargo: Record<string, CargoDetails>
    deliveryLocation: Location
    directionsPolyline: string
    expiresAt: string
    miles: number
    pickupDate: string
    pickupLocation: Location
    rates: {
        standard: {
            bestValue: AvailableRate
            fastest: AvailableRate
            lowest: AvailableRate
        }
    }
    selectedRate: {
        carrierScac: string
        serviceCommitment: string
        serviceCommitmentDescription: string
        serviceLevel: string
        serviceType: string
    }
    totalPalletSpace: number
    totalWeight: number
    type: string
}

type ShipmentResponse = {
    data: ShipmentResponseData
    key: string
    metadata: {
        invalidFieldsRequiredForPurchase: Record<
            string,
            Record<string, { description: string }>
        >
        purchasable: boolean
        rateVersion: number
    }
}

export const usePostQuote = (
    options?: UseMutationOptions<
        AxiosResponse<ShipmentResponse>,
        unknown,
        { shipment: Shipment },
        unknown
    >
): UseMutationResult<
    AxiosResponse<ShipmentResponse>,
    unknown,
    { shipment: Shipment },
    unknown
> =>
    useMutation<
        AxiosResponse<ShipmentResponse>,
        unknown,
        { shipment: Shipment }
    >(
        (data: { shipment: Shipment }) =>
            axios.post<ShipmentResponse>(
                'https://services.mothership.com/axel/quote',
                data
            ),
        options
    )
