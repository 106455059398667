import { useEffect, useRef, useState } from 'react'
import NumberInput from 'components/composite/NumberInput'
import { usePayable } from 'hooks/usePayable'
import { showToastError, showToastInfo, showToastSuccess } from 'hooks/useToast'

const emptyFunc = () => undefined

type BillTotalCellProps = {
    payableId: string
    value?: number
    onSuccess: () => void
}

const BillTotalCell = ({
    payableId,
    value,
    onSuccess = emptyFunc,
}: BillTotalCellProps) => {
    const [total, setTotal] = useState(value)
    const inputRef = useRef<HTMLInputElement>(null)

    const { isSubmitting, onUpdatePayable } = usePayable(payableId)

    const _onSave = async () => {
        if (total === value) {
            return
        }

        const { dismiss } = showToastInfo('Updating payable...')

        try {
            await onUpdatePayable({ adjustedInvoiceTotal: total })
            showToastSuccess('Payable updated')
            onSuccess()
        } catch {
            showToastError('Error updating payable')
        } finally {
            dismiss()
        }
    }

    const _onKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key !== 'Enter') {
            return
        }

        if (!inputRef.current) {
            return
        }

        inputRef.current.blur()

        await _onSave()
    }

    useEffect(() => {
        if (!inputRef.current) {
            return
        }

        inputRef.current?.focus()
    }, [])

    return (
        <NumberInput
            isDisabled={isSubmitting}
            isCellStyle
            value={total}
            placeholder="Bill total"
            ref={inputRef}
            onChange={setTotal}
            onKeyDown={_onKeyDown}
        />
    )
}

export default BillTotalCell
