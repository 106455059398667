import {
    AutomationEvent,
    EmailReceivedAutomationEvent,
    EmailSentAutomationEvent,
    StatusChangedAutomationEvent,
} from 'types/automationEvent.types'

export const isEmailSentAutomationEvent = (
    event: AutomationEvent
): event is EmailSentAutomationEvent => {
    return event.type === 'emailSent'
}

export const isEmailReceivedAutomationEvent = (
    event: AutomationEvent
): event is EmailReceivedAutomationEvent => {
    return event.type === 'emailReceived'
}

export const isStatusChangedAutomationEvent = (
    event: AutomationEvent
): event is StatusChangedAutomationEvent => {
    return event.type === 'automationStatusChanged'
}
