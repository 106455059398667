import { Mail } from 'lucide-react'
import Typography from 'components/core/Typography'
import Log from './Log'
import { EmailSentAutomationEvent } from 'types/automationEvent.types'
import { getTimeFromISOString } from 'utils/date.utils'
import styled from '@emotion/styled'
import Email from './Email'

const StyledEmailSentEvent = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
`

type EmailSentEventProps = {
    event: EmailSentAutomationEvent
}

const EmailSentEvent = ({ event }: EmailSentEventProps) => {
    const { createdAt, data } = event
    const { subject, fromEmail, htmlContent, toEmails } = data

    return (
        <StyledEmailSentEvent>
            <Log>
                <span className="log__icon">
                    <Mail size={12} />
                </span>
                <Typography color="primary" variant="body2">
                    We sent a response
                </Typography>
                <Typography color="secondary" variant="body2">
                    {getTimeFromISOString(createdAt)}
                </Typography>
            </Log>
            <Email
                isOutbound
                subject={subject}
                toEmail={toEmails[0]}
                fromEmail={fromEmail}
                htmlContent={htmlContent}
            />
        </StyledEmailSentEvent>
    )
}

export default EmailSentEvent
