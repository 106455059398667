import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCTSDK } from 'services/ctClient'
import { Outlet } from 'react-router-dom'

const ctSDK = getCTSDK()

export const ShipperPortalRoute = () => {
    const navigate = useNavigate()

    useEffect(() => {
        const validateToken = async () => {
            try {
                await ctSDK.post('/users/sp/validate')
            } catch (error) {
                console.error(error)
                navigate('/login')
            }
        }

        validateToken()
    }, [navigate])

    return <Outlet />
}
