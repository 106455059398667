import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import styled from '@emotion/styled'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import { SPACING } from 'constants/spacing.constants'

const StyledTooltipContent = styled(TooltipPrimitive.Content)`
    border-radius: 0.6rem;
    border: 0.1rem solid ${DESIGN_TOKENS.input};
    padding: ${SPACING.p2};
    font-size: 1.4rem;
    color: ${DESIGN_TOKENS.primaryForeground};
    background-color: ${BASE_TOKENS.grey[950]};
    max-width: 40rem;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    animation: fadeIn 100ms ease-out;
`

const Tooltip = ({
    children,
    content,
    sideOffset = 4,
    delayDuration = 0,
}: {
    delayDuration?: number
    sideOffset?: number
    children: React.ReactChild
    content?: React.ReactChild
}) => {
    if (!content) {
        return <>{children}</>
    }
    return (
        <TooltipPrimitive.Provider delayDuration={delayDuration}>
            <TooltipPrimitive.Root>
                <TooltipPrimitive.Trigger asChild>
                    {children}
                </TooltipPrimitive.Trigger>
                <TooltipPrimitive.Portal>
                    <StyledTooltipContent sideOffset={sideOffset}>
                        {content}
                    </StyledTooltipContent>
                </TooltipPrimitive.Portal>
            </TooltipPrimitive.Root>
        </TooltipPrimitive.Provider>
    )
}

export default Tooltip
