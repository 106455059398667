import styled from '@emotion/styled'
import { pxToRem } from 'utils/rem.utils'
import { ACTIONS_HEIGHT } from '../constants/dialog2.constants'
import { DESIGN_TOKENS } from 'constants/color.constants'

const StyledDialogActions = styled.div`
    height: ${pxToRem(ACTIONS_HEIGHT)};
    min-height: ${pxToRem(ACTIONS_HEIGHT)};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 1.2rem;
    border-top: 1px solid ${DESIGN_TOKENS.border.borderInput};
    padding-left: 2.4rem;
    padding-right: 2.4rem;
`

type DialogActionsProps = {
    children: React.ReactNode
}

const DialogActions = ({ children }: DialogActionsProps) => {
    return <StyledDialogActions>{children}</StyledDialogActions>
}

export default DialogActions
