import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp } from 'lucide-react'

const CHEVRON_ICON_SIZE = 16

type ChevronProps = {
    className?: string
    size?: number
    disabled?: boolean
    orientation?: 'up' | 'down' | 'left' | 'right'
}

export const Chevron = (props: ChevronProps) => {
    const { orientation } = props

    if (orientation === 'left') {
        return <ChevronLeft size={CHEVRON_ICON_SIZE} />
    }
    if (orientation === 'right') {
        return <ChevronRight size={CHEVRON_ICON_SIZE} />
    }
    if (orientation === 'up') {
        return <ChevronUp size={CHEVRON_ICON_SIZE} />
    }

    return <ChevronDown size={CHEVRON_ICON_SIZE} />
}
