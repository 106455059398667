import { useQuery } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { User } from 'types/user.types'

const ctSDK = getCTSDK()

export const useGetMyUsers = () => {
    return useQuery(
        ['useGetMyUsers'],
        async () => await ctSDK.get<{ users: User[] }>(`/users`)
    )
}
