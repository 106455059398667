import { useState } from 'react'
import { Address } from 'types/address.types'
import { useCreateCustomer } from 'mutations/customer.mutations'
import { showToastError, showToastSuccess } from 'hooks/useToast'
import CreateCustomerDialog from './components/CreateCustomerDialog'

type CreateCustomerDialogContainerProps = {
    isOpen: boolean
    onClose: () => void
    onSuccess: () => void
}

const CreateCustomerDialogContainer = ({
    isOpen,
    onClose,
    onSuccess,
}: CreateCustomerDialogContainerProps) => {
    const [billingEmail, setBillingEmail] = useState<string>()
    const [address, setAddress] = useState<Address>()

    const { isLoading: isSubmitting, mutateAsync: createCustomer } =
        useCreateCustomer()

    const _onClear = () => {
        setBillingEmail(undefined)
        setAddress(undefined)
    }

    const _onClose = () => {
        onClose()
        _onClear()
    }

    const _onSave = async () => {
        try {
            await createCustomer({
                name: address?.name,
                nameLowercase: address?.name?.toLowerCase(),
                billingEmail,
                address,
            })
            showToastSuccess('Customer created')
            onClose()
            onSuccess()
            _onClear()
        } catch (error) {
            showToastError('Error creating customer')
        }
    }

    return (
        <CreateCustomerDialog
            isOpen={isOpen}
            isSubmitting={isSubmitting}
            billingEmail={billingEmail}
            address={address}
            onSave={_onSave}
            onClose={_onClose}
            onChangeBillingEmail={setBillingEmail}
            onChangeAddress={setAddress}
        />
    )
}

export default CreateCustomerDialogContainer
