import { useState } from 'react'
import AnchorTag from 'components/core/AnchorTag'
import AutomationDetailsDialog from 'dialogs/AutomationDetailsDialog'

const emptyFunc = () => undefined

type FullIdCellProps = {
    id: string
    fullId: string
    onRefetchRows: () => void
}

const FullIdCell = ({
    id,
    fullId,
    onRefetchRows = emptyFunc,
}: FullIdCellProps) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const _onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        setIsDialogOpen(true)
    }

    return (
        <>
            <AutomationDetailsDialog
                isOpen={isDialogOpen}
                id={id}
                onClose={() => setIsDialogOpen(false)}
                onSuccess={onRefetchRows}
            />
            <AnchorTag href="#" color="primary" onClick={_onClick}>
                {fullId}
            </AnchorTag>
        </>
    )
}

export default FullIdCell
