import { useQuery } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { Payable, PayableStatus } from 'types/payable.types'

const ctSDK = getCTSDK()

export const useGetPayableById = (payableId: string, options?: any) => {
    return useQuery(
        ['useGetPayableById', payableId],
        async () =>
            await ctSDK.get<{ payable: Payable }>(`/payables/${payableId}`),
        options
    )
}

export const USE_GET_PAYABLE_STATUS_COUNTS_QUERY_KEY =
    'useGetPayablesStatusCounts'

export const useGetPayablesStatusCounts = ({
    createdAtStartAt,
    inStatusIdsMap,
}: {
    createdAtStartAt?: string
    inStatusIdsMap: {
        [key: string]: PayableStatus[]
    }
}) => {
    return useQuery(
        [
            USE_GET_PAYABLE_STATUS_COUNTS_QUERY_KEY,
            inStatusIdsMap,
            createdAtStartAt,
        ],
        async () =>
            await ctSDK.get<{ counts: { [key: string]: number } }>(
                '/payables/statusCount',
                {
                    params: {
                        createdAtStartAt,
                        inStatusIdsMap: encodeURIComponent(
                            JSON.stringify(inStatusIdsMap)
                        ),
                    },
                }
            )
    )
}

export const useGetQueuedPayables = () => {
    return useQuery(
        ['useGetQueuedPayables'],
        async () => await ctSDK.get<{ payables: Payable[] }>('/payables/queued')
    )
}
