import { BASE_TOKENS } from 'constants/color.constants'
import { ReceivableStatus } from 'types/receivable.types'

export const RECEIVABLE_STATUS_GROUP_IDS = {
    invoice: 'invoice',
    review: 'review',
    invoiced: 'invoiced',
    manuallyInvoiced: 'manuallyInvoiced',
    paymentReceived: 'paymentReceived',
}

export const RECEIVABLE_STATUS_GROUP_LABELS = {
    [RECEIVABLE_STATUS_GROUP_IDS.invoice]: 'Invoice',
    [RECEIVABLE_STATUS_GROUP_IDS.review]: 'Review',
    [RECEIVABLE_STATUS_GROUP_IDS.invoiced]: 'Invoiced',
    [RECEIVABLE_STATUS_GROUP_IDS.manuallyInvoiced]: 'Manual',
    [RECEIVABLE_STATUS_GROUP_IDS.paymentReceived]: 'Payment received',
}

export const RECEIVABLE_STATUS_GROUPS: { [key: string]: ReceivableStatus[] } = {
    [RECEIVABLE_STATUS_GROUP_IDS.invoice]: ['readyToSend'],
    [RECEIVABLE_STATUS_GROUP_IDS.review]: [
        'requiresReview',
        'manualProcessingEnforced',
        'error',
    ],
    [RECEIVABLE_STATUS_GROUP_IDS.invoiced]: ['invoiced'],
    [RECEIVABLE_STATUS_GROUP_IDS.manuallyInvoiced]: ['manuallyInvoiced'],
    [RECEIVABLE_STATUS_GROUP_IDS.paymentReceived]: ['paymentReceived'],
}

export const STATUS_LABELS: { [key in ReceivableStatus]: string } = {
    invoiced: 'Invoiced',
    readyToSend: 'Ready to send',
    requiresReview: 'Requires review',
    manualProcessingEnforced: 'Disabled',
    paymentReceived: 'Payment received',
    manuallyInvoiced: 'Manual invoiced',
    error: 'Error',
}

export const STATUS_BACKGROUND_COLORS: { [key in ReceivableStatus]: string } = {
    invoiced: BASE_TOKENS.green[50],
    readyToSend: BASE_TOKENS.blue[50],
    requiresReview: BASE_TOKENS.orange[50],
    manualProcessingEnforced: BASE_TOKENS.orange[50],
    paymentReceived: BASE_TOKENS.green[50],
    manuallyInvoiced: BASE_TOKENS.green[50],
    error: BASE_TOKENS.red[50],
}

export const STATUS_BORDER_COLORS: { [key in ReceivableStatus]: string } = {
    invoiced: '#16A34A80',
    readyToSend: BASE_TOKENS.blue[300],
    requiresReview: BASE_TOKENS.orange[300],
    manualProcessingEnforced: BASE_TOKENS.orange[300],
    paymentReceived: '#16A34A80',
    manuallyInvoiced: '#16A34A80',
    error: BASE_TOKENS.red[300],
}

export const STATUS_TEXT_COLORS: { [key in ReceivableStatus]: string } = {
    invoiced: BASE_TOKENS.green[900],
    readyToSend: BASE_TOKENS.blue[900],
    requiresReview: BASE_TOKENS.orange[900],
    manualProcessingEnforced: BASE_TOKENS.orange[900],
    paymentReceived: BASE_TOKENS.green[900],
    manuallyInvoiced: BASE_TOKENS.green[900],
    error: BASE_TOKENS.red[900],
}
