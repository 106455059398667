import { ReactNode } from 'react'
import styled from '@emotion/styled'
import * as RadixMenubar from '@radix-ui/react-menubar'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'

const StyledMenuContent = styled(RadixMenubar.Content)`
    min-width: 16rem;
    background-color: ${BASE_TOKENS.grey[0]};
    border: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};
    border-radius: 0.6rem;
    box-shadow:
        0px 4px 6px -1px rgba(0, 0, 0, 0.1),
        0px 2px 4px -2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    &[data-state='open'] {
        animation: fadeIn 300ms ease-out;
    }
`

type MenuProps = {
    isDisabled?: boolean
    trigger: ReactNode
    children: ReactNode
}

const Menu = ({ isDisabled = false, trigger, children }: MenuProps) => {
    return (
        <RadixMenubar.Root>
            <RadixMenubar.Menu>
                <RadixMenubar.Trigger asChild disabled={isDisabled}>
                    {trigger}
                </RadixMenubar.Trigger>
                <RadixMenubar.Portal>
                    <StyledMenuContent align="end" sideOffset={4}>
                        {children}
                    </StyledMenuContent>
                </RadixMenubar.Portal>
            </RadixMenubar.Menu>
        </RadixMenubar.Root>
    )
}

export default Menu
