import Button from 'components/core/Button'
import TextInput from 'components/core/TextInput'
import AnchorTag from 'components/core/AnchorTag'
import Typography from 'components/core/Typography'
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'constants/urls'
import { LoginFormRoot } from './LoginFormRoot'
import { LoginFormSection } from './LoginFormSection'

type LoginFormProps = {
    isSubmitting: boolean
    email: string
    onLogin: () => void
    onChangeEmail: (value: string) => void
}

const emptyFunc = () => undefined

export const LoginForm = ({
    isSubmitting,
    email,
    onLogin = emptyFunc,
    onChangeEmail = emptyFunc,
}: LoginFormProps) => {
    return (
        <LoginFormRoot>
            <LoginFormSection>
                <Typography color="primary" variant="h2">
                    Login to Cartage
                </Typography>
                <Typography color="secondary" variant="body1">
                    Enter your email to login
                </Typography>
            </LoginFormSection>
            <LoginFormSection>
                <TextInput
                    value={email}
                    placeholder="name@example.com"
                    onChange={onChangeEmail}
                />
                <Button
                    isDisabled={!email}
                    isSubmitting={isSubmitting}
                    isFullWidth
                    variant="primary"
                    onClick={onLogin}
                >
                    Continue
                </Button>
            </LoginFormSection>
            <div>
                <Typography color="secondary" variant="body2">
                    By clicking continue, you agree to our{' '}
                    <AnchorTag
                        rel="noreferrer noopener"
                        href={TERMS_OF_USE_URL}
                        color="secondary"
                        target="_blank"
                    >
                        Terms of Use
                    </AnchorTag>{' '}
                    and{' '}
                    <AnchorTag
                        rel="noreferrer noopener"
                        href={PRIVACY_POLICY_URL}
                        color="secondary"
                        target="_blank"
                    >
                        Privacy Policy
                    </AnchorTag>
                    .
                </Typography>
            </div>
        </LoginFormRoot>
    )
}
