import { useMutation } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { TMSConnectorOrgSettings } from 'types/connectorOrg.types'

const ctSDK = getCTSDK()

export const useMutateRRSettings = () => {
    return useMutation(({ settings }: { settings: TMSConnectorOrgSettings }) =>
        ctSDK.post('/integrations/roserocket/settings', settings)
    )
}
