import { useState } from 'react'
import { useQuery } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { Customer } from 'types/customer.types'
import { User } from 'types/user.types'

export const USE_FIND_USERS_QUERY_KEY = 'useFindUsers'
export type ExtendedUser = User & { customerName?: Customer['name'] }

const ctSDK = getCTSDK()

export const useFindUsers = ({
    limit = 10,
    offset = 0,
}: {
    limit?: number
    offset?: number
}) => {
    const [total, setTotal] = useState(0)
    const [users, setUsers] = useState<ExtendedUser[]>([])

    const { isLoading, isRefetching, error, refetch } = useQuery(
        [USE_FIND_USERS_QUERY_KEY, limit, offset],
        async () =>
            await ctSDK.get<{ users: ExtendedUser[]; total: number }>(
                '/users',
                {
                    params: {
                        limit,
                        offset,
                        hasCustomerData: true,
                    },
                }
            ),
        {
            onSuccess: (data) => {
                setTotal(data?.data?.total || 0)
                setUsers(data?.data?.users || [])
            },
        }
    )

    return {
        isLoading,
        isRefetching,
        total,
        rows: users,
        error,
        refetch,
    }
}
