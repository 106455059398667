import { useGetMyOrgSettings } from 'queries/orgSettings.queries'

export const useMyOrgSettings = () => {
    const { isLoading, data } = useGetMyOrgSettings()

    return {
        isLoading,
        settings: data?.data?.settings,
    }
}
