import styled from '@emotion/styled'
import * as RadixSwitch from '@radix-ui/react-switch'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'

const emptyFunc = () => undefined

const StyledSwitch = styled(RadixSwitch.Root)`
    width: 3.6rem;
    min-width: 3.6rem;
    height: 2rem;
    min-height: 2rem;
    background-color: ${DESIGN_TOKENS.input};
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 999.9rem;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &:focus-visible {
        outline: none;
        box-shadow:
            0 0 0 0.2rem ${BASE_TOKENS.grey[0]},
            0 0 0 0.4rem ${BASE_TOKENS.grey[400]};
    }

    &[data-state='checked'] {
        background-color: ${DESIGN_TOKENS.secondaryForeground};
    }
`

const StyledThumb = styled(RadixSwitch.Thumb)`
    width: 1.6rem;
    height: 1.6rem;
    display: block;
    background-color: ${DESIGN_TOKENS.background};
    border-radius: 999.9rem;

    transition: transform 100ms;
    transform: translateX(0.2rem);
    will-change: transform;

    &[data-state='checked'] {
        transform: translateX(1.8rem);
    }
`

type SwitchProps = {
    isChecked: boolean
    isDisabled?: boolean
    onChange: (isChecked: boolean) => void
}

const Switch = ({
    isChecked,
    isDisabled = false,
    onChange = emptyFunc,
}: SwitchProps) => {
    return (
        <StyledSwitch
            disabled={isDisabled}
            checked={isChecked}
            onCheckedChange={onChange}
        >
            <StyledThumb />
        </StyledSwitch>
    )
}

export default Switch
