import { useState } from 'react'
import { getCTSDK } from 'services/ctClient'
import { RRCarrier } from 'integrations/roserocket/types/rrCarrier.types'
import { SelectOption } from 'components/core/Select'
import { useMutateRRSettings } from 'integrations/roserocket/mutations/settings.mutations'
import { useGetRRSettings } from 'integrations/roserocket/queries/rr.settings.queries'
import { removeDuplicates } from 'utils/string.utils'

const ctSDK = getCTSDK()

export const useRRAutomationSettings = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [carrierDispatchEmailsMap, setCarrierDispatchEmailsMap] = useState<{
        [carrierId: string]: string
    }>()
    const [newCarrierDispatchEmail, setNewCarrierDispatchEmail] = useState<{
        carrierId?: string
        email?: string
    }>()

    const { isLoading: isLoadingSettings } = useGetRRSettings({
        onSuccess: (data) => {
            setCarrierDispatchEmailsMap(
                data?.data?.settings?.carrierDispatchEmailsMap
            )
        },
    })

    const { isLoading: isSubmitting, mutateAsync: saveRRSettings } =
        useMutateRRSettings()

    const onSaveSettings = async () => {
        const data: {
            [carrierId: string]: string
        } = {
            ...carrierDispatchEmailsMap,
            ...(newCarrierDispatchEmail?.carrierId &&
                newCarrierDispatchEmail?.email && {
                    [newCarrierDispatchEmail.carrierId]:
                        newCarrierDispatchEmail.email,
                }),
        }

        const cleanData: { [carrierId: string]: string } = {}

        // just in case remove duplicate emails if user entered similar emails
        Object.keys(data).forEach((carrierId) => {
            cleanData[carrierId] = removeDuplicates(data[carrierId])
        })

        await saveRRSettings({
            settings: {
                carrierDispatchEmailsMap: cleanData,
            },
        })

        if (JSON.stringify(data) !== JSON.stringify(cleanData)) {
            setCarrierDispatchEmailsMap(cleanData)
        }
    }

    const onAddCarrierDispatchEmail = () => {
        setNewCarrierDispatchEmail({})
    }

    const onChangeCarrierDispatchEmail = (carrierId: string, email: string) => {
        setCarrierDispatchEmailsMap((prev) => ({
            ...prev,
            [carrierId]: email,
        }))
    }

    const onChangeNewCarrierDispatchEmail = (
        carrierId?: string,
        email?: string
    ) => {
        setNewCarrierDispatchEmail({ carrierId, email })
    }

    const onFetchCarrierOptions = async (
        searchTerm: string
    ): Promise<SelectOption[]> => {
        try {
            const resp = await ctSDK.get<{ carriers: RRCarrier[] }>(
                '/integrations/roserocket/carriers',
                {
                    params: { searchTerm },
                }
            )

            const fetchedOptions =
                resp.data?.carriers.map((carrier) => ({
                    value: carrier.id,
                    label: carrier.name,
                })) || []

            if (carrierDispatchEmailsMap) {
                const carrierIds = Object.keys(carrierDispatchEmailsMap)

                return fetchedOptions.filter(
                    (option) => !carrierIds.includes(option.value)
                )
            }

            return fetchedOptions
        } catch (error) {
            console.error('Error fetching carrier options:', error)
            return []
        }
    }

    const onFetchInitCarrier =
        (id?: string) => async (): Promise<SelectOption[]> => {
            if (!id) {
                return []
            }

            setIsLoading(true)

            try {
                const carrier = await ctSDK.get<{
                    carrier: RRCarrier
                }>(`/integrations/roserocket/carriers/${id}`)

                return [
                    {
                        value: carrier.data.carrier.id,
                        label: carrier.data.carrier.name,
                    },
                ]
            } catch (error) {
                console.error('Error fetching init vendors:', error)
                return []
            } finally {
                setIsLoading(false)
            }
        }

    return {
        isLoading: isLoading || isLoadingSettings,
        isSubmitting,
        newCarrierDispatchEmail,
        carrierDispatchEmailsMap,
        onSaveSettings,
        onFetchInitCarrier,
        onFetchCarrierOptions,
        onAddCarrierDispatchEmail,
        onChangeCarrierDispatchEmail,
        onChangeNewCarrierDispatchEmail,
    }
}
