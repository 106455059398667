import styled from '@emotion/styled'

const StyledDialogActions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 2.4rem;
    column-gap: 1.2rem;
`

type DialogActionsProps = {
    children: React.ReactNode
}

const DialogActions = ({ children }: DialogActionsProps) => {
    return <StyledDialogActions>{children}</StyledDialogActions>
}

export default DialogActions
