import styled from '@emotion/styled'
import Button from 'components/core/Button'
import Dialog2, { DialogActions2, DialogContent2 } from 'components2/Dialog2'
import { AssignCarrierValidationError } from '../AssignCarrierDialogContainer.types'
import { CarrierAsyncSelect } from 'components/composite/CarrierAsyncSelect'

const StyledDialog = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`

type AssignCarrierDialogProps = {
    isOpen: boolean
    isSubmitting: boolean
    errors?: AssignCarrierValidationError
    onSave: () => void
    onClose: () => void
    onChangeCarrier: (carrierId: string) => void
}

export const AssignCarrierDialog = ({
    isOpen,
    isSubmitting,
    errors,
    onSave,
    onClose,
    onChangeCarrier,
}: AssignCarrierDialogProps) => {
    return (
        <Dialog2
            isOpen={isOpen}
            size="sm"
            title="Assign carrier"
            onClose={onClose}
        >
            <DialogContent2>
                <StyledDialog>
                    <CarrierAsyncSelect
                        shouldPortalMenu
                        error={errors?.carrierId}
                        onChangeCarrier={onChangeCarrier}
                    />
                </StyledDialog>
            </DialogContent2>
            <DialogActions2>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    isSubmitting={isSubmitting}
                    variant="primary"
                    onClick={onSave}
                >
                    Assign
                </Button>
            </DialogActions2>
        </Dialog2>
    )
}
