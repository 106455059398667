import { AxiosResponse } from 'axios'
import { UseMutationOptions, useMutation } from 'react-query'
import { Payable } from 'types/payable.types'
import { getCTSDK } from 'services/ctClient'

const ctSDK = getCTSDK()

export const useSyncPayableById = () => {
    return useMutation(['useSyncPayableById'], (id: string) =>
        ctSDK.post(`/payables/${id}/sync`)
    )
}

export const useUpdatePayableById = (
    id: string,
    options?: UseMutationOptions<
        AxiosResponse<{ payable: Payable }>,
        unknown,
        Partial<Payable>
    >
) => {
    return useMutation(
        ['useUpdatePayableById', id],
        (data: Partial<Payable>) => ctSDK.put(`/payables/${id}`, { data }),
        options
    )
}
