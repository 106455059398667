import { CircleAlert, CircleCheck } from 'lucide-react'
import Row from 'components/core/Row'
import Typography from 'components/core/Typography'
import { SPACING } from 'constants/spacing.constants'
import { EtaStatusType } from 'types/eta.types'
import { ETA_STATUS_LABELS } from 'constants/eta.constants'

export type EtaStatusChipProps = {
    status: EtaStatusType
}

const ICON_MAP: Record<EtaStatusType, React.ReactNode> = {
    late: <CircleAlert size={SPACING.p2} />,
    onTime: <CircleCheck size={SPACING.p2} />,
}

export const EtaStatusChip = (props: EtaStatusChipProps) => {
    return (
        <Row className="flex items-center space-x-2">
            {ICON_MAP[props.status]}
            <Typography shouldTruncate color="primary" variant="body2">
                {ETA_STATUS_LABELS[props.status]}
            </Typography>
        </Row>
    )
}
