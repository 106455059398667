import Button from 'components/core/Button'
import SettingsTab from '../../SettingsTab'
import RRAutomationSettings, {
    RRAutomationSettingsProps,
} from 'integrations/roserocket/components/RRAutomationSettings'
import Card from 'components/composite/Card'
import Switch from 'components/core/Switch'
import Select, { SelectOption } from 'components/core/Select'

type AutomationSettingsProps = {
    isAutoSendEnabled: boolean
    isAutoReplyOnPositiveResponseEnabled: boolean
    userIdAutoSendAs?: string
    autoSendAsOptions: SelectOption[]
    onSaveSettings: () => void
    onChangeUserIdAutoSendAs: (value: string) => void
    onChangeIsAutoSendEnabled: (value: boolean) => void
    onChangeIsAutoReplyOnPositiveResponseEnabled: (value: boolean) => void
} & RRAutomationSettingsProps

const AutomationSettings = ({
    isLoading,
    isSubmitting,
    isAutoSendEnabled,
    isAutoReplyOnPositiveResponseEnabled,
    userIdAutoSendAs,
    newCarrierDispatchEmail,
    carrierDispatchEmailsMap,
    autoSendAsOptions,
    onSaveSettings,
    onFetchInitCarrier,
    onFetchCarrierOptions,
    onChangeUserIdAutoSendAs,
    onChangeIsAutoSendEnabled,
    onAddCarrierDispatchEmail,
    onChangeCarrierDispatchEmail,
    onChangeNewCarrierDispatchEmail,
    onChangeIsAutoReplyOnPositiveResponseEnabled,
}: AutomationSettingsProps) => {
    const isCarrierEmpty =
        carrierDispatchEmailsMap &&
        Object.keys(carrierDispatchEmailsMap).some(
            (key) => !carrierDispatchEmailsMap[key]
        )

    const isNewCarrierEmpty = newCarrierDispatchEmail
        ? !newCarrierDispatchEmail.carrierId || !newCarrierDispatchEmail.email
        : false

    const isDisabled =
        isCarrierEmpty ||
        isNewCarrierEmpty ||
        isSubmitting ||
        isLoading ||
        ((isAutoSendEnabled || isAutoReplyOnPositiveResponseEnabled) &&
            !userIdAutoSendAs)

    return (
        <SettingsTab
            title="Tasks"
            description="These settings control how tasks are automated"
        >
            <Card
                title="Auto-send track and trace emails"
                subtitle="Automatically send track and track emails to carriers"
                HeaderInput={
                    <Switch
                        isChecked={isAutoSendEnabled}
                        isDisabled={isLoading || isSubmitting}
                        onChange={onChangeIsAutoSendEnabled}
                    />
                }
            />
            <Card
                title="Auto-reply to track and trace confirmations"
                subtitle={`Automatically reply with some variation of "thank you" to track and trace confirmations`}
                HeaderInput={
                    <Switch
                        isChecked={isAutoReplyOnPositiveResponseEnabled}
                        isDisabled={isLoading || isSubmitting}
                        onChange={onChangeIsAutoReplyOnPositiveResponseEnabled}
                    />
                }
            />
            <Select
                isDisabled={isSubmitting}
                label="Auto-send as user"
                value={userIdAutoSendAs}
                placeholder="Search users"
                description="Select the user that will be used to for automated emails"
                options={autoSendAsOptions}
                onSelectedOptionChanged={onChangeUserIdAutoSendAs}
            />
            <RRAutomationSettings
                isLoading={isLoading}
                isSubmitting={isSubmitting}
                newCarrierDispatchEmail={newCarrierDispatchEmail}
                carrierDispatchEmailsMap={carrierDispatchEmailsMap}
                onFetchInitCarrier={onFetchInitCarrier}
                onFetchCarrierOptions={onFetchCarrierOptions}
                onAddCarrierDispatchEmail={onAddCarrierDispatchEmail}
                onChangeCarrierDispatchEmail={onChangeCarrierDispatchEmail}
                onChangeNewCarrierDispatchEmail={
                    onChangeNewCarrierDispatchEmail
                }
            />
            <div>
                <Button
                    isDisabled={isDisabled}
                    isSubmitting={isSubmitting}
                    variant="primary"
                    onClick={onSaveSettings}
                >
                    Save
                </Button>
            </div>
        </SettingsTab>
    )
}

export default AutomationSettings
