import { useEffect, useMemo, useRef } from 'react'
import styled from '@emotion/styled'
import { v4 as uuidv4 } from 'uuid'
import { X, ChevronDown, LucideIcon } from 'lucide-react'
import { CircularProgress } from '@mui/material'
import Select, {
    GroupBase,
    MultiValue,
    OptionsOrGroups,
    PropsValue,
    StylesConfig,
    components,
} from 'react-select'
import Typography from 'components/core/Typography'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import Label from './Label'
import MultiValueComponent from './MultiValue'
import { SelectOption } from '../select.types'
import { SEARCH_TERM_MIN_LENGTH } from '../select.constants'
import { useDimensions } from 'hooks/useDimensions'

const StyledBaseSelect = styled.div<{ isCellStyle: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.6rem;

    ${(p) => p.isCellStyle && `height: 100%; justify-content: center;`}
`

const StyledIconContainer = styled.span`
    position: absolute;
    top: 1.1rem;
    left: 1.2rem;
    z-index: 1;
`

const StyledSelectContainer = styled.div`
    position: relative;
`

type BaseSelectProps<TIsMulti> = {
    isMulti: TIsMulti
    isLoading?: boolean
    isDisabled?: boolean
    isCellStyle?: boolean
    shouldPortalMenu?: boolean
    shouldFocusOnMount?: boolean
    shouldHideIndicators?: boolean
    label?: string
    inputValue: string
    placeholder?: string
    description?: string
    error?: string
    menuPlacement?: 'auto' | 'bottom' | 'top'
    options: OptionsOrGroups<SelectOption, GroupBase<SelectOption>>
    selectedOptions: PropsValue<SelectOption>
    menuPortalTarget?: HTMLElement
    styles?: {
        menu?: React.CSSProperties
        control?: React.CSSProperties
    }
    Icon?: LucideIcon
    onChange: (value: MultiValue<SelectOption>) => void
    onInputChange: (value: string) => void
}

const BaseSelect = <TIsMulti extends true | undefined>({
    isMulti,
    isLoading,
    isDisabled,
    isCellStyle = false,
    shouldPortalMenu = false,
    shouldFocusOnMount = false,
    shouldHideIndicators = false,
    label,
    inputValue,
    placeholder,
    description,
    error,
    menuPlacement,
    options,
    selectedOptions,
    styles: propsStyles,
    Icon,
    onChange,
    onInputChange,
}: BaseSelectProps<TIsMulti>) => {
    const selectId = useRef(uuidv4())
    const inputRef = useRef<any>(null)
    const [dimensions, containerRef] = useDimensions()

    const typedSelectedOptions = selectedOptions as SelectOption[] | undefined
    const hasValue =
        (typedSelectedOptions && typedSelectedOptions.length > 0) ||
        !!inputValue

    const hasIcon = !!Icon

    const styles: StylesConfig<
        SelectOption,
        boolean,
        GroupBase<SelectOption>
    > = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderRadius: '0.6rem',
                border: `0.1rem solid ${
                    error ? DESIGN_TOKENS.error : DESIGN_TOKENS.input
                }`,
                transition: 'none',
                '&:hover': {
                    border: `0.1rem solid ${DESIGN_TOKENS.input}`,
                },
                ...(state.isFocused && {
                    outline: 'none',
                    boxShadow: `0 0 0 0.2rem ${BASE_TOKENS.grey[0]},
                    0 0 0 0.4rem ${BASE_TOKENS.grey[400]}`,
                }),
                ...(state.isDisabled && {
                    backgroundColor: BASE_TOKENS.grey[100],
                }),
                ...(isCellStyle && {
                    width: '100%',
                    border: 'none',
                    boxShadow: 'none',
                    borderRadius: 0,

                    '&:hover': {
                        border: 'none',
                    },
                }),
                ...(hasIcon && {
                    paddingLeft: '2rem',
                }),
                ...(propsStyles?.control || {}),
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                paddingLeft: '1.2rem',
                paddingRight: '1.2rem',
                rowGap: '0.2rem',
                ...(state.hasValue &&
                    isMulti && {
                        display: 'flex',
                        columnGap: '0.6rem',
                    }),
            }),
            placeholder: (provided) => ({
                ...provided,
                color: error
                    ? DESIGN_TOKENS.text.textError
                    : DESIGN_TOKENS.text.textMutedForeground,
                fontFamily: 'Inter',
                fontSize: '1.4rem',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '2.4rem',
            }),
            input: (provided) => ({
                ...provided,
                padding: 0,
                margin: 0,
                color: DESIGN_TOKENS.foreground,
                fontFamily: 'Inter',
                fontSize: '1.4rem',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '2.4rem',
                '&:hover': {
                    cursor: 'text',
                },
            }),
            singleValue: (provided, state) => ({
                ...provided,
                color: state.isDisabled
                    ? DESIGN_TOKENS.mutedForeground
                    : DESIGN_TOKENS.foreground,
                fontFamily: 'Inter',
                fontSize: '1.4rem',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '2rem',
            }),
            container: (provided) => ({
                ...provided,
                width: '100%',
                minHeight: '4rem',
                ...(isCellStyle && {
                    display: 'flex',
                    height: '100%',
                }),
            }),
            indicatorSeparator: (provided) => ({
                ...provided,
                backgroundColor: BASE_TOKENS.grey[300],
            }),
            noOptionsMessage: (provided) => ({
                ...provided,
                color: DESIGN_TOKENS.mutedForeground,
                fontFamily: 'Inter',
                fontSize: '1.4rem',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '2.4rem',
            }),
            loadingMessage: (provided) => ({
                ...provided,
                color: DESIGN_TOKENS.mutedForeground,
                fontFamily: 'Inter',
                fontSize: '1.4rem',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '2.4rem',
            }),
            option: (provided) => ({
                ...provided,
                color: DESIGN_TOKENS.accentForeground,
                fontFamily: 'Inter',
                fontSize: '1.4rem',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '2rem',
                cursor: 'pointer',
                backgroundColor: BASE_TOKENS.grey[0],
                borderRadius: '0.6rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                '&:hover': {
                    backgroundColor: DESIGN_TOKENS.accent,
                },
            }),
            menu: (provided) => ({
                ...provided,
                paddingLeft: '0.4rem',
                paddingRight: '0.4rem',
                paddingTop: '0.8rem',
                paddingBottom: '0.8rem',
                borderRadius: '0.6rem',
                ...(propsStyles?.menu || {}),
            }),
            menuList: (provided) => ({
                ...provided,
                padding: 0,
            }),
            menuPortal: (provided) => ({
                ...provided,
                width: dimensions.width,
                maxWidth: dimensions.width,
                ...(shouldPortalMenu && {
                    zIndex: 9999,
                }),
            }),
        }),
        [isMulti, isCellStyle, shouldPortalMenu, error, dimensions.width]
    )

    useEffect(() => {
        if (!shouldFocusOnMount) {
            return
        }

        setTimeout(() => {
            inputRef.current.focus()
        }, 10)
    }, [shouldFocusOnMount])

    return (
        <StyledBaseSelect isCellStyle={isCellStyle} ref={containerRef}>
            {label && <Label htmlFor={selectId.current}>{label}</Label>}
            <StyledSelectContainer>
                {Icon && (
                    <StyledIconContainer>
                        <Icon
                            size={16}
                            color={
                                hasValue
                                    ? DESIGN_TOKENS.foreground
                                    : DESIGN_TOKENS.mutedForeground
                            }
                        />
                    </StyledIconContainer>
                )}
                <Select
                    autoFocus={shouldFocusOnMount}
                    openMenuOnFocus
                    isMulti={isMulti}
                    isDisabled={!!isDisabled}
                    isLoading={isLoading}
                    id={selectId.current}
                    value={selectedOptions}
                    inputValue={inputValue}
                    placeholder={placeholder}
                    options={options}
                    styles={styles}
                    ref={inputRef}
                    menuPlacement={menuPlacement}
                    menuPortalTarget={
                        shouldPortalMenu ? document.body : undefined
                    }
                    noOptionsMessage={() =>
                        inputValue.length < SEARCH_TERM_MIN_LENGTH
                            ? 'Search options'
                            : 'No options to show. Try adjusting your search term.'
                    }
                    components={{
                        MultiValue: MultiValueComponent,
                        LoadingIndicator: () => (
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingLeft: '1.2rem',
                                    paddingRight: '1.4rem',
                                    color: DESIGN_TOKENS.mutedForeground,
                                }}
                            >
                                <CircularProgress size={16} color="inherit" />
                            </span>
                        ),
                        ClearIndicator: (props) => (
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingLeft: '1.2rem',
                                    paddingRight: '1.2rem',
                                    cursor: 'pointer',
                                }}
                                onClick={props.clearValue}
                            >
                                <X
                                    size={16}
                                    color={DESIGN_TOKENS.mutedForeground}
                                />
                            </span>
                        ),
                        DropdownIndicator: () =>
                            shouldHideIndicators ? null : (
                                <span
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        paddingLeft: '1.2rem',
                                        paddingRight: '1.2rem',
                                    }}
                                >
                                    <ChevronDown
                                        size={16}
                                        color={DESIGN_TOKENS.mutedForeground}
                                    />
                                </span>
                            ),
                        IndicatorsContainer: (props) =>
                            shouldHideIndicators ? null : (
                                <components.IndicatorsContainer {...props}>
                                    {props.children}
                                </components.IndicatorsContainer>
                            ),
                    }}
                    onChange={onChange}
                    onInputChange={onInputChange}
                />
            </StyledSelectContainer>
            {description && (
                <Typography color="secondary" variant="caption">
                    {description}
                </Typography>
            )}
            {!!error && (
                <Typography color="error" variant="caption">
                    {error}
                </Typography>
            )}
        </StyledBaseSelect>
    )
}

export default BaseSelect
