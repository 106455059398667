import { UseQueryResult, useQuery } from 'react-query'
import { getCTSDK } from 'services/ctClient'

const sdk = getCTSDK()

type GetAccountingAccountResponse = {
    account: {
        token: {
            isValid: boolean
        }
    }
}

export const useGetAccountingAccount = (): UseQueryResult<
    GetAccountingAccountResponse,
    unknown
> => {
    return useQuery(['useGetAccountingAccount'], async () => {
        const resp = await sdk.get<GetAccountingAccountResponse>(
            `/integrations/accounting/orgs/myOrg`
        )
        return resp.data
    })
}
