import Typography from 'components/core/Typography'
import Popover from 'components/core/Popover'
import Button from 'components/core/Button'
import { CommodityInputs } from 'components/composite/CommodityInputs'
import { OrderCommodity } from 'types/orderCommodity.types'
import { useMemo } from 'react'
import styled from '@emotion/styled'

const StyledCommodityInputsContainer = styled.div`
    max-height: 400px;
    overflow-y: auto;

    .commoditiesTable {
        max-width: 80vw;
    }
`

type OrderDetailsCommoditiesSectionProps = {
    commodities: OrderCommodity[]
    commoditiesMutations?: Partial<OrderCommodity>[]
    onCloseCommoditiesEditPopup: () => void
    onChangeCommodities: (commodities: Partial<OrderCommodity>[]) => void
}

export const OrderDetailsCommoditiesSection = ({
    commodities,
    commoditiesMutations,
    onCloseCommoditiesEditPopup,
    onChangeCommodities,
}: OrderDetailsCommoditiesSectionProps) => {
    const commoditiesSorted = useMemo(() => {
        // sort will mpdify the original array, so we need to clone it
        return [...commodities].sort((a, b) =>
            a.description.localeCompare(b.description)
        )
    }, [commodities])

    return (
        <>
            <div className="details__content__right__body__section">
                <div className="details__content__right__body__section__commodities">
                    <Typography color="secondary" variant="body2">
                        Commodities
                    </Typography>
                    <Popover
                        onClose={onCloseCommoditiesEditPopup}
                        trigger={
                            <div>
                                <Button variant="ghost">
                                    + Change Commodities
                                </Button>
                            </div>
                        }
                    >
                        <StyledCommodityInputsContainer>
                            <CommodityInputs
                                tableClassName="commoditiesTable"
                                commodities={commoditiesMutations}
                                onChange={onChangeCommodities}
                            />
                        </StyledCommodityInputsContainer>
                    </Popover>
                </div>
            </div>

            {!!commoditiesSorted.length && (
                <div className="details__content__right__body__section">
                    <div className="details__content__right__body__row details__content__right__body__row--compressed">
                        <div className="details__content__right__body__row__quantity">
                            <Typography
                                isBold
                                color="secondary"
                                variant="body2"
                            >
                                Quantity
                            </Typography>
                        </div>

                        <div>
                            <Typography
                                isBold
                                color="secondary"
                                variant="body2"
                            >
                                Description
                            </Typography>
                        </div>
                    </div>

                    {commoditiesSorted.map((c) => {
                        return (
                            <div
                                key={c.id}
                                className="details__content__right__body__row details__content__right__body__row--compressed"
                            >
                                <div className="details__content__right__body__row__quantity">
                                    <Typography color="primary" variant="body2">
                                        {c.quantity}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography color="primary" variant="body2">
                                        {c.description}
                                    </Typography>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
        </>
    )
}
