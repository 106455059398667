import styled from '@emotion/styled'
import Separator from 'components/core/Separator'
import Typography from 'components/core/Typography'

const StyledSettingsTab = styled.div`
    display: flex;
    flex-direction: column;
    width: 68rem;

    .settingsTab__title {
        .settingsTab__title__text {
            display: flex;
            flex-direction: column;
            row-gap: 0.4rem;
            margin-bottom: 2.4rem;
        }
    }

    .settingsTab__content {
        display: flex;
        flex-direction: column;
        row-gap: 3.2rem;
        overflow-y: auto;
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;
        padding-right: 3.2rem;
        padding-left: 3.2rem;
        margin-left: -3.2rem;
    }
`

type SettingsTabProps = {
    title: string
    description: string
    children: React.ReactNode
}

const SettingsTab = ({ title, description, children }: SettingsTabProps) => {
    return (
        <StyledSettingsTab>
            <div className="settingsTab__title">
                <div className="settingsTab__title__text">
                    <Typography isBold color="primary" variant="body1">
                        {title}
                    </Typography>
                    <Typography color="secondary" variant="body2">
                        {description}
                    </Typography>
                </div>
                <Separator />
            </div>
            <div className="settingsTab__content">{children}</div>
        </StyledSettingsTab>
    )
}

export default SettingsTab
