import styled from '@emotion/styled'
import { Pen } from 'lucide-react'
import Typography from 'components/core/Typography'
import { DESIGN_TOKENS } from 'constants/color.constants'

const StyledMoneyCell = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

type MoneyCellProps = {
    isEditable: boolean
    value?: number
}

const MoneyCell = ({ isEditable, value }: MoneyCellProps) => {
    return (
        <StyledMoneyCell>
            <Typography shouldTruncate color="primary" variant="body2">
                {value?.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }) || '--'}
            </Typography>
            {isEditable && (
                <Pen size={12} color={DESIGN_TOKENS.text.textMutedForeground} />
            )}
        </StyledMoneyCell>
    )
}

export default MoneyCell
