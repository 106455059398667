import styled from '@emotion/styled'
import { X } from 'lucide-react'
import * as RadixDialog from '@radix-ui/react-dialog'
import IconButton from 'components/core/IconButton'
import Typography from 'components/core/Typography'
import { BASE_TOKENS } from 'constants/color.constants'

const emptyFunc = () => undefined

type DialogSize = 'sm' | 'md' | 'lg' | 'xl'

const StyledDialogOverlay = styled(RadixDialog.Overlay)`
    background-color: #03071280;
    position: fixed;
    inset: 0;
    z-index: 100;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

    @keyframes overlayShow {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`

const StyledDialogContent = styled(RadixDialog.Content)<{ size: DialogSize }>`
    background-color: ${BASE_TOKENS.grey[0]};
    border-radius: 0.8rem;
    box-shadow:
        hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
        hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 101;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    display: flex;
    flex-direction: column;

    /* if you change this, DocumentViewerDialog will break */
    max-height: calc(100% - 9.6rem);
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;

    @keyframes contentShow {
        from {
            opacity: 0;
            transform: translate(-50%, -48%) scale(0.96);
        }
        to {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
    }

    ${(p) => {
        switch (p.size) {
            case 'sm':
                return `
                    width: 46.2rem;
                    min-width: 46.2rem;
                `
            case 'md':
                return `
                    width: 60rem;
                    min-width: 60rem;
                `
            case 'lg':
                // If you change this, DocumentViewerDialog will look weird
                return `
                    width: 90rem;
                    min-width: 84.8rem;
                `
            case 'xl':
                return `
                    width: 132rem;
                    min-width: 132rem;
                `
            default:
                return `
                    width: 46.2rem;
                    min-width: 46.2rem;
                `
        }
    }}
`

const StyledDialogTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    /* if you change this, DocumentViewerDialog will break */
    margin-bottom: 2.4rem;
`

type DialogProps = {
    isOpen: boolean
    size: DialogSize
    title: string
    children: React.ReactNode
    onClose: () => void
}

const Dialog = ({
    isOpen,
    size,
    title,
    children,
    onClose = emptyFunc,
}: DialogProps) => {
    return (
        <RadixDialog.Root open={isOpen}>
            <RadixDialog.Portal>
                <StyledDialogOverlay />
                <StyledDialogContent
                    size={size}
                    onEscapeKeyDown={onClose}
                    onInteractOutside={onClose}
                >
                    <StyledDialogTitle>
                        <RadixDialog.Title asChild>
                            {/* if you change this, DocumentViewerDialog will break */}
                            <Typography color="primary" variant="h2">
                                {title}
                            </Typography>
                        </RadixDialog.Title>
                        <RadixDialog.Close asChild>
                            <IconButton onClick={onClose}>
                                <X size={16} />
                            </IconButton>
                        </RadixDialog.Close>
                    </StyledDialogTitle>
                    {children}
                </StyledDialogContent>
            </RadixDialog.Portal>
        </RadixDialog.Root>
    )
}

export default Dialog
