import { Destination, Order } from 'types/order.types'
import { OrderChangeProp } from '../orderDetails.types'
import { OrderDetailsDestinationSection } from './OrderDetailsDestinationSection'

type OrderDetailsExtraDestinationSectionProps = {
    isEditable: boolean
    extraDestinations?: Destination[]
    extraDestinationsMutation?: Partial<Destination>[]
    destinationLabel: string
    addressLabel: string
    onClosePopup: (propName: OrderChangeProp, index?: number) => void
    onChangeDestinations: (updatedDestinations: Partial<Destination>[]) => void
    onAddDestination: () => void
    onDeleteDestination: (index: number) => void
}

export const OrderDetailsExtraDestinationSection = ({
    isEditable,
    extraDestinations,
    extraDestinationsMutation,
    destinationLabel,
    addressLabel,
    onClosePopup,
    onChangeDestinations,
    onAddDestination,
    onDeleteDestination,
}: OrderDetailsExtraDestinationSectionProps) => {
    const _onClosePopup = (index: number) => (propName: OrderChangeProp) => {
        onClosePopup(propName, index)
    }

    const _onChangeDestination =
        (index: number) => (destination: Partial<Destination>) => {
            const newExtraDestinations = extraDestinations?.map(
                (extraDestination, i) => {
                    if (i === index) {
                        return {
                            ...extraDestination,
                            ...destination,
                        }
                    }

                    return extraDestination
                }
            )

            onChangeDestinations(newExtraDestinations || [])
        }

    if (!extraDestinations) {
        return null
    }

    return (
        <>
            {extraDestinations.map((_, index) => (
                <div
                    key={index}
                    className="details__content__right__body__section"
                >
                    <OrderDetailsDestinationSection
                        hasDeleteButton
                        isEditable={isEditable}
                        hasAddButton={index === extraDestinations.length - 1}
                        destination={extraDestinations?.[index]}
                        destinationMutation={extraDestinationsMutation?.[index]}
                        destinationLabel={destinationLabel}
                        addressLabel={addressLabel}
                        onClosePopup={_onClosePopup(index)}
                        onChangeDestination={_onChangeDestination(index)}
                        onAddDestination={onAddDestination}
                        onDeleteDestination={() => onDeleteDestination(index)}
                    />
                </div>
            ))}
        </>
    )
}
