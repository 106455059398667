import AnchorTag from 'components/core/AnchorTag'
import { useMyOrg } from 'hooks/useMyOrg'
import { AutomationTmsEntityType } from 'types/automation.types'

type ReferenceCellProps = {
    referenceId?: string
    referenceNumber?: string
    automationTmsEntityType?: AutomationTmsEntityType
}

const ReferenceCell = ({
    referenceId,
    referenceNumber,
    automationTmsEntityType,
}: ReferenceCellProps) => {
    const { org } = useMyOrg()

    const href =
        automationTmsEntityType === 'order'
            ? `https://${org.rrSubdomain}.roserocket.com/#/ops/orders/${referenceId}`
            : `https://${org.rrSubdomain}.roserocket.com/#/ops/manifests/${referenceId}`

    return (
        <AnchorTag
            rel="noopener noreferrer"
            // @TODO: Must dynamically construct URL when reference can be more than just manifest
            href={href}
            color="primary"
            target="_blank"
        >
            {referenceNumber}
        </AnchorTag>
    )
}

export default ReferenceCell
