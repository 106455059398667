import { getCTSDK } from 'services/ctClient'
import { showToastError } from 'hooks/useToast'
import AccountingAuthButton from './components/AccountingAuthButton'
import { useGetAccountingAccount } from 'integrations/accounting/queries/accounting.user.queries'

const ctSDK = getCTSDK()

const AccountingAuthButtonContainer = () => {
    const {
        isLoading: isLoadingAccount,
        data,
        refetch,
    } = useGetAccountingAccount()

    const _onConnect = async () => {
        try {
            const resp = await ctSDK.get('/integrations/accounting/authCodeUrl')

            const url = resp.data?.url
            if (!url) {
                showToastError('Error getting accounting login page')
                return
            }

            const popup = window.open(url, '_blank', 'width=650,height=600')
            if (!popup) {
                showToastError('Login popup is blocked by browser')
                return
            }

            const timer = setInterval(() => {
                if (popup.closed) {
                    clearInterval(timer)
                    refetch()
                }
            }, 1000)

            popup.focus()
        } catch (error) {
            showToastError('Error connecting to accounting provider')
        }
    }

    return (
        <AccountingAuthButton
            isLoading={isLoadingAccount}
            isConnected={!!data?.account?.token?.isValid}
            onConnect={_onConnect}
        />
    )
}

export default AccountingAuthButtonContainer
