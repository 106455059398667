import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { TABLE_ROW_LIMIT } from '../board.constants'

type QueryParams<TStatus> = {
    limit: number
    offset: number
    inStatusIds: TStatus[]
    createdAtStartAt?: string
}

export const useBoard = <TRow extends { id: string }, TStatus>({
    defaultTabId,
    statusGroupsMap,
    useRowsFn,
    useRowCountsFn,
}: {
    defaultTabId: string
    statusGroupsMap: { [key: string]: TStatus[] }
    useRowsFn: (args: QueryParams<TStatus>) => {
        isLoading: boolean
        isRefetching: boolean
        total: number
        rows: TRow[]
        error: unknown
        refetch: () => void
    }
    useRowCountsFn: ({
        createdStartAt,
        inStatusIdsMap,
    }: {
        createdStartAt?: string
        inStatusIdsMap: {
            [key: string]: TStatus[]
        }
    }) => {
        statusCounts?: Record<string, number>
        refetchStatusCounts: () => void
    }
}) => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const searchParamsObj = Array.from(searchParams.keys()).reduce(
        (acc, key) => {
            acc[key] = searchParams.get(key) || ''
            return acc
        },
        {} as { [key: string]: string }
    )

    const [offset, setOffset] = useState(0)
    const [selectedTabId, setSelectedTabId] = useState(
        searchParamsObj.tabId || defaultTabId
    )
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([])

    const {
        isLoading,
        isRefetching,
        total,
        rows = [],
        error,
        refetch: refetchRows,
    } = useRowsFn({
        ...searchParamsObj,
        limit: TABLE_ROW_LIMIT,
        offset,
        inStatusIds: statusGroupsMap[selectedTabId],
    })

    const { statusCounts, refetchStatusCounts } = useRowCountsFn({
        ...searchParamsObj,
        inStatusIdsMap: statusGroupsMap,
    })

    const isFirstPage = offset === 0
    const isLastPage = offset + TABLE_ROW_LIMIT >= total

    const _onNextPage = () => {
        if (isLastPage) {
            return
        }

        setOffset((prev) => prev + TABLE_ROW_LIMIT)
    }

    const _onLastPage = () => {
        if (isLastPage) {
            return
        }

        const lastPageOffset =
            Math.floor((total - 1) / TABLE_ROW_LIMIT) * TABLE_ROW_LIMIT

        setOffset(lastPageOffset)
    }

    const _onPreviousPage = () => {
        if (isFirstPage) {
            return
        }

        setOffset((prev) => prev - TABLE_ROW_LIMIT)
    }

    const _onFirstPage = () => {
        if (isFirstPage) {
            return
        }

        setOffset(0)
    }

    const _onSelectAllRows = (isChecked: boolean) => {
        if (isChecked) {
            setSelectedRowIds(rows.map((r) => r.id))
            return
        }

        setSelectedRowIds([])
    }

    const _onSelectRow = ({
        isChecked,
        id,
        index,
    }: {
        isChecked: boolean
        id: string
        index: number
    }) => {
        setSelectedRowIds((prev) => {
            if (isChecked && !prev.includes(id)) {
                return [...prev.slice(0, index), id, ...prev.slice(index)]
            } else if (!isChecked) {
                return prev.filter((selectedId) => selectedId !== id)
            }
            return prev
        })
    }

    const _onClickTab = (tabId: string) => {
        setSelectedTabId(tabId)
        setOffset(0)
    }

    return {
        isLoading,
        isLastPage,
        isFirstPage,
        isRefetching,
        total,
        offset,
        statusCounts,
        selectedTabId,
        selectedRowIds,
        rows,
        error,
        refetchRows,
        refetchStatusCounts,
        setSelectedTabId,
        setSelectedRowIds,
        onClickTab: _onClickTab,
        onNextPage: _onNextPage,
        onLastPage: _onLastPage,
        onSelectRow: _onSelectRow,
        onFirstPage: _onFirstPage,
        onPreviousPage: _onPreviousPage,
        onSelectAllRows: _onSelectAllRows,
    }
}
