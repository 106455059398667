import { useEffect, useRef } from 'react'
import { PayableStatus } from 'types/payable.types'
import { useGetPayablesStatusCounts } from 'queries/payable.queries'

export const usePayablesStatusCounts = (params: {
    createdAtStartAt?: string
    inStatusIdsMap: {
        [key: string]: PayableStatus[]
    }
}) => {
    const ws = useRef<WebSocket | null>(null)

    const { data, refetch } = useGetPayablesStatusCounts(params)

    useEffect(() => {
        ws.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL_2 || '')

        ws.current.onmessage = (event) => {
            const wsEvent = JSON.parse(event.data)

            if (
                [
                    'payableQueued',
                    'payableProcessed',
                    'payableDeleted',
                    'payableSynced',
                    'documentParsed',
                ].includes(wsEvent.type)
            ) {
                refetch()
            }
        }

        return () => {
            ws.current?.close()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { statusCounts: data?.data?.counts, refetchStatusCounts: refetch }
}
