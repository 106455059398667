import { Payable, PayableStatus } from 'types/payable.types'
import { DocumentEntity, DocumentEntityType } from 'types/document.types'
import { DocumentItem } from '../documentViewerDialogExtended.types'
import {
    isBOLEntity,
    isBulkCarrierInvoiceEntity,
    isCarrierInvoiceEntity,
    isMultipleCarrierInvoicesEntity,
    isPODEntity,
} from '../../../utils/document.utils'
import { ProofOfDelivery } from 'types/pod.types'
import { getPayableInvoiceNumber } from '../../../modules/Payables/utils/payable.utils'

export const updateDocumentEntities = ({
    entityId,
    entityType,
    pageNumber,
    entities,
}: {
    entityId: string
    entityType: string
    pageNumber: number
    entities: DocumentEntity[]
}): DocumentEntity[] => {
    const sortedEntities: DocumentEntity[] = [...entities].sort(
        (a, b) => parseInt(a.id, 10) - parseInt(b.id, 10)
    )

    let currentId = 0
    const updatedEntities: DocumentEntity[] = []

    sortedEntities.forEach((e) => {
        if (e.id === entityId && e.pages.length > 1) {
            e.pages.forEach((page, index) => {
                currentId++
                const newType =
                    page.pageNumber === pageNumber
                        ? (entityType as DocumentEntityType)
                        : e.type
                const newEntity: DocumentEntity = {
                    ...e,
                    id: currentId.toString(),
                    type: newType,
                    pages: [page],
                }
                updatedEntities.push(newEntity)
            })
        } else if (e.id === entityId && e.pages.length === 1) {
            currentId++
            updatedEntities.push({
                ...e,
                id: currentId.toString(),
                type: entityType as DocumentEntityType,
            })
        } else {
            currentId++
            updatedEntities.push({ ...e, id: currentId.toString() })
        }
    })

    return updatedEntities
}

export const getDocumentItems = ({
    documentId,
    entities,
    payables,
    pods,
}: {
    documentId?: string
    entities?: DocumentEntity[]
    payables?: Payable[]
    pods?: ProofOfDelivery[]
}): DocumentItem[] => {
    if (!entities || !entities.length) {
        return []
    }

    const items: DocumentItem[] = entities.flatMap((entity) => {
        if (!entity.pages || !entity.pages.length) {
            return []
        }

        const entityItems = entity.pages.map((page) => {
            const item = {
                id: String(page.pageNumber),
                entityId: entity.id,
                documentId,
                pageNumber: page.pageNumber,
                entityType: entity.type,
            }

            if (
                isCarrierInvoiceEntity(entity) ||
                isBulkCarrierInvoiceEntity(entity) ||
                isMultipleCarrierInvoicesEntity(entity)
            ) {
                const filteredPayables =
                    payables?.filter((p) =>
                        p.documentEntityIds?.includes(entity.id)
                    ) || []

                return {
                    ...item,
                    invoiceNumber: filteredPayables
                        .map((p) => getPayableInvoiceNumber(p))
                        .join(', '),
                    payableStatuses: filteredPayables
                        .map((p) => p.status)
                        .filter(Boolean),
                }
            }

            if (isBOLEntity(entity) || isPODEntity(entity)) {
                const filteredPODs =
                    pods?.filter((p) =>
                        p.documentEntityIds?.includes(entity.id)
                    ) || []

                const invoiceNumbers = filteredPODs
                    .map((pod) => {
                        const payable = payables?.find(
                            (payable) => payable.id === pod.payableId
                        )
                        if (!payable) {
                            return payable
                        }

                        return getPayableInvoiceNumber(payable)
                    })
                    .filter(Boolean) as string[]

                return {
                    ...item,
                    invoiceNumber: invoiceNumbers.join(', '),
                    payableStatuses: filteredPODs
                        .map(
                            (pod) =>
                                payables?.find(
                                    (payable) => payable.id === pod.payableId
                                )?.status
                        )
                        .filter(Boolean) as PayableStatus[],
                }
            }

            return item
        })

        return entityItems
    })

    return items
}
