import { useMutation, useQueryClient } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { Order } from 'types/order.types'
import { OrderCommodity } from 'types/orderCommodity.types'

const sdk = getCTSDK()

export const useCreateOrder = () => {
    return useMutation(
        (data: {
            order: Partial<Order>
            commodities: Partial<OrderCommodity>[]
        }) =>
            sdk
                .post<{ order: Order }>('/orders', {
                    data,
                })
                .then((res) => res.data.order)
    )
}

export const useUpdateOrder = () => {
    const queryClient = useQueryClient()
    return useMutation(
        (data: { id: string; order: Partial<Order> }) =>
            sdk
                .put<{ order: Order }>(`/orders/${data.id}`, {
                    order: data.order,
                })
                .then((res) => res.data.order),
        {
            onSuccess: (_, variables) => {
                queryClient.invalidateQueries(['useGetOrderById', variables.id])
            },
        }
    )
}

export const useUpdateCommodities = () => {
    const queryClient = useQueryClient()
    return useMutation(
        (data: { orderId: string; commodities: OrderCommodity[] }) =>
            sdk
                .post<{ commodities: OrderCommodity[] }>(
                    `/orders/${data.orderId}/commodities`,
                    {
                        commodities: data.commodities,
                    }
                )
                .then((res) => res.data.commodities),
        {
            onSuccess: (_, variables) => {
                queryClient.invalidateQueries([
                    'useGetOrderCommoditiesById',
                    variables.orderId,
                ])
                // Invalidate order query for the same Order ID to ensure the order details are up to date.
                queryClient.invalidateQueries([
                    'useGetOrderById',
                    variables.orderId,
                ])
            },
        }
    )
}

export const useGenerateRateConfirmationPdf = () => {
    return useMutation(async (orderId: string) =>
        sdk
            .post<Blob>(
                `/orders/${orderId}/docs/rateCon`,
                {},
                {
                    responseType: 'blob', // Ensure the response is treated as a binary PDF
                }
            )
            .then((res) => res.data)
    )
}
