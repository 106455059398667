import styled from '@emotion/styled'
import TextInput from 'components/core/TextInput'
import AddressSearchInput from 'components/composite/AddressSearchInput'
import { Search } from 'lucide-react'
import { Address } from 'types/address.types'
import { TimeSelect } from 'components/composite/TimeSelect'

const StyledAddressInputs = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    .inputs__double {
        display: flex;
        column-gap: 2rem;
    }
`

type AddressInputsProps = {
    address?: Address
    onChangeAddress: (address: Address) => void
}

export const AddressInputs = ({
    address,
    onChangeAddress,
}: AddressInputsProps) => {
    const {
        name,
        address1,
        address2,
        city,
        state,
        country,
        postalCode,
        placeId,
        phoneNumber,
        phoneNumberExt,
        contactName,
        businessHoursStartAt,
        businessHoursEndAt,
    } = address || {}

    const _onChangeAddress = (key: keyof Address) => (value?: string) => {
        // @ts-ignore
        onChangeAddress({ ...address, [key]: value })
    }

    const _onSearch = (newAddress: Address) => {
        onChangeAddress({ ...address, ...newAddress })
    }

    return (
        <StyledAddressInputs>
            <AddressSearchInput
                label="Search"
                placeholder="Search address"
                value={placeId}
                Icon={Search}
                onChange={_onSearch}
            />
            <TextInput
                label="Name"
                placeholder="Name"
                value={name}
                onChange={_onChangeAddress('name')}
            />
            <TextInput
                label="Address 1"
                placeholder="Address 1"
                value={address1}
                onChange={_onChangeAddress('address1')}
            />
            <TextInput
                label="Address 2"
                placeholder="Address 2"
                value={address2}
                onChange={_onChangeAddress('address2')}
            />
            <div className="inputs__double">
                <TextInput
                    label="City"
                    placeholder="City"
                    value={city}
                    onChange={_onChangeAddress('city')}
                />
                <TextInput
                    label="State"
                    placeholder="State"
                    value={state}
                    onChange={_onChangeAddress('state')}
                />
            </div>
            <div className="inputs__double">
                <TextInput
                    label="Country"
                    placeholder="Country"
                    value={country}
                    onChange={_onChangeAddress('country')}
                />
                <TextInput
                    label="Postal code"
                    placeholder="Postal code"
                    value={postalCode}
                    onChange={_onChangeAddress('postalCode')}
                />
            </div>
            <div className="inputs__double">
                <TimeSelect
                    label="Opening time"
                    value={businessHoursStartAt || ''}
                    onChange={_onChangeAddress('businessHoursStartAt')}
                />
                <TimeSelect
                    label="Closing time"
                    value={businessHoursEndAt || ''}
                    onChange={_onChangeAddress('businessHoursEndAt')}
                />
            </div>
            <TextInput
                label="Contact name"
                placeholder="Contact name"
                value={contactName || ''}
                onChange={_onChangeAddress('contactName')}
            />
            <div className="inputs__double">
                <TextInput
                    label="Phone"
                    value={phoneNumber || ''}
                    placeholder="(123) 456-7890"
                    onChange={_onChangeAddress('phoneNumber')}
                />
                <TextInput
                    label="Ext"
                    placeholder="123"
                    value={phoneNumberExt || ''}
                    onChange={_onChangeAddress('phoneNumberExt')}
                />
            </div>
        </StyledAddressInputs>
    )
}
