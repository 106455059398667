import { Payable, PayableStatus } from 'types/payable.types'

export const getPayableInvoiceNumber = (payable: Payable): string => {
    return payable.adjustedInvoiceNumber || payable.invoiceNumber
}

export const canPayableBeProcessed = (status?: PayableStatus): boolean => {
    return (
        !!status &&
        ['underPriced', 'exactMatch', 'withinRange'].includes(status)
    )
}

export const canPayableBeMarkedAsProcessed = (
    status?: PayableStatus
): boolean => {
    return (
        !!status &&
        [
            'requiresReview',
            'noMatch',
            'carrierMismatch',
            'noPOD',
            'alreadyProcessed',
        ].includes(status)
    )
}

export const canPayableBeRefreshed = (status?: PayableStatus): boolean => {
    return (
        !!status &&
        (canPayableBeProcessed(status) ||
            canPayableBeMarkedAsProcessed(status) ||
            status === 'duplicate')
    )
}

export const canPayableBeDeleted = (status?: PayableStatus): boolean => {
    return (
        !!status &&
        [
            'underPriced',
            'exactMatch',
            'withinRange',
            'requiresReview',
            'noMatch',
            'carrierMismatch',
            'noPOD',
            'duplicate',
            'alreadyProcessed',
            'error',
            'unparsed',
        ].includes(status)
    )
}

export const mapPayableProcessErrorReasonToNote = (
    reason: string | null
): string => {
    switch (reason) {
        case 'qbGetBill':
            return 'QBO auth error'
        case 'qbExportBill':
            return 'Export error'
        case 'manifestNotComplete':
            return 'Delivery incomplete'
        case 'tmsTotalChanged':
            return 'TMS total changed'
        case 'billNotCreated':
            return 'Bill not created'
        case 'hasTax':
            return 'Tax needs review'
        case 'accountingIntegrationInactive':
            return 'Accounting integration inactive'
        default:
            return '--'
    }
}

export const splitSelectedPayableIdsByStatus = (
    selectedPayableIds: string[],
    payables: Payable[]
): {
    payableIdsToProcess: string[]
    payableIdsToMarkAsProcessed: string[]
} => {
    const { payableIdsToProcess, payableIdsToMarkAsProcessed } =
        selectedPayableIds.reduce(
            (
                acc: {
                    payableIdsToProcess: string[]
                    payableIdsToMarkAsProcessed: string[]
                },
                id: string
            ) => {
                const payableStatus = payables.find((p) => p.id === id)?.status

                if (
                    !payableStatus ||
                    ['unparsed', 'processed'].includes(payableStatus)
                ) {
                    return acc
                }

                if (canPayableBeProcessed(payableStatus)) {
                    acc.payableIdsToProcess.push(id)
                } else if (canPayableBeMarkedAsProcessed(payableStatus)) {
                    acc.payableIdsToMarkAsProcessed.push(id)
                }

                return acc
            },
            {
                payableIdsToProcess: [],
                payableIdsToMarkAsProcessed: [],
            }
        )

    return { payableIdsToProcess, payableIdsToMarkAsProcessed }
}
