import { Address } from 'types/address.types'

export const formatAddress = (address?: Address): string => {
    if (!address) {
        return ''
    }

    const { address1, address2, city, state, country, postalCode } = address

    let result = ''

    if (address1) {
        result += address1
    }
    if (address2) {
        result += `, ${address2}`
    }
    if (city) {
        result += `, ${city}`
    }
    const statePostal = [state, postalCode].filter(Boolean).join(' ')
    if (statePostal) {
        result += `, ${statePostal}`
    }
    if (country) {
        result += `, ${country}`
    }

    return result
}
