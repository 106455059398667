import { Destination, Order } from 'types/order.types'
import { DetailsRow } from './DetailsRow'
import { formatAddress } from 'utils/address.utils'
import { DetailsTimeRangeRow } from './DetailsTimeRangeRow'

type OrderDetailsDestinationSectionProps = {
    destinationLabel: string
    addressLabel: string
    destination?: Destination
}

export const OrderDetailsDestinationSection = ({
    destinationLabel,
    addressLabel,
    destination,
}: OrderDetailsDestinationSectionProps) => {
    return (
        <>
            <DetailsRow
                isEditable={false}
                caption={addressLabel}
                valueText={destination?.name}
            />
            <DetailsRow
                isEditable={false}
                caption={destinationLabel + ' address'}
                valueText={formatAddress(destination)}
            />
            <DetailsRow
                isEditable={false}
                caption="Appointment"
                valueText={destination?.isAppointmentRequired ? 'Yes' : 'No'}
            />
            <DetailsTimeRangeRow
                isEditable={false}
                caption={destinationLabel + ' date'}
                value={{
                    from: destination?.arrivalStartAt,
                    to: destination?.arrivalEndAt,
                }}
            />
            <DetailsRow
                isEditable={false}
                caption="Reference #"
                valueText={destination?.referenceNumber || ''}
            />
            <DetailsRow
                isEditable={false}
                caption="Instructions"
                valueText={destination?.specialInstructions || ''}
            />
        </>
    )
}
