import { AxiosResponse } from 'axios'
import { UseQueryOptions, useQuery } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { Automation, AutomationStatus } from 'types/automation.types'
import { AutomationAction } from 'types/automationAction.types'

const ctSDK = getCTSDK()

export const useGetAutomationById = (
    id: string,
    options?: Omit<
        UseQueryOptions<AxiosResponse<{ automation: Automation }>>,
        'queryKey' | 'queryFn'
    >
) => {
    return useQuery(
        ['useGetAutomationById', id],
        async () =>
            await ctSDK.get<{ automation: Automation }>(`/automations/${id}`),
        options
    )
}

export const useGetAutomationActionsById = (
    id: string,
    options?: Omit<
        UseQueryOptions<AxiosResponse<{ actions: AutomationAction[] }>>,
        'queryKey' | 'queryFn'
    >
) => {
    return useQuery(
        ['useGetAutomationActionsById', id],
        async () =>
            await ctSDK.get<{ actions: AutomationAction[] }>(
                `/automations/${id}/actions`
            ),
        options
    )
}

export const USE_GET_AUTOMATION_STATUS_COUNTS_QUERY_KEY =
    'useGetAutomationStatusCounts'

export const useGetAutomationStatusCounts = ({
    createdAtStartAt,
    inStatusIdsMap,
}: {
    createdAtStartAt?: string
    inStatusIdsMap: {
        [key: string]: AutomationStatus[]
    }
}) => {
    return useQuery(
        [
            USE_GET_AUTOMATION_STATUS_COUNTS_QUERY_KEY,
            inStatusIdsMap,
            createdAtStartAt,
        ],
        async () =>
            await ctSDK.get<{ counts: { [key: string]: number } }>(
                '/automations/statusCount',
                {
                    params: {
                        createdAtStartAt,
                        inStatusIdsMap: encodeURIComponent(
                            JSON.stringify(inStatusIdsMap)
                        ),
                    },
                }
            )
    )
}
