import { AxiosResponse } from 'axios'
import { UseQueryOptions, useQuery } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { TMSConnectorOrgSettings } from 'types/connectorOrg.types'

const ctSDK = getCTSDK()

export const useGetRRSettings = (
    options?: Omit<
        UseQueryOptions<AxiosResponse<{ settings: TMSConnectorOrgSettings }>>,
        'queryKey' | 'queryFn'
    >
) => {
    return useQuery(
        ['useGetRRSettings'],
        async () => await ctSDK.get('/integrations/roserocket/settings'),
        options
    )
}
