import { useState } from 'react'
import { useGetMyOrgSettings } from 'queries/orgSettings.queries'
import { useUpdateMyOrgSettings } from 'mutations/orgSettings.mutations'
import { useRRReceivableSettings } from 'integrations/roserocket/components/RRReceivableSettings/hooks/useRRReceivableSettings'
import { showToastError, showToastSuccess } from 'hooks/useToast'
import ReceivableSettings from './components/ReceivableSettings'
import { useGetMyUsers } from 'queries/user.queries'

const ReceivableSettingsContainer = () => {
    const [isAutoProcessEnabled, setIsAutoProcessEnabled] =
        useState<boolean>(false)
    const [userIdAutoProcessAs, setUserIdAutoProcessAs] = useState<string>()

    const { isLoading: isLoadingUsers, data } = useGetMyUsers()
    const { isLoading: isSubmitting, mutateAsync: updateMyOrgSettings } =
        useUpdateMyOrgSettings()
    const { isLoading: isLoadingSettings } = useGetMyOrgSettings({
        onSuccess: (data) => {
            setIsAutoProcessEnabled(
                !!data?.data?.settings?.receivables?.isAutoProcessEnabled
            )
            setUserIdAutoProcessAs(
                data?.data?.settings?.receivables?.userIdAutoProcessAs
            )
        },
    })

    const autoProcessAsOptions =
        data?.data?.users?.map((user) => {
            return {
                value: user.id,
                label: `${user.firstName} ${user.lastName}`,
            }
        }) || []

    const {
        isLoading: isLoadingRRSettings,
        isSubmitting: isSubmittingRRSettings,
        onSaveSettings: onSaveRRSettings,
        onFetchCustomerOptions,
        onFetchInitBOLCustomers,
        onChangeSelectedBOLCustomers,
        onFetchInitAutoProcessingDisabledCustomers,
        onFetchInitManualProcessingEnforcedCustomers,
        onChangeSelectedAutoProcessingDisabledCustomers,
        onChangeSelectedManualProcessingEnforcedCustomers,
    } = useRRReceivableSettings()

    const _onSave = async () => {
        try {
            await updateMyOrgSettings({
                receivables: {
                    isAutoProcessEnabled,
                    userIdAutoProcessAs,
                },
            })
            await onSaveRRSettings()

            showToastSuccess('Settings saved')
        } catch (error) {
            showToastError('Error saving settings')
        }
    }

    return (
        <ReceivableSettings
            isLoading={
                isLoadingUsers || isLoadingSettings || isLoadingRRSettings
            }
            isFormValid={isAutoProcessEnabled ? !!userIdAutoProcessAs : true}
            isSubmitting={isSubmitting || isSubmittingRRSettings}
            isAutoProcessEnabled={isAutoProcessEnabled}
            userIdAutoProcessAs={userIdAutoProcessAs}
            autoProcessAsOptions={autoProcessAsOptions}
            onSaveSettings={_onSave}
            onFetchCustomerOptions={onFetchCustomerOptions}
            onFetchInitBOLCustomers={onFetchInitBOLCustomers}
            onChangeUserIdAutoProcessAs={setUserIdAutoProcessAs}
            onChangeIsAutoProcessEnabled={setIsAutoProcessEnabled}
            onChangeSelectedBOLCustomers={onChangeSelectedBOLCustomers}
            onFetchInitAutoProcessingDisabledCustomers={
                onFetchInitAutoProcessingDisabledCustomers
            }
            onChangeSelectedAutoProcessingDisabledCustomers={
                onChangeSelectedAutoProcessingDisabledCustomers
            }
            onFetchInitManualProcessingEnforcedCustomers={
                onFetchInitManualProcessingEnforcedCustomers
            }
            onChangeSelectedManualProcessingEnforcedCustomers={
                onChangeSelectedManualProcessingEnforcedCustomers
            }
        />
    )
}

export default ReceivableSettingsContainer
