import styled from '@emotion/styled'
import * as RadixToast from '@radix-ui/react-toast'
import { useToast } from 'hooks/useToast'
import { Toast } from './components/Toast'

const StyledToastViewport = styled(RadixToast.Viewport)`
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: 2.4rem;
    row-gap: 1.2rem;
    width: 36rem;
    max-width: 100vw;
    margin: 0;
    list-style: none;
    z-index: 102;
    outline: none;
`

export const ToastProvider = () => {
    const { toasts } = useToast()

    return (
        <RadixToast.ToastProvider>
            {toasts.map((props, index) => {
                return <Toast key={index} {...props} />
            })}
            <StyledToastViewport />
        </RadixToast.ToastProvider>
    )
}
