import { useEffect, useState } from 'react'
import { MultiValue } from 'react-select'
import { LucideIcon } from 'lucide-react'
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'
import BaseSelect from 'components/core/Select/components/BaseSelect'
import { SelectOption } from 'components/core/Select'
import { showToastError } from 'hooks/useToast'
import { Address } from 'types/address.types'
import { getAddressDetails } from './addressSearchInput.utils'

type AddressSearchInputProps = {
    label?: string
    value?: string
    placeholder: string
    styles?: {
        menu?: React.CSSProperties
        control?: React.CSSProperties
    }
    Icon?: LucideIcon
    onChange: (address: Address) => void
}

const AddressSearchInput = ({
    label,
    value: propsValue,
    placeholder,
    styles,
    Icon,
    onChange,
}: AddressSearchInputProps) => {
    const [selectedOption, setSelectedOption] = useState<SelectOption>()

    const {
        value,
        suggestions: { loading, data },
        setValue,
    } = usePlacesAutocomplete({
        callbackName: 'initMap',
        debounce: 300,
    })

    const options = data?.map((suggestion) => ({
        value: suggestion.place_id,
        label: suggestion.description,
    }))

    const _onChange = async (value: MultiValue<SelectOption>) => {
        try {
            // @ts-ignore
            const placeId = value.value as string

            const addressModel = await getAddressDetails(placeId)

            // Call the onChange handler with the populated address model
            onChange(addressModel)

            // Set the selected option in the state
            // @ts-ignore
            setSelectedOption(value)
        } catch {
            showToastError('Error getting address details')
        }
    }

    useEffect(() => {
        if (!propsValue) {
            setSelectedOption(undefined)
            return
        }

        const _onGetDetails = async () => {
            try {
                const details = await getDetails({ placeId: propsValue })

                setSelectedOption({
                    value: propsValue,
                    // @ts-ignore
                    label: details.formatted_address,
                })
            } catch {
                showToastError('Error getting address details')
            }
        }

        _onGetDetails()
    }, [propsValue])

    return (
        <BaseSelect
            isMulti={undefined}
            isLoading={loading}
            label={label}
            inputValue={value}
            placeholder={placeholder}
            options={options}
            selectedOptions={selectedOption ? [selectedOption] : []}
            styles={styles}
            Icon={Icon}
            onChange={_onChange}
            onInputChange={setValue}
        />
    )
}

export default AddressSearchInput
