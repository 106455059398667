import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import Cookies from 'js-cookie'
import Button from 'components/core/Button'
import { Org } from 'types/org.types'
import TextInput from 'components/core/TextInput'
import Typography from 'components/core/Typography'
import { getCTSDK } from 'services/ctClient'
import { showToastError, showToastSuccess } from 'hooks/useToast'
import Dialog, { DialogActions, DialogContent } from 'components/core/Dialog'

const ctSDK = getCTSDK()

const StyledAdminContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4.8rem;
    row-gap: 2.4rem;
`

const AdminContainer = () => {
    const navigate = useNavigate()
    const [orgName, setOrgName] = useState('')
    const [orgShortName, setOrgShortName] = useState('')
    const [orgs, setOrgs] = useState<Org[]>([])
    const [isCreateOrgDialogOpen, setIsCreateOrgDialogOpen] = useState(false)
    const [isRemoveMSAccountDialogOpen, setIsRemoveMSAccountDialogOpen] =
        useState(false)
    const [
        isRenewMSSubscriptionsDialogOpen,
        setIsRenewMSSubscriptionsDialogOpen,
    ] = useState(false)
    const [isGetMSSubscriptionsDialogOpen, setIsGetMSSubscriptionsDialogOpen] =
        useState(false)
    const [userId, setUserId] = useState('')

    const _onOpenCreateOrgDialog = () => {
        setIsCreateOrgDialogOpen(true)
    }

    const _onCloseCreateOrgDialog = () => {
        setIsCreateOrgDialogOpen(false)
    }

    const _onOpenRemoveMSAccountDialog = () => {
        setIsRemoveMSAccountDialogOpen(true)
    }

    const _onCloseRemoveMSAccountDialog = () => {
        setIsRemoveMSAccountDialogOpen(false)
    }

    const _onOpenRenewMSSubscriptionsDialog = () => {
        setIsRenewMSSubscriptionsDialogOpen(true)
    }

    const _onCloseRenewMSSubscriptionsDialog = () => {
        setIsRenewMSSubscriptionsDialogOpen(false)
    }

    const _onOpenGetMSSubscriptionsDialog = () => {
        setIsGetMSSubscriptionsDialogOpen(true)
    }

    const _onCloseGetMSSubscriptionsDialog = () => {
        setIsGetMSSubscriptionsDialogOpen(false)
    }

    const _onRemoveMSAccount = async () => {
        try {
            await ctSDK.post('/admins/removeMSAccount', { userId })

            _onCloseRemoveMSAccountDialog()
            showToastSuccess('Microsoft account removed')
            setUserId('')
        } catch (error) {
            showToastError('Error removing Microsoft account')
        }
    }

    const _onRenewMSSubscriptions = async () => {
        try {
            await ctSDK.post('/admins/renewMSSubscriptions', { userId })

            _onCloseRenewMSSubscriptionsDialog()
            showToastSuccess('Microsoft subscriptions renewed')
            setUserId('')
        } catch (error) {
            showToastError('Error renewing Microsoft subscriptions')
        }
    }

    const _onGetMSSubscriptions = async () => {
        try {
            await ctSDK.post('/admins/getMSSubscriptions', { userId })

            _onCloseGetMSSubscriptionsDialog()
            showToastSuccess('Microsoft subscriptions retrieved')
            setUserId('')
        } catch (error) {
            showToastError('Error retrieving Microsoft subscriptions')
        }
    }

    const _onGetOrgs = async () => {
        try {
            const resp = await ctSDK.get('/orgs')

            setOrgs(resp?.data?.orgs)
        } catch (error) {
            showToastError(error)
        }
    }

    const _onSubmitOrg = async () => {
        try {
            await ctSDK.post('/orgs', {
                name: orgName,
                shortName: orgShortName,
            })

            _onCloseCreateOrgDialog()
            _onGetOrgs()
        } catch (error) {
            showToastError(error)
        }
    }

    const _onLoginToOrg = (orgId: string) => async () => {
        try {
            const resp = await ctSDK.post<{
                token: string
                refreshToken: string
            }>('/admins/orgLogin', { orgId })

            Cookies.set('token', resp.data?.token)
            Cookies.set('refreshToken', resp.data?.refreshToken)

            navigate('/payables')
        } catch (error) {
            showToastError(error)
        }
    }

    useEffect(() => {
        _onGetOrgs()
    }, [])

    return (
        <>
            {isCreateOrgDialogOpen && (
                <Dialog
                    isOpen={isCreateOrgDialogOpen}
                    size="md"
                    title="Create Org"
                    onClose={_onCloseCreateOrgDialog}
                >
                    <DialogContent>
                        <TextInput
                            value={orgName}
                            placeholder="Name"
                            onChange={setOrgName}
                        />
                        <TextInput
                            value={orgShortName}
                            placeholder="Short Name"
                            onChange={setOrgShortName}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={_onCloseCreateOrgDialog}
                            variant="secondary"
                        >
                            Cancel
                        </Button>
                        <Button onClick={_onSubmitOrg} variant="primary">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {isRemoveMSAccountDialogOpen && (
                <Dialog
                    isOpen={isRemoveMSAccountDialogOpen}
                    title="Remove Microsoft Account"
                    size="md"
                    onClose={_onCloseRemoveMSAccountDialog}
                >
                    <DialogContent>
                        <Typography color="primary" variant="body1">
                            Are you sure you want to remove this Microsoft
                            account?
                        </Typography>
                        <TextInput
                            value={userId}
                            placeholder="User ID"
                            onChange={setUserId}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="secondary"
                            onClick={_onCloseRemoveMSAccountDialog}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={_onRemoveMSAccount}>
                            Remove
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {isRenewMSSubscriptionsDialogOpen && (
                <Dialog
                    isOpen={isRenewMSSubscriptionsDialogOpen}
                    title="Renew Microsoft Subscriptions"
                    size="md"
                    onClose={_onCloseRenewMSSubscriptionsDialog}
                >
                    <DialogContent>
                        <TextInput
                            value={userId}
                            placeholder="User ID"
                            onChange={setUserId}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="secondary"
                            onClick={_onCloseRenewMSSubscriptionsDialog}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={_onRenewMSSubscriptions}
                        >
                            Remove
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {isGetMSSubscriptionsDialogOpen && (
                <Dialog
                    isOpen={isGetMSSubscriptionsDialogOpen}
                    title="Get Microsoft Subscriptions"
                    size="md"
                    onClose={_onCloseGetMSSubscriptionsDialog}
                >
                    <DialogContent>
                        <TextInput
                            value={userId}
                            placeholder="User ID"
                            onChange={setUserId}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="secondary"
                            onClick={_onCloseGetMSSubscriptionsDialog}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={_onGetMSSubscriptions}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <StyledAdminContainer>
                <Button variant="primary" onClick={_onOpenCreateOrgDialog}>
                    Create Org
                </Button>
                <Button
                    variant="secondary"
                    onClick={_onOpenRemoveMSAccountDialog}
                >
                    Remove Microsoft Account
                </Button>
                <Button
                    variant="secondary"
                    onClick={_onOpenRenewMSSubscriptionsDialog}
                >
                    Renew Microsoft Subscriptions
                </Button>
                <Button
                    variant="secondary"
                    onClick={_onOpenGetMSSubscriptionsDialog}
                >
                    Get Microsoft Subscriptions
                </Button>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>ID</th>
                            <th>RR Client ID Added</th>
                            <th>RR Client Secret Added</th>
                            <th>Login</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orgs.length ? (
                            <>
                                {orgs.map((org, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{org?.name}</td>
                                            <td>{org?.id}</td>
                                            <td>
                                                {org?.rrClientId ? 'Yes' : ''}
                                            </td>
                                            <td>
                                                {org?.rrClientSecret
                                                    ? 'Yes'
                                                    : ''}
                                            </td>
                                            <td>
                                                <Button
                                                    variant="secondary"
                                                    onClick={_onLoginToOrg(
                                                        org.id
                                                    )}
                                                >
                                                    Login
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </>
                        ) : (
                            <tr>
                                <td>
                                    <span style={{ color: 'white' }}>
                                        No orgs
                                    </span>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </StyledAdminContainer>
        </>
    )
}

export default AdminContainer
