import { useState } from 'react'
import { File } from 'lucide-react'
import IconButton from 'components/core/IconButton'
import Typography from 'components/core/Typography'
import PODViewerDialog from 'dialogs/PODViewerDialog'
import { DESIGN_TOKENS } from 'constants/color.constants'

type PODCellProps = { podIds?: string[] }

const PODCell = ({ podIds }: PODCellProps) => {
    const [podId, setPodId] = useState<string | undefined>()
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const _onClick = (id: string) => () => {
        setPodId(id)
        setIsDialogOpen(true)
    }

    const _onCloseDialog = () => {
        setPodId(undefined)
        setIsDialogOpen(false)
    }

    return podIds?.length ? (
        <>
            <PODViewerDialog
                isOpen={isDialogOpen}
                podId={podId}
                onClose={_onCloseDialog}
            />
            {podIds.map((id) => {
                return (
                    <IconButton onClick={_onClick(id)}>
                        <File
                            size="16"
                            color={DESIGN_TOKENS.secondaryForeground}
                        />
                    </IconButton>
                )
            })}
        </>
    ) : (
        <Typography color="primary" variant="body2">
            --
        </Typography>
    )
}

export default PODCell
