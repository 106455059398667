import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useLoginOld } from 'mutations/user.mutations'
import { showToastError } from 'hooks/useToast'
import Login from './components/Login'
import { getCTSDK } from 'services/ctClient'
import { User } from 'types/user.types'
import { isCustomerUser, isSuperAdmin } from 'utils/user.utils'

const ctSDK = getCTSDK()

const LoginContainer = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const navigate = useNavigate()
    const { isLoading: isSubmitting, mutateAsync: login } = useLoginOld()

    const _onGoToRegister = () => {
        navigate('/register')
    }

    const _onLogin = async () => {
        try {
            const resp = await login({
                email,
                password,
            })

            Cookies.set('token', resp.data?.token)
            Cookies.set('refreshToken', resp.data?.refreshToken)

            const meResp = await ctSDK.get<{ user: User }>('/users/me')
            const me = meResp.data?.user

            if (isSuperAdmin(me)) {
                navigate('/admin')
                return
            } else if (isCustomerUser(me)) {
                navigate('/sp/shipments')
                return
            }

            navigate('/')
        } catch (error) {
            showToastError(error)
            console.error(error)
        }
    }

    return (
        <Login
            isSubmitting={isSubmitting}
            email={email}
            password={password}
            onLogin={_onLogin}
            onChangeEmail={setEmail}
            onGoToRegister={_onGoToRegister}
            onChangePassword={setPassword}
        />
    )
}

export default LoginContainer
