import { DetailsRow } from './DetailsRow'
import { Order } from 'types/order.types'
import { CURRENCY_LABELS } from 'constants/currency.constants'
import { stringToCurrency, stringToPercent } from 'utils/finances.utils'

type OrderDetailsCarrierSectionProps = {
    order?: Order
}

export const OrderDetailsCarrierSection = ({
    order,
}: OrderDetailsCarrierSectionProps) => {
    const {
        carrierId,
        carrierName,
        carrierCost,
        revenue,
        carrierCurrency,
        pricingType,
        markupPercent,
    } = order || {}

    return (
        <>
            <DetailsRow
                isEditable={false}
                caption="Carrier"
                valueText={carrierName}
            />
            {!!carrierId && (
                <>
                    <DetailsRow
                        isEditable={false}
                        caption="Currency"
                        valueText={
                            carrierCurrency
                                ? CURRENCY_LABELS[carrierCurrency]
                                : ''
                        }
                    />
                    {pricingType === 'markup' && (
                        <DetailsRow
                            isEditable={false}
                            caption="Carrier cost"
                            valueText={stringToCurrency(
                                carrierCost,
                                carrierCurrency
                            )}
                        />
                    )}
                    {pricingType === 'markup' && (
                        <DetailsRow
                            isEditable={false}
                            caption="Handling fee"
                            valueText={
                                stringToCurrency(
                                    (carrierCost ?? 0) * (markupPercent ?? 0),
                                    carrierCurrency
                                ) + ` (${stringToPercent(markupPercent)})`
                            }
                        />
                    )}
                    <DetailsRow
                        isEditable={false}
                        caption="Total cost"
                        valueText={stringToCurrency(revenue, carrierCurrency)}
                    />
                </>
            )}
        </>
    )
}
