import styled from '@emotion/styled'
import { DESIGN_TOKENS } from 'constants/color.constants'

export const StyledLabel = styled.label`
    color: ${DESIGN_TOKENS.text.textForeground};
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
`
