import { useState } from 'react'
import { showToastError } from 'hooks/useToast'
import UploadFilesDialog from './components/UploadFilesDialog'

type UploadFilesDialogContainerProps = {
    isOpen: boolean
    onClose: () => void
    onUpload: (files: File[]) => Promise<void>
}

const UploadFilesDialogContainer = ({
    isOpen,
    onClose,
    onUpload,
}: UploadFilesDialogContainerProps) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [files, setFiles] = useState<File[]>([])

    const _onClose = () => {
        setFiles([])
        onClose()
    }

    const _onHandleFileDrop = (droppedFiles: File[]) => {
        const nonPdfFiles = droppedFiles.filter(
            (file) => file.type !== 'application/pdf'
        )

        if (nonPdfFiles.length > 0) {
            showToastError(
                `Only PDF files are allowed. These files could not be added: ${nonPdfFiles
                    .map((f) => f.name)
                    .join(', ')}`
            )
            return
        }

        setFiles((prevFiles) => [...prevFiles, ...droppedFiles])
    }

    const _onUploadFiles = async () => {
        setIsSubmitting(true)

        try {
            await onUpload(files)
            setFiles([])
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <UploadFilesDialog
            isOpen={isOpen}
            isSubmitting={isSubmitting}
            numFiles={files.length}
            onClose={_onClose}
            onDropFiles={_onHandleFileDrop}
            onUploadFiles={_onUploadFiles}
        />
    )
}

export default UploadFilesDialogContainer
