import { pxToRem } from 'utils/rem.utils'

export const SPACING = {
    p0: pxToRem(0),
    sm: pxToRem(4),
    p1: pxToRem(8),
    p2: pxToRem(16),
    p3: pxToRem(24),
    p4: pxToRem(32),
    p5: pxToRem(40),
}
