import styled from '@emotion/styled'
import { Address } from 'types/address.types'
import Typography from 'components/core/Typography'
import Card from 'components/composite/Card'
import { SPACING } from 'constants/spacing.constants'

const StyledAddress = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SPACING.p1};
`

type AddressInfoProps = {
    address?: Address
    title: string
}

export const AddressInfo = ({ address, title }: AddressInfoProps) => {
    return (
        <Card title={title}>
            <StyledAddress>
                <Typography color="primary" variant="body1">
                    {address?.name || ''}
                </Typography>
                <div>
                    <Typography color="secondary" variant="body1">
                        {address?.address1 || ''}
                    </Typography>
                    <Typography color="secondary" variant="body1">
                        {address?.city || ''}, {address?.state || ''}
                    </Typography>
                    <Typography color="secondary" variant="body1">
                        {address?.postalCode || ''}
                    </Typography>
                </div>
            </StyledAddress>
        </Card>
    )
}
