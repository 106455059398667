import styled from '@emotion/styled'
import { SelectAsync, SelectOption } from 'components/core/Select'

const StyledRRReceivableSettings = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem;
`

export type RRReceivableSettingsProps = {
    isLoading: boolean
    isSubmitting: boolean
    onFetchCustomerOptions: (searchTerm: string) => Promise<SelectOption[]>
    onFetchInitBOLCustomers: () => Promise<SelectOption[]>
    onChangeSelectedBOLCustomers: (customers: SelectOption[]) => void
    onFetchInitManualProcessingEnforcedCustomers: () => Promise<SelectOption[]>
    onChangeSelectedManualProcessingEnforcedCustomers: (
        customers: SelectOption[]
    ) => void
}

const RRReceivableSettings = ({
    isLoading,
    isSubmitting,
    onFetchCustomerOptions,
    onFetchInitBOLCustomers,
    onChangeSelectedBOLCustomers,
    onFetchInitManualProcessingEnforcedCustomers,
    onChangeSelectedManualProcessingEnforcedCustomers,
}: RRReceivableSettingsProps) => {
    return (
        <StyledRRReceivableSettings>
            <SelectAsync
                isMulti
                isDisabled={isLoading || isSubmitting}
                label="Enforce manual invoicing based on customer"
                placeholder="Search customers"
                description="Select customers that you would prefer to always manually invoice. PODs will still be automatically uploaded to their orders."
                onFetchOptions={onFetchCustomerOptions}
                onFetchInitValues={onFetchInitManualProcessingEnforcedCustomers}
                onSelectedOptionsChanged={
                    onChangeSelectedManualProcessingEnforcedCustomers
                }
            />
            <SelectAsync
                isMulti
                isDisabled={isLoading || isSubmitting}
                label="Only use bill of ladings for proof of delivery"
                placeholder="Search customers"
                description="For customers selected here, we will only treat bill of ladings as delivery receipts, and will ignore other documents"
                onFetchOptions={onFetchCustomerOptions}
                onFetchInitValues={onFetchInitBOLCustomers}
                onSelectedOptionsChanged={onChangeSelectedBOLCustomers}
            />
        </StyledRRReceivableSettings>
    )
}

export default RRReceivableSettings
