import { useQuery } from 'react-query'
import { User } from 'types/user.types'
import { getCTSDK } from 'services/ctClient'

const ctSDK = getCTSDK()

export const useMe = (): { isLoading: boolean; me: User } => {
    const { isLoading, data } = useQuery(
        ['useMe'],
        async () => await ctSDK.get<{ user: User }>('/users/me')
    )

    return {
        isLoading,
        me: data?.data?.user || ({} as User),
    }
}
