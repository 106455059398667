import { MouseEvent } from 'react'
import styled from '@emotion/styled'
import * as RadixToast from '@radix-ui/react-toast'
import { CircularProgress } from '@mui/material'
import { X, Check, CircleX } from 'lucide-react'
import Typography from 'components/core/Typography'
import { BASE_TOKENS } from 'constants/color.constants'
import { ToasterToast } from 'hooks/useToast'

const StyledToastRoot = styled(RadixToast.Root)<{ isError: boolean }>`
    min-height: 6.8rem;
    position: relative;

    background-color: ${(p) =>
        p.isError ? BASE_TOKENS.red[600] : BASE_TOKENS.grey[0]};
    border: 0.1rem solid ${BASE_TOKENS.grey[200]};
    border-radius: 0.6rem;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -4px rgba(0, 0, 0, 0.1);

    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    column-gap: 1.2rem;

    .toast__icon {
        display: flex;
        align-items: center;
        color: ${BASE_TOKENS.blue[800]};
    }

    .toast__action {
        position: absolute;
        top: 0;
        right: 0;
        padding-top: 0.6rem;
        padding-right: 0.6rem;
        background-color: transparent;

        .toast__action__button {
            margin: 0;
            padding: 0.4rem;

            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.4rem;
            background-color: transparent;
            border: none;
            cursor: pointer;

            opacity: 0;

            color: ${(p) =>
                p.isError ? BASE_TOKENS.grey[300] : BASE_TOKENS.grey[500]};
            transition: all 0.3s ease;

            &:hover {
                color: ${(p) =>
                    p.isError ? BASE_TOKENS.grey[0] : BASE_TOKENS.grey[900]};
            }

            .toast__action__button__icon {
                color: inherit;
            }
        }
    }

    &:hover .toast__action__button {
        opacity: 1;
    }

    &[data-state='open'] {
        animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
    }

    &[data-state='closed'] {
        animation: slideOut 100ms ease-out;
    }

    @keyframes slideIn {
        from {
            transform: translateY(-100%);
        }
        to {
            transform: translateY(0);
        }
    }

    @keyframes slideOut {
        from {
            transform: translateX(var(--radix-toast-swipe-end-x));
        }
        to {
            transform: translateX(calc(100% + 2.4rem));
        }
    }
`

export const Toast = ({
    open,
    variant,
    description,
    onClose,
    onOpenChange,
    ...props
}: ToasterToast) => {
    const _onClose = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        onClose()
    }

    return (
        <StyledToastRoot
            {...props}
            isError={variant === 'error'}
            className="ToastRoot"
            open={open}
            onOpenChange={onOpenChange}
        >
            <div className="toast__icon">
                {variant === 'info' && (
                    <CircularProgress size={24} color="inherit" />
                )}
                {variant === 'success' && (
                    <Check size={24} color={BASE_TOKENS.green[600]} />
                )}
                {variant === 'error' && (
                    <CircleX size={24} color={BASE_TOKENS.grey[0]} />
                )}
            </div>
            <RadixToast.Description asChild>
                <Typography
                    color={variant === 'error' ? 'destructive' : 'primary'}
                    variant="body1"
                >
                    {description}
                </Typography>
            </RadixToast.Description>
            <div className="toast__action">
                <RadixToast.Close asChild>
                    <button
                        className="toast__action__button"
                        onClick={_onClose}
                    >
                        <X size={16} className="toast__action__button__icon" />
                    </button>
                </RadixToast.Close>
            </div>
        </StyledToastRoot>
    )
}
