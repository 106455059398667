import styled from '@emotion/styled'
import { format, parseISO } from 'date-fns'
import { User } from 'types/user.types'
import AnchorTag from 'components/core/AnchorTag'
import CommonChip from 'components/core/CommonChip'
import Typography from 'components/core/Typography'
import { Automation } from 'types/automation.types'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import { AutomationAction } from 'types/automationAction.types'
import { getAvailableActions } from 'modules/Automations/utils/automation.utils'
import Dialog, { DialogActions, DialogContent } from 'components/core/Dialog'
import {
    AUTOMATION_TYPE_LABELS,
    AUTOMATION_STATUS_LABELS,
    AUTOMATION_STATUS_TEXT_COLORS,
    AUTOMATION_STATUS_BORDER_COLORS,
    AUTOMATION_STATUS_BACKGROUND_COLORS,
} from 'modules/Automations/automations.constants'
import CheckEmailAction from './CheckEmailAction'
import Button from 'components/core/Button'
import { CircularProgress } from '@mui/material'
import { AutomationEvent } from 'types/automationEvent.types'
import EmailReceivedEvent from './EmailReceivedEvent'
import {
    isEmailReceivedAutomationEvent,
    isEmailSentAutomationEvent,
    isStatusChangedAutomationEvent,
} from 'utils/automationEvent.utils'
import EmailSentEvent from './EmailSentEvent'
import StatusChangedEvent from './StatusChangedEvent'

const emptyFunc = () => undefined

const StyledLoadingScreen = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`

const StyledDialogContent = styled.div`
    width: 100%;
    height: 600px;
    display: grid;
    column-gap: 1.6rem;

    .content__left {
        height: 100%;
        min-height: 38rem;
        grid-column: 1 / 2;
        border: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};
        border-radius: 0.6rem;
        padding-left: 1.6rem;
        padding-right: 1.6rem;
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        overflow: auto;
    }

    .content__right {
        height: 100%;
        min-height: 38rem;
        grid-column: 2 / 3;
        border: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};
        border-radius: 0.6rem;

        .content__right__header {
            height: 4.8rem;
            padding-left: 1.6rem;
            padding-right: 1.6rem;
            display: flex;
            align-items: center;
            border-bottom: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};
        }

        .content__right__body {
            height: 480px;
            display: flex;
            flex-direction: column;
            row-gap: 0.8rem;
            padding-left: 1.6rem;
            padding-right: 1.6rem;
            padding-top: 1.6rem;
            padding-bottom: 1.6rem;
            overflow: auto;

            .content__right__body__row {
                min-height: 3.2rem;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .content__right__body__row__column {
                    display: flex;
                    flex-direction: column;
                    row-gap: 0.8rem;
                }
            }
        }
    }
`

type AutomationDetailsDialogProps = {
    isOpen: boolean
    isLoading: boolean
    isSubmitting: boolean
    orgSubdomain?: string
    me: User
    automation?: Automation
    action?: AutomationAction
    events: AutomationEvent[]
    onClose: () => void
    onScheduleAction: () => void
    onUnscheduleAction: () => void
}

const AutomationDetailsDialog = ({
    isOpen,
    isLoading,
    isSubmitting,
    orgSubdomain,
    me,
    automation,
    action,
    events,
    onClose = emptyFunc,
    onScheduleAction = emptyFunc,
    onUnscheduleAction = emptyFunc,
}: AutomationDetailsDialogProps) => {
    const {
        id,
        fullId,
        status,
        type,
        createdAt,
        tmsEntityNumbers,
        tmsCarrierName,
        tmsEntityType,
    } = automation || {}

    const availableActions = getAvailableActions({
        automationStatus: status,
        currentActionType: action?.type,
        currentActionStatus: action?.status,
    })

    const options = [
        { id: 'schedule', label: 'Schedule', onClick: onScheduleAction },
        {
            id: 'unschedule',
            label: 'Unschedule',
            onClick: onUnscheduleAction,
        },
    ]

    const filteredActions = options.filter((o) =>
        availableActions.includes(o.id)
    )

    const sortedEvents = events
        .slice()
        .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
    const splicedEvents = [...sortedEvents]
    const firstEmailSentEventIndex = splicedEvents.findIndex(
        isEmailSentAutomationEvent
    )

    if (firstEmailSentEventIndex !== -1) {
        splicedEvents.splice(firstEmailSentEventIndex, 1)
    }

    return (
        <Dialog
            isOpen={isOpen}
            size="xl"
            title="Task details"
            onClose={onClose}
        >
            <DialogContent>
                <StyledDialogContent>
                    <span className="content__left">
                        {isLoading ? (
                            <StyledLoadingScreen>
                                <CircularProgress
                                    style={{ color: BASE_TOKENS.grey[700] }}
                                />
                            </StyledLoadingScreen>
                        ) : (
                            <>
                                <CheckEmailAction
                                    automationId={id}
                                    meEmail={me.email}
                                    orgSubdomain={orgSubdomain}
                                    automationCreatedAt={createdAt}
                                    automationTmsEntityNumbers={
                                        tmsEntityNumbers
                                    }
                                    action={action}
                                />
                                {splicedEvents.map((e) => {
                                    if (isEmailSentAutomationEvent(e)) {
                                        return <EmailSentEvent event={e} />
                                    }
                                    if (isEmailReceivedAutomationEvent(e)) {
                                        return <EmailReceivedEvent event={e} />
                                    }
                                    if (isStatusChangedAutomationEvent(e)) {
                                        return <StatusChangedEvent event={e} />
                                    }

                                    return null
                                })}
                            </>
                        )}
                    </span>
                    <span className="content__right">
                        {isLoading ? (
                            <StyledLoadingScreen>
                                <CircularProgress
                                    style={{ color: BASE_TOKENS.grey[700] }}
                                />
                            </StyledLoadingScreen>
                        ) : (
                            <>
                                <div className="content__right__header">
                                    <Typography
                                        isBold
                                        color="primary"
                                        variant="body1"
                                    >
                                        Details
                                    </Typography>
                                </div>
                                <div className="content__right__body">
                                    <div className="content__right__body__row">
                                        <Typography
                                            color="secondary"
                                            variant="body2"
                                        >
                                            ID
                                        </Typography>
                                        <Typography
                                            color="primary"
                                            variant="body2"
                                        >
                                            {fullId}
                                        </Typography>
                                    </div>
                                    <div className="content__right__body__row">
                                        <Typography
                                            color="secondary"
                                            variant="body2"
                                        >
                                            Status
                                        </Typography>
                                        {status && (
                                            <CommonChip
                                                label={
                                                    AUTOMATION_STATUS_LABELS[
                                                        status
                                                    ]
                                                }
                                                textColor={
                                                    AUTOMATION_STATUS_TEXT_COLORS[
                                                        status
                                                    ]
                                                }
                                                borderColor={
                                                    AUTOMATION_STATUS_BORDER_COLORS[
                                                        status
                                                    ]
                                                }
                                                backgroundColor={
                                                    AUTOMATION_STATUS_BACKGROUND_COLORS[
                                                        status
                                                    ]
                                                }
                                            />
                                        )}
                                    </div>
                                    <div className="content__right__body__row">
                                        <Typography
                                            color="secondary"
                                            variant="body2"
                                        >
                                            Date
                                        </Typography>
                                        <Typography
                                            color="primary"
                                            variant="body2"
                                        >
                                            {createdAt &&
                                                format(
                                                    parseISO(createdAt),
                                                    'MMM d, yyyy'
                                                )}
                                        </Typography>
                                    </div>
                                    <div className="content__right__body__row">
                                        <Typography
                                            color="secondary"
                                            variant="body2"
                                        >
                                            Type
                                        </Typography>
                                        <Typography
                                            color="primary"
                                            variant="body2"
                                        >
                                            {type &&
                                                AUTOMATION_TYPE_LABELS[type]}
                                        </Typography>
                                    </div>
                                    <div className="content__right__body__row">
                                        <Typography
                                            color="secondary"
                                            variant="body2"
                                        >
                                            Partner
                                        </Typography>
                                        <Typography
                                            color="primary"
                                            variant="body2"
                                        >
                                            {tmsCarrierName}
                                        </Typography>
                                    </div>
                                    <div className="content__right__body__row">
                                        <Typography
                                            color="secondary"
                                            variant="body2"
                                        >
                                            Reference
                                        </Typography>
                                        <div className="content__right__body__row__column">
                                            {tmsEntityNumbers?.map((e) => {
                                                const href =
                                                    tmsEntityType === 'order'
                                                        ? `https://${orgSubdomain}.roserocket.com/#/ops/orders/${e.id}`
                                                        : `https://${orgSubdomain}.roserocket.com/#/ops/manifests/${e.id}`

                                                return (
                                                    <AnchorTag
                                                        rel="noopener noreferrer"
                                                        // @TODO: Must dynamically construct URL when reference can be more than just manifest
                                                        href={href}
                                                        color="primary"
                                                        target="_blank"
                                                    >
                                                        {e.fullId}
                                                    </AnchorTag>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </span>
                </StyledDialogContent>
            </DialogContent>
            <DialogActions>
                <Button
                    isDisabled={isSubmitting}
                    variant="secondary"
                    onClick={onClose}
                >
                    Close
                </Button>
                {filteredActions.map((o) => {
                    return (
                        <Button
                            isSubmitting={isSubmitting}
                            key={o.id}
                            variant="primary"
                            onClick={o.onClick}
                        >
                            {o.label}
                        </Button>
                    )
                })}
            </DialogActions>
        </Dialog>
    )
}

export default AutomationDetailsDialog
