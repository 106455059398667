import styled from '@emotion/styled'
import { Pen } from 'lucide-react'
import Typography from 'components/core/Typography'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import { DocumentItem } from '../documentViewerDialogExtended.types'
import Select from 'components/core/Select'
import { DOCUMENT_ENTITY_TYPE_LABELS } from 'constants/document.constants'
import BoardCell from 'components/composite/Board/components/BoardCell'
import {
    canPayableBeMarkedAsProcessed,
    canPayableBeProcessed,
} from 'modules/Payables/utils/payable.utils'

const emptyFunc = () => undefined

const ENTITY_TYPE_OPTIONS = Object.entries(DOCUMENT_ENTITY_TYPE_LABELS)
    .map(([value, label]) => ({
        value,
        label,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

const StyledRow = styled.div`
    display: flex;
`

const StyledHeaderRow = styled(StyledRow)`
    height: 4.8rem;
    position: sticky;
    top: 0;
    border-bottom: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
    background-color: ${BASE_TOKENS.grey[0]};
`

const StyledBodyRow = styled(StyledRow)`
    height: 6.4rem;
    border-bottom: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};
    background-color: ${BASE_TOKENS.grey[0]};
`

const StyledCell = styled.div<{
    isEditable?: boolean
    width: number
}>`
    width: ${(p) => p.width / 10}rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const StyledEntityTypeCell = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const StyledTable = styled.div<{ height: number }>`
    height: ${(p) => p.height}px;
    overflow-y: auto;
    border: 1px solid ${DESIGN_TOKENS.border.borderInput};
    border-radius: 0.6rem;
    background-color: ${BASE_TOKENS.grey[50]};
`

type DocumentDataTableProps = {
    height: number
    items: DocumentItem[]
    onChangeEntityType: ({
        entityId,
        entityType,
        pageNumber,
    }: {
        entityId: string
        entityType: string
        pageNumber: number
    }) => void
}

const DocumentDataTable = ({
    height,
    items,
    onChangeEntityType = emptyFunc,
}: DocumentDataTableProps) => {
    const _onChangeEntityType =
        ({ entityId, pageNumber }: { entityId: string; pageNumber: number }) =>
        (value: string) => {
            onChangeEntityType({
                entityId,
                entityType: value,
                pageNumber,
            })
        }

    return (
        <StyledTable height={height}>
            <StyledHeaderRow>
                <StyledCell key={`pageNumber-header`} width={80}>
                    <Typography
                        isBold
                        shouldTruncate
                        color="secondary"
                        variant="body2"
                    >
                        Page
                    </Typography>
                </StyledCell>
                <StyledCell key={`entityType-header`} width={180}>
                    <Typography
                        isBold
                        shouldTruncate
                        color="secondary"
                        variant="body2"
                    >
                        Type
                    </Typography>
                </StyledCell>
                <StyledCell key={`invoiceNumber-header`} width={180}>
                    <Typography
                        isBold
                        shouldTruncate
                        color="secondary"
                        variant="body2"
                    >
                        Number
                    </Typography>
                </StyledCell>
            </StyledHeaderRow>
            {items.map((item) => {
                const isRowEditable =
                    !item.payableStatuses?.length ||
                    item.payableStatuses.every(
                        (s) =>
                            canPayableBeProcessed(s) ||
                            canPayableBeMarkedAsProcessed(s)
                    )

                return (
                    <StyledBodyRow key={item.id}>
                        <StyledCell key={`pageNumber-${item.id}`} width={80}>
                            <Typography
                                shouldTruncate
                                color="primary"
                                variant="body2"
                            >
                                {item.pageNumber + 1}
                            </Typography>
                        </StyledCell>
                        <BoardCell
                            isEditable={isRowEditable}
                            key={`entityType-${item.id}`}
                            width={180}
                            CellReadOnly={
                                <StyledEntityTypeCell>
                                    <Typography
                                        shouldTruncate
                                        color="primary"
                                        variant="body2"
                                    >
                                        {
                                            DOCUMENT_ENTITY_TYPE_LABELS[
                                                item.entityType
                                            ]
                                        }
                                    </Typography>
                                    {isRowEditable && (
                                        <Pen
                                            size={12}
                                            color={
                                                DESIGN_TOKENS.text
                                                    .textMutedForeground
                                            }
                                        />
                                    )}
                                </StyledEntityTypeCell>
                            }
                            CellEditable={
                                <Select
                                    isCellStyle
                                    shouldPortalMenu
                                    shouldFocusOnMount
                                    value={item.entityType}
                                    placeholder="Search"
                                    options={ENTITY_TYPE_OPTIONS}
                                    onSelectedOptionChanged={_onChangeEntityType(
                                        {
                                            entityId: item.entityId,
                                            pageNumber: item.pageNumber,
                                        }
                                    )}
                                />
                            }
                        />
                        <StyledCell
                            key={`invoiceNumber-${item.id}`}
                            width={180}
                        >
                            <Typography
                                shouldTruncate
                                color="primary"
                                variant="body2"
                            >
                                {item.invoiceNumber || '--'}
                            </Typography>
                        </StyledCell>
                    </StyledBodyRow>
                )
            })}
        </StyledTable>
    )
}

export default DocumentDataTable
