import { useState } from 'react'
import { File } from 'lucide-react'
import IconButton from 'components/core/IconButton'
import Typography from 'components/core/Typography'
import { DESIGN_TOKENS } from 'constants/color.constants'
import DocumentViewerDialogExtended from 'dialogs/DocumentViewerDialogExtended'

const emptyFunc = () => undefined

type FileNameCellProps = {
    url?: string
    label: string
    documentId?: string
    onRefetchRows: () => void
}

const FileNameCell = ({
    url,
    label,
    documentId,
    onRefetchRows = emptyFunc,
}: FileNameCellProps) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const _onClick = () => {
        setIsDialogOpen(true)
    }

    const _onCloseDialog = () => {
        setIsDialogOpen(false)
    }

    const _onDocumentSaved = () => {
        _onCloseDialog()
        onRefetchRows()
    }

    return url ? (
        <>
            <DocumentViewerDialogExtended
                isOpen={isDialogOpen}
                fileUrl={url}
                documentId={documentId}
                onClose={_onCloseDialog}
                onDocumentSaved={_onDocumentSaved}
            />
            <IconButton onClick={_onClick}>
                <File size={16} color={DESIGN_TOKENS.secondaryForeground} />
            </IconButton>
        </>
    ) : (
        <Typography color="primary" variant="body2">
            {label}
        </Typography>
    )
}

export default FileNameCell
