import styled from '@emotion/styled'
import {
    ChangeDateRangePickerValue,
    DateRangePicker,
    DateRangePickerValue,
} from 'components/composite/DatePicker'
import { StyledButton } from 'components/composite/DatePicker/components/DateTimeButton'
import { ChangeDateStringRangePickerValue } from 'components/composite/DatePicker/types/datePicker.types'
import Typography from 'components/core/Typography'
import { SPACING } from 'constants/spacing.constants'

const CustomStyledButton = styled(StyledButton)`
    border-width: 0;
    height: ${SPACING.p4};
    padding-right: ${SPACING.p2};
`

const StyledDetailsRow = styled.div`
    min-height: ${SPACING.p4};
    display: flex;
    justify-content: space-between;
    align-items: center;

    .detailsRow {
        grid-gap: ${SPACING.p2};
        min-height: ${SPACING.p4};
        padding-left: ${SPACING.p2};
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: background-color 0.3s ease;
    }
`

export type DetailsTimeRangeRowProps = {
    isEditable?: boolean
    caption?: string
    placeholder?: string
    value: DateRangePickerValue
    onChange?: (
        dateRange: ChangeDateRangePickerValue,
        dateStringRange: ChangeDateStringRangePickerValue
    ) => void
    onClosePopup?: () => void
}

const emptyFunc = () => undefined

export const DetailsTimeRangeRow = ({
    isEditable,
    caption,
    placeholder = '--',
    value,
    onChange = emptyFunc,
    onClosePopup = emptyFunc,
}: DetailsTimeRangeRowProps) => {
    return (
        <StyledDetailsRow>
            <div className="detailsRow">
                <Typography color="secondary" variant="body2">
                    {caption}
                </Typography>

                <DateRangePicker
                    isEditable={isEditable}
                    hasTime
                    align="end"
                    placeholder={placeholder}
                    value={value}
                    ButtonComponent={CustomStyledButton}
                    onChange={onChange}
                    onClose={onClosePopup}
                />
            </div>
        </StyledDetailsRow>
    )
}
