import { ReactNode } from 'react'
import styled from '@emotion/styled'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'

const TYPOGRAPHY_VARIANTS = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    body1: 'p',
    body2: 'p',
    caption: 'p',
}

const TYPOGRAPHY_COLORS = {
    primary: DESIGN_TOKENS.text.textForeground,
    secondary: DESIGN_TOKENS.text.textMutedForeground,
    destructive: BASE_TOKENS.grey[0],
    success: BASE_TOKENS.green[700],
    error: BASE_TOKENS.red[500],
}

type TypographyVariant =
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'body1'
    | 'body2'
    | 'caption'

type TypographyProps = {
    isBold?: boolean
    isItalic?: boolean
    shouldTruncate?: boolean
    color: keyof typeof TYPOGRAPHY_COLORS
    variant: TypographyVariant
    className?: string
    children: ReactNode
}

const Typography = ({
    isBold = false,
    isItalic = false,
    shouldTruncate = false,
    color,
    variant,
    className,
    children,
}: TypographyProps) => {
    // @ts-ignore
    const StyledTypography = styled[TYPOGRAPHY_VARIANTS[variant]]<{
        isBold: boolean
        shouldTruncate: boolean
        color: keyof typeof TYPOGRAPHY_COLORS
        variant: 'h1' | 'body2'
    }>`
        margin: 0;
        color: ${TYPOGRAPHY_COLORS[color]};
        font-style: ${isItalic ? 'italic' : 'normal'};
        font-family: Inter;

        ${isItalic &&
        `
            padding-right: 0.2rem;
        `}

        ${shouldTruncate &&
        `
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;`}

        ${() => {
            switch (variant) {
                case 'h1':
                    return `
                    font-size: 3rem;
                    line-height: 3.6rem;
                    letter-spacing: -0.09rem;
                    font-weight: 700;
                `
                case 'h2':
                    return `
                    font-size: 2.6rem;
                    line-height: 3.2rem;
                    letter-spacing: -0.09rem;
                    font-weight: 700;
                `
                case 'h3':
                    return `
                    font-size: 2.2rem;
                    line-height: 2.8rem;
                    letter-spacing: -0.04rem;
                    font-weight: 700;
                `
                case 'h4':
                    return `
                    font-size: 2rem;
                    line-height: 2.6rem;
                    letter-spacing: -0.04rem;
                    font-weight: 700;
                `
                case 'body1':
                    return `
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                    letter-spacing: -0.04rem;
                    font-weight: ${isBold ? '500' : '400'};
                `
                case 'body2':
                    return `
                    font-size: 1.4rem;
                    line-height: 2rem;
                    letter-spacing: -0.04rem;
                    font-weight: ${isBold ? '500' : '400'};
                `
                case 'caption':
                    return `
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                    letter-spacing: -0.02rem;
                    font-weight: 400;
                `
                default:
                    return ''
            }
        }}
    `

    return (
        <StyledTypography
            isBold={isBold}
            shouldTruncate={shouldTruncate}
            color={color}
            variant={variant}
            className={className}
        >
            {children}
        </StyledTypography>
    )
}

export default Typography
