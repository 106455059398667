import { getCTSDK } from 'services/ctClient'
import { RRManifest } from 'integrations/roserocket/types/rrManifest.types'
import { SelectAsync, SelectOption } from 'components/core/Select'
import { useConnectPayableToRRManifest } from 'integrations/roserocket/mutations/rr.manifests.mutations'
import { showToastError, showToastSuccess } from 'hooks/useToast'

const emptyFunc = () => undefined

const ctSDK = getCTSDK()

type ManifestSearchCellProps = {
    payableId: string
    manifestId?: string
    manifestNumber?: string
    onSuccess: () => void
}

const ManifestSearchCell = ({
    payableId,
    manifestId: propsManifestId,
    manifestNumber,
    onSuccess = emptyFunc,
}: ManifestSearchCellProps) => {
    const { isLoading: isSubmitting, mutateAsync: connectPayableToRRManifest } =
        useConnectPayableToRRManifest()

    const _onFetchCarrierOptions = async (
        searchTerm: string
    ): Promise<SelectOption[]> => {
        try {
            const resp = await ctSDK.get<{ manifests: RRManifest[] }>(
                '/integrations/roserocket/manifests',
                {
                    params: { searchTerm },
                }
            )

            const fetchedOptions =
                resp.data?.manifests.map((m) => ({
                    value: m.id,
                    label: m.full_id,
                })) || []

            return fetchedOptions
        } catch (error) {
            console.error('Error fetching manifest options:', error)
            return []
        }
    }

    const _onSelectedOptionsChanged = async (
        selectedOptions: SelectOption[]
    ) => {
        const manifestId = selectedOptions[0]?.value

        if (manifestId === propsManifestId) {
            return
        }

        try {
            await connectPayableToRRManifest({
                payableId,
                rrManifestId: manifestId,
            })

            onSuccess()
            showToastSuccess('Payable connected to TMS')
        } catch {
            showToastError('Error assigning manifest to payable')
        }
    }

    const _onFetchInitValues = async () => {
        if (!propsManifestId || !manifestNumber) {
            return []
        }

        return [{ value: propsManifestId, label: manifestNumber }]
    }

    return (
        <SelectAsync
            isCellStyle
            isDisabled={isSubmitting}
            shouldPortalMenu
            shouldFocusOnMount
            placeholder="Search"
            onFetchOptions={_onFetchCarrierOptions}
            onSelectedOptionsChanged={_onSelectedOptionsChanged}
            onFetchInitValues={_onFetchInitValues}
        />
    )
}

export default ManifestSearchCell
