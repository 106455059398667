import styled from '@emotion/styled'
import { BASE_TOKENS } from 'constants/color.constants'

type IconButtonSize = 'sm' | 'base'

const SIZE_STYLES: Record<IconButtonSize, string> = {
    sm: `
        padding: 0.6rem;
    `,
    base: `
        padding: 0.8rem;
    `,
}

const IconButton = styled.button<{ size?: 'sm' | 'base' }>`
    margin: 0;

    ${(p) => SIZE_STYLES[p.size || 'base']}

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.6rem;
    background-color: ${BASE_TOKENS.grey[0]};
    border: none;

    transition: background-color 0.3s ease;

    cursor: pointer;

    &:hover {
        background-color: ${BASE_TOKENS.grey[100]};
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
        background-color: ${BASE_TOKENS.grey[0]};
    }
`

export default IconButton
