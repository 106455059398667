import { OrderPricingType } from 'types/order.types'
import { OrderCommodity } from 'types/orderCommodity.types'

export const ORDER_COMMODITY_DEFAULT: Partial<OrderCommodity> = {
    mode: 'ltl',
    description: undefined,
    quantity: 1,
    freightType: 'pallet',
    length: 0,
    width: 0,
    height: 0,
    pieces: undefined,
    // linearFeet: undefined,
    weight: 0,
    class: undefined,
} as const

export const ORDER_PRICING_OPTIONS: {
    value: OrderPricingType
    label: string
}[] = [
    { label: 'Flat fee', value: 'flatFee' },
    { label: 'Markup', value: 'markup' },
]
