import { useBoardv2 } from 'components/composite/Board'
import { TABLE_ROW_LIMIT } from 'components/composite/Board'
import { Orders } from './components/Orders'
import { useFindShipments } from 'shipperPortal/hooks/useFindShipments'

export const OrdersContainer = () => {
    const {
        isLoading,
        isLastPage,
        isFirstPage,
        isRefetching,
        total,
        offset,
        selectedRowIds,
        rows,
        refetchRows,
        onNextPage,
        onSelectRow,
        onPreviousPage,
        onSelectAllRows,
    } = useBoardv2({ useRowsFn: useFindShipments })

    return (
        <Orders
            isLoading={isLoading}
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
            isRefetching={isRefetching}
            limit={TABLE_ROW_LIMIT}
            offset={offset}
            numOrders={total}
            orders={rows}
            selectedOrderIds={selectedRowIds}
            onNextPage={onNextPage}
            onRefetchRows={refetchRows}
            onSelectOrder={onSelectRow}
            onPreviousPage={onPreviousPage}
            onSelectAllOrders={onSelectAllRows}
        />
    )
}
