import { Order } from 'types/order.types'
import { DetailsRow } from './DetailsRow'

type OrderDetailsCustomerSectionProps = {
    order?: Order
}

export const OrderDetailsCustomerSection = ({
    order,
}: OrderDetailsCustomerSectionProps) => {
    const { customerName, customerReferenceNumber } = order || {}

    return (
        <>
            <DetailsRow
                isEditable={false}
                caption="Customer"
                valueText={customerName}
            />

            <DetailsRow
                isEditable={false}
                caption="Reference #"
                valueText={customerReferenceNumber}
            />
        </>
    )
}
