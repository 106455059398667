import { useMutation } from 'react-query'
import { getCTSDK } from 'services/ctClient'

const ctSDK = getCTSDK()

export const useConnectPayableToRRManifest = () => {
    return useMutation(
        ({
            payableId,
            rrManifestId,
        }: {
            payableId: string
            rrManifestId: string
        }) =>
            ctSDK.post('/integrations/roserocket/manifests/connectPayable', {
                payableId,
                rrManifestId,
            })
    )
}
