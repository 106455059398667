import { useState } from 'react'
import { useBoard, TABLE_ROW_LIMIT } from 'components/composite/Board'
import { useReceivablesStatusCounts } from 'hooks/useReceivablesStatusCounts'
import {
    useProcessReceivables,
    useMarkReceivablesAsProcessed,
} from 'mutations/receivable.mutations'
import { showToastError, showToastSuccess } from 'hooks/useToast'
import Receivables from './components/Receivables'
import { useFindReceivables } from './hooks/useFindReceivables'
import { splitSelectedReceivableIdsByStatus } from './utils/receivables.utils'
import {
    RECEIVABLE_STATUS_GROUPS,
    RECEIVABLE_STATUS_GROUP_IDS,
} from './receivables.constants'
import { useQueuedReceivables } from 'hooks/useQueuedReceivables'

const ReceivablesContainer = () => {
    const [isProcessDialogOpen, setIsProcessDialogOpen] = useState(false)

    const { isLoading: isProcessing, mutateAsync: processReceivables } =
        useProcessReceivables()
    const {
        isLoading: isMarkingAsProcessed,
        mutateAsync: markReceivablesAsProcessed,
    } = useMarkReceivablesAsProcessed()
    const {
        isLoading: isLoadingReceivables,
        isLastPage,
        isFirstPage,
        isRefetching: isRefetchingReceivables,
        total,
        offset,
        statusCounts,
        selectedTabId,
        selectedRowIds,
        rows: receivables,
        error: receivablesError,
        refetchRows: refetchReceivables,
        refetchStatusCounts,
        onClickTab,
        onNextPage,
        onSelectRow,
        onPreviousPage,
        onSelectAllRows,
        setSelectedRowIds,
    } = useBoard({
        defaultTabId: RECEIVABLE_STATUS_GROUP_IDS.invoice,
        statusGroupsMap: RECEIVABLE_STATUS_GROUPS,
        useRowsFn: useFindReceivables,
        useRowCountsFn: useReceivablesStatusCounts,
    })
    const {
        isLoading: isLoadingQueuedReceivables,
        isRefetching: isRefetchingQueuedReceivables,
        error: queuedReceivablesError,
        receivables: queuedReceivables,
        refetch: refetchQueuedReceivables,
    } = useQueuedReceivables()

    const _onRefetchReceivables = async () => {
        refetchReceivables()
        refetchStatusCounts()
        refetchQueuedReceivables()
    }

    const _onProcessReceivables = async () => {
        setIsProcessDialogOpen(false)

        const { receivableIdsToProcess, receivableIdsToMarkAsProcessed } =
            splitSelectedReceivableIdsByStatus(selectedRowIds, receivables)

        try {
            if (receivableIdsToProcess.length) {
                await processReceivables(receivableIdsToProcess)
                showToastSuccess('Receivables added to queue')
            }
            if (receivableIdsToMarkAsProcessed.length) {
                await markReceivablesAsProcessed(receivableIdsToMarkAsProcessed)
                showToastSuccess('Receivables processed')
            }
            _onRefetchReceivables()
        } catch (error) {
            showToastError('Error processing receivables')
        } finally {
            setSelectedRowIds([])
        }
    }

    if (receivablesError || queuedReceivablesError) {
        // showToastError('Error getting receivables');
    }

    return (
        <Receivables
            isLoading={isLoadingReceivables || isLoadingQueuedReceivables}
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
            isRefetching={
                isRefetchingReceivables || isRefetchingQueuedReceivables
            }
            isSubmitting={isProcessing || isMarkingAsProcessed}
            isProcessDialogOpen={isProcessDialogOpen}
            limit={TABLE_ROW_LIMIT}
            offset={offset}
            numRows={total}
            selectedTabId={selectedTabId}
            statusCounts={statusCounts}
            rows={receivables}
            queuedRows={queuedReceivables}
            selectedRowIds={selectedRowIds}
            onNextPage={onNextPage}
            onClickTab={onClickTab}
            onRefetchRows={_onRefetchReceivables}
            onPreviousPage={onPreviousPage}
            onSelectRow={onSelectRow}
            onSelectAllRows={onSelectAllRows}
            onProcessReceivables={_onProcessReceivables}
            onToggleProcessDialogOpen={setIsProcessDialogOpen}
        />
    )
}

export default ReceivablesContainer
