import styled from '@emotion/styled'
import * as RadixTabs from '@radix-ui/react-tabs'
import { DESIGN_TOKENS } from 'constants/color.constants'

const StyledTabItem = styled(RadixTabs.Trigger)<{ isSelected: boolean }>`
    height: 3.2rem;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;

    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;

    transition: all 0.3s ease;

    color: ${DESIGN_TOKENS.mutedForeground};
    background-color: ${DESIGN_TOKENS.muted};

    ${(p) =>
        p.isSelected &&
        `
    border-radius: 0.4rem;
    color: ${DESIGN_TOKENS.foreground};
    background-color: ${DESIGN_TOKENS.background};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    `}
`

export default StyledTabItem
