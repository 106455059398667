import { useState } from 'react'
import { showToastError, showToastSuccess } from 'hooks/useToast'
import { AssignCarrierDialog } from './components/AssignCarrierDialog'
import { AssignCarrierValidationError } from './AssignCarrierDialogContainer.types'
import { useUpdateOrder } from 'mutations/order.mutations'

type AssignCarrierDialogContainerProps = {
    isOpen: boolean
    orderId?: string
    onClose: () => void
}

export const AssignCarrierDialogContainer = ({
    isOpen,
    orderId,
    onClose,
}: AssignCarrierDialogContainerProps) => {
    const [carrierId, setCarrierId] = useState<string>()
    const [errors, setErrors] = useState<
        AssignCarrierValidationError | undefined
    >()

    const { isLoading: isSubmitting, mutateAsync: updateOrder } =
        useUpdateOrder()

    const _onClear = () => {
        setCarrierId(undefined)
    }

    const _onClose = () => {
        onClose()
        _onClear()
    }

    const _onValidate = () => {
        const errors: AssignCarrierValidationError = {}

        if (!carrierId) {
            errors.carrierId = 'Carrier is required'
        }

        if (!Object.keys(errors).length) {
            setErrors(undefined)
            return
        }

        setErrors(errors)
        return errors
    }

    const _onSave = async () => {
        try {
            if (!orderId) {
                return
            }

            const errors = _onValidate()
            if (errors || !carrierId) {
                showToastError('Please fill in all required fields')
                return
            }

            await updateOrder({
                id: orderId,
                order: { carrierId },
            })

            showToastSuccess('Carrier assigned')
            onClose()
            _onClear()
        } catch (error) {
            showToastError('Error assigning carrier')
        }
    }

    return (
        <AssignCarrierDialog
            isOpen={isOpen}
            isSubmitting={isSubmitting}
            errors={errors}
            onSave={_onSave}
            onClose={_onClose}
            onChangeCarrier={setCarrierId}
        />
    )
}
