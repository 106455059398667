import styled from '@emotion/styled'
import Popover from 'components/core/Popover'
import Typography from 'components/core/Typography'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import { SPACING } from 'constants/spacing.constants'
import { OctagonAlert } from 'lucide-react'

const StyledDetailsRow = styled.div<{ isRequiredAndMissing?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .detailsRow {
        min-height: ${SPACING.p4};
        padding-left: ${SPACING.p2};
        padding-right: ${SPACING.p2};
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-gap: ${SPACING.p2};

        &.detailsRow--clickable {
            transition: background-color 0.3s ease;

            &:hover {
                cursor: pointer;
                background-color: ${(p) =>
                    p.isRequiredAndMissing
                        ? BASE_TOKENS.red[50]
                        : BASE_TOKENS.grey[50]};
            }
        }

        .detailsRow__caption {
            width: 30%;
        }

        .detailsRow__valueText {
            display: flex;
            justify-content: flex-end;
            width: 70%;
        }
    }
`

const DetailsRowPopoverContainer = styled.div`
    min-width: 28rem;
`

export type DetailsRowProps = {
    isEditable?: boolean
    isRequired?: boolean
    caption?: string
    side?: 'top' | 'right' | 'bottom' | 'left'
    align?: 'start' | 'center' | 'end'
    valueText?: string
    trigger?: React.ReactNode
    children?: React.ReactNode
    onClosePopup?: () => void
}

const emptyFunc = () => undefined

export const DetailsRow = ({
    isEditable,
    isRequired,
    side,
    align,
    children,
    trigger,
    caption,
    valueText,
    onClosePopup = emptyFunc,
}: DetailsRowProps) => {
    const isRequiredAndMissing = isRequired && !valueText

    const DefaultTrigger = (
        <>
            <div className="detailsRow__caption">
                <Typography
                    isBold={isRequiredAndMissing}
                    color={isRequiredAndMissing ? 'error' : 'secondary'}
                    variant="body2"
                >
                    {caption}
                </Typography>
            </div>

            <div className="detailsRow__valueText">
                {isRequiredAndMissing ? (
                    <OctagonAlert
                        size={SPACING.p2}
                        strokeWidth={2}
                        color={DESIGN_TOKENS.error}
                    />
                ) : (
                    <Typography color="primary" variant="body2">
                        {valueText || ''}
                    </Typography>
                )}
            </div>
        </>
    )

    if (!isEditable) {
        return (
            <StyledDetailsRow isRequiredAndMissing={isRequiredAndMissing}>
                {<div className="detailsRow">{trigger || DefaultTrigger}</div>}
            </StyledDetailsRow>
        )
    }

    return (
        <StyledDetailsRow isRequiredAndMissing={isRequiredAndMissing}>
            <Popover
                side={side}
                align={align}
                onClose={onClosePopup}
                trigger={
                    <div
                        className="detailsRow detailsRow--clickable"
                        role="button"
                    >
                        {trigger || DefaultTrigger}
                    </div>
                }
            >
                <DetailsRowPopoverContainer>
                    {children}
                </DetailsRowPopoverContainer>
            </Popover>
        </StyledDetailsRow>
    )
}
