import { useState } from 'react'
import { getCTSDK } from 'services/ctClient'
import { RRCarrier } from 'integrations/roserocket/types/rrCarrier.types'
import { SelectOption } from 'components/core/Select'
import { useMutateRRSettings } from 'integrations/roserocket/mutations/settings.mutations'

const ctSDK = getCTSDK()

export const useRRPayableSettings = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [selectedVendors, setSelectedVendors] = useState<SelectOption[]>([])
    const [selectedBillableCarriers, setSelectedBillableCarriers] = useState<
        SelectOption[]
    >([])
    const [selectedInvoiceDateCarriers, setSelectedInvoiceDateCarriers] =
        useState<SelectOption[]>([])
    const [selectedSkipCarriers, setSelectedSkipCarriers] = useState<
        SelectOption[]
    >([])

    const { isLoading: isSubmitting, mutateAsync: saveRRSettings } =
        useMutateRRSettings()

    const onSaveSettings = async () => {
        await saveRRSettings({
            settings: {
                carrierIdsSkipNameCheck: selectedSkipCarriers.map(
                    (carrier) => carrier.value
                ),
                carrierIdsMarkedAsVendors: selectedVendors.map(
                    (carrier) => carrier.value
                ),
                carrierIdsToUseInvoiceDate: selectedInvoiceDateCarriers.map(
                    (carrier) => carrier.value
                ),
                carrierIdsBillableWithoutPOD: selectedBillableCarriers.map(
                    (carrier) => carrier.value
                ),
            },
        })
    }

    const onFetchCarrierOptions = async (
        searchTerm: string
    ): Promise<SelectOption[]> => {
        try {
            const resp = await ctSDK.get<{ carriers: RRCarrier[] }>(
                '/integrations/roserocket/carriers',
                {
                    params: { searchTerm },
                }
            )

            const fetchedOptions =
                resp.data?.carriers.map((carrier) => ({
                    value: carrier.id,
                    label: carrier.name,
                })) || []

            return fetchedOptions
        } catch (error) {
            console.error('Error fetching carrier options:', error)
            return []
        }
    }

    const onFetchInitVendors = async (): Promise<SelectOption[]> => {
        setIsLoading(true)

        try {
            const settingsResp = await ctSDK.get<{
                settings: { carrierIdsMarkedAsVendors: string[] }
            }>('/integrations/roserocket/settings')

            const carrierIdsMarkedAsVendors =
                settingsResp.data?.settings?.carrierIdsMarkedAsVendors || []

            if (!carrierIdsMarkedAsVendors.length) {
                return []
            }

            const carriersMarkedAsVendors = await ctSDK.get<{
                carriers: RRCarrier[]
            }>('/integrations/roserocket/carriers', {
                params: { inCarrierIds: carrierIdsMarkedAsVendors },
            })

            const initOptions =
                carriersMarkedAsVendors.data?.carriers.map((carrier) => ({
                    value: carrier.id,
                    label: carrier.name,
                })) || []

            return initOptions
        } catch (error) {
            console.error('Error fetching init vendors:', error)
            return []
        } finally {
            setIsLoading(false)
        }
    }

    const onFetchInitInvoiceDateCarriers = async (): Promise<
        SelectOption[]
    > => {
        setIsLoading(true)

        try {
            const settingsResp = await ctSDK.get<{
                settings: { carrierIdsToUseInvoiceDate: string[] }
            }>('/integrations/roserocket/settings')

            const carrierIdsToUseInvoiceDate =
                settingsResp.data?.settings?.carrierIdsToUseInvoiceDate || []

            if (!carrierIdsToUseInvoiceDate.length) {
                return []
            }

            const carriersToUseInvoiceDate = await ctSDK.get<{
                carriers: RRCarrier[]
            }>('/integrations/roserocket/carriers', {
                params: { inCarrierIds: carrierIdsToUseInvoiceDate },
            })

            const initOptions =
                carriersToUseInvoiceDate.data?.carriers.map((carrier) => ({
                    value: carrier.id,
                    label: carrier.name,
                })) || []

            return initOptions
        } catch (error) {
            console.error('Error fetching init invoice date carriers:', error)
            return []
        } finally {
            setIsLoading(false)
        }
    }

    const onFetchInitBillableCarriers = async (): Promise<SelectOption[]> => {
        setIsLoading(true)

        try {
            const settingsResp = await ctSDK.get<{
                settings: { carrierIdsBillableWithoutPOD: string[] }
            }>('/integrations/roserocket/settings')

            const carrierIdsBillableWithoutPOD =
                settingsResp.data?.settings?.carrierIdsBillableWithoutPOD || []

            if (!carrierIdsBillableWithoutPOD.length) {
                return []
            }

            const carriersBillableWithoutPOD = await ctSDK.get<{
                carriers: RRCarrier[]
            }>('/integrations/roserocket/carriers', {
                params: { inCarrierIds: carrierIdsBillableWithoutPOD },
            })

            const initOptions =
                carriersBillableWithoutPOD.data?.carriers.map((carrier) => ({
                    value: carrier.id,
                    label: carrier.name,
                })) || []

            return initOptions
        } catch (error) {
            console.error('Error fetching init invoice date carriers:', error)
            return []
        } finally {
            setIsLoading(false)
        }
    }

    const onFetchInitSkipCarriers = async (): Promise<SelectOption[]> => {
        setIsLoading(true)

        try {
            const settingsResp = await ctSDK.get<{
                settings: { carrierIdsSkipNameCheck: string[] }
            }>('/integrations/roserocket/settings')

            const carrierIdsSkipNameCheck =
                settingsResp.data?.settings?.carrierIdsSkipNameCheck || []

            if (!carrierIdsSkipNameCheck.length) {
                return []
            }

            const carriersSkipNameCheck = await ctSDK.get<{
                carriers: RRCarrier[]
            }>('/integrations/roserocket/carriers', {
                params: { inCarrierIds: carrierIdsSkipNameCheck },
            })

            const initOptions =
                carriersSkipNameCheck.data?.carriers.map((carrier) => ({
                    value: carrier.id,
                    label: carrier.name,
                })) || []

            return initOptions
        } catch (error) {
            console.error('Error fetching init skip carriers:', error)
            return []
        } finally {
            setIsLoading(false)
        }
    }

    return {
        isLoading,
        isSubmitting,
        onSaveSettings,
        onChangeVendors: setSelectedVendors,
        onFetchInitVendors,
        onChangeSkipCarriers: setSelectedSkipCarriers,
        onFetchCarrierOptions,
        onFetchInitSkipCarriers,
        onChangeBillableCarriers: setSelectedBillableCarriers,
        onFetchInitBillableCarriers,
        onChangeInvoiceDateCarriers: setSelectedInvoiceDateCarriers,
        onFetchInitInvoiceDateCarriers,
    }
}
