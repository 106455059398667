import { Org } from 'types/org.types'

export const isDemoOrg = (org: Org): boolean => {
    return [
        'EHWvza2G8lMZ4m9mvgYZ', // YC demo org
        'k9E8Ki6pgsWwdlyTH4gQ', // Piper demo org
    ].includes(org.id)
}

export const isTestOrg = (org: Org): boolean => {
    return [
        'hVK9IwQ0I6QZiV17Z938', // Piper google dev
        'nJMHTd8IhR1Wqfkr0Ndy', // Piper ms dev
        'k9E8Ki6pgsWwdlyTH4gQ', // Piper prod
    ].includes(org.id)
}
