import Typography from 'components/core/Typography'
import { OrderCommodity } from 'types/orderCommodity.types'
import { useMemo } from 'react'

type OrderDetailsCommoditiesSectionProps = {
    commodities: OrderCommodity[]
}

export const OrderDetailsCommoditiesSection = ({
    commodities,
}: OrderDetailsCommoditiesSectionProps) => {
    const commoditiesSorted = useMemo(() => {
        // sort will mpdify the original array, so we need to clone it
        return [...commodities].sort((a, b) =>
            a.description.localeCompare(b.description)
        )
    }, [commodities])

    return (
        <>
            <div className="details__content__right__body__section">
                <div className="details__content__right__body__row">
                    <Typography color="secondary" variant="body2">
                        Commodities
                    </Typography>
                </div>
            </div>

            {!!commoditiesSorted.length && (
                <div className="details__content__right__body__section">
                    <div className="details__content__right__body__row details__content__right__body__row--compressed">
                        <div className="details__content__right__body__row__quantity">
                            <Typography
                                isBold
                                color="secondary"
                                variant="body2"
                            >
                                Quantity
                            </Typography>
                        </div>

                        <div>
                            <Typography
                                isBold
                                color="secondary"
                                variant="body2"
                            >
                                Description
                            </Typography>
                        </div>
                    </div>

                    {commoditiesSorted.map((c) => {
                        return (
                            <div
                                key={c.id}
                                className="details__content__right__body__row details__content__right__body__row--compressed"
                            >
                                <div className="details__content__right__body__row__quantity">
                                    <Typography color="primary" variant="body2">
                                        {c.quantity}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography color="primary" variant="body2">
                                        {c.description}
                                    </Typography>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
        </>
    )
}
