import styled from '@emotion/styled'
import Typography from 'components/core/Typography'
import Button from 'components/core/Button'
import Dialog, { DialogActions, DialogContent } from 'components/core/Dialog'

const emptyFunc = () => undefined

const StyledConfirmDeleteDialog = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
`

type ConfirmDeleteDialogProps = {
    isOpen: boolean
    header: string
    message: string
    onClose: () => void
    onConfirm: () => void
}

const ConfirmDeleteDialog = ({
    isOpen,
    header,
    message,
    onClose = emptyFunc,
    onConfirm = emptyFunc,
}: ConfirmDeleteDialogProps) => {
    return (
        <Dialog isOpen={isOpen} size="sm" title={header} onClose={onClose}>
            <DialogContent>
                <StyledConfirmDeleteDialog>
                    <Typography color="primary" variant="body1">
                        {message}
                    </Typography>
                </StyledConfirmDeleteDialog>
            </DialogContent>
            <DialogActions>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="destructive" onClick={onConfirm}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDeleteDialog
