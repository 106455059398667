import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetRRUserAccount } from 'integrations/roserocket/queries/rr.user.queries'
import { useMe } from 'hooks/useMe'

const RRAuthButtonContainer = () => {
    const [isTokenValid, setIsTokenValid] = useState(true)
    const { me } = useMe()
    const navigate = useNavigate()

    const { isLoading } = useGetRRUserAccount({
        enabled: !!me.role && me.role !== 'superadmin',
        onSuccess: (data: {
            data: { account?: { token: { isValid: boolean } } }
        }) => {
            setIsTokenValid(!!data.data.account?.token?.isValid)
        },
    })

    if (!isLoading && !isTokenValid) {
        navigate('/login')
    }

    return null
}

export default RRAuthButtonContainer
