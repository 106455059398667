import Select from 'components/core/Select'
import { CurrencyType } from 'types/currency.types'

const SELECT_OPTIONS: { value: CurrencyType; label: string }[] = [
    {
        value: 'usd',
        label: 'USD',
    },
    {
        value: 'cad',
        label: 'CAD',
    },
]

type CurrencySelectProps = {
    currencyType?: CurrencyType
    onChange: (currencyType: CurrencyType) => void
}

export const CurrencySelect = ({
    currencyType,
    onChange,
}: CurrencySelectProps) => {
    const _onChange = (value: string) => {
        onChange(value as CurrencyType)
    }

    return (
        <Select
            value={currencyType}
            options={SELECT_OPTIONS}
            onSelectedOptionChanged={_onChange}
        />
    )
}
