import Button from 'components/core/Button'
import RRReceivableSettings, {
    RRReceivableSettingsProps,
} from 'integrations/roserocket/components/RRReceivableSettings'
import SettingsTab from '../../SettingsTab'
import Select, { SelectAsync, SelectOption } from 'components/core/Select'
import Switch from 'components/core/Switch'
import Separator from 'components/core/Separator'
import Card from 'components/composite/Card'

type ReceivableSettingsProps = {
    isLoading: boolean
    isFormValid: boolean
    isAutoProcessEnabled: boolean
    userIdAutoProcessAs?: string
    autoProcessAsOptions: SelectOption[]
    onSaveSettings: () => void
    onChangeUserIdAutoProcessAs: (value: string) => void
    onChangeIsAutoProcessEnabled: (value: boolean) => void

    // Hack - should be in RR component
    onFetchInitAutoProcessingDisabledCustomers: () => Promise<SelectOption[]>
    onChangeSelectedAutoProcessingDisabledCustomers: (
        customers: SelectOption[]
    ) => void
} & RRReceivableSettingsProps

const ReceivableSettings = ({
    isLoading,
    isFormValid,
    isSubmitting,
    isAutoProcessEnabled,
    userIdAutoProcessAs,
    autoProcessAsOptions,
    onSaveSettings,
    onFetchCustomerOptions,
    onFetchInitBOLCustomers,
    onChangeUserIdAutoProcessAs,
    onChangeSelectedBOLCustomers,
    onChangeIsAutoProcessEnabled,
    onFetchInitAutoProcessingDisabledCustomers,
    onFetchInitManualProcessingEnforcedCustomers,
    onChangeSelectedAutoProcessingDisabledCustomers,
    onChangeSelectedManualProcessingEnforcedCustomers,
}: ReceivableSettingsProps) => {
    return (
        <SettingsTab
            title="Receivables"
            description="These settings control how receivables are automated"
        >
            <Card
                title="Auto-process receivables"
                subtitle='Automatically process receivables that enter the
                        "Invoice" tab'
                HeaderInput={
                    <Switch
                        isChecked={isAutoProcessEnabled}
                        isDisabled={isLoading || isSubmitting}
                        onChange={onChangeIsAutoProcessEnabled}
                    />
                }
            >
                {isAutoProcessEnabled && (
                    <div className="card__inputs">
                        <Separator />
                        <Select
                            isDisabled={isLoading || isSubmitting}
                            label="Auto-process as user"
                            value={userIdAutoProcessAs}
                            placeholder="Search users"
                            description="Select the user that will be used to auto-process payables"
                            options={autoProcessAsOptions}
                            onSelectedOptionChanged={
                                onChangeUserIdAutoProcessAs
                            }
                        />
                        <SelectAsync
                            isMulti
                            isDisabled={isLoading || isSubmitting}
                            label="Disable auto-processing based on customer"
                            placeholder="Search customers"
                            description="For customers selected here, receivables will not be auto-processed"
                            onFetchOptions={onFetchCustomerOptions}
                            onFetchInitValues={
                                onFetchInitAutoProcessingDisabledCustomers
                            }
                            onSelectedOptionsChanged={
                                onChangeSelectedAutoProcessingDisabledCustomers
                            }
                        />
                    </div>
                )}
            </Card>
            <RRReceivableSettings
                isLoading={isLoading}
                isSubmitting={isSubmitting}
                onFetchCustomerOptions={onFetchCustomerOptions}
                onFetchInitBOLCustomers={onFetchInitBOLCustomers}
                onChangeSelectedBOLCustomers={onChangeSelectedBOLCustomers}
                onFetchInitManualProcessingEnforcedCustomers={
                    onFetchInitManualProcessingEnforcedCustomers
                }
                onChangeSelectedManualProcessingEnforcedCustomers={
                    onChangeSelectedManualProcessingEnforcedCustomers
                }
            />
            <div>
                <Button
                    isDisabled={isLoading || !isFormValid}
                    isSubmitting={isSubmitting}
                    variant="primary"
                    onClick={onSaveSettings}
                >
                    Save
                </Button>
            </div>
        </SettingsTab>
    )
}

export default ReceivableSettings
