import CommonChip from 'components/core/CommonChip'
import Typography from 'components/core/Typography'
import { BoardColumn } from 'components/composite/Board'
import { Payable } from 'types/payable.types'
import RRManifestSearchCell from 'integrations/roserocket/components/RRManifestSearchCell'
import { getPayableDateString, getDateFromISOString } from 'utils/date.utils'
import ManifestCell from './components/ManifestCell'
import FileNameCell from './components/FileNameCell'
import TMSTotalCell from './components/TMSTotalCell'
import BillTotalCell from './components/BillTotalCell'
import PayableActionsCell from './components/PayableActionsCell'
import { transformToSentenceCase } from './utils/textTransform.utils'
import {
    canPayableBeMarkedAsProcessed,
    canPayableBeProcessed,
    getPayableInvoiceNumber,
    mapPayableProcessErrorReasonToNote,
} from './utils/payable.utils'
import {
    DERIVED_STATUS_LABELS,
    STATUS_BACKGROUND_COLORS,
    STATUS_BORDER_COLORS,
    STATUS_TEXT_COLORS,
} from './payables.constants'
import MoneyCell from 'components/composite/MoneyCell'

export const tableColumns: BoardColumn[] = [
    {
        id: 'fileName',
        label: 'File',
        width: 52,
        renderCell: (row: Payable, onRefetchRows) => (
            <FileNameCell
                url={row.fileUrl}
                label={row.fileName}
                documentId={row.documentId}
                onRefetchRows={onRefetchRows}
            />
        ),
    },
    {
        id: 'invoiceNumber',
        label: 'Invoice #',
        width: 104,
        renderCell: (row: Payable) => (
            <Typography shouldTruncate variant="body2" color="primary">
                {getPayableInvoiceNumber(row)}
            </Typography>
        ),
    },
    {
        id: 'adjustedInvoiceDate',
        label: 'Date',
        width: 104,
        renderCell: (row: Payable) => (
            <Typography shouldTruncate variant="body2" color="primary">
                {row.adjustedInvoiceDate
                    ? getPayableDateString(row.adjustedInvoiceDate)
                    : getDateFromISOString(row.createdAt)}
            </Typography>
        ),
    },
    {
        id: 'status',
        label: 'Status',
        width: 120,
        renderCell: (row: Payable) => (
            <CommonChip
                label={DERIVED_STATUS_LABELS[row.status]}
                textColor={STATUS_TEXT_COLORS[row.status]}
                borderColor={STATUS_BORDER_COLORS[row.status]}
                backgroundColor={STATUS_BACKGROUND_COLORS[row.status]}
            />
        ),
    },
    {
        id: 'carrierName',
        label: 'Carrier',
        width: 132,
        renderCell: (row: Payable) => (
            <Typography shouldTruncate variant="body2" color="primary">
                {row.rrCarrierName ||
                    transformToSentenceCase(row.carrierName) ||
                    '--'}
            </Typography>
        ),
    },
    {
        id: 'manifestNumber',
        label: 'Manifest',
        width: 132,
        isEditable: (row: Payable) =>
            canPayableBeProcessed(row.status) ||
            canPayableBeMarkedAsProcessed(row.status),
        renderCell: (row: Payable) => (
            <ManifestCell
                manifestId={row.rrManifestId}
                payableStatus={row.status}
                manifestNumber={row.manifestNumber}
            />
        ),
        renderCellEditable: (row: Payable, onSuccess: () => void) => (
            <RRManifestSearchCell
                payableId={row.id}
                manifestId={row.rrManifestId}
                manifestNumber={row.manifestNumber}
                onSuccess={onSuccess}
            />
        ),
    },
    {
        id: 'shipperName',
        label: 'Shipper',
        width: 132,
        getValue: (value: any) => transformToSentenceCase(value),
    },
    {
        id: 'consigneeName',
        label: 'Consignee',
        width: 132,
        getValue: (value: any) => transformToSentenceCase(value),
    },
    {
        id: 'invoiceTotal',
        label: 'Bill total',
        width: 100,
        isEditable: (row: Payable) =>
            canPayableBeProcessed(row.status) ||
            canPayableBeMarkedAsProcessed(row.status),
        renderCell: (row: Payable) => (
            <MoneyCell
                isEditable={
                    canPayableBeProcessed(row.status) ||
                    canPayableBeMarkedAsProcessed(row.status)
                }
                value={row.adjustedInvoiceTotal || row.invoiceTotal}
            />
        ),
        renderCellEditable: (row: Payable, onSuccess: () => void) => (
            <BillTotalCell
                payableId={row.id}
                value={row.adjustedInvoiceTotal || row.invoiceTotal}
                onSuccess={onSuccess}
            />
        ),
    },
    {
        id: 'rrInvoiceTotal',
        label: 'TMS total',
        width: 100,
        isEditable: (row: Payable) =>
            (canPayableBeProcessed(row.status) ||
                canPayableBeMarkedAsProcessed(row.status)) &&
            !!row.rrManifestId,
        renderCell: (row: Payable) => (
            <MoneyCell
                isEditable={
                    (canPayableBeProcessed(row.status) ||
                        canPayableBeMarkedAsProcessed(row.status)) &&
                    !!row.rrManifestId
                }
                value={row.rrInvoiceTotal}
            />
        ),
        renderCellEditable: (row: Payable, onSuccess: () => void) => (
            <TMSTotalCell
                payableId={row.id}
                value={row.rrInvoiceTotal}
                onSuccess={onSuccess}
            />
        ),
    },
    {
        id: 'processErrorReason',
        label: 'Notes',
        width: 132,
        getValue: (value: any) => mapPayableProcessErrorReasonToNote(value),
    },
    {
        id: 'actions',
        width: 52,
        renderCell: (row: Payable, onRefetchRows: () => void) => (
            <PayableActionsCell
                payableId={row.id}
                documentId={row.documentId}
                payableStatus={row.status}
                onRefetchPayables={onRefetchRows}
            />
        ),
    },
]
