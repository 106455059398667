import styled from '@emotion/styled'
import Row from 'components/core/Row'
import Typography from 'components/core/Typography'
import { DESIGN_TOKENS } from 'constants/color.constants'
import { SPACING } from 'constants/spacing.constants'
import { useDimensions } from 'hooks/useDimensions'

const StyledCell = styled(Row)<{
    isEditable?: boolean
    width: number
}>`
    width: ${(p) => p.width / 10}rem;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const StyledHeaderRow = styled(Row)`
    padding: ${SPACING.p2} 0;
    position: sticky;
    top: 0;
    border-bottom: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};
`

const StyledHeaderCell = styled(StyledCell)`
    padding: 0 ${SPACING.p2};
    color: ${DESIGN_TOKENS.text.textMutedForeground};
`

const StyledBodyRow = styled(Row)`
    padding: ${SPACING.p2} 0;
    border-bottom: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};
`

const StyledValueCell = styled(Row)`
    padding: 0 ${SPACING.p2};
    color: ${DESIGN_TOKENS.text.textForeground};
`

export type TableColumn<TRow> = {
    isRequired?: boolean
    id: string
    label: string
    width: number
    renderCell?: (row: TRow, index: number) => JSX.Element
}

type RowWithOptionalId = {
    id?: string // The id is now optional
    [key: string]: any // Other properties for the row can be dynamic
}

type TableProps<TRow> = {
    rows: TRow[]
    columns: TableColumn<TRow>[]
    className?: string
}

const Table = <TRow extends RowWithOptionalId>({
    rows,
    columns,
    className,
}: TableProps<TRow>) => {
    const [dimensions, ref] = useDimensions()

    const totalColumnsWidth = columns.reduce(
        (acc, column) => (acc += column.width),
        0
    )

    return (
        <div ref={ref} className={className}>
            <StyledHeaderRow>
                {columns.map((column, index) => {
                    return (
                        <StyledHeaderCell
                            key={`${index}-${column.id}`}
                            width={
                                (dimensions.width / totalColumnsWidth) *
                                column.width
                            }
                        >
                            <Typography
                                isBold
                                shouldTruncate
                                color="secondary"
                                variant="body2"
                            >
                                {column.label}
                                {column.isRequired && ' *'}
                            </Typography>
                        </StyledHeaderCell>
                    )
                })}
            </StyledHeaderRow>
            {rows.map((row, index) => {
                return (
                    <StyledBodyRow key={index}>
                        {columns.map((column) => {
                            const value = row[column.id as keyof typeof row]

                            return (
                                <StyledCell
                                    key={`${row?.id}-${index}-${column.id}`}
                                    width={
                                        (dimensions.width / totalColumnsWidth) *
                                        column.width
                                    }
                                >
                                    {column.renderCell?.(row, index) || (
                                        <StyledValueCell>
                                            <Typography
                                                shouldTruncate
                                                color="primary"
                                                variant="body2"
                                            >
                                                {value || '--'}
                                            </Typography>
                                        </StyledValueCell>
                                    )}
                                </StyledCell>
                            )
                        })}
                    </StyledBodyRow>
                )
            })}
        </div>
    )
}

export default Table
