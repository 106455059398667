import DocumentViewerDialog from 'dialogs/DocumentViewerDialog'

const emptyFunc = () => undefined

type PODViewerDialogProps = {
    isOpen: boolean
    fileUrl?: string
    onClose: () => void
}

const PODViewerDialog = ({
    isOpen,
    fileUrl,
    onClose = emptyFunc,
}: PODViewerDialogProps) => {
    return (
        <DocumentViewerDialog
            isOpen={isOpen}
            size="lg"
            fileUrl={fileUrl}
            onClose={onClose}
        />
    )
}

export default PODViewerDialog
