import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { Carrier } from 'types/carrier.types'

export const USE_FIND_CARRIERS_QUERY_KEY = 'useFindCarriers'

const ctSDK = getCTSDK()

export const useFindCarriers = ({
    limit = 10,
    offset = 0,
}: {
    limit?: number
    offset?: number
}) => {
    const ws = useRef<WebSocket | null>(null)

    const [total, setTotal] = useState(0)
    const [carriers, setCarriers] = useState<Carrier[]>([])

    const { isLoading, isRefetching, error, refetch } = useQuery(
        [USE_FIND_CARRIERS_QUERY_KEY, limit, offset],
        async () =>
            await ctSDK.get<{ carriers: Carrier[]; total: number }>(
                '/carriers',
                {
                    params: {
                        limit,
                        offset,
                    },
                }
            ),
        {
            onSuccess: (data) => {
                setTotal(data?.data?.total || 0)
                setCarriers(data?.data?.carriers || [])
            },
        }
    )

    useEffect(() => {
        ws.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL_2 || '')

        ws.current.onmessage = (event) => {
            // const wsEvent = JSON.parse(event.data);
            // if (['orderUpdated'].includes(wsEvent.type)) {
            //     refetch();
            // }
        }

        return () => {
            ws.current?.close()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        isLoading,
        isRefetching,
        total,
        rows: carriers,
        error,
        refetch,
    }
}
