import Button from 'components/core/Button'
import NumberInput from 'components/composite/NumberInput'
import RRPayableSettings, {
    RRPayableSettingsProps,
} from 'integrations/roserocket/components/RRPayableSettings'
import SettingsTab from '../../SettingsTab'
import Switch from 'components/core/Switch'
import Separator from 'components/core/Separator'
import Select, { SelectOption } from 'components/core/Select'
import Card from 'components/composite/Card'

type PayableSettingsProps = {
    isTestOrg: boolean
    isLoading: boolean
    isSubmitting: boolean
    isAutoProcessEnabled: boolean
    isUseInvoiceDateEnabled: boolean
    userIdAutoProcessAs?: string
    acceptableLowerRange?: number
    acceptableUpperRange?: number
    autoProcessAsOptions: SelectOption[]
    onSaveSettings: () => void
    onChangeUserIdAutoProcessAs: (value: string) => void
    onChangeAcceptableLowerRange: (value?: number) => void
    onChangeAcceptableUpperRange: (value?: number) => void
    onChangeIsAutoProcessEnabled: (value: boolean) => void
    onChangeIsUseInvoiceDateEnabled: (value: boolean) => void
} & RRPayableSettingsProps

const PayableSettings = ({
    isTestOrg,
    isLoading,
    isSubmitting,
    isAutoProcessEnabled,
    isUseInvoiceDateEnabled,
    userIdAutoProcessAs,
    acceptableLowerRange,
    acceptableUpperRange,
    autoProcessAsOptions,
    onSaveSettings,
    onChangeVendors,
    onFetchInitVendors,
    onChangeSkipCarriers,
    onFetchCarrierOptions,
    onFetchInitSkipCarriers,
    onChangeBillableCarriers,
    onChangeUserIdAutoProcessAs,
    onFetchInitBillableCarriers,
    onChangeInvoiceDateCarriers,
    onChangeAcceptableLowerRange,
    onChangeAcceptableUpperRange,
    onChangeIsAutoProcessEnabled,
    onFetchInitInvoiceDateCarriers,
    onChangeIsUseInvoiceDateEnabled,
}: PayableSettingsProps) => {
    return (
        <SettingsTab
            title="Payables"
            description="These settings control how payables are automated"
        >
            <Card
                title="Auto-process payables"
                subtitle='Automatically process payables that enter the
                            "Process" tab'
                HeaderInput={
                    <Switch
                        isChecked={isAutoProcessEnabled}
                        isDisabled={isLoading || isSubmitting}
                        onChange={onChangeIsAutoProcessEnabled}
                    />
                }
            >
                {isAutoProcessEnabled && (
                    <div className="card__inputs">
                        <Separator />
                        <Select
                            isDisabled={isSubmitting}
                            label="Auto-process as user"
                            value={userIdAutoProcessAs}
                            placeholder="Search users"
                            description="Select the user that will be used to auto-process payables"
                            options={autoProcessAsOptions}
                            onSelectedOptionChanged={
                                onChangeUserIdAutoProcessAs
                            }
                        />
                    </div>
                )}
            </Card>
            <RRPayableSettings
                isTestOrg={isTestOrg}
                isLoading={isLoading}
                isSubmitting={isSubmitting}
                isUseInvoiceDateEnabled={isUseInvoiceDateEnabled}
                onChangeVendors={onChangeVendors}
                onFetchInitVendors={onFetchInitVendors}
                onChangeSkipCarriers={onChangeSkipCarriers}
                onFetchCarrierOptions={onFetchCarrierOptions}
                onFetchInitSkipCarriers={onFetchInitSkipCarriers}
                onChangeBillableCarriers={onChangeBillableCarriers}
                onFetchInitBillableCarriers={onFetchInitBillableCarriers}
                onChangeInvoiceDateCarriers={onChangeInvoiceDateCarriers}
                onFetchInitInvoiceDateCarriers={onFetchInitInvoiceDateCarriers}
                onChangeIsUseInvoiceDateEnabled={
                    onChangeIsUseInvoiceDateEnabled
                }
            />
            <NumberInput
                isDisabled={isLoading || isSubmitting}
                label="Within range boundary"
                value={acceptableUpperRange}
                placeholder="Within range boundary"
                description="Sets the acceptable range for carrier invoices with a total amount greater than your TMS total"
                onChange={onChangeAcceptableUpperRange}
            />
            <NumberInput
                isDisabled={isLoading || isSubmitting}
                label="Under price boundary"
                value={acceptableLowerRange}
                placeholder="Under price boundary"
                description="Sets the acceptable range for carrier invoices with a total amount less than your TMS total"
                onChange={onChangeAcceptableLowerRange}
            />
            <div>
                <Button
                    isDisabled={
                        isLoading ||
                        (isAutoProcessEnabled && !userIdAutoProcessAs)
                    }
                    isSubmitting={isSubmitting}
                    variant="primary"
                    onClick={onSaveSettings}
                >
                    Save
                </Button>
            </div>
        </SettingsTab>
    )
}

export default PayableSettings
