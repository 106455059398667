import AnchorTag from 'components/core/AnchorTag'
import Typography from 'components/core/Typography'
import { useMyOrg } from 'hooks/useMyOrg'

type InvoiceCellProps = {
    invoiceId?: string
    invoiceNumber?: string
}

const InvoiceCell = ({ invoiceId, invoiceNumber }: InvoiceCellProps) => {
    const { org } = useMyOrg()

    return invoiceNumber ? (
        <AnchorTag
            rel="noopener noreferrer"
            href={`https://${org.rrSubdomain}.roserocket.com/#/ops/accounting/receivables/invoices/${invoiceId}`}
            color="primary"
            target="_blank"
        >
            {invoiceNumber}
        </AnchorTag>
    ) : (
        <Typography color="primary" variant="body2">
            --
        </Typography>
    )
}

export default InvoiceCell
