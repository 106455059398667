import Table from 'components/composite/Table'
import { OrderCommodity } from 'types/orderCommodity.types'

type CommoditiesTableProps = { commodities?: OrderCommodity[] }

export const CommoditiesTable = ({ commodities }: CommoditiesTableProps) => {
    const columns = [
        {
            id: 'quantity',
            label: 'Qty',
            width: 14,
        },
        {
            id: 'description',
            label: 'Description',
            width: 80,
        },
    ]

    return <Table rows={commodities || []} columns={columns} />
}
