// inspired by https://time.openstatus.dev/

import styled from '@emotion/styled'

import React from 'react'
import {
    getArrowByType,
    getDateByType,
    setDateByType,
    Period,
    TimePickerType,
} from '../DatePicker/utils/datePicker.utils'
import { SPACING } from 'constants/spacing.constants'
import { BASE_TOKENS } from 'constants/color.constants'

const StyledInput = styled.input`
    width: ${SPACING.p5};
    padding: ${SPACING.p1};
    text-align: center;
    font-size: ${SPACING.p2};
    caret-color: transparent;
    border-radius: ${SPACING.p1};
    border: 1px solid ${BASE_TOKENS.grey[200]};
    /* Remove spin buttons in webkit browsers */
    &::-webkit-inner-spin-button {
        appearance: none;
    }
`

export interface TimePickerInputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    picker: TimePickerType
    date: Date | undefined
    setDate: (date: Date | undefined) => void
    period?: Period
    onRightFocus?: () => void
    onLeftFocus?: () => void
}

const TimePickerInput = React.forwardRef<
    HTMLInputElement,
    TimePickerInputProps
>(
    (
        {
            className,
            type = 'tel',
            value,
            id,
            name,
            date = new Date(new Date().setHours(0, 0, 0, 0)),
            setDate,
            onChange,
            onKeyDown,
            picker,
            period,
            onLeftFocus,
            onRightFocus,
            ...props
        },
        ref
    ) => {
        const [flag, setFlag] = React.useState<boolean>(false)
        const [prevIntKey, setPrevIntKey] = React.useState<string>('0')

        /**
         * allow the user to enter the second digit within 2 seconds
         * otherwise start again with entering first digit
         */
        React.useEffect(() => {
            if (flag) {
                const timer = setTimeout(() => {
                    setFlag(false)
                }, 2000)

                return () => clearTimeout(timer)
            }
        }, [flag])

        const calculatedValue = React.useMemo(() => {
            return getDateByType(date, picker)
        }, [date, picker])

        const calculateNewValue = (key: string) => {
            /*
             * If picker is '12hours' and the first digit is 0, then the second digit is automatically set to 1.
             * The second entered digit will break the condition and the value will be set to 10-12.
             */
            if (picker === '12hours') {
                if (
                    flag &&
                    calculatedValue.slice(1, 2) === '1' &&
                    prevIntKey === '0'
                )
                    return '0' + key
            }

            return !flag ? '0' + key : calculatedValue.slice(1, 2) + key
        }

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Tab') return
            e.preventDefault()
            if (e.key === 'ArrowRight') onRightFocus?.()
            if (e.key === 'ArrowLeft') onLeftFocus?.()
            if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
                const step = e.key === 'ArrowUp' ? 1 : -1
                const newValue = getArrowByType(calculatedValue, step, picker)
                if (flag) setFlag(false)
                const tempDate = new Date(date)
                setDate(setDateByType(tempDate, newValue, picker, period))
            }
            if (e.key >= '0' && e.key <= '9') {
                if (picker === '12hours') setPrevIntKey(e.key)

                const newValue = calculateNewValue(e.key)
                if (flag) onRightFocus?.()
                setFlag((prev) => !prev)
                const tempDate = new Date(date)
                setDate(setDateByType(tempDate, newValue, picker, period))
            }
        }

        return (
            <StyledInput
                ref={ref}
                id={id || picker}
                name={name || picker}
                value={value || calculatedValue}
                onChange={(e) => {
                    e.preventDefault()
                    onChange?.(e)
                }}
                type={type}
                inputMode="decimal"
                onKeyDown={(e) => {
                    onKeyDown?.(e)
                    handleKeyDown(e)
                }}
                {...props}
            />
        )
    }
)

TimePickerInput.displayName = 'TimePickerInput'

export { TimePickerInput }
