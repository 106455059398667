import { Order } from 'types/order.types'
import { BoardColumn } from 'components/composite/Board'
import AnchorTag from 'components/core/AnchorTag'
import { format } from 'date-fns'
import Typography from 'components/core/Typography'
import Column from 'components/core/Column'
import OrderStatusChip from 'components/composite/OrderStatusChip'
import { getOrderStatus, getOrderSummary } from 'utils/order.utils'
import { URLS } from 'utils/url.utils'

export const tableColumns: BoardColumn[] = [
    {
        id: 'fullId',
        label: 'Shipment',
        width: 30,
        renderCell: (row: Order) => (
            <AnchorTag
                href={URLS.cartage.shipperPortal.shipment(row.id)}
                color="primary"
            >
                {row.fullId}
            </AnchorTag>
        ),
    },
    {
        id: 'lastUpdate',
        label: 'Last Update',
        width: 80,
        renderCell: (row: Order) => {
            return (
                <Typography shouldTruncate color="primary" variant="body2">
                    {getOrderSummary(row)}
                </Typography>
            )
        },
    },
    {
        id: 'status',
        label: 'Status',
        width: 30,
        isHiddenForSmScreen: true,
        renderCell: (row: Order) => (
            <OrderStatusChip status={getOrderStatus(row)} />
        ),
    },
    {
        id: 'shipper',
        label: 'Shipper',
        width: 30,
        isHiddenForSmScreen: true,
        renderCell: (row: Order) => (
            <Column className="truncate">
                <Typography shouldTruncate color="primary" variant="body2">
                    {row.customerName ||
                        row.pickup?.name ||
                        row.pickup?.address1}
                </Typography>
                <Typography shouldTruncate color="primary" variant="caption">
                    {row.pickup?.city}, {row.pickup?.state}
                </Typography>
            </Column>
        ),
    },
    {
        id: 'consignee',
        label: 'Consignee',
        width: 30,
        renderCell: (row: Order) => (
            <Column className="truncate">
                <Typography shouldTruncate color="primary" variant="body2">
                    {row.dropoff?.name || row.dropoff?.address1}
                </Typography>
                <Typography shouldTruncate color="primary" variant="caption">
                    {row.dropoff?.city}, {row.dropoff?.state}
                </Typography>
            </Column>
        ),
    },
    {
        id: 'dropoffStartAt',
        label: 'Delivery date',
        width: 30,
        isHiddenForSmScreen: true,
        getValue: (_: string, row?: Order) => {
            if (!row?.dropoff?.arrivalStartAt) {
                return
            }

            const date = new Date(row.dropoff.arrivalStartAt)
            return format(date, 'MMM do, yyyy')
        },
    },
    {
        id: 'customerReferenceNumber',
        label: 'Reference IDs',
        width: 30,
        isHiddenForSmScreen: true,
        renderCell: (row: Order) => (
            <Typography shouldTruncate color="primary" variant="caption">
                {row.customerReferenceNumber || '--'}
            </Typography>
        ),
    },
    {
        id: 'carrierName',
        label: 'Carrier',
        width: 30,
    },
]
