import { UseQueryResult, useQuery } from 'react-query'
import { getCTSDK } from 'services/ctClient'

const sdk = getCTSDK()

type GetRRUserAccountResponse = {
    account: {
        token?: {
            isValid: boolean
        }
    }
}

export const useGetRRUserAccount = (
    options?: any
): UseQueryResult<GetRRUserAccountResponse, unknown> => {
    return useQuery(
        ['useGetRRUserAccount'],
        async () =>
            await sdk.get<GetRRUserAccountResponse>(
                `/integrations/roserocket/users/me`
            ),
        options
    )
}
