import styled from '@emotion/styled'
import { SelectAsync, SelectOption } from 'components/core/Select'
import TextArea from 'components/core/TextArea'
import Button from 'components/core/Button'
import { Plus } from 'lucide-react'
import { removeSpaces } from 'utils/string.utils'
import Card from 'components/composite/Card'

const StyledRRAutomationSettings = styled.div``

const StyledInputRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .inputRow__input {
        width: 28rem;
        min-width: 20rem;
    }
`

export type RRAutomationSettingsProps = {
    isLoading: boolean
    isSubmitting: boolean
    newCarrierDispatchEmail?: { carrierId?: string; email?: string }
    carrierDispatchEmailsMap?: Record<string, string>
    onFetchInitCarrier: (id?: string) => () => Promise<SelectOption[]>
    onFetchCarrierOptions: (searchTerm: string) => Promise<SelectOption[]>
    onAddCarrierDispatchEmail: () => void
    onChangeCarrierDispatchEmail: (carrierId: string, email: string) => void
    onChangeNewCarrierDispatchEmail: (
        carrierId?: string,
        email?: string
    ) => void
}

const RRAutomationSettings = ({
    isLoading,
    isSubmitting,
    newCarrierDispatchEmail,
    carrierDispatchEmailsMap,
    onFetchInitCarrier,
    onFetchCarrierOptions,
    onAddCarrierDispatchEmail,
    onChangeCarrierDispatchEmail,
    onChangeNewCarrierDispatchEmail,
}: RRAutomationSettingsProps) => {
    const _onChangeCarrier = (email: string) => (options: SelectOption[]) => {
        const option = options[0]
        if (!option) {
            return
        }

        onChangeCarrierDispatchEmail(option.value, email)
    }

    const _onChangeEmail = (carrierId: string) => (email: string) => {
        onChangeCarrierDispatchEmail(carrierId, removeSpaces(email))
    }

    const _onChangeNewEmail = (carrierId?: string) => (email: string) => {
        onChangeNewCarrierDispatchEmail(carrierId, removeSpaces(email))
    }

    const _onChangeNewCarrier =
        (email?: string) => (options: SelectOption[]) => {
            const option = options[0]

            onChangeNewCarrierDispatchEmail(
                option?.value,
                removeSpaces(email || '')
            )
        }

    return (
        <StyledRRAutomationSettings>
            <Card
                title="Carrier dispatch emails"
                subtitle="These emails will be used when a check email is
                            prepared for a carrier"
            >
                <div className="card__inputs">
                    {carrierDispatchEmailsMap &&
                        Object.entries(carrierDispatchEmailsMap).map(
                            ([carrierId, email]) => (
                                <StyledInputRow key={carrierId}>
                                    <span className="inputRow__input">
                                        <SelectAsync
                                            isDisabled
                                            placeholder="Search carriers"
                                            onFetchOptions={
                                                onFetchCarrierOptions
                                            }
                                            onFetchInitValues={onFetchInitCarrier(
                                                carrierId
                                            )}
                                            onSelectedOptionsChanged={_onChangeCarrier(
                                                email
                                            )}
                                        />
                                    </span>
                                    <span className="inputRow__input">
                                        <TextArea
                                            value={email}
                                            placeholder="Email"
                                            minRows={1}
                                            onChange={_onChangeEmail(carrierId)}
                                        />
                                    </span>
                                </StyledInputRow>
                            )
                        )}
                    {newCarrierDispatchEmail && (
                        <StyledInputRow key="newCarrierDispatchEmail">
                            <span className="inputRow__input">
                                <SelectAsync
                                    isDisabled={isLoading || isSubmitting}
                                    placeholder="Search carriers"
                                    onFetchOptions={onFetchCarrierOptions}
                                    onSelectedOptionsChanged={_onChangeNewCarrier(
                                        newCarrierDispatchEmail.email
                                    )}
                                />
                            </span>
                            <span className="inputRow__input">
                                <TextArea
                                    value={newCarrierDispatchEmail.email || ''}
                                    placeholder="Email"
                                    minRows={1}
                                    onChange={_onChangeNewEmail(
                                        newCarrierDispatchEmail.carrierId
                                    )}
                                />
                            </span>
                        </StyledInputRow>
                    )}
                </div>
                <div>
                    <Button
                        isDisabled={!!newCarrierDispatchEmail || isSubmitting}
                        variant="secondary"
                        onClick={onAddCarrierDispatchEmail}
                    >
                        <Plus size={16} />
                        Add
                    </Button>
                </div>
            </Card>
        </StyledRRAutomationSettings>
    )
}

export default RRAutomationSettings
