import { useState, useEffect, RefObject, useRef } from 'react'
import debounce from 'lodash/debounce'

export const useDimensions = (): [
    DOMRectReadOnly,
    RefObject<HTMLDivElement>,
] => {
    const targetRef = useRef<HTMLDivElement>(null)
    const [dimensions, setDimensions] = useState<DOMRectReadOnly>(
        {} as DOMRectReadOnly
    )

    useEffect(() => {
        const handleResize = debounce((entries: ResizeObserverEntry[]) => {
            if (entries && entries.length > 0) {
                const newDimensions = entries[0].contentRect
                setDimensions(newDimensions)
            }
        }, 1)

        const resizeObserver = new ResizeObserver((entries) => {
            handleResize(entries)
        })

        if (targetRef.current) {
            resizeObserver.observe(targetRef.current)
        }

        return () => {
            resizeObserver.disconnect()
            handleResize.cancel()
        }
    }, [])

    return [dimensions, targetRef]
}
