import styled from '@emotion/styled'
import { X } from 'lucide-react'
import * as RadixDialog from '@radix-ui/react-dialog'
import IconButton from 'components/core/IconButton'
import Typography from 'components/core/Typography'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import { pxToRem } from 'utils/rem.utils'
import { HEADER_HEIGHT } from './constants/dialog2.constants'
import { Children, cloneElement, isValidElement } from 'react'

const emptyFunc = () => undefined

type DialogSize = 'sm' | 'md' | 'lg' | 'xl'

const StyledDialogOverlay = styled(RadixDialog.Overlay)`
    background-color: #03071280;
    position: fixed;
    inset: 0;
    z-index: 100;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

    @keyframes overlayShow {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`

const StyledDialogContent = styled(RadixDialog.Content)<{ size: DialogSize }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: ${BASE_TOKENS.grey[0]};
    border-radius: 0.8rem;
    box-shadow:
        hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
        hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    transform: translate(-50%, -50%);
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 101;

    @keyframes contentShow {
        from {
            opacity: 0;
            transform: translate(-50%, -48%) scale(0.96);
        }
        to {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
    }

    ${(p) => {
        switch (p.size) {
            case 'sm':
                return `
                    width: 40rem;
                    min-width: 40rem;
                `
            case 'md':
                return `
                    width: 60rem;
                    min-width: 60rem;
                `
            case 'lg':
                return `
                    width: 90rem;
                    min-width: 90rem;
                `
            case 'xl':
                return `
                    width: 120rem;
                    min-width: 120rem;
                    height: 90vh;
                `
            default:
                return `
                    width: 60rem;
                    min-width: 60rem;
                `
        }
    }}
`

const StyledDialogTitle = styled.div`
    height: ${pxToRem(HEADER_HEIGHT)};
    min-height: ${pxToRem(HEADER_HEIGHT)};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    border-bottom: 1px solid ${DESIGN_TOKENS.border.borderInput};
`

type DialogProps = {
    isOpen: boolean
    title: string
    size: DialogSize
    children: React.ReactNode
    onClose: () => void
}

const Dialog = ({
    isOpen,
    title,
    size,
    children,
    onClose = emptyFunc,
}: DialogProps) => {
    const enhancedChildren = Children.map(children, (child) => {
        if (isValidElement(child)) {
            // @ts-ignore
            return cloneElement(child, { size })
        }
        return child
    })

    return (
        <RadixDialog.Root open={isOpen}>
            <RadixDialog.Portal>
                <StyledDialogOverlay />
                <StyledDialogContent
                    size={size}
                    onEscapeKeyDown={onClose}
                    onInteractOutside={onClose}
                >
                    <StyledDialogTitle>
                        <RadixDialog.Title asChild>
                            <Typography color="primary" variant="h2">
                                {title}
                            </Typography>
                        </RadixDialog.Title>
                        <RadixDialog.Close asChild>
                            <IconButton onClick={onClose}>
                                <X size={16} />
                            </IconButton>
                        </RadixDialog.Close>
                    </StyledDialogTitle>
                    {enhancedChildren}
                </StyledDialogContent>
            </RadixDialog.Portal>
        </RadixDialog.Root>
    )
}

export default Dialog
