import { Destination, Order } from 'types/order.types'
import { DetailsRow } from './DetailsRow'
import { DestinationType, OrderChangeProp } from '../orderDetails.types'
import TextInput from 'components/core/TextInput'
import { AddressInputs } from 'components/composite/AddressInputs'
import { SPACING } from 'constants/spacing.constants'
import styled from '@emotion/styled'
import { formatAddress } from 'utils/address.utils'
import { Address } from 'types/address.types'
import TextArea from 'components/core/TextArea'
import { DetailsTimeRangeRow } from './DetailsTimeRangeRow'
import {
    ChangeDateStringPickerValue,
    ChangeDateStringRangePickerValue,
} from 'components/composite/DatePicker/types/datePicker.types'
import Card from 'components/composite/Card'
import Switch from 'components/core/Switch'
import Button from 'components/core/Button'
import IconButton from 'components/core/IconButton'
import { Trash2 } from 'lucide-react'
import { DetailsTimeRow } from './DetailsTimeRow'

const StyledPopoverContent = styled.div`
    padding: ${SPACING.p1};
`

const StyledIconButton = styled(IconButton)`
    padding-left: ${SPACING.p2};
    padding-right: ${SPACING.p2};
`

type OrderDetailsDestinationSectionProps = {
    isEditable: boolean
    hasAddButton?: boolean
    hasDeleteButton?: boolean
    destinationLabel: string
    addressLabel: string
    destination?: Destination
    destinationMutation?: Partial<Destination>
    onClosePopup: (propName: OrderChangeProp) => void
    onChangeDestination: (orderMutation: Partial<Destination>) => void
    onAddDestination: () => void
    onDeleteDestination?: () => void
}

const emptyFunction = () => undefined

export const OrderDetailsDestinationSection = ({
    isEditable,
    hasAddButton,
    hasDeleteButton,
    destinationLabel,
    addressLabel,
    destination,
    destinationMutation,
    onClosePopup,
    onChangeDestination,
    onAddDestination,
    onDeleteDestination = emptyFunction,
}: OrderDetailsDestinationSectionProps) => {
    const getMutatedDestination = (newDestination: Partial<Destination>) => {
        return {
            ...destination,
            ...newDestination,
        }
    }

    const _onClosePopup = (propName: OrderChangeProp) => () => {
        onClosePopup(propName)
    }

    const _onChangeName = (name: string) => {
        onChangeDestination(getMutatedDestination({ name }))
    }

    const _onChangeAddress = (address: Partial<Address>) => {
        onChangeDestination(getMutatedDestination(address))
    }

    const _onChangeTimeWindow = (
        dateStringRange: ChangeDateStringRangePickerValue
    ) => {
        onChangeDestination(
            getMutatedDestination({
                arrivalStartAt: dateStringRange?.from,
                arrivalEndAt: dateStringRange?.to,
            })
        )
    }

    const _onChangeTime = (dateString: ChangeDateStringPickerValue) => {
        onChangeDestination(
            getMutatedDestination({
                arrivalStartAt: dateString,
            })
        )
    }

    const _onChangeReferenceNumber = (referenceNumber: string) => {
        onChangeDestination(
            getMutatedDestination({
                referenceNumber,
            })
        )
    }

    const _onChangeSpecialInstructions = (specialInstructions: string) => {
        onChangeDestination(
            getMutatedDestination({
                specialInstructions,
            })
        )
    }

    const _onChangeIsAppointmentRequired = (isAppointmentRequired: boolean) => {
        onChangeDestination(
            getMutatedDestination({
                isAppointmentRequired,
            })
        )
    }

    return (
        <>
            <DetailsRow
                isEditable={isEditable}
                caption={addressLabel}
                valueText={destination?.name}
                onClosePopup={_onClosePopup('address')}
            >
                <TextInput
                    placeholder={`${addressLabel} name`}
                    value={destinationMutation?.name}
                    onChange={_onChangeName}
                />
            </DetailsRow>
            <DetailsRow
                isEditable={isEditable}
                side="left"
                align="start"
                caption={destinationLabel + ' address'}
                valueText={formatAddress(destination)}
                onClosePopup={_onClosePopup('address')}
            >
                <StyledPopoverContent>
                    <AddressInputs
                        address={destinationMutation}
                        onChangeAddress={_onChangeAddress}
                    />
                </StyledPopoverContent>
            </DetailsRow>
            <DetailsRow
                isEditable={isEditable}
                caption="Appointment"
                valueText={destination?.isAppointmentRequired ? 'Yes' : 'No'}
                onClosePopup={_onClosePopup('isAppointmentRequired')}
            >
                <Card
                    title="Is appointment required"
                    HeaderInput={
                        <Switch
                            isChecked={
                                !!destinationMutation?.isAppointmentRequired
                            }
                            onChange={_onChangeIsAppointmentRequired}
                        />
                    }
                />
            </DetailsRow>
            {destination?.isAppointmentRequired ? (
                <DetailsTimeRow
                    isEditable={isEditable}
                    caption={destinationLabel + ' date'}
                    value={destinationMutation?.arrivalStartAt}
                    onClosePopup={_onClosePopup('arrivalStartAt')}
                    onChange={(_, dateString) => {
                        _onChangeTime(dateString)
                    }}
                />
            ) : (
                <DetailsTimeRangeRow
                    isEditable={isEditable}
                    caption={destinationLabel + ' date'}
                    value={{
                        from: destinationMutation?.arrivalStartAt,
                        to: destinationMutation?.arrivalEndAt,
                    }}
                    onClosePopup={_onClosePopup('arrivalStartAt')}
                    onChange={(_, dateStringRange) => {
                        _onChangeTimeWindow(dateStringRange)
                    }}
                />
            )}
            <DetailsRow
                isEditable={isEditable}
                caption="Reference #"
                valueText={destination?.referenceNumber || ''}
                onClosePopup={_onClosePopup('referenceNumber')}
            >
                <TextInput
                    placeholder="Reference number"
                    value={destinationMutation?.referenceNumber || ''}
                    onChange={_onChangeReferenceNumber}
                />
            </DetailsRow>
            <DetailsRow
                isEditable={isEditable}
                caption="Instructions"
                valueText={destination?.specialInstructions || ''}
                onClosePopup={_onClosePopup('specialInstructions')}
            >
                <TextArea
                    minRows={5}
                    maxRows={18}
                    value={destinationMutation?.specialInstructions || ''}
                    onChange={_onChangeSpecialInstructions}
                />
            </DetailsRow>
            {(hasAddButton || hasDeleteButton) && (
                <div className="flex justify-end w-full">
                    {hasAddButton && (
                        <Button variant="ghost" onClick={onAddDestination}>
                            + Add {destinationLabel.toLowerCase()}
                        </Button>
                    )}
                    {hasDeleteButton && (
                        <StyledIconButton onClick={onDeleteDestination}>
                            <Trash2 size={SPACING.p2} />
                        </StyledIconButton>
                    )}
                </div>
            )}
        </>
    )
}
