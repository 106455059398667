import { CurrencyType } from 'types/currency.types'

export const stringToCurrency = (
    value: number | undefined,
    currency: CurrencyType = 'usd'
) => {
    return value?.toLocaleString('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })
}

export const stringToPercent = (value: number | undefined) => {
    return `${((value ?? 0) * 100).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    })}%`
}
