import * as RadixCheckbox from '@radix-ui/react-checkbox'
import styled from '@emotion/styled'
import { Check } from 'lucide-react'
import { v4 as uuidv4 } from 'uuid'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import { useRef } from 'react'
import Typography from 'components/core/Typography'

const emptyFunc = () => undefined

const StyledCheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.8rem;
`

const StyledCheckbox = styled(RadixCheckbox.Root)`
    font: inherit;
    cursor: pointer;

    background-color: ${BASE_TOKENS.grey[0]};
    min-width: 1.6rem;
    min-height: 1.6rem;
    max-width: 1.6rem;
    max-height: 1.6rem;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid ${DESIGN_TOKENS.border.borderPrimary};

    transition: background-color 0.3s ease;

    &:hover {
        background-color: ${BASE_TOKENS.grey[100]};
    }

    span {
        display: flex;
        max-width: 1.6rem;
        max-height: 1.6rem;
    }
`

const StyledIndicator = styled(RadixCheckbox.Indicator)`
    margin-top: 0.1rem;
`

type CheckboxProps = {
    isChecked: boolean
    label?: string
    className?: string
    onChange: (isChecked: boolean) => void
}

const Checkbox = ({
    isChecked,
    label,
    className,
    onChange = emptyFunc,
}: CheckboxProps) => {
    const id = useRef(uuidv4())

    return (
        <StyledCheckboxContainer>
            <StyledCheckbox
                checked={isChecked}
                id={id.current}
                className={className}
                onCheckedChange={onChange}
            >
                <StyledIndicator>
                    <Check width={12} height={12} />
                </StyledIndicator>
            </StyledCheckbox>
            {label && (
                <label htmlFor={id.current}>
                    <Typography variant="body2" color="secondary">
                        {label}
                    </Typography>
                </label>
            )}
        </StyledCheckboxContainer>
    )
}

export default Checkbox
