import { useState } from 'react'
import { useRRAutomationSettings } from 'integrations/roserocket/components/RRAutomationSettings/hooks/useRRAutomationSettings'
import AutomationSettings from './components/AutomationSettings'
import { showToastError, showToastSuccess } from 'hooks/useToast'
import { useUpdateMyOrgSettings } from 'mutations/orgSettings.mutations'
import { useGetMyOrgSettings } from 'queries/orgSettings.queries'
import { useGetMyUsers } from 'queries/user.queries'

const AutomationSettingsContainer = () => {
    const [isAutoSendEnabled, setIsAutoSendEnabled] = useState(false)
    const [
        isAutoReplyOnPositiveResponseEnabled,
        setIsAutoReplyOnPositiveResponseEnabled,
    ] = useState(false)
    const [userIdAutoSendAs, setUserIdAutoSendAs] = useState<string>()

    const { isLoading: isLoadingUsers, data } = useGetMyUsers()
    const {
        isLoading: isSubmittingSettings,
        mutateAsync: updateMyOrgSettings,
    } = useUpdateMyOrgSettings()
    const { isLoading: isLoadingSettings } = useGetMyOrgSettings({
        onSuccess: (data) => {
            setIsAutoSendEnabled(
                !!data?.data?.settings?.checkEmails?.isAutoSendEnabled
            )
            setIsAutoReplyOnPositiveResponseEnabled(
                !!data.data?.settings?.checkEmails
                    ?.isAutoReplyOnPositiveResponseEnabled
            )
            setUserIdAutoSendAs(
                data.data?.settings?.checkEmails?.userIdAutoSendAs
            )
        },
    })

    const {
        isLoading: isLoadingRRSettings,
        isSubmitting: isSubmittingRRSettings,
        newCarrierDispatchEmail,
        carrierDispatchEmailsMap,
        onSaveSettings: onSaveRRSettings,
        onFetchInitCarrier,
        onFetchCarrierOptions,
        onAddCarrierDispatchEmail,
        onChangeCarrierDispatchEmail,
        onChangeNewCarrierDispatchEmail,
    } = useRRAutomationSettings()

    const autoSendAsOptions =
        data?.data?.users?.map((user) => {
            return {
                value: user.id,
                label: `${user.firstName} ${user.lastName}`,
            }
        }) || []

    const _onSave = async () => {
        try {
            await updateMyOrgSettings({
                checkEmails: {
                    isAutoSendEnabled,
                    isAutoReplyOnPositiveResponseEnabled,
                    userIdAutoSendAs,
                },
            })
            await onSaveRRSettings()

            showToastSuccess('Settings saved')
        } catch (error) {
            showToastError('Error saving settings')
        }
    }

    return (
        <AutomationSettings
            isLoading={
                isLoadingUsers || isLoadingSettings || isLoadingRRSettings
            }
            isSubmitting={isSubmittingSettings || isSubmittingRRSettings}
            isAutoSendEnabled={isAutoSendEnabled}
            isAutoReplyOnPositiveResponseEnabled={
                isAutoReplyOnPositiveResponseEnabled
            }
            newCarrierDispatchEmail={newCarrierDispatchEmail}
            carrierDispatchEmailsMap={carrierDispatchEmailsMap}
            userIdAutoSendAs={userIdAutoSendAs}
            autoSendAsOptions={autoSendAsOptions}
            onSaveSettings={_onSave}
            onFetchInitCarrier={onFetchInitCarrier}
            onFetchCarrierOptions={onFetchCarrierOptions}
            onChangeUserIdAutoSendAs={setUserIdAutoSendAs}
            onAddCarrierDispatchEmail={onAddCarrierDispatchEmail}
            onChangeIsAutoSendEnabled={setIsAutoSendEnabled}
            onChangeCarrierDispatchEmail={onChangeCarrierDispatchEmail}
            onChangeNewCarrierDispatchEmail={onChangeNewCarrierDispatchEmail}
            onChangeIsAutoReplyOnPositiveResponseEnabled={
                setIsAutoReplyOnPositiveResponseEnabled
            }
        />
    )
}

export default AutomationSettingsContainer
