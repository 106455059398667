import styled from '@emotion/styled'
import Button from 'components/core/Button'
import Typography from 'components/core/Typography'
import Dialog, { DialogActions, DialogContent } from 'components/core/Dialog'

const emptyFunc = () => undefined

const StyledProcessReceivablesDialog = styled.div`
    width: 100%;
`

const StyledList = styled.ul`
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
`

const StyledListItem = styled.li`
    padding-top: 0;
    padding-bottom: 0;
`

type ProcessReceivablesDialogProps = {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
}

const ProcessReceivablesDialog = ({
    isOpen,
    onClose = emptyFunc,
    onConfirm = emptyFunc,
}: ProcessReceivablesDialogProps) => {
    return (
        <Dialog isOpen={isOpen} size="md" title="Process" onClose={onClose}>
            <DialogContent>
                <StyledProcessReceivablesDialog>
                    <Typography color="primary" variant="body1">
                        This action will make changes to your system, including:
                    </Typography>
                    <StyledList>
                        <StyledListItem>
                            <Typography color="primary" variant="body1">
                                Send an invoice to your customer, via email
                            </Typography>
                        </StyledListItem>
                        <StyledListItem>
                            <Typography color="primary" variant="body1">
                                Export your bill to your accounting system
                            </Typography>
                        </StyledListItem>
                    </StyledList>
                    <Typography color="primary" variant="body1">
                        <strong>Note:</strong> Receivables in "Requires review"
                        status must be invoiced manually. Invoicing in Cartage
                        will simply update their status to "Manual invoiced".
                    </Typography>
                </StyledProcessReceivablesDialog>
            </DialogContent>
            <DialogActions>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={onConfirm}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProcessReceivablesDialog
