import { ReactElement } from 'react'
import { Check, CircleAlert, Mail } from 'lucide-react'
import Typography from 'components/core/Typography'
import Log from './Log'
import { EmailReceivedAutomationEvent } from 'types/automationEvent.types'
import { getTimeFromISOString } from 'utils/date.utils'
import styled from '@emotion/styled'
import Email from './Email'
import { BASE_TOKENS } from 'constants/color.constants'

const StyledEmailReceivedEvent = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
`

type EmailReceivedEventProps = {
    event: EmailReceivedAutomationEvent
}

const EmailReceivedEvent = ({ event }: EmailReceivedEventProps) => {
    const { createdAt, data } = event
    const { subject, fromEmail, htmlContent, sentiment, toEmails } = data

    let Icon: ReactElement | undefined
    if (sentiment) {
        Icon =
            sentiment === 'positive' ? (
                <Check size={24} color={BASE_TOKENS.green[600]} />
            ) : (
                <CircleAlert size={20} color={BASE_TOKENS.orange[600]} />
            )
    }

    return (
        <StyledEmailReceivedEvent>
            <Log>
                <span className="log__icon">
                    <Mail size={12} />
                </span>
                <Typography color="primary" variant="body2">
                    We received a response
                </Typography>
                <Typography color="secondary" variant="body2">
                    {getTimeFromISOString(createdAt)}
                </Typography>
            </Log>
            <Email
                isOutbound={false}
                subject={subject}
                toEmail={toEmails[0]}
                fromEmail={fromEmail}
                htmlContent={htmlContent}
                Icon={Icon}
            />
        </StyledEmailReceivedEvent>
    )
}

export default EmailReceivedEvent
