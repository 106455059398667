import { MapPinCheck } from 'lucide-react'
import OrderEventControl from './OrderEventControl'

type OrderDeliveredEventProps = {
    deliveredAt: string
}

const OrderDeliveredEvent = ({ deliveredAt }: OrderDeliveredEventProps) => {
    return (
        <OrderEventControl
            title="Order delivered"
            timestamp={deliveredAt}
            Icon={MapPinCheck}
        />
    )
}

export default OrderDeliveredEvent
