import AnchorTag from 'components/core/AnchorTag'
import Typography from 'components/core/Typography'
import { useMyOrg } from 'hooks/useMyOrg'

type OrderCellProps = {
    orderId?: string
    orderNumber?: string
}

const OrderCell = ({ orderId, orderNumber }: OrderCellProps) => {
    const { org } = useMyOrg()

    return orderNumber ? (
        <AnchorTag
            rel="noopener noreferrer"
            href={`https://${org.rrSubdomain}.roserocket.com/#/ops/orders/${orderId}`}
            color="primary"
            target="_blank"
        >
            {orderNumber}
        </AnchorTag>
    ) : (
        <Typography color="primary" variant="body2">
            --
        </Typography>
    )
}

export default OrderCell
