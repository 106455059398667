import { Pen } from 'lucide-react'
import Typography from 'components/core/Typography'
import Log from './Log'
import { StatusChangedAutomationEvent } from 'types/automationEvent.types'
import { getTimeFromISOString } from 'utils/date.utils'
import styled from '@emotion/styled'
import { AUTOMATION_STATUS_LABELS } from 'modules/Automations/automations.constants'
import { AutomationStatus } from 'types/automation.types'

const StyledStatusChangedEvent = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
`

type StatusChangedEventProps = {
    event: StatusChangedAutomationEvent
}

const StatusChangedEvent = ({ event }: StatusChangedEventProps) => {
    const { data, createdAt } = event
    const { status } = data

    return (
        <StyledStatusChangedEvent>
            <Log>
                <span className="log__icon">
                    <Pen size={12} />
                </span>
                <Typography color="primary" variant="body2">
                    We updated task status to "
                    {AUTOMATION_STATUS_LABELS[status as AutomationStatus]}"
                </Typography>
                <Typography color="secondary" variant="body2">
                    {getTimeFromISOString(createdAt)}
                </Typography>
            </Log>
        </StyledStatusChangedEvent>
    )
}

export default StatusChangedEvent
