import { BASE_TOKENS } from 'constants/color.constants'
import { Payable, PayableStatus } from 'types/payable.types'

export const DERIVED_STATUS_LABELS: { [key in Payable['status']]: string } = {
    unparsed: 'Loading',
    exactMatch: 'Exact match',
    underPriced: 'Under price',
    withinRange: 'Within range',
    carrierMismatch: 'Carrier mismatch',
    noPOD: 'No POD',
    requiresReview: 'Review',
    noMatch: 'No match',
    duplicate: 'Duplicate',
    alreadyProcessed: 'Already processed',
    error: 'Error',
    processed: 'Processed',
    manuallyProcessed: 'Manual processed',
}

export const PAYABLE_STATUS_GROUP_IDS = {
    process: 'process',
    review: 'review',
    processed: 'processed',
    manuallyProcessed: 'manuallyProcessed',
}

export const PAYABLE_STATUS_GROUP_LABELS = {
    [PAYABLE_STATUS_GROUP_IDS.process]: 'Process',
    [PAYABLE_STATUS_GROUP_IDS.review]: 'Review',
    [PAYABLE_STATUS_GROUP_IDS.processed]: 'Processed',
    [PAYABLE_STATUS_GROUP_IDS.manuallyProcessed]: 'Manual',
}

export const PAYABLE_STATUS_GROUPS: { [key: string]: PayableStatus[] } = {
    [PAYABLE_STATUS_GROUP_IDS.process]: [
        'unparsed',
        'exactMatch',
        'underPriced',
        'withinRange',
    ],
    [PAYABLE_STATUS_GROUP_IDS.review]: [
        'requiresReview',
        'noMatch',
        'carrierMismatch',
        'noPOD',
        'duplicate',
        'alreadyProcessed',
        'error',
    ],
    [PAYABLE_STATUS_GROUP_IDS.processed]: ['processed'],
    [PAYABLE_STATUS_GROUP_IDS.manuallyProcessed]: ['manuallyProcessed'],
}

export const STATUS_BACKGROUND_COLORS: { [key in PayableStatus]: string } = {
    unparsed: BASE_TOKENS.grey[50],
    exactMatch: BASE_TOKENS.blue[50],
    underPriced: BASE_TOKENS.blue[50],
    withinRange: BASE_TOKENS.blue[50],
    requiresReview: BASE_TOKENS.orange[50],
    carrierMismatch: BASE_TOKENS.orange[50],
    noPOD: BASE_TOKENS.orange[50],
    duplicate: BASE_TOKENS.orange[50],
    alreadyProcessed: BASE_TOKENS.orange[50],
    noMatch: BASE_TOKENS.red[50],
    error: BASE_TOKENS.red[50],
    processed: BASE_TOKENS.green[50],
    manuallyProcessed: BASE_TOKENS.green[50],
}

export const STATUS_BORDER_COLORS: { [key in PayableStatus]: string } = {
    unparsed: BASE_TOKENS.grey[300],
    exactMatch: BASE_TOKENS.blue[300],
    underPriced: BASE_TOKENS.blue[300],
    withinRange: BASE_TOKENS.blue[300],
    requiresReview: BASE_TOKENS.orange[300],
    carrierMismatch: BASE_TOKENS.orange[300],
    noPOD: BASE_TOKENS.orange[300],
    duplicate: BASE_TOKENS.orange[300],
    alreadyProcessed: BASE_TOKENS.orange[300],
    noMatch: BASE_TOKENS.red[300],
    error: BASE_TOKENS.red[300],
    processed: '#16A34A80',
    manuallyProcessed: '#16A34A80',
}

export const STATUS_TEXT_COLORS: { [key in PayableStatus]: string } = {
    unparsed: BASE_TOKENS.grey[900],
    exactMatch: BASE_TOKENS.blue[900],
    underPriced: BASE_TOKENS.blue[900],
    withinRange: BASE_TOKENS.blue[900],
    requiresReview: BASE_TOKENS.orange[900],
    carrierMismatch: BASE_TOKENS.orange[900],
    noPOD: BASE_TOKENS.orange[900],
    duplicate: BASE_TOKENS.orange[900],
    alreadyProcessed: BASE_TOKENS.orange[900],
    noMatch: BASE_TOKENS.red[900],
    error: BASE_TOKENS.red[900],
    processed: BASE_TOKENS.green[900],
    manuallyProcessed: BASE_TOKENS.green[900],
}
