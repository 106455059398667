export const isDevelopmentEnv = (): boolean => {
    return process.env.NODE_ENV === 'development'
}

export const isTestEnv = (): boolean => {
    return process.env.NODE_ENV === 'test'
}

export const isProductionEnv = (): boolean => {
    return !isDevelopmentEnv() && !isTestEnv()
}

export const getPublicUrl = (url: string): string => {
    return `${process.env.REACT_APP_URL}${url}`
}
