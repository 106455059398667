import styled from '@emotion/styled'
import { LucideProps, Trash2 } from 'lucide-react'
import Popover from 'components/core/Popover'
import 'react-day-picker/style.css'
import { JSXElementConstructor } from 'react'
import Separator from 'components/core/Separator'
import { formatDateTimeRangeString } from 'utils/date.utils'
import {
    changeDatePreservingTime,
    convertDateToStringNoTz,
} from './utils/datePicker.utils'
import { StyledDayPicker } from './components/StyledDayPicker'
import { Chevron } from './components/Chevron'
import { StyledLabel } from './components/StyledLabel'
import { SPACING } from 'constants/spacing.constants'
import Column from 'components/core/Column'
import { TimePicker } from '../TimePicker'
import { DateTimeButton } from './components/DateTimeButton'
import IconButton from 'components/core/IconButton'
import Row from 'components/core/Row'
import {
    ChangeDatePickerValue,
    ChangeDateStringPickerValue,
    DatePickerValue,
    DefaultDatePickerValue,
} from './types/datePicker.types'

const StyledDatePickerContainer = styled(Column)<{ isCellStyle: boolean }>`
    row-gap: ${SPACING.sm};

    ${(p) => p.isCellStyle && `height: 100%;`}
`

const StyledPopoverTrigger = styled.button`
    width: 100%;
`

const StyledPopoverFooter = styled(Row)`
    justify-content: space-between;
`

const StyledIconButton = styled(IconButton)`
    padding-left: ${SPACING.p2};
    padding-right: ${SPACING.p2};
`

const emptyFunc = () => undefined

type DatePickerProps = {
    isEditable?: boolean
    isCellStyle?: boolean
    hasTime?: boolean
    label?: string
    value?: DatePickerValue
    placeholder?: string
    align?: 'center' | 'end' | 'start'
    defaultDateTimeLabelFormat?: string
    Icon?: JSXElementConstructor<LucideProps>
    ButtonComponent?: JSXElementConstructor<any>
    onChange: (
        date: ChangeDatePickerValue,
        dateString: ChangeDateStringPickerValue
    ) => void
    onClose?: () => void
}

export const DatePicker = ({
    isEditable,
    isCellStyle,
    hasTime,
    label,
    value,
    placeholder,
    align = 'center',
    Icon,
    ButtonComponent,
    onChange,
    onClose = emptyFunc,
}: DatePickerProps) => {
    const valueDate = typeof value === 'string' ? new Date(value) : value

    const dateText = formatDateTimeRangeString({
        from: value,
        hasTime,
    })

    const _onChangeTime = (date: DefaultDatePickerValue) => {
        if (!date) return

        onChange(date, convertDateToStringNoTz(date))
    }

    /**
     * carry over the current time when a user clicks a new day
     * instead of resetting to 00:00
     */
    const _onChangeDate = (newDate: DefaultDatePickerValue) => {
        if (!newDate) return
        if (!valueDate) {
            onChange(newDate, convertDateToStringNoTz(newDate))
            return
        }

        const newDateFull = changeDatePreservingTime(valueDate, newDate)

        onChange(newDateFull, convertDateToStringNoTz(newDateFull))
    }

    const _onClearDate = () => {
        onChange(null, null)
    }

    if (!isEditable) {
        return (
            <StyledDatePickerContainer isCellStyle={!!isCellStyle}>
                {label && <StyledLabel>{label}</StyledLabel>}
                <StyledPopoverTrigger>
                    <DateTimeButton
                        isDisabled
                        placeholder={placeholder}
                        dateText={dateText}
                        Icon={Icon}
                        ButtonComponent={ButtonComponent}
                    />
                </StyledPopoverTrigger>
            </StyledDatePickerContainer>
        )
    }

    return (
        <StyledDatePickerContainer isCellStyle={!!isCellStyle}>
            {label && <StyledLabel>{label}</StyledLabel>}
            <Popover
                align={align}
                onClose={onClose}
                trigger={
                    <StyledPopoverTrigger>
                        <DateTimeButton
                            placeholder={placeholder}
                            dateText={dateText}
                            Icon={Icon}
                            ButtonComponent={ButtonComponent}
                        />
                    </StyledPopoverTrigger>
                }
            >
                <StyledDayPicker
                    mode="single"
                    selected={valueDate ?? undefined}
                    components={{ Chevron }}
                    onDayClick={_onChangeDate}
                />
                {(hasTime || !!valueDate) && (
                    <>
                        <Separator />
                        <StyledPopoverFooter>
                            {hasTime && (
                                <TimePicker
                                    date={valueDate || undefined}
                                    onChange={_onChangeTime}
                                />
                            )}
                            {valueDate && (
                                <StyledIconButton onClick={_onClearDate}>
                                    <Trash2 size={SPACING.p2} />
                                </StyledIconButton>
                            )}
                        </StyledPopoverFooter>
                    </>
                )}
            </Popover>
        </StyledDatePickerContainer>
    )
}
