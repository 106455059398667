import Board from 'components/composite/Board/Board'
import Button from 'components/core/Button'
import Typography from 'components/core/Typography'
import { Payable } from 'types/payable.types'
import DropdownButton from 'components/core/DropdownButton'
import UploadFilesDialog from 'dialogs/UploadFilesDialog'
import Menu, { MenuItem } from 'components/core/Menu'
import ProcessPayablesDialog from 'dialogs/ProcessPayablesDialog'
import { tableColumns } from '../payables.columns'
import {
    canPayableBeMarkedAsProcessed,
    canPayableBeProcessed,
} from '../utils/payable.utils'
import {
    PAYABLE_STATUS_GROUP_IDS,
    PAYABLE_STATUS_GROUP_LABELS,
} from '../payables.constants'

const emptyFunc = () => undefined

const getTabLabel = ({
    label,
    count,
}: {
    label: string
    count?: number
}): string => {
    return `${label}${count !== undefined ? ` (${count})` : ''}`
}

type PayablesProps = {
    isLoading: boolean
    isLastPage: boolean
    isFirstPage: boolean
    isRefetching: boolean
    isSubmitting: boolean
    isUploadDialogOpen: boolean
    isProcessDialogOpen: boolean
    limit: number
    offset: number
    numPayables: number
    selectedTabId: string
    statusCounts?: { [key in string]: number }
    payables: Payable[]
    queuedPayables: Payable[]
    selectedPayableIds: string[]
    onNextPage: () => void
    onClickTab: (tabId: string) => void
    onRefetchRows: () => void
    onDownloadCSV: () => void
    onPreviousPage: () => void
    onUploadPayables: (files: File[]) => Promise<void>
    onProcessPayables: () => void
    onSelectAllPayables: (isChecked: boolean) => void
    onToggleUploadDialogOpen: (isOpen: boolean) => void
    onToggleProcessDialogOpen: (isOpen: boolean) => void
    onSelectPayable: ({
        isChecked,
        id,
        index,
    }: {
        isChecked: boolean
        id: string
        index: number
    }) => void
}

const Payables = ({
    isLoading,
    isLastPage,
    isFirstPage,
    isRefetching,
    isSubmitting,
    isUploadDialogOpen,
    isProcessDialogOpen,
    limit,
    offset,
    numPayables,
    selectedTabId,
    statusCounts,
    payables,
    queuedPayables,
    selectedPayableIds,
    onNextPage = emptyFunc,
    onClickTab = emptyFunc,
    onRefetchRows = emptyFunc,
    onDownloadCSV = emptyFunc,
    onPreviousPage = emptyFunc,
    onSelectPayable = emptyFunc,
    onUploadPayables,
    onProcessPayables = emptyFunc,
    onSelectAllPayables = emptyFunc,
    onToggleUploadDialogOpen = emptyFunc,
    onToggleProcessDialogOpen = emptyFunc,
}: PayablesProps) => {
    const _onToggleUploadDialogOpen = (isOpen: boolean) => () =>
        onToggleUploadDialogOpen(isOpen)

    const _onToggleProcessDialogOpen = (isOpen: boolean) => () =>
        onToggleProcessDialogOpen(isOpen)

    const tabs = [
        {
            id: PAYABLE_STATUS_GROUP_IDS.process,
            label: getTabLabel({
                label: PAYABLE_STATUS_GROUP_LABELS[
                    PAYABLE_STATUS_GROUP_IDS.process
                ],
                count: statusCounts?.[PAYABLE_STATUS_GROUP_IDS.process],
            }),
        },
        {
            id: PAYABLE_STATUS_GROUP_IDS.review,
            label: getTabLabel({
                label: PAYABLE_STATUS_GROUP_LABELS[
                    PAYABLE_STATUS_GROUP_IDS.review
                ],
                count: statusCounts?.[PAYABLE_STATUS_GROUP_IDS.review],
            }),
        },
        {
            id: PAYABLE_STATUS_GROUP_IDS.processed,
            label: getTabLabel({
                label: PAYABLE_STATUS_GROUP_LABELS[
                    PAYABLE_STATUS_GROUP_IDS.processed
                ],
                count: statusCounts?.[PAYABLE_STATUS_GROUP_IDS.processed],
            }),
        },
        {
            id: PAYABLE_STATUS_GROUP_IDS.manuallyProcessed,
            label: getTabLabel({
                label: PAYABLE_STATUS_GROUP_LABELS[
                    PAYABLE_STATUS_GROUP_IDS.manuallyProcessed
                ],
                count: statusCounts?.[
                    PAYABLE_STATUS_GROUP_IDS.manuallyProcessed
                ],
            }),
        },
    ]

    const buttons = [
        <Button
            key="upload"
            variant="secondary"
            onClick={_onToggleUploadDialogOpen(true)}
        >
            Upload
        </Button>,
        <Button
            isDisabled={!selectedPayableIds.length}
            key="download"
            variant="secondary"
            onClick={onDownloadCSV}
        >
            Download
        </Button>,
        <Button
            isDisabled={
                !selectedPayableIds.some(
                    (id) =>
                        canPayableBeProcessed(
                            payables.find((payable) => payable.id === id)
                                ?.status
                        ) ||
                        canPayableBeMarkedAsProcessed(
                            payables.find((payable) => payable.id === id)
                                ?.status
                        )
                )
            }
            key="process"
            variant="primary"
            onClick={_onToggleProcessDialogOpen(true)}
        >
            Process
        </Button>,
    ]

    const subButtons = [
        <Menu
            isDisabled={!queuedPayables.length}
            trigger={
                <DropdownButton>
                    {getTabLabel({
                        label: 'Processing',
                        count: queuedPayables?.length,
                    })}
                </DropdownButton>
            }
        >
            {queuedPayables.map((p) => {
                return (
                    <MenuItem isClickable={false} key={p.id}>
                        <Typography color="primary" variant="body2">
                            {p.invoiceNumber}
                        </Typography>
                    </MenuItem>
                )
            })}
        </Menu>,
    ]

    return (
        <>
            <UploadFilesDialog
                isOpen={isUploadDialogOpen}
                onClose={_onToggleUploadDialogOpen(false)}
                onUpload={onUploadPayables}
            />
            <ProcessPayablesDialog
                isOpen={isProcessDialogOpen}
                onClose={_onToggleProcessDialogOpen(false)}
                onConfirm={onProcessPayables}
            />
            <Board
                isLoading={isLoading}
                isLastPage={isLastPage}
                isFirstPage={isFirstPage}
                isRefetching={isRefetching}
                isSubmitting={isSubmitting}
                limit={limit}
                offset={offset}
                header="Payables"
                numRows={numPayables}
                selectedTabId={selectedTabId}
                rows={payables}
                tabs={tabs}
                columns={tableColumns}
                buttons={buttons}
                subButtons={subButtons}
                selectedRowIds={selectedPayableIds}
                onNextPage={onNextPage}
                onClickTab={onClickTab}
                onSelectRow={onSelectPayable}
                onRefetchRows={onRefetchRows}
                onPreviousPage={onPreviousPage}
                onSelectAllRows={onSelectAllPayables}
            />
        </>
    )
}

export default Payables
