import styled from '@emotion/styled'
import * as RadixMenubar from '@radix-ui/react-menubar'
import { BASE_TOKENS } from 'constants/color.constants'

const emptyFunc = () => undefined

const StyledMenuItem = styled(RadixMenubar.Item)``

type MenuItemProps = {
    isClickable?: boolean
    children: React.ReactNode
    onClick?: () => void
}

const MenuItem = ({
    isClickable = true,
    children,
    onClick = emptyFunc,
}: MenuItemProps) => {
    const element = isClickable ? 'button' : 'div'

    const StyledItem = styled(element)`
        width: 100%;
        height: 3.2rem;
        margin: 0;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
        border: none;
        background-color: ${BASE_TOKENS.grey[0]};
        display: flex;
        justify-content: flex-start;
        align-items: center;

        transition: background-color 0.3s ease;

        &:hover {
            outline: none;
            background-color: ${BASE_TOKENS.grey[100]};
        }

        ${isClickable &&
        `
        cursor: pointer;
            
        `}
    `

    return (
        <StyledMenuItem asChild onClick={onClick}>
            <StyledItem>{children}</StyledItem>
        </StyledMenuItem>
    )
}

export default MenuItem
