import Board from 'components/composite/Board/Board'
import Button from 'components/core/Button'
import { Order } from 'types/order.types'
import { tableColumns } from '../orders.columns'

const emptyFunc = () => undefined

type OrdersProps = {
    isLoading: boolean
    isLastPage: boolean
    isFirstPage: boolean
    isRefetching: boolean
    limit: number
    offset: number
    numOrders: number
    orders: Order[]
    selectedOrderIds: string[]
    onNextPage: () => void
    onRefetchRows: () => void
    onPreviousPage: () => void
    onSelectAllOrders: (isChecked: boolean) => void
    onSelectOrder: ({
        isChecked,
        id,
        index,
    }: {
        isChecked: boolean
        id: string
        index: number
    }) => void
    onCreateOrder: () => void
}

const Orders = ({
    isLoading,
    isLastPage,
    isFirstPage,
    isRefetching,
    limit,
    offset,
    numOrders,
    orders,
    selectedOrderIds,
    onNextPage = emptyFunc,
    onRefetchRows = emptyFunc,
    onSelectOrder = emptyFunc,
    onPreviousPage = emptyFunc,
    onSelectAllOrders = emptyFunc,
    onCreateOrder = emptyFunc,
}: OrdersProps) => {
    const tabs: [] = []

    const buttons = [
        <Button key="create" variant="primary" onClick={onCreateOrder}>
            Create
        </Button>,
    ]

    const subButtons: [] = []

    return (
        <Board
            isLoading={isLoading}
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
            isRefetching={isRefetching}
            limit={limit}
            offset={offset}
            header="Orders"
            numRows={numOrders}
            rows={orders}
            tabs={tabs}
            columns={tableColumns}
            buttons={buttons}
            subButtons={subButtons}
            selectedRowIds={selectedOrderIds}
            onNextPage={onNextPage}
            onSelectRow={onSelectOrder}
            onRefetchRows={onRefetchRows}
            onPreviousPage={onPreviousPage}
            onSelectAllRows={onSelectAllOrders}
        />
    )
}

export default Orders
