import { JSXElementConstructor, forwardRef } from 'react'
import styled from '@emotion/styled'
import { LucideProps } from 'lucide-react'
import {
    SourceInfo,
    NumericFormat,
    NumberFormatValues,
} from 'react-number-format'
import Typography from 'components/core/Typography'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'

const emptyFunc = () => undefined

const StyledNumberInputContainer = styled.div<{ isCellStyle: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.6rem;

    ${(p) =>
        p.isCellStyle &&
        `
            height: 100%;
            justify-content: center;
        `}

    .container__input {
        display: flex;
        position: relative;
        align-items: center;

        .container__input__icon {
            margin-top: 0.3rem;
            position: absolute;
            left: 1.2rem;
            color: ${DESIGN_TOKENS.text.textMutedForeground};
        }
    }
`

const StyledLabel = styled.label`
    color: ${DESIGN_TOKENS.text.textForeground};
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
`

const StyledNumberInput = styled(NumericFormat)<{
    hasIcon: boolean
    isCellStyle: boolean
}>`
    width: 100%;
    height: 4rem;
    padding-left: ${(p) => (p.hasIcon ? '3.4rem' : '1.2rem')};
    padding-right: 1.2rem;
    padding-bottom: 0.1rem;

    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    color: ${DESIGN_TOKENS.text.textForeground};

    border-radius: 0.6rem;
    border: 0.1rem solid ${DESIGN_TOKENS.input};

    appearance: textfield;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:disabled {
        color: ${DESIGN_TOKENS.mutedForeground};
        background-color: ${BASE_TOKENS.grey[100]};
    }

    &::placeholder {
        color: ${DESIGN_TOKENS.mutedForeground};
    }

    &:focus-visible {
        outline: none;
        box-shadow: 0 0 0 0.2rem ${BASE_TOKENS.grey[0]},
            0 0 0 0.4rem ${BASE_TOKENS.grey[400]};
    }

    ${(p) =>
        p.isCellStyle &&
        `
        height: 100%;
        border: none;
        border-radius: 0;

        &:focus-visible {
            box-shadow: none;
        }
    `}
`

type NumberInputProps = {
    isPercentage?: boolean
    isDisabled?: boolean
    isCellStyle?: boolean
    value?: number
    label?: string
    prefix?: string
    suffix?: string
    decimalScale?: number
    placeholder: string
    description?: string
    Icon?: JSXElementConstructor<LucideProps>
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
    onChange: (
        value?: number,
        e?: React.SyntheticEvent<HTMLInputElement>
    ) => void
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
    (props, ref) => {
        const {
            isPercentage,
            isDisabled = false,
            isCellStyle = false,
            value,
            label,
            prefix,
            suffix,
            decimalScale,
            placeholder,
            description,
            Icon,
            onBlur = emptyFunc,
            onChange = emptyFunc,
            onKeyDown = emptyFunc,
        } = props

        const _onChange = (
            values: NumberFormatValues,
            { event }: SourceInfo
        ) => {
            const { value } = values
            let newValue: number | undefined
            if (isPercentage) {
                newValue = value === '' ? undefined : Number(value) / 100
            } else {
                newValue = value === '' ? undefined : Number(value)
            }

            onChange(newValue, event)
        }

        return (
            <StyledNumberInputContainer isCellStyle={isCellStyle}>
                {label && <StyledLabel>{label}</StyledLabel>}
                <div className="container__input">
                    {Icon && (
                        <span className="container__input__icon">
                            <Icon
                                size={16}
                                color={
                                    value
                                        ? DESIGN_TOKENS.text.textForeground
                                        : DESIGN_TOKENS.text.textMutedForeground
                                }
                            />
                        </span>
                    )}
                    <StyledNumberInput
                        hasIcon={!!Icon}
                        disabled={isDisabled}
                        isCellStyle={isCellStyle}
                        decimalScale={isPercentage ? 2 : decimalScale}
                        allowNegative={false}
                        value={isPercentage ? (value ?? 0) * 100 : value}
                        prefix={isPercentage ? '' : prefix}
                        suffix={isPercentage ? ' %' : suffix}
                        placeholder={placeholder}
                        getInputRef={ref}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        onValueChange={_onChange}
                    />
                </div>
                {description && (
                    <Typography color="secondary" variant="caption">
                        {description}
                    </Typography>
                )}
            </StyledNumberInputContainer>
        )
    }
)

export default NumberInput
