import styled from '@emotion/styled'
import Typography from 'components/core/Typography'

const StyledErrorBoundary = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ErrorBoundary = () => {
    return (
        <StyledErrorBoundary>
            <img
                alt="Error boundary"
                src={`${process.env.PUBLIC_URL}/errorBoundary.png`}
            />
            <Typography color="primary" variant="body1">
                Unfortunately, something went wrong. Please try refreshing the
                page.
            </Typography>
        </StyledErrorBoundary>
    )
}

export default ErrorBoundary
