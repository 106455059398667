import styled from '@emotion/styled'
import Popover from 'components/core/Popover'
import Typography from 'components/core/Typography'
import { BASE_TOKENS } from 'constants/color.constants'
import { SPACING } from 'constants/spacing.constants'

const StyledDetailsRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .detailsRow {
        min-height: ${SPACING.p4};
        padding-left: ${SPACING.p2};
        padding-right: ${SPACING.p2};
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-gap: ${SPACING.p2};

        &.detailsRow--clickable {
            transition: background-color 0.3s ease;

            &:hover {
                cursor: pointer;
                background-color: ${BASE_TOKENS.grey[50]};
            }
        }

        .detailsRow__caption {
            width: 30%;
        }

        .detailsRow__valueText {
            display: flex;
            justify-content: flex-end;
            width: 70%;
        }
    }
`

const DetailsRowPopoverContainer = styled.div`
    min-width: 28rem;
`

export type DetailsRowProps = {
    isEditable?: boolean
    caption?: string
    valueText?: string
    trigger?: React.ReactNode
    children?: React.ReactNode
    onClosePopup?: () => void
}

const emptyFunc = () => undefined

export const DetailsRow = ({
    isEditable,
    children,
    trigger,
    caption,
    valueText,
    onClosePopup = emptyFunc,
}: DetailsRowProps) => {
    const DefaultTrigger = (
        <>
            <div className="detailsRow__caption">
                <Typography color="secondary" variant="body2">
                    {caption}
                </Typography>
            </div>

            <div className="detailsRow__valueText">
                <Typography color="primary" variant="body2">
                    {valueText || ''}
                </Typography>
            </div>
        </>
    )

    if (!isEditable) {
        return (
            <StyledDetailsRow>
                {<div className="detailsRow">{trigger || DefaultTrigger}</div>}
            </StyledDetailsRow>
        )
    }

    return (
        <StyledDetailsRow>
            <Popover
                onClose={onClosePopup}
                trigger={
                    <div
                        className="detailsRow detailsRow--clickable"
                        role="button"
                    >
                        {trigger || DefaultTrigger}
                    </div>
                }
            >
                <DetailsRowPopoverContainer>
                    {children}
                </DetailsRowPopoverContainer>
            </Popover>
        </StyledDetailsRow>
    )
}
