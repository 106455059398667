import { useEffect, useRef, useState } from 'react'
import debounce from 'lodash.debounce'
import { MultiValue } from 'react-select'
import { LucideIcon } from 'lucide-react'
import BaseSelect from './BaseSelect'
import { SelectOption } from '../select.types'
import { SEARCH_TERM_MIN_LENGTH } from '../select.constants'

type SelectAsyncProps<TIsMulti> = {
    isMulti?: TIsMulti
    isDisabled?: boolean
    isCellStyle?: boolean
    shouldPortalMenu?: boolean
    shouldFocusOnMount?: boolean
    label?: string
    placeholder: string
    description?: string
    error?: string
    menuPortalTarget?: HTMLElement
    Icon?: LucideIcon
    onFetchOptions: (searchTerm: string) => Promise<SelectOption[]>
    onFetchInitValues?: () => Promise<SelectOption[]>
    onSelectedOptionsChanged: (selectedOptions: SelectOption[]) => void
}

const SelectAsync = <TIsMulti extends true | undefined>({
    isMulti,
    isDisabled,
    isCellStyle,
    shouldPortalMenu,
    shouldFocusOnMount,
    label,
    placeholder,
    description,
    error,
    menuPortalTarget,
    Icon,
    onFetchOptions: propsOnFetchOptions,
    onFetchInitValues,
    onSelectedOptionsChanged,
}: SelectAsyncProps<TIsMulti>) => {
    const [isLoadingOptions, setIsLoadingOptions] = useState(false)
    const [isLoadingInitOptions, setIsLoadingInitOptions] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [options, setOptions] = useState<SelectOption[]>([])
    const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([])
    const fetchOptionsRef = useRef<any>()

    const _onFetchOptions = async (searchTerm: string) => {
        if (searchTerm.length < SEARCH_TERM_MIN_LENGTH) {
            return
        }

        setIsLoadingOptions(true)

        try {
            const fetchedOptions = await propsOnFetchOptions(searchTerm)
            setOptions(fetchedOptions)
        } catch (error) {
            console.error('Error fetching options:', error)
        } finally {
            setIsLoadingOptions(false)
        }
    }

    const _onInputChange = async (searchTerm: string) => {
        setInputValue(searchTerm)

        if (fetchOptionsRef.current?.cancel) {
            fetchOptionsRef.current.cancel()
        }

        if (!searchTerm) {
            setOptions([])
            return
        }

        fetchOptionsRef.current = debounce(_onFetchOptions, 300)
        fetchOptionsRef.current(searchTerm)
    }

    const _onChange = (newValue: MultiValue<SelectOption>) => {
        const selectedOptions = Array.isArray(newValue) ? newValue : [newValue]
        setSelectedOptions(selectedOptions)
        onSelectedOptionsChanged(selectedOptions)
    }

    useEffect(() => {
        if (!onFetchInitValues) {
            return
        }

        const _onFetchInitValues = async () => {
            setIsLoadingInitOptions(true)

            try {
                const initOptions = await onFetchInitValues()
                setSelectedOptions(initOptions)
                onSelectedOptionsChanged(initOptions)
            } catch (error) {
                console.error('Error fetching initial options:', error)
            } finally {
                setIsLoadingInitOptions(false)
            }
        }

        _onFetchInitValues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <BaseSelect
            isMulti={isMulti}
            isDisabled={isLoadingInitOptions || !!isDisabled}
            isLoading={isLoadingOptions || isLoadingInitOptions}
            isCellStyle={isCellStyle}
            shouldPortalMenu={shouldPortalMenu}
            shouldFocusOnMount={shouldFocusOnMount}
            inputValue={inputValue}
            label={label}
            placeholder={placeholder}
            description={description}
            error={error}
            options={options}
            selectedOptions={selectedOptions}
            menuPortalTarget={menuPortalTarget}
            Icon={Icon}
            onChange={_onChange}
            onInputChange={_onInputChange}
        />
    )
}

export default SelectAsync
