import { useState } from 'react'
import { TABLE_ROW_LIMIT } from '../board.constants'

type QueryParams = {
    offset: number
}

export const useBoardv2 = <TRow extends { id: string }>({
    useRowsFn,
}: {
    useRowsFn: (args: QueryParams) => {
        isLoading: boolean
        isRefetching: boolean
        total: number
        rows: TRow[]
        error: unknown
        refetch: () => void
    }
}) => {
    const [offset, setOffset] = useState(0)
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([])

    const {
        isLoading,
        isRefetching,
        total,
        rows = [],
        error,
        refetch: refetchRows,
    } = useRowsFn({ offset })

    const isFirstPage = offset === 0
    const isLastPage = offset + TABLE_ROW_LIMIT >= total

    const _onNextPage = () => {
        if (isLastPage) {
            return
        }

        setOffset((prev) => prev + TABLE_ROW_LIMIT)
    }

    const _onLastPage = () => {
        if (isLastPage) {
            return
        }

        const lastPageOffset =
            Math.floor((total - 1) / TABLE_ROW_LIMIT) * TABLE_ROW_LIMIT

        setOffset(lastPageOffset)
    }

    const _onPreviousPage = () => {
        if (isFirstPage) {
            return
        }

        setOffset((prev) => prev - TABLE_ROW_LIMIT)
    }

    const _onFirstPage = () => {
        if (isFirstPage) {
            return
        }

        setOffset(0)
    }

    const _onSelectAllRows = (isChecked: boolean) => {
        if (isChecked) {
            setSelectedRowIds(rows.map((r) => r.id))
            return
        }

        setSelectedRowIds([])
    }

    const _onSelectRow = ({
        isChecked,
        id,
        index,
    }: {
        isChecked: boolean
        id: string
        index: number
    }) => {
        setSelectedRowIds((prev) => {
            if (isChecked && !prev.includes(id)) {
                return [...prev.slice(0, index), id, ...prev.slice(index)]
            } else if (!isChecked) {
                return prev.filter((selectedId) => selectedId !== id)
            }
            return prev
        })
    }

    return {
        isLoading,
        isLastPage,
        isFirstPage,
        isRefetching,
        total,
        offset,
        selectedRowIds,
        rows,
        error,
        refetchRows,
        setSelectedRowIds,
        onNextPage: _onNextPage,
        onLastPage: _onLastPage,
        onSelectRow: _onSelectRow,
        onFirstPage: _onFirstPage,
        onPreviousPage: _onPreviousPage,
        onSelectAllRows: _onSelectAllRows,
    }
}
