import { Destination, Order } from 'types/order.types'
import { OrderChangeProp } from '../orderDetails.types'
import { DetailsRow } from './DetailsRow'
import { CustomerAsyncSelect } from 'components/composite/CustomerAsyncSelect'
import { Customer } from 'types/customer.types'
import TextInput from 'components/core/TextInput'

type OrderDetailsCustomerSectionProps = {
    isEditable: boolean
    order?: Order
    orderMutation?: Partial<Order>
    onClosePopup: (propName: OrderChangeProp) => void
    onChangeOrder: (orderMutation: Partial<Order>) => void
}

export const OrderDetailsCustomerSection = ({
    isEditable,
    order,
    orderMutation,
    onClosePopup,
    onChangeOrder,
}: OrderDetailsCustomerSectionProps) => {
    const { customerName, customerReferenceNumber } = order || {}
    const { customerReferenceNumber: customerReferenceNumberMutation } =
        orderMutation || {}

    const _onClosePopup = (propName: OrderChangeProp) => () => {
        onClosePopup(propName)
    }

    const getMutatedPickup = (pickup: Partial<Destination>) => {
        return {
            ...order?.pickup,
            ...pickup,
        }
    }

    const _onChangeCustomer = (customerId: string, customer?: Customer) => {
        onChangeOrder({
            customerId,
            customerName: customer?.name,
            pickup: getMutatedPickup({
                ...customer?.address,
            }),
        })
    }

    const _onChangeOrder = (propName: OrderChangeProp) => (value: string) => {
        onChangeOrder({ [propName]: value })
    }

    const _onCloseOrderEditPopup = (propName: OrderChangeProp) => () => {
        onClosePopup(propName)
    }

    return (
        <>
            <DetailsRow
                isEditable={isEditable}
                caption="Customer"
                valueText={customerName}
                onClosePopup={_onClosePopup('customerId')}
            >
                <CustomerAsyncSelect onChangeCustomer={_onChangeCustomer} />
            </DetailsRow>
            <DetailsRow
                isRequired
                isEditable={isEditable}
                caption="Reference #"
                valueText={customerReferenceNumber}
                onClosePopup={_onCloseOrderEditPopup('customerReferenceNumber')}
            >
                <TextInput
                    placeholder="Reference #"
                    value={customerReferenceNumberMutation}
                    onChange={_onChangeOrder('customerReferenceNumber')}
                />
            </DetailsRow>
        </>
    )
}
