import { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Settings } from 'lucide-react'
import { usePostHog } from 'posthog-js/react'
import { useMe } from 'hooks/useMe'
import Typography from 'components/core/Typography'
import IconButton from 'components/core/IconButton'
import { pxToRem } from 'utils/rem.utils'
import { USE_MY_ORG_QUERY_KEY, useMyOrg } from 'hooks/useMyOrg'
import MSAuthButton from 'integrations/email/microsoft/components/MSAuthButton'
import RRAuthButton from 'integrations/roserocket/components/RRAuthButton'
import { NAV_HEIGHT } from 'constants/app.constants'
import { getInitials, isCustomerUser, isOpsUser } from 'utils/user.utils'
import { DESIGN_TOKENS } from 'constants/color.constants'
import Avatar, { AvatarFallback } from 'components/core/Avatar'
import AppNavMenu, {
    AppNavMenuItem,
    AppNavMenuLink,
    AppNavMenuList,
} from './components/AppNavMenu'
import GcAuthButton from 'integrations/email/google/components/GcAuthButton'
import { useMyOrgs } from 'hooks/useMyOrgs'
import Cookies from 'js-cookie'
import { showToastError } from 'hooks/useToast'
import { useSwitchOrg } from 'mutations/user.mutations'
import { useQueryClient } from 'react-query'
import Menu, { MenuItem } from 'components/core/Menu'
import { AxiosResponse } from 'axios'
import { Org } from 'types/org.types'
import {
    isAutomationsModuleFFDisabled,
    isCarriersModuleFFEnabled,
    isCustomersModuleFFEnabled,
    isOrdersModuleFFEnabled,
    isPayablesModuleFFDisabled,
    isPricingModuleFFEnabled,
    isReceivablesModuleFFDisabled,
} from 'utils/featureFlag.utils'
import { useMyOrgSettings } from 'hooks/useMyOrgSettings'
import { Outlet } from 'react-router-dom'
import { getImageUrl } from 'utils/getImageUrl'

const StyledMotherComponent = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: ${pxToRem(NAV_HEIGHT)} 1fr;
    height: 100vh;

    .motherComponent__header {
        grid-column: 1;
        grid-row: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 1.6rem;
        padding-right: 1.6rem;
        border-bottom: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};

        .motherComponent__header__left {
            display: flex;
            align-items: center;

            .motherComponent__header__left__logo {
                height: 2.8rem;
                margin-right: 1.2rem;
            }
        }

        .motherComponent__header__right {
            display: flex;
            align-items: center;
            column-gap: 1.6rem;
        }
    }

    .motherComponent__app {
        grid-column: 1;
        grid-row: 2;
        overflow-x: auto;
        overflow-y: none;
        padding-left: 3.2rem;
        padding-right: 3.2rem;
    }
`

const StyledLink = styled(Link)`
    color: inherit;
    text-decoration: none;
`

const MotherComponent = () => {
    const { me } = useMe()
    const {
        isLoading,
        org: currentOrg,
        connectorOrgs: currentConnectorOrgs,
    } = useMyOrg()
    const { orgs } = useMyOrgs()
    const { isLoading: isLoadingOrgSettings, settings } = useMyOrgSettings()
    const location = useLocation()
    const navigate = useNavigate()
    const posthog = usePostHog()
    const { mutateAsync: switchOrg } = useSwitchOrg()
    const queryClient = useQueryClient()

    const rrConnectorOrg = currentConnectorOrgs.find(
        (o) => o.vendorId === 'roserocket'
    )

    const orgOptions =
        orgs.map((o) => ({
            label: o.name,
            value: o.id,
        })) || []

    const _onClickSettingsButton = () => {
        navigate('/settings')
    }

    const _onSwitchOrg = (orgId: string) => async () => {
        if (orgId === currentOrg.id) {
            return
        }

        const cachedResp =
            queryClient.getQueryData<AxiosResponse<{ org: Org }>>(
                USE_MY_ORG_QUERY_KEY
            )

        queryClient.setQueryData([USE_MY_ORG_QUERY_KEY], {
            data: { org: orgs.find((org) => org.id === orgId) },
        })

        try {
            const resp = await switchOrg(orgId)
            Cookies.set('token', resp.data?.token)
            Cookies.set('refreshToken', resp.data?.refreshToken)
            navigate('/')
        } catch {
            queryClient.setQueryData([USE_MY_ORG_QUERY_KEY], cachedResp)
            showToastError('Error switch orgs')
        }
    }

    const currentOrgId = currentOrg?.id

    useEffect(() => {
        if (!me.id) {
            return
        }
        if (!posthog) {
            return
        }
        if (!currentOrgId) {
            return
        }

        posthog.identify(me.id, { orgId: currentOrgId })
    }, [me.id, posthog, currentOrgId])

    if (isCustomerUser(me)) {
        return (
            <StyledMotherComponent>
                <div className="motherComponent__header">
                    <div className="motherComponent__header__left">
                        <img
                            alt="Logo"
                            className="motherComponent__header__left__logo"
                            src={getImageUrl('newShortLogo.png')}
                        />
                        <AppNavMenu>
                            <AppNavMenuList>
                                {!isLoadingOrgSettings && (
                                    <>
                                        <AppNavMenuItem
                                            isSelected={location.pathname.includes(
                                                '/sp/shipments'
                                            )}
                                        >
                                            <StyledLink to="/sp/shipments">
                                                <AppNavMenuLink>
                                                    Shipments
                                                </AppNavMenuLink>
                                            </StyledLink>
                                        </AppNavMenuItem>
                                    </>
                                )}
                            </AppNavMenuList>
                        </AppNavMenu>
                    </div>
                    <div className="motherComponent__header__right">
                        <Avatar>
                            <AvatarFallback>
                                {getInitials({
                                    firstName: me.firstName,
                                    lastName: me.lastName,
                                })}
                            </AvatarFallback>
                        </Avatar>
                    </div>
                </div>
                <div className="motherComponent__app">
                    <Outlet />
                </div>
            </StyledMotherComponent>
        )
    }

    return (
        <StyledMotherComponent>
            <div className="motherComponent__header">
                <div className="motherComponent__header__left">
                    <img
                        alt="Logo"
                        className="motherComponent__header__left__logo"
                        src={getImageUrl('newShortLogo.png')}
                    />
                    <AppNavMenu>
                        <AppNavMenuList>
                            {!isLoadingOrgSettings && (
                                <>
                                    {!isPayablesModuleFFDisabled(settings) && (
                                        <AppNavMenuItem
                                            isSelected={
                                                location.pathname ===
                                                '/payables'
                                            }
                                        >
                                            <StyledLink to="/payables">
                                                <AppNavMenuLink>
                                                    Payables
                                                </AppNavMenuLink>
                                            </StyledLink>
                                        </AppNavMenuItem>
                                    )}
                                    {!isReceivablesModuleFFDisabled(
                                        settings
                                    ) && (
                                        <AppNavMenuItem
                                            isSelected={
                                                location.pathname ===
                                                '/receivables'
                                            }
                                        >
                                            <StyledLink to="/receivables">
                                                <AppNavMenuLink>
                                                    Receivables
                                                </AppNavMenuLink>
                                            </StyledLink>
                                        </AppNavMenuItem>
                                    )}
                                    {!isAutomationsModuleFFDisabled(
                                        settings
                                    ) && (
                                        <AppNavMenuItem
                                            isSelected={
                                                location.pathname === '/tasks'
                                            }
                                        >
                                            <StyledLink to="/tasks">
                                                <AppNavMenuLink>
                                                    Tasks
                                                </AppNavMenuLink>
                                            </StyledLink>
                                        </AppNavMenuItem>
                                    )}
                                    {isOrdersModuleFFEnabled(settings) && (
                                        <AppNavMenuItem
                                            isSelected={
                                                location.pathname === '/orders'
                                            }
                                        >
                                            <StyledLink to="/orders">
                                                <AppNavMenuLink>
                                                    Orders
                                                </AppNavMenuLink>
                                            </StyledLink>
                                        </AppNavMenuItem>
                                    )}
                                    {isCustomersModuleFFEnabled(settings) && (
                                        <AppNavMenuItem
                                            isSelected={
                                                location.pathname ===
                                                '/customers'
                                            }
                                        >
                                            <StyledLink to="/customers">
                                                <AppNavMenuLink>
                                                    Customers
                                                </AppNavMenuLink>
                                            </StyledLink>
                                        </AppNavMenuItem>
                                    )}
                                    {isCarriersModuleFFEnabled(settings) && (
                                        <AppNavMenuItem
                                            isSelected={
                                                location.pathname ===
                                                '/carriers'
                                            }
                                        >
                                            <StyledLink to="/carriers">
                                                <AppNavMenuLink>
                                                    Carriers
                                                </AppNavMenuLink>
                                            </StyledLink>
                                        </AppNavMenuItem>
                                    )}
                                    {isPricingModuleFFEnabled(settings) && (
                                        <AppNavMenuItem
                                            isSelected={
                                                location.pathname === '/pricing'
                                            }
                                        >
                                            <StyledLink to="/pricing">
                                                <AppNavMenuLink>
                                                    Pricing
                                                </AppNavMenuLink>
                                            </StyledLink>
                                        </AppNavMenuItem>
                                    )}
                                    <AppNavMenuItem
                                        isSelected={
                                            location.pathname === '/users'
                                        }
                                    >
                                        <StyledLink to="/users">
                                            <AppNavMenuLink>
                                                Users
                                            </AppNavMenuLink>
                                        </StyledLink>
                                    </AppNavMenuItem>
                                </>
                            )}

                            {rrConnectorOrg && <RRAuthButton />}
                        </AppNavMenuList>
                    </AppNavMenu>
                </div>
                <div className="motherComponent__header__right">
                    {!isLoading && (
                        <>
                            {me.role === 'superadmin' && (
                                <Typography variant="body2" color="secondary">
                                    {currentOrg.name}
                                </Typography>
                            )}
                            {me.orgIds && me.orgIds?.length > 1 && (
                                <Menu
                                    trigger={
                                        <IconButton>
                                            <Typography
                                                variant="body2"
                                                color="primary"
                                            >
                                                {currentOrg.name}
                                            </Typography>
                                        </IconButton>
                                    }
                                >
                                    {orgOptions
                                        .filter(
                                            (option) =>
                                                option.value !== currentOrg.id
                                        )
                                        .map((o) => {
                                            return (
                                                <MenuItem
                                                    key={o.value}
                                                    onClick={_onSwitchOrg(
                                                        o.value
                                                    )}
                                                >
                                                    <Typography
                                                        color="primary"
                                                        variant="body2"
                                                    >
                                                        {o.label}
                                                    </Typography>
                                                </MenuItem>
                                            )
                                        })}
                                </Menu>
                            )}
                        </>
                    )}
                    <Avatar>
                        <AvatarFallback>
                            {getInitials({
                                firstName: me.firstName,
                                lastName: me.lastName,
                            })}
                        </AvatarFallback>
                    </Avatar>
                    {currentOrg.emailProvider === 'microsoft' ? (
                        <MSAuthButton />
                    ) : (
                        <GcAuthButton />
                    )}
                    <IconButton onClick={_onClickSettingsButton}>
                        <Settings size={16} />
                    </IconButton>
                </div>
            </div>
            <div className="motherComponent__app">
                <Outlet />
            </div>
        </StyledMotherComponent>
    )
}

export default MotherComponent
