import styled from '@emotion/styled'
import { Mail } from 'lucide-react'
import { BASE_TOKENS } from 'constants/color.constants'

const emptyFunc = () => undefined

const StyledAuthButton = styled.button<{
    isLoading: boolean
    isConnected: boolean
}>`
    margin: 0;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;

    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.8rem;
    border-radius: 0.6rem;

    color: ${BASE_TOKENS.grey[950]};
    background-color: ${BASE_TOKENS.grey[0]};
    border: 0.1rem solid ${BASE_TOKENS.slate[200]};

    transition: background-color 0.3s ease;

    cursor: pointer;

    &:hover {
        background-color: ${BASE_TOKENS.slate[50]};
    }

    &:disabled {
        background-color: ${BASE_TOKENS.grey[0]};
        opacity: 1;
        cursor: default;
    }

    .authButton__circle {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        background: ${(p) => {
            if (p.isLoading) {
                return BASE_TOKENS.red[500]
            }

            return p.isConnected ? BASE_TOKENS.green[600] : BASE_TOKENS.red[500]
        }};
    }
`

type MSAuthButtonProps = {
    isLoading: boolean
    isConnected: boolean
    onConnectToMS: () => void
}

const MSAuthButton = ({
    isLoading,
    isConnected,
    onConnectToMS = emptyFunc,
}: MSAuthButtonProps) => {
    return (
        <StyledAuthButton
            disabled={isConnected}
            isLoading={isLoading}
            isConnected={isConnected}
            onClick={onConnectToMS}
        >
            <Mail width={20} height={20} color={'#111827'} />
            <span className="authButton__circle" />
        </StyledAuthButton>
    )
}

export default MSAuthButton
