import styled from '@emotion/styled'
import * as RadixNavigationMenu from '@radix-ui/react-navigation-menu'

const StyledAppNavMenuList = styled(RadixNavigationMenu.List)`
    display: flex;
    list-style: none;
    padding: 0;
`

export default StyledAppNavMenuList
