import styled from '@emotion/styled'
import NumberInput from 'components/composite/NumberInput'
import Popover from 'components/core/PopoverOld'
import Typography from 'components/core/Typography'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import { FileBox, Package, Ruler, Weight } from 'lucide-react'

const formatDimensions = ({
    width,
    length,
    height,
    weight,
}: {
    width?: number
    length?: number
    height?: number
    weight?: number
}) => {
    if (!length && !width && !height && !weight) {
        return undefined
    }

    const formattedLength = length ? `${length}L` : '-L'
    const formattedWidth = width ? `${width}W` : '-W'
    const formattedHeight = height ? `${height}H` : '-H'
    const formattedWeight = weight ? `${weight}lbs` : '-lbs'

    return `${formattedWidth}, ${formattedLength}, ${formattedHeight}, ${formattedWeight}`
}

const StyledDetailsTrigger = styled.button`
    width: 18rem;
    height: 4.8rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-bottom: 0.1rem;

    display: flex;
    align-items: center;
    column-gap: 0.8rem;
    background-color: ${BASE_TOKENS.grey[0]};

    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    color: ${DESIGN_TOKENS.text.textMutedForeground};

    border-radius: 0.6rem;
    border: 0.1rem solid ${DESIGN_TOKENS.input};

    cursor: text;
    transition: background-color 0.3s ease;

    &::placeholder {
        color: ${DESIGN_TOKENS.mutedForeground};
    }

    &:focus-visible {
        outline: none;
        box-shadow:
            0 0 0 0.2rem ${BASE_TOKENS.grey[0]},
            0 0 0 0.4rem ${BASE_TOKENS.grey[400]};
    }

    &:hover {
        background-color: ${BASE_TOKENS.grey[50]};
    }

    .details__text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
`

const StyledDetails = styled.div`
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 2.4rem;

    .details__row {
        width: 32rem;
        display: flex;
        column-gap: 1.2rem;
    }
`

type FreightDetailsProps = {
    numPallets?: number
    length?: number
    width?: number
    height?: number
    weight?: number
    onChangeNumPallets: (value?: number) => void
    onChangeLength: (value?: number) => void
    onChangeWidth: (value?: number) => void
    onChangeHeight: (value?: number) => void
    onChangeWeight: (value?: number) => void
}

const FreightDetails = ({
    numPallets,
    length,
    width,
    height,
    weight,
    onChangeNumPallets,
    onChangeLength,
    onChangeWidth,
    onChangeHeight,
    onChangeWeight,
}: FreightDetailsProps) => {
    const hasNoDetails = !numPallets && !length && !width && !height && !weight

    const formattedDimensions = formatDimensions({
        width,
        length,
        height,
        weight,
    })

    return (
        <Popover
            trigger={
                <StyledDetailsTrigger>
                    <Package
                        size={16}
                        strokeWidth={1.8}
                        color={
                            hasNoDetails
                                ? DESIGN_TOKENS.text.textMutedForeground
                                : DESIGN_TOKENS.text.textForeground
                        }
                    />
                    <div className="details__text">
                        {hasNoDetails && (
                            <Typography color="secondary" variant="body2">
                                Freight details
                            </Typography>
                        )}
                        {numPallets !== undefined && numPallets > 0 && (
                            <Typography color="primary" variant="body2">
                                {numPallets} pallet{numPallets > 1 ? 's' : ''}
                            </Typography>
                        )}
                        {formattedDimensions && (
                            <Typography color="primary" variant="caption">
                                {formattedDimensions}
                            </Typography>
                        )}
                    </div>
                </StyledDetailsTrigger>
            }
        >
            <StyledDetails>
                <Typography isBold color="primary" variant="body1">
                    Freight details
                </Typography>
                <div className="details__row">
                    <NumberInput
                        placeholder="How many pallets?"
                        value={numPallets}
                        suffix=" pallets"
                        Icon={FileBox}
                        onChange={onChangeNumPallets}
                    />
                </div>
                <div className="details__row">
                    <NumberInput
                        placeholder="Width (in)"
                        value={width}
                        suffix=" W (in)"
                        Icon={Ruler}
                        onChange={onChangeWidth}
                    />
                    <NumberInput
                        placeholder="Length (in)"
                        value={length}
                        suffix=" L (in)"
                        Icon={Ruler}
                        onChange={onChangeLength}
                    />
                </div>
                <div className="details__row">
                    <NumberInput
                        placeholder="Height (in)"
                        value={height}
                        suffix=" H (in)"
                        Icon={Ruler}
                        onChange={onChangeHeight}
                    />
                    <NumberInput
                        placeholder="Weight (lbs)"
                        value={weight}
                        suffix=" lbs"
                        Icon={Weight}
                        onChange={onChangeWeight}
                    />
                </div>
            </StyledDetails>
        </Popover>
    )
}

export default FreightDetails
