import styled from '@emotion/styled'
import Switch from 'components/core/Switch'
import Typography from 'components/core/Typography'
import { BASE_TOKENS } from 'constants/color.constants'
import Select, { SelectAsync, SelectOption } from 'components/core/Select'
import Separator from 'components/core/Separator'
import Checkbox from 'components/core/Checkbox'
import Card from 'components/composite/Card'

const StyledCheckbox = styled(Checkbox)`
    border-color: ${BASE_TOKENS.grey[300]};
`

const TAX_ID_LABELS = {
    on: 'Ontario (13%)',
    bc: 'British Columbia (12%)',
    nb: 'New Brunswick (15%)',
    nl: 'Newfoundland (15%)',
    ns: 'Nova Scotia (15%)',
    pe: 'Prince Edward Island (15%)',
    qc: 'Quebec (5%)',
    mb: 'Manitoba (5%)',
    ab: 'Alberta (5%)',
    nt: 'Northwest Territories (5%)',
    nu: 'Nunavut (5%)',
    sk: 'Saskatchewan (5%)',
    yt: 'Yukon (5%)',
    exempt: 'Exempt (International, Outbound, Inbound, Intra) (0%)',
}

const StyledRRPayableSettings = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem;

    .rrPayableSettings__tax {
        display: flex;
        flex-direction: column;
        row-gap: 0.6rem;
    }
`

const StyledInputRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .rrPayableTaxSettings__input {
        width: 20rem;
        min-width: 20rem;
    }
`

export type RRPayableSettingsProps = {
    isTestOrg: boolean
    isLoading: boolean
    isSubmitting: boolean
    isUseInvoiceDateEnabled: boolean
    onChangeVendors: (selectedOptions: SelectOption[]) => void
    onFetchInitVendors: () => Promise<SelectOption[]>
    onChangeSkipCarriers: (selectedOptions: SelectOption[]) => void
    onFetchCarrierOptions: (searchTerm: string) => Promise<SelectOption[]>
    onFetchInitSkipCarriers: () => Promise<SelectOption[]>
    onChangeBillableCarriers: (selectedOptions: SelectOption[]) => void
    onFetchInitBillableCarriers: () => Promise<SelectOption[]>
    onChangeInvoiceDateCarriers: (selectedOptions: SelectOption[]) => void
    onFetchInitInvoiceDateCarriers: () => Promise<SelectOption[]>
    onChangeIsUseInvoiceDateEnabled: (value: boolean) => void
}

const RRPayableSettings = ({
    isTestOrg,
    isLoading,
    isSubmitting,
    isUseInvoiceDateEnabled,
    onChangeVendors,
    onFetchInitVendors,
    onChangeSkipCarriers,
    onFetchCarrierOptions,
    onFetchInitSkipCarriers,
    onChangeBillableCarriers,
    onFetchInitBillableCarriers,
    onChangeInvoiceDateCarriers,
    onFetchInitInvoiceDateCarriers,
    onChangeIsUseInvoiceDateEnabled,
}: RRPayableSettingsProps) => {
    return (
        <StyledRRPayableSettings>
            <Card
                title="Carrier invoice date"
                subtitle="When processing payables, use the date on the
                            carrier invoice as the invoice date, instead of the
                            date the invoice was received."
            >
                <div className="card__inputs">
                    <Separator />
                    <SelectAsync
                        isMulti
                        isDisabled={
                            isLoading || isSubmitting || isUseInvoiceDateEnabled
                        }
                        label="Select carriers"
                        placeholder="Search carriers"
                        onFetchOptions={onFetchCarrierOptions}
                        onFetchInitValues={onFetchInitInvoiceDateCarriers}
                        onSelectedOptionsChanged={onChangeInvoiceDateCarriers}
                    />
                    <StyledCheckbox
                        isChecked={isUseInvoiceDateEnabled}
                        label="Apply to all carriers"
                        onChange={onChangeIsUseInvoiceDateEnabled}
                    />
                </div>
            </Card>
            <SelectAsync
                isMulti
                isDisabled={isLoading || isSubmitting}
                label="Treat carrier as vendor"
                placeholder="Search carriers"
                description="Trips assigned to these carriers will be ignored for the purposes of uploading proof of deliveries"
                onFetchOptions={onFetchCarrierOptions}
                onFetchInitValues={onFetchInitVendors}
                onSelectedOptionsChanged={onChangeVendors}
            />
            <SelectAsync
                isMulti
                isDisabled={isLoading || isSubmitting}
                label="Billable without delivery receipt"
                placeholder="Search carriers"
                description="For carriers selected here, our automation will process the payable even if there is no proof of delivery"
                onFetchOptions={onFetchCarrierOptions}
                onFetchInitValues={onFetchInitBillableCarriers}
                onSelectedOptionsChanged={onChangeBillableCarriers}
            />
            <SelectAsync
                isMulti
                isDisabled={isLoading || isSubmitting}
                label="Skip carrier name check"
                placeholder="Search carriers"
                description="For carriers selected here, we will not flag the payable for review if the carrier name on the invoice is different from the carrier name in your TMS"
                onFetchOptions={onFetchCarrierOptions}
                onFetchInitValues={onFetchInitSkipCarriers}
                onSelectedOptionsChanged={onChangeSkipCarriers}
            />
            {isTestOrg && (
                <Card
                    title="Enable taxes in bills (Canadian entities)"
                    subtitle="If taxes are found in the carrier invoice, we
                                will update tax values in your bill accordingly.
                                Please select which tax identifier we should use
                                for specific freight destinations."
                    HeaderInput={
                        <Switch isChecked={false} onChange={() => undefined} />
                    }
                >
                    <div className="card__inputs">
                        {Object.keys(TAX_ID_LABELS).map((taxId) => {
                            return (
                                <StyledInputRow key={taxId}>
                                    <Typography
                                        isBold
                                        color="primary"
                                        variant="body2"
                                    >
                                        {
                                            TAX_ID_LABELS[
                                                taxId as keyof typeof TAX_ID_LABELS
                                            ]
                                        }
                                    </Typography>
                                    <span className="rrPayableTaxSettings__input">
                                        <Select
                                            placeholder="Select tax ID"
                                            options={[]}
                                            onSelectedOptionChanged={() =>
                                                undefined
                                            }
                                        />
                                    </span>
                                </StyledInputRow>
                            )
                        })}
                    </div>
                </Card>
            )}
        </StyledRRPayableSettings>
    )
}

export default RRPayableSettings
