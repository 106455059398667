import styled from '@emotion/styled'
import Button from 'components/core/Button'
import Typography from 'components/core/Typography'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import { format, parseISO } from 'date-fns'
import { ChevronDown, ChevronUp, CircleCheck, LucideProps } from 'lucide-react'
import { JSXElementConstructor } from 'react'

const emptyFunc = () => undefined

const StyledOrderEventControl = styled.div<{
    hasTopBorder: boolean
    hasBottomBorder: boolean
}>`
    height: 8.4rem;
    min-height: 8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    background-color: ${BASE_TOKENS.grey[0]};

    ${(p) =>
        p.hasTopBorder &&
        `border-top: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};`}

    ${(p) =>
        p.hasBottomBorder &&
        `border-bottom: 0.1rem solid ${DESIGN_TOKENS.border.borderInput};`}

    .event__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 0.8rem;

        .event__content__title {
            display: flex;
            align-items: center;
            column-gap: 2rem;

            .event__content__title__main {
                display: flex;
                align-items: center;
                column-gap: 0.8rem;

                .event__content__title__main__icon {
                    width: 2.8rem;
                    height: 2.8rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: ${BASE_TOKENS.grey[200]};
                    border: 0.1rem solid ${BASE_TOKENS.grey[300]};
                    border-radius: 50%;
                }
            }
        }
    }
`

const StyledSuccessBadge = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.2rem;

    .badge__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${BASE_TOKENS.green[700]};
    }
`

type OrderEventControlProps = {
    hasPanel?: boolean
    isExpanded?: boolean
    hasTopBorder?: boolean
    hasBottomBorder?: boolean
    title: string
    timestamp: string
    Icon: JSXElementConstructor<LucideProps>
    onClick?: () => void
}

const OrderEventControl = ({
    hasPanel = false,
    isExpanded = false,
    hasTopBorder = false,
    hasBottomBorder = true,
    title,
    timestamp,
    Icon,
    onClick = emptyFunc,
}: OrderEventControlProps) => {
    return (
        <StyledOrderEventControl
            hasTopBorder={hasTopBorder}
            hasBottomBorder={hasBottomBorder}
        >
            <span className="event__content">
                <span className="event__content__title">
                    <span className="event__content__title__main">
                        <span className="event__content__title__main__icon">
                            <Icon fill={BASE_TOKENS.grey[0]} size={16} />
                        </span>
                        <Typography isBold color="primary" variant="body2">
                            {title}
                        </Typography>
                    </span>
                    <StyledSuccessBadge>
                        <CircleCheck
                            size={16}
                            fill={BASE_TOKENS.green[600]}
                            color={BASE_TOKENS.grey[0]}
                        />
                        <Typography variant="body2" color="success">
                            Success
                        </Typography>
                    </StyledSuccessBadge>
                </span>
                <Typography color="secondary" variant="body2">
                    {format(parseISO(timestamp), "MMM d, yyyy ' @ ' HH:mm")}
                </Typography>
            </span>
            {hasPanel && (
                <Button size="sm" variant="ghost" onClick={onClick}>
                    Details{' '}
                    {isExpanded ? (
                        <ChevronUp size={16} />
                    ) : (
                        <ChevronDown size={16} />
                    )}
                </Button>
            )}
        </StyledOrderEventControl>
    )
}

export default OrderEventControl
