import { useState } from 'react'
import styled from '@emotion/styled'
import { CircularProgress } from '@mui/material'
import {
    File,
    LucideProps,
    MapPinCheck,
    MapPinHouse,
    Plus,
    Search,
} from 'lucide-react'
import Button from 'components/core/Button'
import Typography from 'components/core/Typography'
import { pxToRem } from 'utils/rem.utils'
import { NAV_HEIGHT } from 'constants/app.constants'
import { useDimensions } from 'hooks/useDimensions'
import AddressSearchInput from 'components/composite/AddressSearchInput'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import Map from 'components/composite/Map'
import Quote from './Quote'
import FreightDetails from './FreightDetails'
import SelectedQuoteSummary from './SelectedQuoteSummary'
import { Rate } from '../PricingContainer'
import UploadFilesDialog from 'dialogs/UploadFilesDialog'
import IconButton from 'components/core/IconButton'
import PoViewerDialog from './PoViewerDialog'

const HEADER_HEIGHT = 40
const INPUTS_HEIGHT = 88
const PADDING_HEIGHT = 32
const QUOTES_HEADER_HEIGHT = 60
const QUOTES_SELECT_HEIGHT = 200

const StyledPricing = styled.div<{ isQuoteSelected: boolean }>`
    height: 100%;
    padding-top: ${pxToRem(PADDING_HEIGHT)};
    padding-bottom: ${pxToRem(PADDING_HEIGHT)};

    display: flex;
    flex-direction: column;

    .pricing__header {
        display: flex;
        justify-content: space-between;
        height: ${pxToRem(HEADER_HEIGHT)};
        min-height: ${pxToRem(HEADER_HEIGHT)};

        .pricing__header__logo {
            height: 3.2rem;
        }
    }

    .pricing__inputs {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .pricing__inputs__left {
            height: ${pxToRem(INPUTS_HEIGHT)};
            min-height: ${pxToRem(INPUTS_HEIGHT)};
            display: flex;
            align-items: center;
            column-gap: 2rem;

            .pricing__inputs__left__text {
                padding-left: 1.2rem;
                padding-right: 1.2rem;
            }

            .pricing__inputs__left__search {
                width: 28rem;
                min-width: 28rem;
            }
        }

        .pricing__inputs__right {
            display: flex;
            align-items: center;
            column-gap: 1.2rem;
        }
    }

    .pricing__results {
        height: 100%;
        display: flex;
        column-gap: 1.6rem;

        .pricing__results__quotes {
            height: 100%;
            width: 56rem;
            border-radius: 0.8rem;
            border: 0.1rem solid ${DESIGN_TOKENS.input};
            display: flex;
            flex-direction: column;

            .pricing__results__quotes__header {
                height: ${pxToRem(QUOTES_HEADER_HEIGHT)};
                min-height: ${pxToRem(QUOTES_HEADER_HEIGHT)};
                border-bottom: 0.1rem solid ${DESIGN_TOKENS.input};
                padding-left: 2rem;
                padding-right: 2rem;
                display: flex;
                align-items: center;
            }

            .pricing__results__quotes__scroll {
                height: calc(
                    100vh -
                        ${(p) => {
                            let height =
                                NAV_HEIGHT +
                                HEADER_HEIGHT +
                                INPUTS_HEIGHT +
                                QUOTES_HEADER_HEIGHT +
                                PADDING_HEIGHT +
                                PADDING_HEIGHT

                            if (p.isQuoteSelected) {
                                height += QUOTES_SELECT_HEIGHT
                            }

                            return pxToRem(height)
                        }}
                );
                overflow-y: auto;
                border-bottom-left-radius: 0.8rem;
                border-bottom-right-radius: 0.8rem;
            }

            .pricing__results__quotes__noResults {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                row-gap: 1.6rem;
                height: calc(
                    100vh -
                        ${(p) => {
                            let height =
                                NAV_HEIGHT +
                                HEADER_HEIGHT +
                                INPUTS_HEIGHT +
                                QUOTES_HEADER_HEIGHT +
                                PADDING_HEIGHT +
                                PADDING_HEIGHT

                            if (p.isQuoteSelected) {
                                height += QUOTES_SELECT_HEIGHT
                            }

                            return pxToRem(height)
                        }}
                );
            }
        }
    }
`

const StyledGetQuoteButton = styled(Button)`
    min-height: 4.8rem;
`

const StyledQuoteResultsButton = styled(Button)`
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 1.2rem;
`

const StyledIconButton = styled(IconButton)`
    width: 4rem;
    height: 4rem;
`

const MapPinHouseIcon = (props: LucideProps) => (
    <MapPinHouse {...props} style={{ marginTop: 5 }} />
)

const MapPinCheckIcon = (props: LucideProps) => (
    <MapPinCheck {...props} style={{ ...props.style, marginTop: 5 }} />
)

type PricingProps = {
    isDisabled: boolean
    isSubmitting: boolean
    isUploadDialogOpen: boolean
    numPallets?: number
    weight?: number
    length?: number
    width?: number
    height?: number
    selectedOriginPlaceId?: string
    selectedDestinationPlaceId?: string
    allRates: Rate[]
    featuredRates: Rate[]
    selectedRate?: Rate
    poFileUrl?: string
    onChangeNumPallets: (numPallets?: number) => void
    onChangeWeight: (weight?: number) => void
    onChangeLength: (length?: number) => void
    onChangeWidth: (width?: number) => void
    onChangeHeight: (height?: number) => void
    onChangeOrigin: (placeId?: string) => void
    onChangeDestination: (placeId?: string) => void
    onGetQuotes: () => void
    onSelectQuote: (id: string) => void
    onUploadPO: (files: File[]) => Promise<void>
    onChangeIsUploadDialogOpen: (isOpen: boolean) => void
}

const Pricing = ({
    isDisabled,
    isSubmitting,
    isUploadDialogOpen,
    numPallets,
    weight,
    length,
    width,
    height,
    selectedOriginPlaceId,
    selectedDestinationPlaceId,
    allRates,
    featuredRates,
    selectedRate,
    poFileUrl,
    onChangeNumPallets,
    onChangeWeight,
    onChangeLength,
    onChangeWidth,
    onChangeHeight,
    onChangeOrigin,
    onChangeDestination,
    onGetQuotes,
    onSelectQuote,
    onUploadPO,
    onChangeIsUploadDialogOpen,
}: PricingProps) => {
    const [isShowingAllQuotes, setIsShowingAllQuotes] = useState(false)
    const [dimensions, ref] = useDimensions()
    const [isPoDialogOpen, setIsPoDialogOpen] = useState(false)

    const isPoUploaded = !!poFileUrl

    const placeIds: string[] = [
        selectedOriginPlaceId,
        selectedDestinationPlaceId,
    ].filter(Boolean) as string[]

    const _onChangeIsUploadDialogOpen = (isOpen: boolean) => () => {
        onChangeIsUploadDialogOpen(isOpen)
    }

    return (
        <>
            <UploadFilesDialog
                isOpen={isUploadDialogOpen}
                onClose={_onChangeIsUploadDialogOpen(false)}
                onUpload={onUploadPO}
            />
            <PoViewerDialog
                isOpen={isPoDialogOpen}
                fileUrl={poFileUrl}
                onClose={() => setIsPoDialogOpen(false)}
            />
            <StyledPricing isQuoteSelected={!!selectedRate?.id}>
                <div className="pricing__header">
                    <Typography color="primary" variant="h1">
                        Pricing calculator
                    </Typography>
                </div>
                <div className="pricing__inputs">
                    <div className="pricing__inputs__left">
                        <span className="pricing__inputs__left__text">
                            <Typography color="primary" variant="body1">
                                Where from?
                            </Typography>
                        </span>
                        <span className="pricing__inputs__left__search">
                            <AddressSearchInput
                                placeholder="Postal code or city"
                                styles={{
                                    menu: { width: '40rem' },
                                    control: { minHeight: '4.8rem' },
                                }}
                                value={selectedOriginPlaceId}
                                // @ts-ignore
                                Icon={MapPinHouseIcon}
                                onChange={(address) =>
                                    onChangeOrigin(address?.placeId)
                                }
                            />
                        </span>
                        <span className="pricing__inputs__left__text">
                            <Typography color="primary" variant="body1">
                                Where to?
                            </Typography>
                        </span>
                        <span className="pricing__inputs__left__search">
                            <AddressSearchInput
                                placeholder="Postal code or city"
                                styles={{
                                    menu: { width: '40rem' },
                                    control: { minHeight: '4.8rem' },
                                }}
                                value={selectedDestinationPlaceId}
                                // @ts-ignore
                                Icon={MapPinCheckIcon}
                                onChange={(address) =>
                                    onChangeDestination(address?.placeId)
                                }
                            />
                        </span>
                        <FreightDetails
                            numPallets={numPallets}
                            length={length}
                            width={width}
                            height={height}
                            weight={weight}
                            onChangeNumPallets={onChangeNumPallets}
                            onChangeLength={onChangeLength}
                            onChangeWidth={onChangeWidth}
                            onChangeHeight={onChangeHeight}
                            onChangeWeight={onChangeWeight}
                        />
                        <StyledGetQuoteButton
                            isFullWidth
                            isDisabled={isDisabled}
                            isSubmitting={isSubmitting}
                            variant="confirm"
                            onClick={onGetQuotes}
                        >
                            <Search size={16} />
                            Get a freight quote
                        </StyledGetQuoteButton>
                    </div>
                    <div className="pricing__inputs__right">
                        <Button
                            variant="secondary"
                            onClick={_onChangeIsUploadDialogOpen(true)}
                        >
                            <Plus size={16} /> Upload PO
                        </Button>
                        {isPoUploaded && (
                            <StyledIconButton
                                onClick={() => setIsPoDialogOpen(true)}
                            >
                                <File
                                    size={16}
                                    color={DESIGN_TOKENS.secondaryForeground}
                                />
                            </StyledIconButton>
                        )}
                    </div>
                </div>
                <div className="pricing__results" ref={ref}>
                    <Map
                        placeIds={placeIds}
                        styles={{
                            borderRadius: '0.8rem',
                            height: dimensions.height,
                            border: `0.1rem solid ${DESIGN_TOKENS.input}`,
                        }}
                    />
                    <div className="pricing__results__quotes">
                        <div className="pricing__results__quotes__header">
                            <Typography isBold color="primary" variant="h4">
                                Carrier quotes
                            </Typography>
                        </div>
                        {featuredRates.length > 0 ? (
                            <div className="pricing__results__quotes__scroll">
                                {featuredRates.map((rate) => (
                                    <Quote
                                        isSelected={
                                            selectedRate?.id === rate.id
                                        }
                                        rate={rate}
                                        onClick={() => onSelectQuote(rate.id)}
                                    />
                                ))}
                                {allRates.length > 0 && (
                                    <>
                                        <StyledQuoteResultsButton
                                            variant="ghost"
                                            size="sm"
                                            onClick={() =>
                                                setIsShowingAllQuotes(
                                                    (prev) => !prev
                                                )
                                            }
                                        >
                                            {isShowingAllQuotes
                                                ? '- Collapse'
                                                : '+ See all'}
                                        </StyledQuoteResultsButton>
                                        {isShowingAllQuotes && (
                                            <>
                                                {allRates.map((rate) => (
                                                    <Quote
                                                        isSelected={
                                                            selectedRate?.id ===
                                                            rate.id
                                                        }
                                                        rate={rate}
                                                        onClick={() =>
                                                            onSelectQuote(
                                                                rate.id
                                                            )
                                                        }
                                                    />
                                                ))}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        ) : (
                            <div className="pricing__results__quotes__noResults">
                                {isSubmitting && (
                                    <CircularProgress
                                        size={48}
                                        style={{ color: BASE_TOKENS.grey[700] }}
                                    />
                                )}
                                <Typography color="secondary" variant="body1">
                                    No quotes to show
                                </Typography>
                            </div>
                        )}
                        {selectedRate?.id && (
                            <SelectedQuoteSummary
                                height={QUOTES_SELECT_HEIGHT}
                                rate={selectedRate}
                            />
                        )}
                    </div>
                </div>
            </StyledPricing>
        </>
    )
}

export default Pricing
