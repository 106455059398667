import { Book } from 'lucide-react'
import OrderEventControl from './OrderEventControl'

type OrderBookedEventProps = {
    bookedAt: string
}

const OrderBookedEvent = ({ bookedAt }: OrderBookedEventProps) => {
    return (
        <OrderEventControl
            title="Order booked"
            timestamp={bookedAt}
            Icon={Book}
        />
    )
}

export default OrderBookedEvent
