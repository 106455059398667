import { useEffect, useState } from 'react'
import { MultiValue } from 'react-select'
import { LucideIcon } from 'lucide-react'
import BaseSelect from './components/BaseSelect'
import { SelectOption } from './select.types'

// Define types with conditional logic for onSelectedOptionChanged
type SingleSelectProps = {
    isMulti?: false
    value?: string
    onSelectedOptionChanged: (value: string) => void
}

type MultiSelectProps = {
    isMulti: true
    value?: string[]
    onSelectedOptionChanged: (value: string[]) => void
}

// Union type for SelectProps to handle both cases
type SelectProps = (SingleSelectProps | MultiSelectProps) & {
    isLoading?: boolean
    isDisabled?: boolean
    isCellStyle?: boolean
    shouldPortalMenu?: boolean
    shouldFocusOnMount?: boolean
    shouldHideIndicators?: boolean
    label?: string
    placeholder?: string
    description?: string
    error?: string
    menuPlacement?: 'auto' | 'bottom' | 'top'
    options: SelectOption[]
    Icon?: LucideIcon
}

const Select = ({
    isLoading,
    isDisabled,
    isCellStyle,
    shouldPortalMenu,
    shouldFocusOnMount,
    shouldHideIndicators,
    isMulti,
    value,
    label,
    placeholder,
    description,
    error,
    menuPlacement,
    options,
    Icon,
    onSelectedOptionChanged,
}: SelectProps) => {
    const [inputValue, setInputValue] = useState('')
    const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([])

    const _onChange = (newValue: MultiValue<SelectOption>) => {
        const selectedOption = Array.isArray(newValue) ? newValue : [newValue]
        setSelectedOptions(selectedOption)
        const selectedValues = selectedOption.map((option) => option.value)
        if (isMulti) {
            onSelectedOptionChanged(selectedValues) // string[]
        } else {
            onSelectedOptionChanged(selectedValues[0]) // string
        }
    }

    useEffect(() => {
        if (!value || !options.length) {
            return
        }

        if (isMulti && Array.isArray(value)) {
            // Handle multi-select case
            const selected = options.filter((option) =>
                value.includes(option.value)
            )
            setSelectedOptions(selected)
        } else if (!isMulti && typeof value === 'string') {
            // Handle single-select case
            const selectedOption = options.find(
                (option) => option.value === value
            )
            if (selectedOption) {
                setSelectedOptions([selectedOption])
            }
        }
    }, [value, options, isMulti])

    return (
        <BaseSelect
            isMulti={isMulti ? true : undefined}
            isLoading={!!isLoading}
            isDisabled={!!isDisabled}
            isCellStyle={!!isCellStyle}
            shouldPortalMenu={!!shouldPortalMenu}
            shouldFocusOnMount={!!shouldFocusOnMount}
            shouldHideIndicators={!!shouldHideIndicators}
            inputValue={inputValue}
            label={label}
            placeholder={placeholder}
            description={description}
            error={error}
            menuPlacement={menuPlacement}
            options={options}
            selectedOptions={selectedOptions}
            Icon={Icon}
            onChange={_onChange}
            onInputChange={setInputValue}
        />
    )
}

export default Select
