import { useMutation } from 'react-query'
import { getCTSDK } from 'services/ctClient'

const ctSDK = getCTSDK()

export const useDeleteReceivable = () => {
    return useMutation((id: string) => ctSDK.post(`/receivables/${id}/delete`))
}

export const useProcessReceivables = () => {
    return useMutation((receivableIds: string[]) =>
        ctSDK.post('/receivables/process', { receivableIds })
    )
}

export const useMarkReceivablesAsProcessed = () => {
    return useMutation((receivableIds: string[]) =>
        ctSDK.post('/receivables/markAsProcessed', { receivableIds })
    )
}
