import { Tracking } from './components/Tracking'
import { BASE_TOKENS } from 'constants/color.constants'
import CircularProgress from '@mui/material/CircularProgress'
import { useGetTracking } from 'queries/tracking.queries'
import { NoTrackingInfoFound } from './components/NoTrackingInfoFound'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { SPACING } from 'constants/spacing.constants'

const TrackingContainer = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const trackingToken = searchParams.get('trackingToken')

    const { isLoading, data } = useGetTracking({ trackingToken })

    useEffect(() => {
        if (!trackingToken) {
            navigate('/')
        }
    }, [trackingToken, navigate])

    if (isLoading) {
        return (
            <CircularProgress
                size={SPACING.p5}
                style={{ color: BASE_TOKENS.grey[700] }}
            />
        )
    }

    if (!data?.data.order) {
        return <NoTrackingInfoFound />
    }

    return <Tracking order={data?.data.order} />
}

export default TrackingContainer
