import styled from '@emotion/styled'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import { pxToRem } from 'utils/rem.utils'
import { Rate } from '../PricingContainer'
import Typography from 'components/core/Typography'
import { Truck } from 'lucide-react'
import Button from 'components/core/Button'
import { getImageUrl } from 'utils/getImageUrl'

const StyledLogoContainer = styled.div`
    width: 3.6rem;
    height: 3.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #1e1e1e;

    .container__logo {
        height: 2.8rem;
        width: 2.8rem;
    }
`

const StyledIconContainer = styled.div`
    width: 3.6rem;
    height: 3.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${BASE_TOKENS.grey[200]};
`

const StyledSelectedQuoteSummary = styled.div<{ height: number }>`
    height: ${(p) => pxToRem(p.height)};
    border-top: 0.1rem solid ${DESIGN_TOKENS.input};
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .summary__row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .summary__row__left {
            display: flex;
            align-items: center;
            column-gap: 1.2rem;

            .summary__row__left__icon {
                height: 3.6rem;
                width: 3.6rem;
            }
        }
    }
`

type SelectedQuoteSummaryProps = {
    height: number
    rate: Rate
}

const SelectedQuoteSummary = ({ height, rate }: SelectedQuoteSummaryProps) => {
    const total = rate.ctFee + rate.carrierRate

    return (
        <StyledSelectedQuoteSummary height={height}>
            <div className="summary__row">
                <span className="summary__row__left">
                    <StyledLogoContainer>
                        <img
                            alt="Logo"
                            className="container__logo"
                            src={getImageUrl('newShortLogo.png')}
                        />
                    </StyledLogoContainer>
                    <Typography color="primary" variant="body1">
                        Handling fee
                    </Typography>
                </span>
                <Typography color="primary" variant="body1">
                    +${rate.ctFee?.toFixed(2)}
                </Typography>
            </div>
            <div className="summary__row">
                <span className="summary__row__left">
                    <StyledIconContainer>
                        <Truck fill={BASE_TOKENS.grey[100]} />
                    </StyledIconContainer>
                    <Typography isBold color="primary" variant="body1">
                        Total
                    </Typography>
                </span>
                <Typography isBold color="primary" variant="body1">
                    ${total.toFixed(2)}
                </Typography>
            </div>
            <Button isFullWidth variant="confirm" onClick={() => undefined}>
                Confirm shipment
            </Button>
        </StyledSelectedQuoteSummary>
    )
}

export default SelectedQuoteSummary
