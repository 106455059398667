import styled from '@emotion/styled'
import SettingsTab from '../../SettingsTab'
import Card from 'components/composite/Card'
import AccountingAuthButton from 'integrations/accounting/components/AccountingAuthButton'

const StyledImage = styled.img`
    width: 3.2rem;
    height: 3.2rem;
`

const IntegrationSettings = () => {
    return (
        <SettingsTab
            title="Integrations"
            description="These settings control your connections to other systems"
        >
            <Card
                title="Xero"
                subtitle="This integration allows Cartage to make updates to bills and invoices in your accounting system"
                HeaderIcon={
                    <StyledImage
                        alt="Xero logo"
                        src={`${process.env.PUBLIC_URL}/xeroLogo.png`}
                    />
                }
                HeaderInput={<AccountingAuthButton />}
            />
        </SettingsTab>
    )
}

export default IntegrationSettings
