import styled from '@emotion/styled'
import { Order } from 'types/order.types'
import { AddressesInfo } from './AddressesInfo'
import { CarrierInfo } from './CarrierInfo'
import { CommoditiesTable } from './CommoditiesTable'
import Column from 'components/core/Column'
import { OrderProgress } from './OrderProgress'
import { SPACING } from 'constants/spacing.constants'
import { DESIGN_TOKENS } from 'constants/color.constants'
import { useDimensions } from 'hooks/useDimensions'
import Map from 'components/composite/Map'
import { SCREEN_SIZES } from 'constants/screenSizes.constants'
import { MODULE_HEADER_HEIGHT, NAV_HEIGHT } from 'constants/app.constants'
import { pxToRem } from 'utils/rem.utils'
import Typography from 'components/core/Typography'
import { getImageUrl } from 'utils/getImageUrl'
import { useMemo } from 'react'

const MODULE_PADDING = 32

const StyledTracking = styled.div`
    .tracking__nav {
        height: ${pxToRem(NAV_HEIGHT)};
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: ${SPACING.p2};
        padding-right: ${SPACING.p2};
        border-bottom: 1px solid ${DESIGN_TOKENS.border.borderInput};

        .tracking__nav__logo {
            height: 2.8rem;
        }
    }

    .tracking__main {
        display: flex;
        flex-direction: column;
        padding-top: ${pxToRem(MODULE_PADDING)};
        padding-bottom: ${pxToRem(MODULE_PADDING)};
        padding-left: ${pxToRem(MODULE_PADDING)};
        padding-right: ${pxToRem(MODULE_PADDING)};

        .tracking__main__header {
            height: ${pxToRem(MODULE_HEADER_HEIGHT)};
        }

        .tracking__main__body {
            display: flex;
            column-gap: ${SPACING.p4};

            @media (max-width: ${SCREEN_SIZES.lg}) {
                flex-direction: column;
                row-gap: ${SPACING.p4};
            }
        }
    }
`

const StyledMapContainer = styled(Column)`
    width: 50%;
    height: calc(
        100vh -
            ${pxToRem(MODULE_HEADER_HEIGHT + NAV_HEIGHT + MODULE_PADDING * 2)}
    );

    @media (max-width: ${SCREEN_SIZES.lg}) {
        width: 100%;
        height: ${pxToRem(300)};
    }
`

const StyledDetails = styled(Column)`
    width: 50%;
    height: calc(
        100vh -
            ${pxToRem(MODULE_HEADER_HEIGHT + NAV_HEIGHT + MODULE_PADDING * 2)}
    );
    row-gap: ${SPACING.p3};
    overflow-y: auto;

    @media (max-width: ${SCREEN_SIZES.lg}) {
        width: 100%;
        height: 100%;
    }
`

type TrackingProps = { order?: Order }

export const Tracking = ({ order }: TrackingProps) => {
    const [dimensions, ref] = useDimensions()

    const placeIds = useMemo(() => {
        // Extract placeIds from extraPickups and extraDropoffs, if they exist
        const extraPickupPlaceIds =
            order?.extraPickups
                ?.map((pickup) => pickup.placeId)
                .filter(Boolean) || []
        const extraDropoffPlaceIds =
            order?.extraDropoffs
                ?.map((dropoff) => dropoff.placeId)
                .filter(Boolean) || []

        // Combine placeIds from pickup, dropoff, extraPickups, and extraDropoffs
        return [
            order?.pickup?.placeId,
            ...extraPickupPlaceIds,
            order?.dropoff?.placeId,
            ...extraDropoffPlaceIds,
        ].filter(Boolean)
    }, [
        order?.pickup?.placeId,
        order?.dropoff?.placeId,
        order?.extraPickups,
        order?.extraDropoffs,
    ]) as string[]

    return (
        <Column>
            <StyledTracking>
                <div className="tracking__nav">
                    <img
                        alt="Logo"
                        className="tracking__nav__logo"
                        src={getImageUrl('newShortLogo.png')}
                    />
                </div>
                <div className="tracking__main">
                    <div className="tracking__main__header">
                        <Typography color="primary" variant="h1">
                            Order ID: {order?.fullId}
                        </Typography>
                    </div>
                    <div className="tracking__main__body">
                        <StyledMapContainer ref={ref}>
                            <Map
                                placeIds={placeIds}
                                styles={{
                                    borderRadius: SPACING.p1,
                                    height: dimensions.height,
                                    border: `1px solid ${DESIGN_TOKENS.input}`,
                                }}
                            />
                        </StyledMapContainer>
                        <StyledDetails>
                            <OrderProgress order={order} />
                            <CommoditiesTable
                                commodities={order?.commodities}
                            />
                            <AddressesInfo order={order} />
                            <CarrierInfo order={order} />
                        </StyledDetails>
                    </div>
                </div>
            </StyledTracking>
        </Column>
    )
}
