import { DetailsRow } from './DetailsRow'
import { OrderChangeProp } from '../orderDetails.types'
import { Order } from 'types/order.types'
import { EquipmentSelect } from 'components/composite/EquipmentSelect'
import { EquipmentType } from 'types/equipment.types'
import { getEquipmentNames } from 'utils/equipment.utils'

type OrderDetailsEquipmentSectionProps = {
    isEditable: boolean
    order?: Order
    orderMutation?: Partial<Order>
    onClosePopup: (propName: OrderChangeProp) => void
    onChangeOrder: (orderMutation: Partial<Order>) => void
}

export const OrderDetailsEquipmentSection = ({
    isEditable,
    order,
    orderMutation,
    onClosePopup,
    onChangeOrder,
}: OrderDetailsEquipmentSectionProps) => {
    const { equipment } = order || {}
    const { equipment: equipmentMutation } = orderMutation || {}

    const _onClosePopup = (propName: OrderChangeProp) => () => {
        onClosePopup(propName)
    }

    const _onChangeEquipment = (equipment: EquipmentType[]) => {
        onChangeOrder({ equipment })
    }

    return (
        <DetailsRow
            isEditable={isEditable}
            caption="Equipment"
            valueText={getEquipmentNames(equipment)}
            onClosePopup={_onClosePopup('equipment')}
        >
            <EquipmentSelect
                equipment={equipmentMutation}
                onChange={_onChangeEquipment}
            />
        </DetailsRow>
    )
}
