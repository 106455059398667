import styled from '@emotion/styled'
import * as RadixNavigationMenu from '@radix-ui/react-navigation-menu'
import Typography from 'components/core/Typography'
import { pxToRem } from 'utils/rem.utils'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import { MODULE_HEADER_HEIGHT, NAV_HEIGHT } from 'constants/app.constants'
import PayableSettings from './PayableSettings'
import ReceivableSettings from './ReceivableSettings'
import AutomationSettings from './AutomationSettings'
import { TabId } from '../settings.types'
import IntegrationSettings from './IntegrationSettings'

const emptyFunc = () => undefined

const SPACER_HEIGHT = 40
const SUB_HEADER_HEIGHT = 36

const StyledSettings = styled.div`
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    display: flex;
    flex-direction: column;
    height: 100%;

    .settings__header {
        height: ${pxToRem(MODULE_HEADER_HEIGHT)};
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .settings__subHeader {
        height: ${pxToRem(SUB_HEADER_HEIGHT)};
        display: flex;
        align-items: flex-end;
    }

    .settings__spacer {
        height: ${pxToRem(SPACER_HEIGHT)};
    }

    .settings__body {
        height: calc(
            100vh - ${pxToRem(NAV_HEIGHT)} - ${pxToRem(MODULE_HEADER_HEIGHT)} -
                ${pxToRem(SUB_HEADER_HEIGHT)} - ${pxToRem(SPACER_HEIGHT)}
        );
        display: flex;
        column-gap: 3.2rem;

        .settings__body__nav {
            min-width: 24rem;
        }
    }
`

const StyledNavigationMenuList = styled(RadixNavigationMenu.List)`
    list-style: none;
    padding: 0;
    margin: 0;
`

const StyledNavigationMenuTrigger = styled(RadixNavigationMenu.Trigger)<{
    isSelected: boolean
}>`
    width: 100%;
    height: 4rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;

    border: none;
    border-radius: 0.6rem;

    cursor: pointer;

    background-color: ${(p) =>
        p.isSelected ? DESIGN_TOKENS.muted : BASE_TOKENS.grey[0]};
    transition: background-color 0.3s ease;

    &:disabled {
        cursor: default;
    }

    &:hover {
        ${(p) => !p.isSelected && `background-color: ${BASE_TOKENS.grey[50]};`}
    }
`

const TAB_COMPONENTS_MAP = {
    payables: <PayableSettings />,
    receivables: <ReceivableSettings />,
    automations: <AutomationSettings />,
    integrations: <IntegrationSettings />,
}

type SettingsProps = {
    isAutomationsFFEnabled: boolean
    isReceivablesFFEnabled: boolean
    selectedTabId: TabId
    onSetSelectedTabId: (tabId: TabId) => void
}

const Settings = ({
    isAutomationsFFEnabled,
    isReceivablesFFEnabled,
    selectedTabId,
    onSetSelectedTabId = emptyFunc,
}: SettingsProps) => {
    const _onSetSelectedTabId = (tabId: TabId) => () => {
        onSetSelectedTabId(tabId)
    }

    return (
        <StyledSettings>
            <div className="settings__header">
                <Typography color="primary" variant="h1">
                    Settings
                </Typography>
            </div>
            <div className="settings__subHeader">
                <Typography color="secondary" variant="body1">
                    Manage your automation settings and preferences
                </Typography>
            </div>
            <div className="settings__spacer" />
            <div className="settings__body">
                <span className="settings__body__nav">
                    <RadixNavigationMenu.Root>
                        <StyledNavigationMenuList>
                            <RadixNavigationMenu.Item>
                                <StyledNavigationMenuTrigger
                                    isSelected={selectedTabId === 'payables'}
                                    onClick={_onSetSelectedTabId('payables')}
                                >
                                    Payables
                                </StyledNavigationMenuTrigger>
                            </RadixNavigationMenu.Item>
                            {isReceivablesFFEnabled && (
                                <RadixNavigationMenu.Item>
                                    <StyledNavigationMenuTrigger
                                        isSelected={
                                            selectedTabId === 'receivables'
                                        }
                                        onClick={_onSetSelectedTabId(
                                            'receivables'
                                        )}
                                    >
                                        Receivables
                                    </StyledNavigationMenuTrigger>
                                </RadixNavigationMenu.Item>
                            )}
                            {isAutomationsFFEnabled && (
                                <RadixNavigationMenu.Item>
                                    <StyledNavigationMenuTrigger
                                        isSelected={
                                            selectedTabId === 'automations'
                                        }
                                        onClick={_onSetSelectedTabId(
                                            'automations'
                                        )}
                                    >
                                        Tasks
                                    </StyledNavigationMenuTrigger>
                                </RadixNavigationMenu.Item>
                            )}
                            <RadixNavigationMenu.Item>
                                <StyledNavigationMenuTrigger
                                    isSelected={
                                        selectedTabId === 'integrations'
                                    }
                                    onClick={_onSetSelectedTabId(
                                        'integrations'
                                    )}
                                >
                                    Integrations
                                </StyledNavigationMenuTrigger>
                            </RadixNavigationMenu.Item>
                        </StyledNavigationMenuList>
                    </RadixNavigationMenu.Root>
                </span>
                {TAB_COMPONENTS_MAP[selectedTabId]}
            </div>
        </StyledSettings>
    )
}

export default Settings
