import { Receivable, ReceivableStatus } from 'types/receivable.types'

export const canReceivableBeInvoiced = (status?: ReceivableStatus): boolean => {
    return !!status && ['readyToSend'].includes(status)
}

export const canReceivableBeMarkedAsInvoiced = (
    status?: ReceivableStatus
): boolean => {
    return (
        !!status &&
        ['requiresReview', 'manualProcessingEnforced'].includes(status)
    )
}

export const canReceivableBeDeleted = (status?: ReceivableStatus): boolean => {
    return (
        !!status &&
        [
            'requiresReview',
            'readyToSend',
            'manualProcessingEnforced',
            'error',
        ].includes(status)
    )
}

export const mapReceivableProcessErrorReasonToNote = (
    reason: string | null
): string => {
    switch (reason) {
        case 'qbGetInvoice':
            return 'QBO auth error'
        case 'qbExportInvoice':
            return 'Export error'
        case 'autoProcessingDisabled':
            return 'Auto-processing disabled'
        default:
            return '--'
    }
}

export const splitSelectedReceivableIdsByStatus = (
    selectedReceivableIds: string[],
    receivables: Receivable[]
): {
    receivableIdsToProcess: string[]
    receivableIdsToMarkAsProcessed: string[]
} => {
    const { receivableIdsToProcess, receivableIdsToMarkAsProcessed } =
        selectedReceivableIds.reduce(
            (
                acc: {
                    receivableIdsToProcess: string[]
                    receivableIdsToMarkAsProcessed: string[]
                },
                id: string
            ) => {
                const receivableStatus = receivables.find(
                    (p) => p.id === id
                )?.status

                if (
                    !receivableStatus ||
                    (!canReceivableBeInvoiced(receivableStatus) &&
                        !canReceivableBeMarkedAsInvoiced(receivableStatus))
                ) {
                    return acc
                }

                if (canReceivableBeInvoiced(receivableStatus)) {
                    acc.receivableIdsToProcess.push(id)
                } else if (canReceivableBeMarkedAsInvoiced(receivableStatus)) {
                    acc.receivableIdsToMarkAsProcessed.push(id)
                }

                return acc
            },
            {
                receivableIdsToProcess: [],
                receivableIdsToMarkAsProcessed: [],
            }
        )

    return { receivableIdsToProcess, receivableIdsToMarkAsProcessed }
}
