import { Destination, Order } from 'types/order.types'
import { OrderDetailsDestinationSection } from './OrderDetailsDestinationSection'

type OrderDetailsExtraDestinationSectionProps = {
    extraDestinations?: Destination[]
    destinationLabel: string
    addressLabel: string
}

export const OrderDetailsExtraDestinationSection = ({
    extraDestinations,
    destinationLabel,
    addressLabel,
}: OrderDetailsExtraDestinationSectionProps) => {
    if (!extraDestinations) {
        return null
    }

    return (
        <>
            {extraDestinations.map((_, index) => (
                <div
                    key={index}
                    className="details__content__right__body__section"
                >
                    <OrderDetailsDestinationSection
                        destination={extraDestinations?.[index]}
                        destinationLabel={destinationLabel}
                        addressLabel={addressLabel}
                    />
                </div>
            ))}
        </>
    )
}
