import styled from '@emotion/styled'
import { LucideProps, Minus, Trash2 } from 'lucide-react'
import Popover from 'components/core/Popover'
import 'react-day-picker/style.css'
import { JSXElementConstructor } from 'react'
import Separator from 'components/core/Separator'
import { formatDateTimeRangeString } from 'utils/date.utils'
import Column from 'components/core/Column'
import Row from 'components/core/Row'
import {
    changeDatePreservingTime,
    convertDateRangeToStringNoTz,
} from './utils/datePicker.utils'
import { StyledDayPicker } from './components/StyledDayPicker'
import { Chevron } from './components/Chevron'
import { StyledLabel } from './components/StyledLabel'
import { SPACING } from 'constants/spacing.constants'
import { TimePicker } from '../TimePicker'
import { DateTimeButton } from './components/DateTimeButton'
import IconButton from 'components/core/IconButton'
import {
    ChangeDateRangePickerValue,
    ChangeDateStringRangePickerValue,
    DateRangePickerValue,
    DefaultDateRangePickerValue,
} from './types/datePicker.types'

const StyledDateRangePickerContainer = styled(Column)`
    row-gap: ${SPACING.sm};
`

const StyledPopoverTrigger = styled.button`
    width: 100%;
`

const StyledPopoverFooter = styled(Row)`
    justify-content: space-between;
`

const StyledIconButton = styled(IconButton)`
    padding-left: ${SPACING.p2};
    padding-right: ${SPACING.p2};
`

type DateRangePickerProps = {
    isEditable?: boolean
    hasTime?: boolean
    label?: string
    value?: DateRangePickerValue
    placeholder?: string
    align?: 'center' | 'end' | 'start'
    defaultDateTimeLabelFormat?: string
    Icon?: JSXElementConstructor<LucideProps>
    ButtonComponent?: JSXElementConstructor<any>
    onChange: (
        dateRange: ChangeDateRangePickerValue,
        dateStringRange: ChangeDateStringRangePickerValue
    ) => void
    onClose?: () => void
}

const emptyFunc = () => undefined

export const DateRangePicker = ({
    isEditable,
    hasTime,
    label,
    value,
    placeholder,
    align = 'center',
    Icon,
    ButtonComponent,
    onChange,
    onClose = emptyFunc,
}: DateRangePickerProps) => {
    let valueDateRange: DefaultDateRangePickerValue
    if (value) {
        let valueFrom: Date | undefined
        if (typeof value.from === 'string') {
            valueFrom = new Date(value.from)
        } else {
            valueFrom = value.from || undefined
        }

        let valueTo: Date | undefined
        if (typeof value.to === 'string') {
            valueTo = new Date(value.to)
        } else {
            valueTo = value.to || undefined
        }

        valueDateRange = {
            from: valueFrom,
            to: valueTo,
        }
    }

    const dateText = formatDateTimeRangeString({
        from: value?.from,
        to: value?.to,
        hasTime,
    })

    const _onChangeTime = (type: 'from' | 'to') => (date: Date | undefined) => {
        if (!date) return

        const newDateRange = {
            from: valueDateRange?.from,
            to: valueDateRange?.to,
        }

        newDateRange[type] = date

        onChange(newDateRange, convertDateRangeToStringNoTz(newDateRange))
    }

    const _onClearDate = () => {
        onChange({ to: null, from: null }, { to: null, from: null })
    }

    /**
     * carry over the current time when a user clicks a new day
     * instead of resetting to 00:00
     */
    const _onSelect = (newDateRange: DefaultDateRangePickerValue) => {
        if (!newDateRange) return

        if (!valueDateRange) {
            onChange(newDateRange, convertDateRangeToStringNoTz(newDateRange))
            return
        }

        let newFromDateFull = newDateRange.from
        let newToDateFull = newDateRange.to

        if (newDateRange.from && valueDateRange.from) {
            newFromDateFull = changeDatePreservingTime(
                valueDateRange.from,
                newDateRange.from
            )
        }

        if (newDateRange.to && valueDateRange.to) {
            newToDateFull = changeDatePreservingTime(
                valueDateRange.to,
                newDateRange.to
            )
        }

        const result = {
            from: newFromDateFull,
            to: newToDateFull,
        }

        onChange(result, convertDateRangeToStringNoTz(result))
    }

    if (!isEditable) {
        return (
            <StyledDateRangePickerContainer>
                {label && <StyledLabel>{label}</StyledLabel>}
                <StyledPopoverTrigger>
                    <DateTimeButton
                        isDisabled
                        placeholder={placeholder}
                        dateText={dateText}
                        Icon={Icon}
                        ButtonComponent={ButtonComponent}
                    />
                </StyledPopoverTrigger>
            </StyledDateRangePickerContainer>
        )
    }

    return (
        <StyledDateRangePickerContainer>
            {label && <StyledLabel>{label}</StyledLabel>}
            <Popover
                align={align}
                onClose={onClose}
                trigger={
                    <StyledPopoverTrigger>
                        <DateTimeButton
                            placeholder={placeholder}
                            dateText={dateText}
                            Icon={Icon}
                            ButtonComponent={ButtonComponent}
                        />
                    </StyledPopoverTrigger>
                }
            >
                <StyledDayPicker
                    mode="range"
                    numberOfMonths={2}
                    selected={valueDateRange}
                    onSelect={_onSelect}
                    components={{ Chevron }}
                />
                {(hasTime ||
                    !!valueDateRange?.to ||
                    !!valueDateRange?.from) && (
                    <>
                        <Separator />
                        <StyledPopoverFooter>
                            {hasTime && (
                                <Row>
                                    <TimePicker
                                        date={valueDateRange?.from}
                                        onChange={_onChangeTime('from')}
                                        Icon={Minus}
                                    />
                                    <TimePicker
                                        date={valueDateRange?.to}
                                        onChange={_onChangeTime('to')}
                                    />
                                </Row>
                            )}
                            {(valueDateRange?.to || valueDateRange?.from) && (
                                <StyledIconButton onClick={_onClearDate}>
                                    <Trash2 size={SPACING.p2} />
                                </StyledIconButton>
                            )}
                        </StyledPopoverFooter>
                    </>
                )}
            </Popover>
        </StyledDateRangePickerContainer>
    )
}
