import styled from '@emotion/styled'
import Typography from 'components/core/Typography'
import { SPACING } from 'constants/spacing.constants'

const StyledNoTrackingInfoFound = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${SPACING.p4};
`

export const NoTrackingInfoFound = () => {
    return (
        <StyledNoTrackingInfoFound>
            <Typography color="primary" variant="h3">
                No Tracking Information Found
            </Typography>
        </StyledNoTrackingInfoFound>
    )
}
