import { BASE_TOKENS } from 'constants/color.constants'
import { AutomationStatus, AutomationType } from 'types/automation.types'

export const AUTOMATION_STATUS_LABELS: { [key in AutomationStatus]: string } = {
    active: 'Active',
    completed: 'Completed',
    manualCompleted: 'Manual completed',
    requiresReview: 'Review',
    error: 'Error',
}

export const AUTOMATION_TYPE_LABELS: { [key in AutomationType]: string } = {
    tracking: 'Tracking',
    orderConfirmation: 'Confirmation',
}

export const AUTOMATION_STATUS_GROUP_IDS = {
    active: 'active',
    review: 'review',
    completed: 'completed',
    manual: 'manual',
}

export const AUTOMATION_STATUS_GROUP_LABELS = {
    [AUTOMATION_STATUS_GROUP_IDS.active]: 'Active',
    [AUTOMATION_STATUS_GROUP_IDS.review]: 'Review',
    [AUTOMATION_STATUS_GROUP_IDS.completed]: 'Completed',
    [AUTOMATION_STATUS_GROUP_IDS.manual]: 'Manual',
}

export const AUTOMATION_STATUS_GROUPS: { [key: string]: AutomationStatus[] } = {
    [AUTOMATION_STATUS_GROUP_IDS.active]: ['active'],
    [AUTOMATION_STATUS_GROUP_IDS.review]: ['requiresReview', 'error'],
    [AUTOMATION_STATUS_GROUP_IDS.completed]: ['completed'],
    [AUTOMATION_STATUS_GROUP_IDS.manual]: ['manualCompleted'],
}

export const AUTOMATION_STATUS_BACKGROUND_COLORS: {
    [key in AutomationStatus]: string
} = {
    active: BASE_TOKENS.blue[50],
    requiresReview: BASE_TOKENS.orange[50],
    completed: BASE_TOKENS.green[50],
    manualCompleted: BASE_TOKENS.green[50],
    error: BASE_TOKENS.red[50],
}

export const AUTOMATION_STATUS_BORDER_COLORS: {
    [key in AutomationStatus]: string
} = {
    active: BASE_TOKENS.blue[300],
    requiresReview: BASE_TOKENS.orange[300],
    completed: '#16A34A80',
    manualCompleted: '#16A34A80',
    error: BASE_TOKENS.red[300],
}

export const AUTOMATION_STATUS_TEXT_COLORS: {
    [key in AutomationStatus]: string
} = {
    active: BASE_TOKENS.blue[900],
    requiresReview: BASE_TOKENS.orange[900],
    completed: BASE_TOKENS.green[900],
    manualCompleted: BASE_TOKENS.green[900],
    error: BASE_TOKENS.red[900],
}
