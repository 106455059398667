import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCTSDK } from 'services/ctClient'
import { Outlet } from 'react-router-dom'

const ctSDK = getCTSDK()

const AdminRoute = () => {
    const navigate = useNavigate()

    useEffect(() => {
        const validateToken = async () => {
            try {
                await ctSDK.post('/admins/validate')
            } catch (error) {
                console.error(error)
                navigate('/')
            }
        }

        validateToken()
    }, [navigate])

    return <Outlet />
}

export default AdminRoute
