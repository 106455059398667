import { useQuery } from 'react-query'
import { getCTSDK } from 'services/ctClient'
import { ProofOfDelivery } from 'types/pod.types'

const ctSDK = getCTSDK()

export const useGetPODs = (args: { documentId: string }, options?: any) => {
    return useQuery(
        ['useGetPODs', args.documentId],
        async () =>
            await ctSDK.get<{ pods: ProofOfDelivery[] }>(`/pods`, {
                params: { documentId: args.documentId },
            }),
        options
    )
}

export const useGetPODById = (podId?: string, options?: any) => {
    return useQuery(
        ['useGetPODById', podId],
        async () => await ctSDK.get<{ pod: ProofOfDelivery }>(`/pods/${podId}`),
        options
    )
}
